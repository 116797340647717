<template>
    <main>
        <div class="under-line"></div>
        <div class="contents-wrapper">
            <!-- background img -->
            <div class="image-box">
                <img src="@/assets/img/last-level-back.png" alt="background" />
            </div>
            <!-- ending text -->
            <div class="item-wrap">
                <p>{{ endingText }}</p>
            </div>
            <!-- button -->
            <button class="btn" @click="handleClick">{{ $t('message.next') }}</button>
        </div>
    </main>
</template>

<script>
import { outroEnglish, outroGerman } from "../../utils/level";
export default {
    data() {
        return {
            btnType: '', // yes or no
            lastLevel: {}, // last level array
            outro: {}, // outro array
            endingText: '', // ending text by type
        };
    },
    mounted() {
        this.btnType = this.$route.params.type;
        if (this.$i18n.locale === 'en') {
            this.lastLevel = outroEnglish.pop();
        } else {
            this.lastLevel = outroGerman.pop();
        }
        this.outro = this.lastLevel.outro;
        this.endingText = this.outro[this.btnType];
    },
    methods: {
        handleClick() {
            this.$router.push({ name: 'GameDone' });
        },
    },
};
</script>

<style scoped>
main {
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.contents-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 3rem 2rem;
    gap: 2.5rem;
}
.image-box {
    width: 100%;
    max-width: 450px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}
.item-wrap {
    width: 100%;
    padding: 2rem;
}
img {
    width: 100%;
    height: 100%;
    opacity: 0.7;
}
p {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: 150%;
    word-break: keep-all;
}
</style>