<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 127 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.77106 139.407C6.89691 139.407 8.62025 137.684 8.62025 135.558C8.62025 133.432 6.89691 131.709 4.77106 131.709C2.64522 131.709 0.921875 133.432 0.921875 135.558C0.921875 137.684 2.64522 139.407 4.77106 139.407Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M122.154 149.031C124.28 149.031 126.003 147.308 126.003 145.182C126.003 143.056 124.28 141.333 122.154 141.333C120.028 141.333 118.305 143.056 118.305 145.182C118.305 147.308 120.028 149.031 122.154 149.031Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M58.6851 36.4494C60.811 36.4494 62.5343 34.726 62.5343 32.6002C62.5343 30.4743 60.811 28.751 58.6851 28.751C56.5593 28.751 54.8359 30.4743 54.8359 32.6002C54.8359 34.726 56.5593 36.4494 58.6851 36.4494Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M62.5054 8.54115C64.6313 8.54115 66.3546 6.81781 66.3546 4.69196C66.3546 2.56611 64.6313 0.842773 62.5054 0.842773C60.3796 0.842773 58.6562 2.56611 58.6562 4.69196C58.6562 6.81781 60.3796 8.54115 62.5054 8.54115Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M82.7164 100.924C84.8422 100.924 86.5656 99.2006 86.5656 97.0748C86.5656 94.9489 84.8422 93.2256 82.7164 93.2256C80.5905 93.2256 78.8672 94.9489 78.8672 97.0748C78.8672 99.2006 80.5905 100.924 82.7164 100.924Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M4.28906 136.046L25.4596 148.555L39.8941 130.272L66.8384 133.159L101.481 140.857L121.689 145.669L108.217 128.347L82.2351 97.5538L79.3482 76.3832L62.0269 53.2881L58.1777 32.1176L46.6301 23.4569L47.5924 11.9093L62.0269 4.21094L76.4613 9.98472L80.3105 26.3438L67.8007 36.929L58.1777 32.1176"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M25.4565 150.565C26.5673 150.565 27.4677 149.665 27.4677 148.554C27.4677 147.443 26.5673 146.543 25.4565 146.543C24.3458 146.543 23.4453 147.443 23.4453 148.554C23.4453 149.665 24.3458 150.565 25.4565 150.565Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M39.9097 132.284C41.0204 132.284 41.9208 131.384 41.9208 130.273C41.9208 129.162 41.0204 128.262 39.9097 128.262C38.7989 128.262 37.8984 129.162 37.8984 130.273C37.8984 131.384 38.7989 132.284 39.9097 132.284Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M66.8159 135.171C67.9267 135.171 68.8271 134.27 68.8271 133.16C68.8271 132.049 67.9267 131.148 66.8159 131.148C65.7052 131.148 64.8047 132.049 64.8047 133.16C64.8047 134.27 65.7052 135.171 66.8159 135.171Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M100.55 141.906C101.661 141.906 102.561 141.006 102.561 139.895C102.561 138.784 101.661 137.884 100.55 137.884C99.4395 137.884 98.5391 138.784 98.5391 139.895C98.5391 141.006 99.4395 141.906 100.55 141.906Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M108.207 130.353C109.317 130.353 110.218 129.453 110.218 128.342C110.218 127.232 109.317 126.331 108.207 126.331C107.096 126.331 106.195 127.232 106.195 128.342C106.195 129.453 107.096 130.353 108.207 130.353Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M79.3706 78.3935C80.4814 78.3935 81.3818 77.4931 81.3818 76.3823C81.3818 75.2716 80.4814 74.3711 79.3706 74.3711C78.2598 74.3711 77.3594 75.2716 77.3594 76.3823C77.3594 77.4931 78.2598 78.3935 79.3706 78.3935Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M62.0425 55.3027C63.1532 55.3027 64.0536 54.4023 64.0536 53.2915C64.0536 52.1807 63.1532 51.2803 62.0425 51.2803C60.9317 51.2803 60.0312 52.1807 60.0312 53.2915C60.0312 54.4023 60.9317 55.3027 62.0425 55.3027Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M80.3081 28.3593C81.4189 28.3593 82.3193 27.4589 82.3193 26.3481C82.3193 25.2374 81.4189 24.3369 80.3081 24.3369C79.1973 24.3369 78.2969 25.2374 78.2969 26.3481C78.2969 27.4589 79.1973 28.3593 80.3081 28.3593Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M76.4565 11.9941C77.5673 11.9941 78.4677 11.0937 78.4677 9.98291C78.4677 8.87215 77.5673 7.97168 76.4565 7.97168C75.3458 7.97168 74.4453 8.87215 74.4453 9.98291C74.4453 11.0937 75.3458 11.9941 76.4565 11.9941Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M67.8081 37.9833C68.9189 37.9833 69.8193 37.0829 69.8193 35.9722C69.8193 34.8614 68.9189 33.9609 67.8081 33.9609C66.6973 33.9609 65.7969 34.8614 65.7969 35.9722C65.7969 37.0829 66.6973 37.9833 67.8081 37.9833Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M47.5894 25.4687C48.7001 25.4687 49.6005 24.5683 49.6005 23.4575C49.6005 22.3468 48.7001 21.4463 47.5894 21.4463C46.4786 21.4463 45.5781 22.3468 45.5781 23.4575C45.5781 24.5683 46.4786 25.4687 47.5894 25.4687Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M47.5894 13.9199C48.7001 13.9199 49.6005 13.0194 49.6005 11.9087C49.6005 10.7979 48.7001 9.89746 47.5894 9.89746C46.4786 9.89746 45.5781 10.7979 45.5781 11.9087C45.5781 13.0194 46.4786 13.9199 47.5894 13.9199Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
