<template>
    <div :id="activeSlideIndex" class="carousel-container">
        <div
            class="slide"
            ref="slide"
            v-for="(slide, idx) in slides"
            :class="slide.position"
            :key="slide.id"
        >
            <img :src="slide.image" alt="" />
            <h3 class="title">{{ slide.title }}</h3>
            <p>{{ slide.dsc }}</p>

            <button
                @click="$emit('tutorialBtn')"
                v-if="idx === slides.length - 1"
                class="btn"
            >
                {{ $t("message.continue") }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activeSlideIndex: {
            type: Number,
        },
        slides: {
            type: Array,
            default: () => [
                {
                    id: 1,
                    title: "slider One",
                    image: "",
                    dsc: "something",
                },
                {
                    id: 2,
                    title: "slider two!",
                    image: "",
                    dsc: "something",
                },
                {
                    id: 3,
                    title: "slider three",
                    image: "",
                    dsc: "something",
                },
            ],
        },
    },
};
</script>

<style scoped>
.carousel-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    background: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}
.slide {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 1rem;
    font-size: 1.25rem;
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    transition: 0.3s all linear;
    background: var(--primary-light);
    border-radius: var(--border-radius-1);
}
img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
}
.title {
    margin: 1rem 0 0.5rem 0;
}
p {
    height: calc(60% - 40px);
    font-size: 1rem;
}

.slide.activeSlide {
    opacity: 1;
    transform: translateX(0);
}
.slide.lastSlide {
    transform: translateX(-100%);
}
.slide.nextSlide {
    transform: translate(100%);
}
</style>
