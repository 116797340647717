<template>
    <div class="timer-wrap">
        <span class="timer"></span>
    </div>
</template>

<script>
import { gsap } from "gsap";
import { mapState } from "vuex";
export default {
    mounted() {
        this.timerAnimation();
    },
    beforeDestroy() {
        this.timeLineAni.kill();
    },
    data() {
        return {
            activityDuration: 20,
            timeLineAni: gsap.timeline({}),
        };
    },
    computed: {
        ...mapState({
            activity: (state) => state.activitiesState.artActivity,
            showZoomImg: (state) => state.activitiesState.showZoomImg,
            showResult: (state) => state.activitiesState.showResult,
        }),
    },
    watch: {
        activity(value) {
            if (
                value.name &&
                value?.name != "DragAndMatch" &&
                value.name &&
                value.name !== "DragPoll"
            ) {
                this.activityTimer();
            }
        },
        showZoomImg(value) {
            if (
                this.activity.name == "DragAndMatch" ||
                this.activity.name == "DragPoll"
            ) {
                return;
            } else {
                value ? this.timeLineAni.pause() : this.timeLineAni.play();
            }
        },
    },
    methods: {
        timerAnimation() {
            this.timeLineAni.from(
                ".timer",
                {
                    width: 0,
                    duration: this.activityDuration,
                    ease: "none",
                    display: "block",
                    onComplete: () => {
                        this.$store.dispatch("TOGGLE_TIMEOUT_MODAL", true);
                        gsap.set(".timer", { clearProps: "all" });
                    },
                },
                0
            );
        },
        activityTimer() {
            this.timeLineAni.progress() > 0
                ? this.timeLineAni.restart()
                : this.timeLineAni.play();
        },
    },
};
</script>

<style scoped>
.timer-wrap {
    width: 100%;
    display: flex;
    background-color: #2D2D2D;
    margin-bottom: 1rem;
}
.timer {
    top: 0;
    z-index: 1;
    height: 10px;
    width: 100%;
    display: none;
    position: sticky;
    background: var(--primary-white);
}
</style>
