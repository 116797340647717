<template>
    <main>
        <div v-if="isUploading" class="overlay">
            <div v-if="!message" class="loading"></div>
            <h2 :style="{ color: message.color }" v-else>
                {{ message.text }}
            </h2>
        </div>
        <router-link to="/dashboard">Back to dashboard</router-link>
        <section>
            <h1 class="art-name">{{ art.title }}</h1>
            <SwitchLanguage @localeChanged="getData" />
            <figure class="image-container">
                <img :src="art.digital_collection" :alt="art.title" />
            </figure>
            <form>
                <div
                    class="art-top-section"
                    :class="{ showDetailsSection: showDetails }"
                >
                    <button
                        type="button"
                        class="btn"
                        @click="showDetails = !showDetails"
                    >
                        {{ !showDetails ? "Show" : "Hide" }} arts details
                    </button>
                    <h2>Art Details</h2>
                    <SingleArtTop :art="art" />
                </div>
                <div
                    v-for="(item, idx) in activities"
                    class="single-story"
                    :key="item.id"
                    ref="story"
                >
                    <StoriesGroup
                        @handleStoryClick="handleStoryClick(idx)"
                        @removeStory="removeStory(idx)"
                        @testStory="testStory(idx)"
                        :item="item"
                    />
                </div>
                <SelectActivity @addNewStory="addNewStory" />
                <button type="button" @click="submitChanges" class="btn">
                    Update
                </button>
            </form>
        </section>
    </main>
</template>

<script>
import SelectActivity from "../../components/admin/activityGames/SelectActivity.vue";
import { SingleArtTop, StoriesGroup, SwitchLanguage } from "../../components";
/* eslint-disable vue/no-unused-components */
export default {
    components: {
        StoriesGroup,
        SelectActivity,
        SingleArtTop,
        SwitchLanguage,
    },
    mounted() {
        this.getData();
    },
    data() {
        return {
            id: this.$route.params.inventoryNumber,
            art: {},
            activities: [],
            showDetails: false,
            isUploading: false,
            message: {
                color: "",
                text: "",
            },
        };
    },
    methods: {
        handleStoryClick(idx) {
            this.$refs.story[idx].classList.toggle("show");
        },
        addNewStory(selected) {
            if (!this.activities) return;
            switch (selected) {
                case "DragCommon":
                    this.activities.push({
                        name: selected,
                        options: [
                            { id: 1, img: "" },
                            { id: 2, img: "" },
                        ],
                        rightKeywords: [],
                    });
                    break;

                case "DragAndMatch":
                    this.activities.push({
                        name: selected,
                        rightKeywords: [],
                    });
                    break;

                default:
                    this.activities.push({
                        name: selected,
                        options: [],
                    });
            }
        },
        testStory(index) {
            let selectedStory = this.activities.find(
                (option, idx) => index == idx
            );
            localStorage.setItem("storyTest", JSON.stringify(selectedStory));
            let routeData = this.$router.resolve({
                name: "TestStory",
            });
            window.open(routeData.href, "_blank");
        },
        removeStory(index) {
            this.activities = this.activities.filter(
                (option, idx) => index !== idx
            );
        },
        async getData() {
            localStorage.setItem("locale", this.$i18n.locale);
            const res = await this.$http(`/game/art/game-data/`, {
                headers: {
                    "Accept-Language": this.$i18n.locale,
                },
            });
            const res2 = await this.$http(`/game/art/activities/${this.id}/`, {
                headers: {
                    "Accept-Language": this.$i18n.locale,
                },
            });
            const [data1, data2] = await Promise.all([res, res2]);
            this.art = data1.data.game_data.find(
                (item) => item.inventory_number == this.id
            );

            if (data2.data) {
                this.activities = res2.data;
            } else {
                this.activities = [];
            }
        },

        async submitChanges() {
            let data = this.art;
            let activity_games = this.activities;
            delete data.activity_games;

            this.message.text = "";
            this.isUploading = true;
            try {
                let res = await this.$http.patch(
                    `/game/art/update-item/${this.art.inventory_number}/`,
                    { art_details: data, activity_games },
                    {
                        headers: {
                            "Accept-Language": this.$i18n.locale,
                        },
                    }
                );

                this.message = {
                    color: "#4BB543",
                    text: "Uploaded!",
                };
                setTimeout(() => {
                    this.isUploading = false;
                    console.log(res.data);
                }, 1000);
            } catch (error) {
                console.log(error);
                this.message = {
                    color: "red",
                    text: "Something when wrong, please try again",
                };
                setTimeout(() => {
                    this.isUploading = false;
                }, 2000);
            }
        },
    },
};
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    display: grid;
    place-content: center;
}
.overlay h2 {
    font-size: 6vh;
}
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid var(--primary-white);
    border-radius: 50%;
    border-top-color: var(--main-color-3);
    animation: spinner 0.6s linear infinite;
}
.loading {
    margin: 0 auto;
}
main {
    max-width: 600px;
    margin: 3rem auto;
}

.art-name {
    margin-bottom: 1rem;
}

.single-story {
    height: 50px;
    overflow: hidden;
    padding: 1rem;
    background: var(--main-color-3);
    border-radius: 10px;
    margin: 1rem 0;
}
.show {
    height: fit-content;
}

.image-container img {
    width: 100%;
    max-height: 50vh;
    object-fit: contain;
    margin: 1rem 0;
}
.art-top-section {
    height: 50px;
    overflow: hidden;
}
.showDetailsSection {
    height: unset;
}

@media screen and (min-width: 1200px) {
    main {
        max-width: unset;
        padding: 1rem;
    }
    form {
        max-width: 960px;
        margin: 0 auto;
    }
}
</style>
