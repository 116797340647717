<template>
    <div class="container">
        <auth-navbar />
        <div class="form">

            <div v-if="sendEmailFlag==false">
                <div class="title">Welcome</div>
                <div class="greetings">
                    {{ $t('message.create_desc') }}
                </div>

                <!--
                    By signing in to this game, <br>you agree to the 
                    <a :href="$i18n.locale==='de' ? 
                    'https://www.staedelmuseum.de/de/datenschutz':
                    'https://www.staedelmuseum.de/en/privacy-policy'" target=“_blank”>
                    Privacy Policy
                    </a>. 
                -->
                <div class="term" v-html="$t('message.privacy_policy', {privacy_policy_link: $t('message.privacy_policy_link')})">
                </div>
                <div class="email-area">
                    <label for="email">Email address</label>
                    <input class="email-input" name="email"
                        v-model="email"
                        placeholder="Email address" 
                        @input="joinedEmailFlag=false"
                        required/>
                    <div class="text-msg">
                        {{ joinedEmailFlag ? "This email addresss is already in use." : textMsg }}
                    </div>
                </div>
                <button type="submit" class="btn-submit"  @click.prevent="sendEmail()" 
                    :style="{ background: email === '' || !checkEmail(email) ? '#E8E8E8' : '#DA8E61' }"
                      :disabled = "email==='' || !checkEmail(email) || sendEmailFlag">
                      {{ $t('message.continue')}}
                </button>
            </div>
            <div v-else>
                <div class="after-send-email-1">
                    {{ $t('message.plz_verify_email') }}
                </div>
                <div class="after-send-email-2">
                    {{ $t('message.we_send_email', {email_address: email}) }}
                </div>
                <div class="after-send-email-3">
                    {{ $t('message.create_just_click') }}    
                </div>
                <div class="after-send-email-4">
                    {{ $t('message.resend_email') }}
                </div>
                <div class="after-send-email-5" @click="sendEmailFlag=false;email=''">
                    {{ $t('message.resend_email_link') }}
                </div>
                
            </div>

        </div>
        <msg-modal v-if="isMsgModalShow"
        :msg="msg"
        :type="msgType"
        @close="isMsgModalShow=false" />
    </div>
</template>

<script>
export default {
    name: 'JoinStep1',
    components: {
        'msg-modal': () => import('@/components/ui/MsgModal.vue'),
        'auth-navbar': () => import('@/components/ui/AuthNavbar.vue')
    },
    data() {
        return {
            email: '',
            sendEmailFlag: false,
            joinedEmailFlag: false,
            msg: '',
            msgType: '',
            isMsgModalShow: false,
            textMsg: '',
            netMsg: ''
        }
    },
    methods: {
        checkEmail(email) {
            if (email === '') {
                this.textMsg = "";
                return false;
            }
            else {
                const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!emailRegExp.test(email)) {
                    this.textMsg = this.$t('message.valid_email');
                    return false;
                }
            }
            this.textMsg = '';
            return true;
        },

        getEmailMessages() {
            let emailMessages = {};
            let i = 0;
            this.$t('message.create_email_intro').split('\n').forEach((line) => {
                emailMessages['top_line_' + (i + 1)] = line;
                i++;
            });
            emailMessages['button'] = this.$t('message.verify_email_btn');
            emailMessages['bottom_line_1'] = this.$t('message.create_email_outro');
            return emailMessages;
        },
        async sendEmail() {
            if (!this.checkEmail(this.email)) {
                return;
            }
            try {
                const res = await this.$store.dispatch("SEND_AUTH_EMAIL", {
                    email: this.email.trim(),
                    player_id: localStorage.getItem("player__id"),
                    game_id: localStorage.getItem("game__id"),
                    subject: this.$t('message.join_email_subject'),
                    messages: this.getEmailMessages()
                });
                console.log(res.data.msg)
                if (res.status === 200) {
                    this.sendEmailFlag = true;
                    this.joinedEmailFlag = false;
                    this.isMsgModalShow = false;
                }
               
            } catch (error) {
                console.log(error.response.data.msg)
                if (error.response.status === 400 && error.response.data.msg === "already joined email") {
                    this.joinedEmailFlag = true;
                    this.textMsg = this.$t('message.already_use_email');
                    this.isMsgModalShow = false;
                }
                else {
                    this.textMsg = this.$t('message.try_again');
                    this.isMsgModalShow = false;
                }
            }
        },
    }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f5f5f5;
  color:black
}

.container {
    width: 100%;
    height: 100vh;
}

.form {
    width: 100%;
    height: 600px;
    left: 0px;
    top: 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10%;
}


.email-input {
  width: 100%;
  height: 44px;
  border-color:#EEEEEE;
  background-color: #EEEEEE;
  font-size: 20px;
  margin-top: 10px;
  padding: 20px;
  color: black;
  text-align: left;
  border-width: 0px;
}

.empty-space {
  height: 50px;
}

.text-area {
  font-size: 20px;
  margin-bottom: 20px;
  color: #000000;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: left;
  align-items: left;
  justify-items: left;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 20px;

}
.greetings {
  font-style: normal;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 60px;
}

.password {
  border-width: 0px;
  width: 100%;
  height: 31px;
  text-align: left;
  outline: none;
  color: #000000;
  padding-left: 16px;
}
.password-hidden {
  width: 30px;
  height: 30px;
}
.password-input-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 6px solid #EEEEEE;
    border-radius: 2px;
    background-color: #f5f5f5;
    font-size: inherit;
    margin-top: 10px;
    margin-bottom: 5px;
    color: black;
    padding: 4px;
}

.email-area {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    height: 100px;
}
.btn-submit {
    color: #000000;
    font-size: 20px;
    margin-top: 40px;
    height: 50px;
    width: 100%;
}

.term {
    font-size: 17px;
    color: black;
}

label {
  color: #000000;
  font-size: 20px;
}
a {
    color: blue;
    text-decoration: underline;
}
.text-msg {
    color: red;
    font-size: 12x;
    margin-top: 10px;
    padding-left: 20px
}
.after-send-email-1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
}
.after-send-email-2 {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 130%;
}
.after-send-email-3 {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 130%;
}
.after-send-email-4{
    font-size: 15px;
    line-height: 130%;
}
.after-send-email-5 {
    font-size: 15px;
    line-height: 130%;
    color: blue;
    text-decoration: none;
}

.user-email {
    text-decoration: underline;
}
input {
  font-size: 20px;
}
</style>
