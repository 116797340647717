<template>
    <section>
        <!-- <h2 class="competence-title">{{ $t("message.competences") }}</h2> -->
        <div class="buttons-wrapper">
            <button
                :class="{ ['is-active']: sortType === 'most' }"
                class="sort-btn"
                @click="$emit('handleSort', 'most')"
            >
                {{ $t("message.sort_most_achievable_competences") }}
            </button>
            <button
                :class="{ ['is-active']: sortType === 'collected' }"
                class="sort-btn"
                @click="$emit('handleSort', 'collected')"
            >
                {{ $t("message.sort_acquired_competences") }}
            </button>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        sortType: {
            type: String,
        },
    },
    emits: ["handleSort"],
};
</script>

<style scoped>
.competence-title {
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 100%;
    text-transform: capitalize;
}
.buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin: 1rem auto;
}
.sort-btn {
    width: fit-content;
    background: var(--primary-black);
    border-radius: 5px;
    border: 1px solid var(--primary-white);
    padding: 0.2rem 0.5rem;
    color: var(--primary-white);
    z-index: 1;
    text-transform: capitalize;
}
.is-active {
    background: var(--primary-white);
    color: var(--primary-black);
}
</style>
