var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"modal-content"},[_c('img',{staticClass:"img",attrs:{"src":_vm.currentArt.digital_collection,"alt":""}}),_c('h4',[_vm._v(_vm._s(_vm.currentArt.title))]),(!_vm.allStoriesCompleted)?_c('router-link',{staticClass:"btn",attrs:{"to":{
                name: 'StoryList',
                params: {
                    zodiacName: _vm.currentArt.constellation,
                    inventoryNumber: _vm.currentArt.inventory_number,
                },
            }}},[_vm._v(_vm._s(_vm.$t("message.play_another_story")))]):_c('div',[_c('h3',[_vm._v("Completed!!!")])]),(!_vm.currentArt.core_art && !_vm.isCompleted)?_c('router-link',{staticClass:"btn",attrs:{"to":{
                name: 'SelectImage',
                params: {
                    zodiacName: _vm.currentArt.constellation,
                },
            }}},[_vm._v(_vm._s(_vm.buttonText))]):_vm._e(),(!_vm.currentArt.coreArt && _vm.isCompleted)?_c('router-link',{staticClass:"btn",attrs:{"to":{
                name: 'WheelOfColors',
            }}},[_vm._v("Wheel of colors")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }