<template>
    <div class="activity-wrapper">
        <section class="activity-image-container top-section">
            <img v-if="img" :src="imageActivity" :alt="title" />
        </section>
        <section class="result-section">
            <div
                :class="{ 'answered': showTotalVotes }"
                class="activity-buttons"
            >
                <button
                    ref="btns"
                    v-for="(art, idx) in options"
                    :key="idx"
                    class="btn activity-btn"
                    @click="handleClick(art, idx)"
                >
                    <span v-html="art.option"></span>
                </button>
            </div>
        </section>
    </div>
</template>

<script>
// import { gsap } from "gsap";
export default {
    props: {
        story_name: {
            type: String,
            default: "Story name",
        },
        title: {
            type: String,
            default: "Title",
        },
        img: {
            type: String,
            default: "ANSELM-KIEFER.png",
        },

        options: {
            type: Array,
        },
    },

    data() {
        return {
            asAnswered: false,
            showTotalVotes: false,
            totalVotes: 0,
        };
    },
    computed: {
        imageActivity() {
            return this.img.startsWith("http")
                ? this.img
                : require(`../../assets/img/art-img/${this.img}`);
        },
    },
    methods: {
        handleClick(item, idx) {
            this.$refs.btns[idx].classList.add("is-selected");

            this.asAnswered = true;

            this.totalVotes = this.options.reduce((acc, cur) => {
                acc += cur.votes;
                return acc;
            }, 0);
            if (!this.showTotalVotes) {
                ++item.votes;
                ++this.totalVotes;
                this.$emit("updateVotes", {
                    id: item.id,
                    totalVotes: this.totalVotes,
                });
                this.showTotalVotes = true;
            }
        },
    },
};
</script>

<style scoped>
.activity-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 13px;
    margin: 1.3rem 0;
}

.activity-btn {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    padding: 1.8rem 1rem;
    background-color: var(--primary-gray);
    border: 1px solid var(--primary-white);
    overflow: hidden;
}

.vote-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}
.answered .activity-btn.is-selected {
    border: 2px solid var(--primary-white);
    background-color: var(--primary-white);
    color: var(--primary-black);
}
</style>
