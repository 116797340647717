<template>
    <div
        class="modal-overlay-wrapper"
        :style="{
            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.6) 30%, 
            rgba(255,255,255,0) 62%, 
            rgba(0,0,0,0.8) 80%), 
            url('${currentArt.digital_collection}')`,
        }"
    >
        <div class="progress-bg">
            <img
                class="stars-images"
                src="../../assets/img/found-story-stars.png"
                alt=""
            />
            <div>
                <h2 class="progress-modal-title">
                    {{ $t("message.story_cleared") }}
                </h2>
                <div class="details">
                    <p>
                        {{ $route.params.storyName }}
                    </p>
                    <div class="indicator">
                        <StoriesIndicator
                            :activity_games="currentArt.activity_games"
                        />
                    </div>
                </div>
            </div>
            <div class="bottom-section-wrapper">
                <button @click="handleClick" class="btn-round">
                    {{ $t("message.continue") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import StoriesIndicator from "../selectImage/StoriesIndicator.vue";

import { mapState } from "vuex";

export default {
    components: {
        StoriesIndicator,
    },

    computed: {
        ...mapState({
            currentArt: (state) => state.gameState.currentArt,
        }),
        completedStories() {
            return this.currentArt.activity_games.filter(
                (item) => item.is_completed
            ).length;
        },
    },

    methods: {
        handleClick() {
            this.$emit("ArtStoryModalClick");
        },
    },
};
</script>

<style scoped>
.modal-overlay-wrapper {
    background-position: top;
    background-size: cover;
}

.progress-bg {
    width: 100%;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.img-wrapper {
    padding: 0 80px;
}

.stars-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.details p {
    text-align: center;
    margin: 0.7rem 0;

    font-style: normal;
    font-weight: 350;
    font-size: 20px;
    line-height: 130%;
}

.indicator {
    margin-top: 1.5rem;
}

.indicator .stories-container {
    display: flex;
    gap: 0.56rem;
    position: relative;
    bottom: unset;
    right: unset;
}
.indicator .stories-container .isFound {
    fill: var(--green-matrix-2);
    display: none;
}

.bottom-section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 34px;
}
.bottom-section-wrapper button {
    width: 100%;
    height: 60px;
    background-color: white;
    border: 0.5px solid black;
    border-radius: 3px;
    vertical-align: middle;
    text-align: center;
    font-family: var(--primary-font-family-3);
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 140%;
    color: var(--primary-gray);
}
</style>
