<template>
    <div class="message-indicator">
        <div :style="getProgress" class="indicator"></div>
    </div>
</template>

<script>
export default {
    name: "MessageIndicator",
    props: {
        currentIndex: {
            type: Number,
            default: 0,
        },
        dataLength: {
            type: Number,
            default: 2,
        },
    },
    computed: {
        getProgress() {
            const scale = ((this.currentIndex + 1) * 100) / this.dataLength;
            return `transform: scaleX(${scale}%)`;
        },
    },
};
</script>

<style scoped>
.message-indicator {
    width: 100%;
    height: 2px;
    background: var(--primary-gray);
    margin-bottom: 1rem;
}

.indicator {
    width: 100%;
    height: 100%;
    transform-origin: top left;
    background: var(--primary-white);
}
.is-active-color {
    background: var(--primary-white);
}
</style>
