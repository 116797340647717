var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"links-container"},[(!_vm.allCompleted)?_c('router-link',{staticClass:"btn",attrs:{"to":{
            name: 'StoryList',
            params: {
                zodiacName: this.$route.params.zodiacName,
                inventoryNumber: _vm.inventory_number,
            },
        }}},[_vm._v(_vm._s(_vm.$t("message.play_another_story")))]):_vm._e(),_c('router-link',{staticClass:"btn",attrs:{"to":{
            name: 'SelectImage',
            params: {
                zodiacName: _vm.$route.params.zodiacName,
            },
        }}},[_vm._v(_vm._s(_vm.buttonText))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }