<template>
    <div class="component-wrapper">
        <small>{{ $t("message.select_art_to_zoom") }}</small>
        <div class="switcher">
            <input type="checkbox" id="checkbox" v-model="checkbook" />
            <label for="checkbox" checked>
                <p
                    :class="{ active: selectedImg === opt }"
                    v-for="opt in 2"
                    :key="`Lang${opt}`"
                >
                    {{ opt }}
                </p>
                <div class="ball"></div>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch("CURRENT_OPTION", 1);
    },
    data() {
        return {
            checkbook: true,
            selectedImg: 1,
        };
    },
    watch: {
        checkbook(newValue) {
            this.selectedImg = newValue ? 2 : 1;

            this.$store.dispatch("CURRENT_OPTION", this.selectedImg - 1);
        },
    },
};
</script>

<style scoped>
.component-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 5px;
}
.switcher {
    width: 50px;
    height: 25px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid var(--primary-white);
    margin: 0 auto;
    position: relative;
    align-self: end;
}
input {
    visibility: hidden;
}
input,
label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--primary-white);
}

.active {
    color: var(--primary-white);
}
.ball {
    width: 22px;
    height: 22px;
    background: var(--primary-white);
    /* z-index: -1; */
    position: absolute;
    top: 50%;
    left: 2px;
    border-radius: 100%;
    transform: translateY(-50%);
    transition: all 0.3s linear;
}
input:checked + label .ball {
    transform: translate(24px, -50%);
}
small {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 1.2rem;
    font-style: normal;
    line-height: normal;
}
</style>
