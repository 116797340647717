<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 174 141"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M152.92 8.07045C155.045 8.07045 156.769 6.34711 156.769 4.22126C156.769 2.09541 155.045 0.37207 152.92 0.37207C150.794 0.37207 149.07 2.09541 149.07 4.22126C149.07 6.34711 150.794 8.07045 152.92 8.07045Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M169.349 65.7726C171.475 65.7726 173.198 64.0493 173.198 61.9234C173.198 59.7976 171.475 58.0742 169.349 58.0742C167.223 58.0742 165.5 59.7976 165.5 61.9234C165.5 64.0493 167.223 65.7726 169.349 65.7726Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M24.9586 139.883C27.0844 139.883 28.8078 138.16 28.8078 136.034C28.8078 133.908 27.0844 132.185 24.9586 132.185C22.8327 132.185 21.1094 133.908 21.1094 136.034C21.1094 138.16 22.8327 139.883 24.9586 139.883Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M26.8492 90.8292C28.975 90.8292 30.6984 89.1059 30.6984 86.98C30.6984 84.8542 28.975 83.1309 26.8492 83.1309C24.7233 83.1309 23 84.8542 23 86.98C23 89.1059 24.7233 90.8292 26.8492 90.8292Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M2.97216 114.124C4.08292 114.124 4.98333 113.224 4.98333 112.113C4.98333 111.002 4.08292 110.102 2.97216 110.102C1.86141 110.102 0.960938 111.002 0.960938 112.113C0.960938 113.224 1.86141 114.124 2.97216 114.124Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M13.5503 105.467C14.661 105.467 15.5615 104.566 15.5615 103.456C15.5615 102.345 14.661 101.444 13.5503 101.444C12.4395 101.444 11.5391 102.345 11.5391 103.456C11.5391 104.566 12.4395 105.467 13.5503 105.467Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M49.1753 140.107C50.286 140.107 51.1865 139.207 51.1865 138.096C51.1865 136.985 50.286 136.085 49.1753 136.085C48.0645 136.085 47.1641 136.985 47.1641 138.096C47.1641 139.207 48.0645 140.107 49.1753 140.107Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M74.1909 134.336C75.3017 134.336 76.2021 133.435 76.2021 132.325C76.2021 131.214 75.3017 130.313 74.1909 130.313C73.0802 130.313 72.1797 131.214 72.1797 132.325C72.1797 133.435 73.0802 134.336 74.1909 134.336Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M90.5581 80.4501C91.6689 80.4501 92.5693 79.5497 92.5693 78.4389C92.5693 77.3282 91.6689 76.4277 90.5581 76.4277C89.4473 76.4277 88.5469 77.3282 88.5469 78.4389C88.5469 79.5497 89.4473 80.4501 90.5581 80.4501Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M110.738 55.4208C111.849 55.4208 112.749 54.5204 112.749 53.4096C112.749 52.2989 111.849 51.3984 110.738 51.3984C109.627 51.3984 108.727 52.2989 108.727 53.4096C108.727 54.5204 109.627 55.4208 110.738 55.4208Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M120.355 46.7587C121.466 46.7587 122.366 45.8583 122.366 44.7475C122.366 43.6368 121.466 42.7363 120.355 42.7363C119.244 42.7363 118.344 43.6368 118.344 44.7475C118.344 45.8583 119.244 46.7587 120.355 46.7587Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M135.769 41.954C136.88 41.954 137.78 41.0536 137.78 39.9428C137.78 38.8321 136.88 37.9316 135.769 37.9316C134.658 37.9316 133.758 38.8321 133.758 39.9428C133.758 41.0536 134.658 41.954 135.769 41.954Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M169.441 22.7079C170.552 22.7079 171.452 21.8075 171.452 20.6967C171.452 19.586 170.552 18.6855 169.441 18.6855C168.33 18.6855 167.43 19.586 167.43 20.6967C167.43 21.8075 168.33 22.7079 169.441 22.7079Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M168.472 46.7587C169.583 46.7587 170.483 45.8583 170.483 44.7475C170.483 43.6368 169.583 42.7363 168.472 42.7363C167.361 42.7363 166.461 43.6368 166.461 44.7475C166.461 45.8583 167.361 46.7587 168.472 46.7587Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M153.581 3.85254L169.94 20.2116L136.259 40.4198L119.9 45.2313L110.277 53.892L90.069 78.9117L74.6723 131.838L49.6526 137.612L24.6328 135.687L2.5 111.63L13.0853 103.931L27.5197 86.6101"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M169.923 62.5598L168.961 44.2762L169.923 20.2188"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
