<template>
    <div class="activity-wrapper">
        <section class="activity-image-container top-section">
            <img v-if="img" :src="imageActivity" :alt="title" />
        </section>
        <section class="result-section">
            <div class="activity-buttons">
                <button
                    ref="btns"
                    v-for="(art, idx) in options"
                    :key="idx"
                    class="btn activity-btn"
                    @click="handleClick(art, idx)"
                >
                    <ActivityButton
                        v-if="showTotalVotes"
                        :art="art"
                        :showTotalVotes="showTotalVotes"
                        :totalVotes="totalVotes"
                        :isSelected="selectNum === idx"
                    />
                    <span v-else>{{ art.option }}</span>
                </button>
            </div>
            <h3 class="vote-count" v-if="showTotalVotes">{{ $t("message.total_votes") }}: {{ totalVotes }}</h3>
        </section>
    </div>
</template>

<script>
// import gsap from "gsap";
import ActivityButton from "../activity/ActivityButton";
export default {
    components: {
        ActivityButton,
    },
    props: {
        story_name: {
            type: String,
            default: "Story name",
        },
        title: {
            type: String,
            default: "Title",
        },
        img: {
            type: String,
            default: "ANSELM-KIEFER.png",
        },

        options: {
            type: Array,
        },
    },

    data() {
        return {
            showTotalVotes: false,
            totalVotes: 0,
            selectNum: null,
        };
    },

    computed: {
        imageActivity() {
            return this.img.startsWith("http")
                ? this.img
                : require(`../../assets/img/art-img/${this.img}`);
        },
    },
    
    methods: {
        handleClick(item, idx) {
            this.totalVotes = this.options.reduce((acc, cur) => {
                acc += cur.votes;
                return acc;
            }, 0);
            
            if (!this.showTotalVotes) {
                ++item.votes;
                ++this.totalVotes;
                this.$emit("updateVotes", {
                    id: item.id,
                    totalVotes: this.totalVotes,
                });
                this.showTotalVotes = true;
            }
            this.selectNum = idx;
        },
    },
};
</script>

<style scoped>
.activity-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 13px;
    margin-top: 0.6rem;
}
.activity-btn {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    padding: 1.8rem 1rem;
    background-color: var(--primary-gray);
    border: 1px solid var(--primary-white);
    overflow: hidden;
}
.vote-count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary-white);
    font-family: var(--primary-font-light);
    font-size: 1.2rem;
    font-style: normal;
    line-height: normal;
    padding-top: 1.5rem;
}
.is-selected {
    border: 2px solid var(--primary-white);
}
</style>
