<template>
    <svg
        width="13"
        height="10"
        viewBox="0 0 13 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.09807 7.70934L11.5796 0.150527C11.7336 -0.0294639 12.0068 -0.0511593 12.1884 0.102315L12.8474 0.656749C13.0298 0.810223 13.0517 1.08101 12.8969 1.26101L5.53337 9.84916C5.37287 10.0356 5.08836 10.0517 4.90759 9.88371L0.136393 5.44009C-0.0378866 5.27778 -0.0459942 5.00698 0.116939 4.83423L0.709499 4.20987C0.872432 4.03712 1.14642 4.02908 1.31989 4.19059L5.09807 7.70934Z"
            fill="black"
        />
    </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
