<template>
    <section class="set-circle-modal">
        <div class="component-container">
            <div class="paint-details-fake">
                <img class="circle-img" :src="dragImage" ref="refImg" />
                <span ref="circle" class="circle-drag"></span>
            </div>

            <div class="form-container">
                <section class="fake-circles-wrapper">
                    <div
                        v-for="(item, idx) in listOfCircles"
                        :key="idx"
                        @click="handleCircleClick(idx)"
                    >
                        {{ idx }}
                    </div>
                </section>
                <button @click="confirmCircle" class="btn">Confirm</button>
                <button type="button" @click="copyValues" class="btn">
                    {{ copyMessage }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);
export default {
    props: {
        dragImage: {
            type: String,
        },
    },
    mounted() {
        this.circle = this.$refs.circle;
        this.draggableFunc();
    },

    data() {
        return {
            top: 0,
            left: 0,

            circle: null,
            imageWidth: 0,
            imageHeight: 0,

            listOfCircles: [],

            circlePosition: "",
            copyMessage: "Copy circle position",
        };
    },
    methods: {
        confirmCircle() {
            this.listOfCircles.push(this.circlePosition);
            console.log(this.circlePosition);
        },
        copyValues() {
            function copyToClipboard(textToCopy) {
                // navigator clipboard api needs a secure context (https)
                if (navigator.clipboard && window.isSecureContext) {
                    // navigator clipboard api method'
                    return navigator.clipboard.writeText(textToCopy);
                } else {
                    // text area method
                    let textArea = document.createElement("textarea");
                    textArea.value = textToCopy;
                    // make the textarea out of viewport
                    textArea.style.position = "fixed";
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();
                    return new Promise((res, rej) => {
                        // here the magic happens
                        document.execCommand("copy") ? res() : rej();
                        textArea.remove();
                    });
                }
            }

            let clipText = `fake_circles: ${JSON.stringify(
                this.listOfCircles
            )}`;

            copyToClipboard(clipText);

            this.copyMessage = "Copied";
            setTimeout(() => {
                this.$emit("keyWordPositionCopied");
            }, 500);
        },
        draggableFunc() {
            Draggable.create(this.circle, {
                type: "x,y",
                bounds: ".paint-details-fake",
                onDrag: () => {
                    let transformvaluesToArray =
                        this.circle.style.transform.split("(");
                    let transformvalues2 = transformvaluesToArray[1].split(",");
                    this.left = parseInt(transformvalues2[0]);
                    this.top = parseInt(transformvalues2[1]);
                },
                onDragEnd: () => {
                    this.imageWidth = this.$refs.refImg.width;
                    this.imageHeight = this.$refs.refImg.height;
                    let keyword = {
                        top: Math.floor((this.top * 100) / this.imageHeight),
                        left: Math.floor((this.left * 100) / this.imageWidth),
                    };
                    this.circlePosition = {
                        top: keyword.top,
                        left: keyword.left,
                    };
                },
            });
        },
        handleCircleClick(idx) {
            this.listOfCircles = this.listOfCircles.filter(
                (item, index) => index !== idx
            );
        },
    },
};
</script>

<style scoped>
.set-circle-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2000;
}
.component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: var(--primary-white);
    padding: 1rem;
}
.fake-circles-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    border: 2px solid black;
    width: 100%;
}
.fake-circles-wrapper div {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-gray);
    color: var(--primary-white);
    border-radius: 50%;
}

.paint-details-fake {
    position: relative;
    -webkit-align-self: center;
    align-self: center;
    width: fit-content;
    height: fit-content;
}
.paint-details-fake img {
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 350px;
}

.circle-drag {
    min-height: 0;
    position: absolute;
    border: 4px dotted white;
    background: transparent;
    bottom: 0;
    height: 62px;
    width: 62px;
    border-radius: 50%;
}

.circle-drag {
    top: 0;
    left: 0;
}

.btn {
    transition: all 0.3s linear;
    font-size: 17px;
}
</style>
