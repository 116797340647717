<template>
    <div class="main">
        <div class="selection">
            <h3>Languages</h3>
            <select v-model="selectedLang">
                <option :value="lang" v-for="lang in languages" :key="lang">
                    {{ lang }}
                </option>
            </select>
            <h3>Art names</h3>
            <select v-model="selectedArt">
                <option :value="art" v-for="(art, idx) in artTitles" :key="idx">
                    {{ art }}
                </option>
            </select>
        </div>
        <h2>
            Selected Art: <br />
            {{ selectedArt }}
        </h2>
        <img :src="artImage" alt="" />

        <router-link
            v-for="activity in activitiesList"
            :key="activity.activity_name"
            :to="{
                name: 'TestStory',
                params: {
                    storyName: activity.activity_name,
                    lang: selectedLang,
                },
            }"
        >
            <h3>
                {{ activity.activity_name }}
            </h3>
        </router-link>
    </div>
</template>

<script>
import gameApi from "../../api/gameApi";

export default {
    data() {
        return {
            artTitles: [],
            activitiesTypes: [],
            activitiesList: [],
            activitiesAll: [],
            languages: ["de", "en"],
            selectedLang: "en",
            selectedType: "",
            selectedArt: "",
            artImage: "",
        };
    },
    mounted() {
        this.getActivities();
    },
    watch: {
        selectedArt() {
            this.setFilters();
        },
        selectedLang() {
            this.getActivities();
        },
    },
    methods: {
        async getActivities() {
            const res = await gameApi(
                `/game/all-activities?lang=${this.selectedLang}`
            );

            const data = res.data.activity;

            this.activitiesAll = data;

            const artAllTitles = data.map((item) => {
                return item.art_title;
            });
            const activities = data.map((item) => {
                return item.type;
            });

            this.artTitles = [...new Set(artAllTitles)];
            this.activities = [...new Set(activities)];
            this.setFilters();
            this.selectedArt = this.artTitles[0];
        },

        setFilters() {
            const list = [...this.activitiesAll];
            this.activitiesList = list.filter(
                (item) => item.art_title == this.selectedArt
            );

            if (this.activitiesList.length > 0) {
                this.artImage = this.activitiesList[0].art_img;
            }

            // this.artImage = this.activitiesList[0].art_img;
        },
    },
};
</script>

<style scoped>
.main {
    padding: 1rem;
}
h2 {
    margin: 2rem 0;
    color: red;
}
h3 {
    margin: 1rem 0;
}
img {
    width: 100%;
    max-width: 500px;
    max-height: 300px;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}
.selection {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

select {
    height: 50px;
    font-size: 1rem;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}
</style>
