<template>
    <div class="constellations-nav">
        <div
            v-if="artsByConstellation.length > 0"
            class="wheel-container-wrapper"
            id="wheelContainerWrapper"
        >
            <DiskNav
                class="wheel-container"
                :artsByConstellation="artsByConstellation"
                :activeSlideIndex="0"
                :selectedConstellation="selectedConstellation"
                @handleWheelRelease="handleWheelRelease"
                :canRotate="canRotate"
            />
        </div>
    </div>
</template>

<script>
import DiskNav from "./DiskNav.vue";
import gameApi from "../../api/gameApi";

export default {
    components: {
        DiskNav,
    },
    props: {
        canRotate: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            artsByConstellation: [],
            selectedConstellation: {},
        };
    },

    mounted() {
        this.getUserProgress();
    },
    methods: {
        handleWheelRelease(item) {
            this.selectedConstellation = item;
            let constellation = this.artsByConstellation.find(
                (el) => el.name.toLowerCase() === item.name.toLowerCase()
            );

            this.$store.dispatch("SET_ACTIVE_CONSTELLATION", constellation);
        },

        async getUserProgress() {
            try {
                const { data } = await gameApi.post(
                    "game/constellations-progress"
                );

                this.artsByConstellation = data.art_by_constellations;

                this.selectedConstellation = data.art_by_constellations[0];

                let constellationName = localStorage.getItem(
                    "selectConstellationName"
                );
                if (!constellationName) constellationName = "aries";

                this.selectedConstellation = this.artsByConstellation.find(
                    (item) => item.name === constellationName.toLowerCase()
                );
                setTimeout(() => {
                    this.$emit("loaded");
                }, 10);
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.constellations-nav {
    width: 100%;
}
.content {
    opacity: 0;
    justify-content: space-around;
    height: 100%;
    position: relative;
}

.wheel-container-wrapper {
    overflow: hidden;
    display: grid;
    place-content: center;
    position: fixed;
    top: 78%;
    left: 50%;
    z-index: 12;
    transform: translateX(-50%);
    position: absolute;
}
.wheel-container {
    width: 25em;
    height: 25em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
</style>
