<template>
    <div class="competences-wrapper">
        <div
            class="single-competence"
            v-for="(competence, idx) in competencesList"
            :key="competence.id"
            :data-total="competence.total"
            :data-collected="competence.collected"
            :data-index="idx"
        >
            <div
                class="competence-circle"
                :style="`width:${competence.size}px;
                    height:${competence.size}px`"
                v-show="competence.total > 0"
            >
                <div
                    :style="`width:${competenceProgress(competence)}%;
                    height:${competenceProgress(competence)}%; `"
                    class="competence-competition"
                >
                    <img
                        v-if="competence.total === competence.collected"
                        class="competence-img"
                        src="../../assets/img/competences-img/competence-base.png"
                        alt=""
                    />
                </div>
            </div>
            <p
                :style="`transform:scale(${scaleText(competence.size)})`"
                class="competence-details"
            >
                {{ competence.name }} <br />
                <span>
                    {{ competence.collected }}/
                    <span class="competences-total">
                        {{ competence.total }}</span
                    ></span
                >
            </p>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
export default {
    props: {
        competencesList: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        competenceProgress() {
            return (competence) =>
                Math.floor((competence.collected * 100) / competence.total);
        },
        scaleText() {
            return (size) => gsap.utils.clamp(1, 1.5, size / 90);
        },
    },
};
</script>

<style scoped>
.competences-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.4rem;
    justify-content: space-around;
    align-items: center;
}

.single-competence {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    position: relative;
}

.competence-circle {
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-gray);
}
.competence-competition {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: var(--primary-white);
}
.competence-img {
    width: 100%;
}
.competence-details {
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
}
.competence-details span {
    display: inline-block;
}

.competences-total {
    opacity: 0.7;
}

.competence-count-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
}
.icon {
    width: 10px;
    height: 10px;
    border: 1px solid var(--primary-white);
    border-radius: 50%;
}

.completed {
    background: var(--green-matrix-2);
    border-color: var(--green-matrix-2);
}
</style>
