<template>
    <div class="buttons">
        <button
            v-for="(story, idx) in storyOrder"
            :key="idx"
            @click="handleBtnClick(story, idx)"
            :disabled="story.isDisable"
            :class="{ isCollected: story.is_completed }"
            class="btn"
        >
        <!-- <button
            v-for="(story, idx) in stories"
            :key="idx"
            @click="handleBtnClick(story, idx)"
            :class="{ isCollected: story.is_completed }"
            class="btn"
        > -->
            {{ story.activity_name }}
            <CheckIcon class="check" v-if="story.is_completed" />

        </button>
    </div>
</template>

<script>
import CheckIcon from "../icons/CheckIcon.vue";
export default {
    components: {
        CheckIcon,
    },
    props: {
        stories: {
            type: Array,
        },
        playInOrder: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
    },

    computed: {
        storyOrder() {
            if (this.playInOrder) {
                return this.setStoryOrder();
            }
            return this.stories;
        },
    },

    methods: {
        setStoryOrder() {
            let isStoryCompleted = this.stories.filter(
                (story) => story.is_completed
            );

            return this.stories.map((story, idx) => {
                if (idx === 0 || idx <= isStoryCompleted.length) {
                    story.isDisable = false;
                } else {
                    story.isDisable = true;
                }
                return story;
            });
        },

        handleBtnClick(story) {
            if (!story.is_completed) {
                this.$router.push({
                    name: "Activities",
                    params: {
                        id: this.$route.params.id,
                        storyName: story.activity_name,
                    },
                });
            } else {
                // return;
                this.$router.push({
                    name: "ActivityCompleted",
                    params: {
                        id: this.$route.params.id,
                        storyName: story.activity_name,
                    },
                });
            }
        },
    },
};
</script>

<style scoped>
.buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
}
.btn {
    width: 100%;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.6);
    border: 0.5px solid var(--primary-white);

    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-light);
    font-size: 2rem;
    font-style: normal;
    line-height: 100%; /* 20px */
}
.btn:disabled {
    opacity: 0.5;
}
.isCollected {
    background: white;
    color: black;
}
.check {
    position: absolute;
    right: 18px;
}
</style>
