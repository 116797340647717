<template>
    <section>
        <!-- <p class="hint">
            {{ $t("message.drag_bar_left_or_right_to_compare") }}
        </p> -->
        <div class="activity-image-container top-section">
            <section ref="imagesContainer" class="drag-poll-wrapper">
                <img
                    v-for="(art, idx) in options"
                    :key="art.id"
                    class="img"
                    :class="`${idx === 1 ? 'dragIt' : ''}`"
                    :src="imageActivity(art.img)"
                    alt=""
                />
                <div class="dragger">
                    <div class="holder">
                        <span class="icon">
                            <ArrowIcon />
                        </span>
                        <span class="rotate icon">
                            <ArrowIcon />
                        </span>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import ArrowIcon from "../icons/ArrowIcon.vue";
gsap.registerPlugin(Draggable);

export default {
    components: {
        ArrowIcon,
    },
    props: {
        options: {
            type: Array,
        },
    },
    mounted() {
        this.dragImages();
    },
    beforeDestroy() {
        this.draggableInstance[0].kill();
    },
    data() {
        return {
            draggableInstance: null,
            showDragger: false,
        };
    },
    computed: {
        imageActivity() {
            return (img) =>
                img.startsWith("http")
                    ? img
                    : require(`../../assets/img/art-img/${img}`);
        },
    },
    methods: {
        dragImages() {
            this.showDragger = true;
            this.draggableInstance = Draggable.create(".dragger", {
                type: "x",
                bounds: ".drag-poll-wrapper",
                onDrag: function () {
                    let x = -gsap.getProperty(this.target, "x") + 150;
                    gsap.set(".dragIt", {
                        clipPath: `inset(  0px ${x}px 0px  0px )`,
                        transformOrigin: "top left",
                    });
                },
            });
        },
    },
};
</script>

<style scoped>
.activity-image-container {
    height: unset;
    max-height: 90%;
}
.drag-poll-wrapper {
    min-height: 300px;
    max-width: 90%;
    margin: 0 auto;
    position: relative;
}
p {
    text-align: center;
}
.img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* object-fit: cover;
    object-position: top; */
}
.dragger {
    top: 0;
    right: 150px;
    width: 6px;
    height: 100%;
    background: white;
    position: absolute;
    display: grid;
    place-content: center;
}
.dragIt {
    clip-path: inset(0px 150px 0px 0px);
}
.holder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon svg {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: var(--primary-black);
}

.rotate svg {
    rotate: 180deg;
}
</style>
