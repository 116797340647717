<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 193 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M188.834 74.8283C190.959 74.8283 192.683 73.1049 192.683 70.9791C192.683 68.8532 190.959 67.1299 188.834 67.1299C186.708 67.1299 184.984 68.8532 184.984 70.9791C184.984 73.1049 186.708 74.8283 188.834 74.8283Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M4.07575 8.37904C6.2016 8.37904 7.92494 6.6557 7.92494 4.52985C7.92494 2.404 6.2016 0.680664 4.07575 0.680664C1.9499 0.680664 0.226562 2.404 0.226562 4.52985C0.226562 6.6557 1.9499 8.37904 4.07575 8.37904Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M181.386 48.1171C182.497 48.1171 183.397 47.2167 183.397 46.1059C183.397 44.9952 182.497 44.0947 181.386 44.0947C180.275 44.0947 179.375 44.9952 179.375 46.1059C179.375 47.2167 180.275 48.1171 181.386 48.1171Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M138.105 25.9794C139.216 25.9794 140.116 25.079 140.116 23.9682C140.116 22.8575 139.216 21.957 138.105 21.957C136.994 21.957 136.094 22.8575 136.094 23.9682C136.094 25.079 136.994 25.9794 138.105 25.9794Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M5.32031 4.72168L138.117 23.9676L181.421 46.1004L189.119 70.1579"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
