<template>
    <div class="container main-container">
        <div class="link-home">
            <router-link to="/">Back Home</router-link>
        </div>
        <h4 class="story-title">1 - Select a Language</h4>
        <select v-model="selectedLang">
            <option value="en">English</option>
            <option value="de">German</option>
        </select>
        <h4 class="story-title">2 - Select a story</h4>
        <div class="btns-container">
            <button
                @click="handleClick(story.activity_data)"
                v-for="story in dragChallengeArray"
                :key="story.id"
                class="btn"
                :class="{
                    isActive: story.activity_name === selectedStory.story_name,
                }"
            >
                {{ story.activity_name }}
            </button>
        </div>
        <button @click="addFakeCircles" class="btn-circles">
            Add fake circles
        </button>
        <div v-if="showArt">
            <MatchDragDevelopment :selectedStory="selectedStory" />
        </div>
        <SetStoryCirclesFake
            :dragImage="selectedStory.img"
            @keyWordPositionCopied="showSetFakeCircles = false"
            v-if="showSetFakeCircles"
        />
    </div>
</template>

<script>
import SetStoryCirclesFake from "@/components/admin/SetStoryCirclesFake.vue";
import { MatchDragDevelopment } from "@/components/index.js";

import gameApi from "../../api/gameApi";
export default {
    components: {
        MatchDragDevelopment,
        SetStoryCirclesFake,
    },
    mounted() {
        this.getInitialData();
    },
    data() {
        return {
            dragChallengeArray: [],
            images: [],
            selectedStory: {},
            showArt: false,
            showSetFakeCircles: false,
            selectedLang: "en",
        };
    },
    watch: {
        selectedLang() {
            this.getInitialData();
        },
    },

    methods: {
        async getInitialData() {
            try {
                this.showArt = false;
                let res = await gameApi.post(
                    "/game/drag-and-match-activities",
                    {
                        lang: this.selectedLang,
                    }
                );
                this.dragChallengeArray = res.data;
                this.selectedStory = this.dragChallengeArray[0].activity_data;
                this.showArt = true;
            } catch (error) {
                console.log(error);
            }
        },
        addFakeCircles() {
            this.showSetFakeCircles = true;
        },
        handleClick(story) {
            this.showArt = false;
            this.selectedStory = story;
            setTimeout(() => {
                this.showArt = true;
            }, 100);
        },
    },
};
</script>

<style scoped>
.link-home {
    width: 100%;
    max-width: 600px;
    margin: 1rem auto;
}
.link-home a {
    border-bottom: 2px solid white;
    padding-bottom: 0.5rem;
    transition: all 0.3s linear;
}
.link-home:hover a {
    border-bottom: 2px solid red;
    letter-spacing: 0.05rem;
}
.container {
    position: relative;
    max-width: 100%;
}

.wrapper {
    min-height: 100%;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    padding: 0 1rem;
    position: relative;
    perspective: 1500px;
    overflow: hidden;
}
img {
    width: 100%;
    max-width: 600px;
}
.story-title {
    font-size: 2rem;
    margin: 0.5rem;
    color: var(--primary-white);
}
select {
    display: block;
    width: 100%;
    height: 40px;
    max-width: 300px;
    margin: 0 auto;
    font-size: 1.5rem;
    text-align: center;
}
h2,
span {
    color: var(--primary-white);
}
.buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

/* card */

.main-card-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -100%;
    left: 0;
    transform: scale(0);
}

.card-reward-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 556.06px;
    width: 100%;
    z-index: 10;
}

.container-layout {
    border: 2px solid var(--primary-white);
    border-bottom: none;
    width: var(--width-1);
}

.option-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1605; /* fix */
    align-self: flex-end;
    border-width: 0.5px;
    max-width: 250px;
    margin: 0 auto;
    margin-top: 0.5rem;
    background: white;
}
.text-result {
    transform: translate(-50%, 100%);
}
.isOpen {
    transform: translate(-50%, 0);
    top: 0;
}

.btns-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    gap: 0.5rem;
    max-width: 600px;
    margin: 1rem auto;
}
.btns-container .btn {
    font-size: 12px;
    width: fit-content;
    margin: 0;
    height: 40px;
    padding: 0.5rem;
}
.btn-img {
    display: block;
    width: 20px;
}
.isActive {
    background: #afbc88;
}
.btn-circles {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 1rem;
    margin: 0 auto;
    border: none;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    background: #ffe748;
}
</style>
