const en = {
    message: {
        CODE: "en",

        just_play: "Just Play",
        login_play: "Login to Play",
        sign_up: "Sign Up",
        privacy_policy: "By signing in to this game, you agree to the {privacy_policy_link}.",
        privacy_policy_word: "Privacy Policy",
        privacy_policy_link: "<a href='https://www.staedelmuseum.de/en/privacy-policy' target='_blank' style='color:blue'>Privacy Policy</a>",
        next: "next",
    
        level: "Level",
        go_to_the_star_signs: "Go to the star signs",
        start: "Start",
    
        libra: "libra",
        scorpius: "scorpio",
        cancer: "cancer",
        aquarius: "aquarius",
        pisces: "pisces",
        taurus: "taurus",
        virgo: "virgo",
        sagittarius: "sagittarius",
        aries: "aries",
        gemini: "gemini",
        capricorn: "capricorn",
        leo: "leo",
    
        wait_core_art_title: "Wait a minute!",
        wait_core_art_message: "You need to find the rest of the artworks of this constellation before you can continue.",
        
        title_Al_message: "Message from Al",
        title_constellation_book: "Starsigns",
        title_color_wheel: "Color Wheel",
        competences: "Competences",
        book_of_knowledge: "Book of Knowledge",
        title_discover: "More about the Städel",
        title_creators: "Legal Notice",
        title_my_page: "Privacy Policy",
        locked_level: "This level is<br/>currently locked.<br/>Please complete the previous one to unlock it.",
        core_art_story: "core art story",
        rotate_wheel_message: "Rotate the wheel to choose other star constellations",
        lock_art_progress: "lock art progress",
        message: "Message",
        locked_constellation: "This constellation is locked.<br/>Please complete<br/>the Core Art stories to unlock it.",
        
        form_art_title: "You can find the artwork on the",
        show_me_code: "Click for inventory number",
        art_id: "Inventory Number",
        continue: "Continue",
        story_list_title_top: "Choose a Story",
    
        time_out: "Time Out",
        answer_popup: "Ups! That answer was wrong.",
        fail_popup: "Failed. Would you like to try again?",
        answer_popup_button_one: "Back to Stories",
        answer_popup_button_two: "Try Again",
    
        select_art_to_zoom: "select art to zoom",
        zoom_double_tap: "Double tap or pinch on the image to zoom it.",
        drag_right_words: "Drag the right words here",
        total_votes: "Total Votes",
        learn_more: "Learn More",
    
        story_cleared: "Story Completed",
        popup_get_title: "Your reward",
        new_key: "a new key",
        popup_core_unlock: "core art unlocked",
    
        core_art_cleared: "core art completed",
        get_page: "Now you can get a page of the book of knowledge",
        send_text: "Now you can send a message to the future.",
        get_a_page_btn: "Get a page",
        transmit_data: "Transmitting Data Now",
    
        send_message_Al: "Send a message to Al",
    
        intro_title: "Welcome!",
        login_desc: "Immerse yourself in the Städel Universe!",
        email: "Email address",
        password: "Password",
        try_again: "Please Try Again.",
        forgot_password: "Forgot Password?",
        login: "Login",
        imprint: "Legal Notice",
    
        create_desc: "Once you've created an account, you can continue playing the game at any time.",
        valid_email: "Please enter a valid email address",
        already_use_email: "This email address is already in use",
        plz_verify_email: "Please verify your email",
        we_send_email: "We sent an email to {email_address}",
        create_just_click: "Just click on the link in that email to complete your signup.\nIf you don’t see it, you may need to check your spam folder",
        resend_email: "Still can’t find the email?",
        resend_email_link: "Resend Email",
        join_email_subject: "STÄDEL UNIVERSE: Confirm your email address",
        create_email_intro: "Hi.\nThanks for signing up!\nBefore you get started, we just need to confirm that is you.",
        verify_email_btn: "Verify your Email",
        create_email_outro: "If you’re having trouble clicking the “Verify Email” button, copy and paste the URL below into your web browser.",
        verify_complete: "Verification complete!",
        create_input_password: "You’re almost there.\nPlease create your password.",
        confirm_password: "Confirm Password",
        not_matched: "Not matched",
        matched: "Matched",
        congratulations: "Congratulations!",
        create_account: "Your account has been succesfully created.\nPlease login to immerse yourself \nin the Städel Universe.",
        
        forgot_title: "Forgot your password?",
        forgot_desc: "Please enter your email address. You will receive a link to reset your password via email.",
        forgot_just_click: "Just click on the link in that email to reset your password.",
        forgot_email_intro: "Hi.\nDo you want to reset your password?\nIf you didn’t request this, you can delete this mail and carry on as you were",
        reset_password: "Reset Password",
        forgot_email_subject: "STÄDEL UNIVERSE: Reset your password",
        forgot_email_outro: "If you’re having trouble clicking the “Reset Password” button, copy and paste the URL below into your web browser.",
        reset_password_title: "Reset your password",
        plz_enter_password: "Please enter new password.",
        new_password: "New password",
        reset_complete_title: "Password Reset Complete.",
        reset_complete_desc: "Your account password has been reset. You may sign in using your new password.",

        discover_headline: "Discover even more of the Städel",
        discover_ticket_shop_btn: "TO THE TICKET SHOP",
        discover_ticket_shop_desc: "Major special exhibitions and 700 years of art under a single roof",
        discover_visit_plan_btn: "PLAN YOUR VISIT NOW",
        discover_visit_plan_desc: "Located right on the bank of the Main with a sculpture garden, shop, café and restaurant and the new “Städel Rooftop",
        discover_now_btn: "DISCOVER NOW",
        discover_now_desc: "From guided tours and workshops to events. Enjoy our diverse programme!",
        discover_digital_offer_btn: "TO THE DIGITAL OFFERS",
        discover_digital_offer_desc: "Delve deeper with the Digitoral, online course, Digital Collection and more",
        
        outro_name_libra: "Libra",
        outro_name_scorpius: "Scorpio",
        outro_name_cancer: "Cancer",
        outro_name_aquarius: "Aquarius",
        outro_name_pisces: "Pisces",
        outro_name_taurus: "Taurus",
        outro_name_virgo: "Virgo",
        outro_name_sagittarius: "Sagittarius",
        outro_name_aries: "Aries",
        outro_name_gemini: "Gemini",
        outro_name_capricorn: "Capricorn",
        outro_name_leo: "Leo",

        outro_text_libra: "On an Even Keel?", 
        outro_text_scorpius: "How Much Is Enough?",
        outro_text_cancer: "Work hard and<br/> you'll be richly rewarded",
        outro_text_aquarius: "Turning Crisis into Opportunity",
        outro_text_pisces: "Is curiosity the key to progress?",
        outro_text_taurus: "Where Does Time Go?",
        outro_text_virgo: "How can we be more compassionate?",
        outro_text_sagittarius: "How Do I Know What Love Is?",
        outro_text_aries: "Does Evil Lurk in All of Us?",
        outro_text_gemini: "How Do I Know Who I Am?",
        outro_text_capricorn: "Home Is Where the Heart Is",
        outro_text_leo: "Is it alright for an artist to present<br/> something that can’t possibly be true?",
    },
};

export default en;
