import { render, staticRenderFns } from "./ArtsList.vue?vue&type=template&id=b2304886&scoped=true&"
import script from "./ArtsList.vue?vue&type=script&lang=js&"
export * from "./ArtsList.vue?vue&type=script&lang=js&"
import style0 from "./ArtsList.vue?vue&type=style&index=0&id=b2304886&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2304886",
  null
  
)

export default component.exports