<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 115 159"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M111.021 7.69838C113.147 7.69838 114.87 5.97504 114.87 3.84919C114.87 1.72334 113.147 0 111.021 0C108.895 0 107.172 1.72334 107.172 3.84919C107.172 5.97504 108.895 7.69838 111.021 7.69838Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M17.6929 64.4757C19.8188 64.4757 21.5421 62.7524 21.5421 60.6265C21.5421 58.5007 19.8188 56.7773 17.6929 56.7773C15.5671 56.7773 13.8438 58.5007 13.8438 60.6265C13.8438 62.7524 15.5671 64.4757 17.6929 64.4757Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M32.107 158.777C34.2328 158.777 35.9562 157.053 35.9562 154.927C35.9562 152.801 34.2328 151.078 32.107 151.078C29.9812 151.078 28.2578 152.801 28.2578 154.927C28.2578 157.053 29.9812 158.777 32.107 158.777Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M102.334 144.348C104.459 144.348 106.183 142.624 106.183 140.499C106.183 138.373 104.459 136.649 102.334 136.649C100.208 136.649 98.4844 138.373 98.4844 140.499C98.4844 142.624 100.208 144.348 102.334 144.348Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M87.8804 78.9083C90.0063 78.9083 91.7296 77.185 91.7296 75.0591C91.7296 72.9333 90.0063 71.21 87.8804 71.21C85.7546 71.21 84.0312 72.9333 84.0312 75.0591C84.0312 77.185 85.7546 78.9083 87.8804 78.9083Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M102.837 140.974L69.1563 123.653L44.1366 125.577L31.6267 154.446L2.75781 109.218V87.0855H18.1546L17.1923 60.1411L112.46 2.40332"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M17.2031 60.1455L53.7704 79.3914L86.4885 74.58"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M2.75338 111.229C3.86414 111.229 4.76458 110.329 4.76458 109.218C4.76458 108.108 3.86414 107.207 2.75338 107.207C1.64263 107.207 0.742188 108.108 0.742188 109.218C0.742188 110.329 1.64263 111.229 2.75338 111.229Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M2.75338 89.1005C3.86414 89.1005 4.76458 88.2001 4.76458 87.0894C4.76458 85.9786 3.86414 85.0781 2.75338 85.0781C1.64263 85.0781 0.742188 85.9786 0.742188 87.0894C0.742188 88.2001 1.64263 89.1005 2.75338 89.1005Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M18.1674 89.1005C19.2782 89.1005 20.1786 88.2001 20.1786 87.0894C20.1786 85.9786 19.2782 85.0781 18.1674 85.0781C17.0567 85.0781 16.1562 85.9786 16.1562 87.0894C16.1562 88.2001 17.0567 89.1005 18.1674 89.1005Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M53.7534 81.4033C54.8641 81.4033 55.7646 80.5028 55.7646 79.3921C55.7646 78.2813 54.8641 77.3809 53.7534 77.3809C52.6426 77.3809 51.7422 78.2813 51.7422 79.3921C51.7422 80.5028 52.6426 81.4033 53.7534 81.4033Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M44.1206 127.597C45.2313 127.597 46.1318 126.696 46.1318 125.585C46.1318 124.475 45.2313 123.574 44.1206 123.574C43.0098 123.574 42.1094 124.475 42.1094 125.585C42.1094 126.696 43.0098 127.597 44.1206 127.597Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M69.1675 125.67C70.2782 125.67 71.1786 124.769 71.1786 123.659C71.1786 122.548 70.2782 121.647 69.1675 121.647C68.0567 121.647 67.1562 122.548 67.1562 123.659C67.1562 124.769 68.0567 125.67 69.1675 125.67Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
