<template>
    <div class="modal-wrapper">
        <div class="modal-wrapper-content">
            <h3>{{ modalTitle }}</h3>
            <div class="btn-wrapper">
                <button @click="$router.go(-1)" class="btn">
                    {{ $t("message.answer_popup_button_one") }}
                </button>
                <button @click="handleModalClick" class="btn">
                    {{ $t("message.answer_popup_button_two") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modalTitle: {
            type: String,
        },
    },
    emits: ["closeBtn"],

    methods: {
        handleModalClick() {
            this.$emit("closeTimeOutModal");
        },
    },
};
</script>

<style scoped>
.modal-wrapper {
    padding: 2.5rem;
}
.modal-wrapper-content {
    width: 100%;
    background: var(--primary-black);
    border: 1px solid var(--primary-white);
    border-radius: 12px;
    padding: 2rem;
}
h3 {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-semibold);
    font-size: 3rem;
    font-style: normal;
    line-height: 100%; /* 30px */
    text-transform: capitalize;
}
.btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}
.btn-round {
    width: 100%;
    height: auto;
    background-color: transparent;
    border: 2px solid var(--primary-orange-color);
    color: black;
    font-family: var(--primary-font-medium);
}
.btn-round:active {
    background-color: var(--primary-orange-color);
    color: white;
}
</style>
