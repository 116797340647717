<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 158 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M154.021 8.50306C156.147 8.50306 157.87 6.77972 157.87 4.65388C157.87 2.52803 156.147 0.804688 154.021 0.804688C151.895 0.804688 150.172 2.52803 150.172 4.65388C150.172 6.77972 151.895 8.50306 154.021 8.50306Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M126.123 151.884C128.248 151.884 129.972 150.161 129.972 148.035C129.972 145.909 128.248 144.186 126.123 144.186C123.997 144.186 122.273 145.909 122.273 148.035C122.273 150.161 123.997 151.884 126.123 151.884Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M148.232 36.4113C150.358 36.4113 152.081 34.6879 152.081 32.5621C152.081 30.4362 150.358 28.7129 148.232 28.7129C146.106 28.7129 144.383 30.4362 144.383 32.5621C144.383 34.6879 146.106 36.4113 148.232 36.4113Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M3.90388 134.565C6.02972 134.565 7.75306 132.841 7.75306 130.715C7.75306 128.59 6.02972 126.866 3.90388 126.866C1.77803 126.866 0.0546875 128.59 0.0546875 130.715C0.0546875 132.841 1.77803 134.565 3.90388 134.565Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M4.38281 131.194L21.7042 117.722L82.3289 89.8154L148.727 31.1152L132.368 136.968L126.595 148.515L64.0452 146.591L29.4025 137.93L4.38281 131.194Z"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M148.711 31.1184L153.522 6.09863"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M64.0581 148.601C65.1688 148.601 66.0693 147.701 66.0693 146.59C66.0693 145.48 65.1688 144.579 64.0581 144.579C62.9473 144.579 62.0469 145.48 62.0469 146.59C62.0469 147.701 62.9473 148.601 64.0581 148.601Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M132.363 138.977C133.474 138.977 134.374 138.077 134.374 136.966C134.374 135.856 133.474 134.955 132.363 134.955C131.252 134.955 130.352 135.856 130.352 136.966C130.352 138.077 131.252 138.977 132.363 138.977Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M82.3159 91.832C83.4266 91.832 84.3271 90.9316 84.3271 89.8208C84.3271 88.71 83.4266 87.8096 82.3159 87.8096C81.2051 87.8096 80.3047 88.71 80.3047 89.8208C80.3047 90.9316 81.2051 91.832 82.3159 91.832Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M22.6596 119.739C23.7704 119.739 24.6708 118.839 24.6708 117.728C24.6708 116.617 23.7704 115.717 22.6596 115.717C21.5489 115.717 20.6484 116.617 20.6484 117.728C20.6484 118.839 21.5489 119.739 22.6596 119.739Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
