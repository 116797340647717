var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-container",attrs:{"id":_vm.activeSlideIndex}},[_c('div',{staticClass:"close-icon",on:{"click":function($event){return _vm.$emit('closeBtn')}}},[_vm._v("X")]),_vm._l((_vm.slides),function(slide){return _c('div',{key:slide.order,staticClass:"slide",class:slide.position},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(`message.${slide.name}`)))]),_c('p',{staticClass:"constellation-name"},[_vm._v(_vm._s(_vm.$t(`message.${slide.group}`)))]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":_vm.image(slide),"alt":""}}),(!slide.isComplete)?_c('div',{staticClass:"instructions"},[_vm._m(0,true),_c('router-link',{staticClass:"btn",attrs:{"to":{
                        name: 'SelectImage',
                        params: {
                            id: slide.group,
                            zodiacName: slide.name,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.$t("message.play")))])],1):_vm._e()]),_c('section',{staticClass:"book-content"},[(slide.isComplete)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(slide.book_of_knowledge)}})]):_c('div',[_c('IconPadlock')],1)])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" You have not collected this book yet. "),_c('br'),_vm._v("Complete the constellation to earn it! ")])
}]

export { render, staticRenderFns }