<template>
    <div class="main-container">
        <div class="modal-content">
            <span @click="nextSlide" class="slider-btn next">
                <ArrowIcon />
            </span>
            <span @click="prevSlide" class="slider-btn prev">
                <ArrowIcon />
            </span>
            <SlidersBooks
                :activeSlideIndex="slideActive"
                :slides="slides"
                v-if="showSlides"
                @closeBtn="$emit('closeModal')"
            />
            <div class="indicator">
                <span
                    v-for="item in 12"
                    :class="{
                        isActiveBook: slideActive == item - 1,
                    }"
                    :key="item"
                >
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import ArrowIcon from "../icons/ArrowIcon.vue";
import SlidersBooks from "./SlidersBooks.vue";
export default {
    components: {
        ArrowIcon,
        SlidersBooks,
    },
    props: {
        slides: {
            type: Array,
        },
        activeSlideIndex: {
            type: Number,
        },
    },
    mounted() {
        this.slideActive = this.activeSlideIndex;
        this.orderSlides(this.slides, this.activeSlideIndex);
    },
    data() {
        return {
            showSlides: false,
            slideActive: 0,
        };
    },

    methods: {
        nextSlide() {
            ++this.slideActive;
            this.displayOrder(this.slideActive);
        },
        prevSlide() {
            --this.slideActive;
            this.displayOrder();
        },
        displayOrder() {
            if (this.slideActive === this.slides.length) {
                this.slideActive = 0;
            } else if (this.slideActive < 0) {
                this.slideActive = this.slides.length - 1;
            }

            this.orderSlides(this.slides, this.slideActive);
        },
        orderSlides(array, activeIndex) {
            array.forEach((element, idx) => {
                element.position = "nextSlide";
                if (idx === activeIndex) {
                    element.position = "activeSlide";
                } else if (
                    idx === activeIndex - 1 ||
                    (activeIndex === 0 && idx === array.length - 1)
                ) {
                    element.position = "lastSlide";
                }
            });
            if (!this.showSlides) {
                this.showSlides = true;
            }
        },
    },
};
</script>

<style scoped>
.main-container {
    height: calc(100vh - 50px);
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    display: grid;
    place-content: center;
    color: black;
    position: fixed;
    top: 0;
    left: 0;
}
.modal-content {
    width: 100vw;
    height: 100vh;
    position: relative;
}
.slider-btn {
    position: absolute;
    top: 30px;
    z-index: 1;
    /* background: var(--primary-gray); */
    width: 30px;
    height: 30px;
    display: grid;
    place-content: center;
    border-radius: 50%;
    margin: 0 3rem;
}
.slider-btn svg {
    fill: var(--primary-white);
}
.next {
    right: 0;
}
.next svg {
    transform: rotate(-180deg);
}

.prev {
    left: 0;
}

span svg {
    width: 10px;
}

.indicator {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    margin-top: 0.5rem;
}
.indicator span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid var(--primary-white);
}
.isActiveBook {
    background: var(--primary-white);
}
</style>
