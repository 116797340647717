<template :key="zodiacName">
    <main>
        <div>
            <div class="main-container choose-img-container">
                <DragImage
                    v-for="art in artsByConstellation"
                    :key="art.id"
                    :art="art"
                    :counter="counter"
                    @click.native="handleArtClick(art)"
                />

                <SelectArtModal
                    @closeBtn="closeModal"
                    v-if="showArtDetailModal"
                    :art="selectedArt"
                    :counter="counter"
                    :canCollectBook="canCollectBook"
                    @modalClick="showArtDetailModal = false"
                />
                <Modal
                    v-if="artCompleted"
                    @closeBtn="closeModal"
                    :modalContent="modalContent"
                />

                <FormInput
                    :art="selectedArt"
                    v-if="showEnterCodeForm"
                    @closeBtn="showEnterCodeForm = !showEnterCodeForm"
                />
            </div>
        </div>
    </main>
</template>

<script>
import { mapState } from "vuex";
import { windowHeight } from "../../helperFunctions/utils";
import { cardDragAnimation } from "../../helperFunctions/animations/cardDragAnimation.js";
import {
    DragImage,
    FormInput,
    SelectArtModal,
    Modal,
} from "@/components/index.js";

export default {
    components: {
        FormInput,
        DragImage,
        SelectArtModal,
        Modal,
    },

    mounted() {
        this.pageSetUp();
        windowHeight();
    },
    beforeDestroy() {
        cardDragAnimation(true);
    },

    data() {
        return {
            // Modal
            showArtDetailModal: false,
            artCompleted: false,

            selectedArt: {},

            showEnterCodeForm: false,
            zodiacName: this.$route.params.zodiacName,
            counter: 0,
            canCollectBook: false,

            modalContent: {
                title: `Well done you completed ${this.$route.params.zodiacName}!`,
                text: `You can know collected ${this.$route.params.zodiacName}'s books of
            knowledge`,
                btnText: "Collect",
                btnAction: { name: "BooksArchive" },
            },
        };
    },

    computed: {
        ...mapState({
            artsByConstellation: (state) => state.gameState.artsByConstellation,
        }),
    },

    methods: {
        async pageSetUp() {
            await this.$store.dispatch("FETCH_ARTS_BY_GROUP_AND_CONSTELLATION");

            this.artsByConstellation.forEach((art) => {
                if (art.isFound) {
                    this.counter++;
                }
            });

            let core_art = this.artsByConstellation.find(
                (art) => art.core_art === true
            );

            if (!core_art.can_play) {
                this.$router.push({ name: "SelectConstellation" });
            }

            if (core_art.is_completed) this.allArtsCompleted();
            cardDragAnimation();
        },

        handleArtClick(art) {
            this.selectedArt = art;
            if (
                this.selectedArt.room.toLowerCase() === "digital collection" ||
                this.selectedArt.room.toLowerCase() === "digitale cammlung" ||
                this.selectedArt.isFound
            ) {
                this.showArtDetailModal = true;
            }
            if (art.core_art && this.counter !== 2) {
                this.showArtDetailModal = true; // TODO before it was showing core art message
            }

            if (!art.core_art && this.counter !== 2 && !art.isFound) {
                this.showEnterCodeForm = !this.showEnterCodeForm;
            } else if (
                this.selectedArt.core_art &&
                this.counter == 2 &&
                !this.selectedArt.isFound
            ) {
                this.showEnterCodeForm = true;
            }
        },

        closeModal() {
            this.showEnterCodeForm = false;
            this.showArtDetailModal = false;
            this.artCompleted = false;
        },
        allArtsCompleted() {
            this.artCompleted = true;
            this.canCollectBook = true;
        },
    },
};
</script>

<style scoped>
main {
    overflow: hidden;
    position: relative;
    max-width: 375px;
    margin: 0 auto;
}

.choose-img-container {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
}
</style>
