<template>
    <main>
        <h2>Page not found</h2>
        <router-link class="btn" to="/">Back Home</router-link>
    </main>
</template>

<script>
export default {};
</script>

<style scoped>
main {
    height: 90vh;
    display: grid;
    place-content: center;
    gap: 1rem;
}
</style>
