<template>
    <main>
        <div v-if="stats.available_records">
            <h1>{{ selectedYear }}/{{ selectedMonth }}</h1>
            <!-- select month -->
            <select @change="getData" v-model="selectedYear">
                <option
                    v-for="year in stats.available_records.years"
                    :key="year"
                >
                    {{ year }}
                </option>
            </select>
            <!-- select year -->
            <select @change="getData" v-model="selectedMonth">
                <option
                    v-for="month in stats.available_records.months"
                    :key="month"
                >
                    {{ month }}
                </option>
            </select>
        </div>
        <section>
            <p>New players: {{ stats.new_player }}</p>
            <h3>Games by language</h3>
            <p v-for="(key, value, idx) in stats.games_by_lang" :key="idx">
                {{ value }}: {{ key }}
            </p>
        </section>
        <section>
            <h2>Games by languages</h2>
            <select @change="updateChart" v-model="selectedLang">
                <option v-for="(lang, idx) in languages" :key="idx">
                    {{ lang }}
                </option>
            </select>
            <p>New players: {{ selected.players }}</p>
            <p>Total games: {{ selected.total_games }}</p>
            <p>Core Art completed {{ selected.total_core_art_completed }}</p>
            <p>
                Total number of levels completed
                {{ selected.total_levels_completed }}
            </p>
        </section>
        <section>
            <h2>{{ selectedStat.replaceAll("_", " ") }}</h2>
            <select @change="updateChart" v-model="selectedStat">
                <option v-for="(option, idx) in statsList" :key="idx">
                    {{ option.replaceAll("_", " ") }}
                </option>
            </select>
            <apexchart
                v-show="showStats"
                width="100%"
                height="300"
                type="bar"
                :options="chartOptions"
                :series="series"
            />
        </section>
    </main>
</template>

<script>
import gameApi from "../../api/gameApi";

export default {
    data() {
        return {
            selected: {},
            stats: [],
            chartOptions: {},
            series: [],
            selectedStats: {},

            selectedLang: "english",
            selectedStat: "activities",

            statsList: [
                "activities",
                "most_completed_core_art",
                "most_completed_levels",
            ],
            languages: ["german", "english"],
            selectedYear: null,
            selectedMonth: null,
            showStats: false,
        };
    },
    mounted() {
        this.getData();
    },

    methods: {
        async getData() {
            try {
                this.showStats = false;
                let data = null;
                if (!this.selectedMonth || !this.selectedYear) {
                    data = await gameApi("/stats/month-summary");
                } else {
                    data = await gameApi(
                        `/stats/month-summary?year=${this.selectedYear}&&month=${this.selectedMonth}`
                    );
                }

                this.stats = data.data;

                if (!this.selectedMonth || !this.selectedYear) {
                    this.selectedYear = this.stats.available_records.years[0];
                    this.selectedMonth = this.stats.available_records.months[0];
                }
                this.selected = this.stats[this.selectedLang];
                this.updateChart();
                this.showStats = true;
            } catch (error) {
                console.log(error);
            }
        },
        updateChart() {
            this.setChart(
                this.stats[this.selectedLang][
                    this.selectedStat.replaceAll(" ", "_")
                ]
            );
        },

        setChart(selectedStats) {
            // https://github.com/apexcharts/vue-apexcharts
            this.series = [
                {
                    name: this.selected,
                    data: Object.values(selectedStats),
                },
            ];
            this.chartOptions = {
                chart: {
                    id: this.selected.length,
                    foreColor: "var(--primary-white)",
                },

                xaxis: {
                    categories: Object.keys(selectedStats),
                },
            };
        },
    },
};
</script>

<style scoped>
main {
    max-width: unset;
}
select {
    display: block;
    width: 60%;
    max-width: 300%;
    margin: 1rem auto;
    height: 30px;
    border-radius: 20px;
    text-align: center;
    font-size: 1.25rem;
}
p {
    text-align: center;
}

section {
    margin: 2rem auto;
    max-width: 1260px;
}
</style>
