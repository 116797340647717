<template>
    <div class="stories-container" :class="isDragArt && 'drag-art'">
        <IconStarStory
            v-for="(story, idx) in activity_games"
            :key="idx"
            class="stories-count"
            :class="{
                isFound:
                    story.is_completed ||
                    story.isCollected ||
                    story.isCompleted,
            }"
        />
    </div>
</template>

<script>
import IconStarStory from "../icons/IconStarStory";
export default {
    components: {
        IconStarStory,
    },
    props: {
        activity_games: {
            type: Array,
        },
        isFound: {
            type: Boolean,
        },
        isDragArt: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style>
.stories-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.drag-art {
    position: absolute;
    bottom: 17px;
    right: 10px;
}
.isFound {
    fill: white;
 }
.isFound path {
    opacity: 1;
}
</style>
