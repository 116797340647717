<template>
    <div class="switcher">
        <input type="checkbox" id="checkbox" v-model="checkbook"
            @click="$emit('localeChanged', checkbook?'de':'en')" />
        <label for="checkbox" checked>
            <p
                :class="{ activeLang: $i18n.locale === lang.toLowerCase() }"
                class="font-semibold"
                v-for="(lang, i) in langs"
                :key="`Lang${i}`"
            >
                {{ lang }}
            </p>
            <div class="ball"></div>
        </label>
    </div>
</template>

<script>
export default {
    mounted() {
        this.checkbook = this.$i18n.locale === "en" ? true : false;
    },
    data() {
        return { 
            checkbook: false, 
            langs: ["De", "En"] 
        };
    },
    // watch: {
    //     checkbook(newValue) {
    //         newValue ? (this.$i18n.locale = "en") : (this.$i18n.locale = "de");
    //         document.querySelector("html").lang = this.$i18n.locale;
    //         this.$emit("localeChanged");
    //     },
    // },
};
</script>

<style scoped>
.switcher {
    width: 112px;
    height: 60px;
    background: var(--primary-white);
    border-radius: 27px;
    margin: 0 auto;
    position: relative;
    align-self: end;
}
input {
    visibility: hidden;
}
input,
label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    color: black;
    font-size: 20px;
}

.activeLang {
    color: var(--primary-white);
}
.ball {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 6px;
    background: var(--primary-gray);
    border-radius: 100%;
    transform: translateY(-50%);
    transition: all 0.3s linear;
    z-index: -1;
}
input:checked + label .ball {
    transform: translate(50px, -50%);
}
</style>
