<template>
    <div class="content-container">
        <section class="section-title">
            <h2>{{ message }}</h2>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        currentArt: {
            type: Object,
        },
        totalNumberOfKeys: {
            type: Number,
        },
        numberOfKeyFound: {
            type: Number,
        },
    },
    mounted() {
        this.artProgress();
    },
    data() {
        return {
            arts: [],
            message: "",
            counter: 0,

            canPlayCoreArt: false,
            allStoriesCompleted: false,
        };
    },
    methods: {
        artProgress() {
            // localStorage.removeItem("currentArt");
            // if (this.currentArt.activity_games) {

            let counter = 0;
            this.currentArt.activity_games.forEach((activity) => {
                if (activity.is_completed) {
                    ++counter;
                }
            });

            if (!this.currentArt.core_art) {
                if (this.currentArt.activity_games.length === counter) {
                    this.allStoriesCompleted = true;
                    this.$emit("allStoriesCompleted", true);
                }

                if (this.totalNumberOfKeys !== this.numberOfKeyFound) {
                    if (counter !== 1) {
                        this.message = `Congratulations, you collected ${counter} out ${this.currentArt.activity_games.length}  stories`;
                    } else {
                        this.message = `You have found the key of "${this.currentArt.title}" you need to find one more key to unlocked the core art.`;
                    }
                } else if (this.totalNumberOfKeys == this.numberOfKeyFound) {
                    if (counter === 1) {
                        this.message = `You have found the key of "${this.currentArt.title}" and you unlock the core art.`;
                    } else {
                        this.message = `Congratulations, you collected ${counter} out ${this.currentArt.activity_games.length}  stories`;
                    }
                    this.canPlayCoreArt = true;
                }
            } else {
                this.message = `Congratulations, you collected "The book of knowledge"`;
                // this.message = `Congratulations, you need to collected ${storiesMissing} more ${storiesOrStory}  to earn the book of knowledge`;
            }
        },
    },
};
</script>

<style scoped>
.content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    text-align: center;
}

.section-title {
    border-bottom: 0.5px solid var(--primary-white);
    padding: 31px 0;
}
</style>
