var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-page"},[_c('span',{staticClass:"arrow-btn next",on:{"click":function($event){return _vm.$emit('handlePrevPageClick')}}},[_c('ArrowIcon')],1),_c('span',{staticClass:"arrow-btn prev",on:{"click":function($event){return _vm.$emit('handleNextPageClick')}}},[_c('ArrowIcon')],1),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(`message.${_vm.constellation.name}`)))]),_c('p',{staticClass:"constellation-name"},[_vm._v(" "+_vm._s(_vm.$t(`message.${_vm.constellation.group}`))+" ")]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"image-link",on:{"click":function($event){return _vm.handleNavigation(
                        _vm.constellation.group,
                        _vm.constellation.name
                    )}}},[_c('img',{attrs:{"src":_vm.image(_vm.constellation),"alt":""}})]),(!_vm.constellation.is_completed)?_c('div',{staticClass:"instructions"},[_vm._m(0),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.handleNavigation(
                            _vm.constellation.group,
                            _vm.constellation.name
                        )}}},[_vm._v(" "+_vm._s(_vm.$t("message.play"))+" ")])]):_vm._e()]),_c('section',{staticClass:"book-content"},[(_vm.constellation.is_completed)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.constellation.book_of_knowledge)}})]):_c('div',[_c('IconPadlock')],1)]),(!_vm.constellation.can_play)?_c('div',{staticClass:"level-overlay"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("message.locked_constellation"))+" "),_c('br'),_vm._v(_vm._s(_vm.constellation.level)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("message.locked_constellation"))+" ")])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" You have not collected this book yet. "),_c('br'),_vm._v("Complete the constellation to earn it! ")])
}]

export { render, staticRenderFns }