<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19 1L1 19" :stroke="color" />
        <path d="M0.999999 1L19 19" :stroke="color" />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "white",
        },
    },
};
</script>
