<template>
    <div class="container">
        <zoom-effect v-if="showZoomEffect" @zoomCompleted="onZoomCompleted()" />
        <auth-navbar />
        <div class="form">
            <div class="text-area">
                <div class="title">
                    {{ $t('message.intro_title') }}
                </div>
                <div class="greetings">
                    {{ $t('message.login_desc') }}
                </div>
            </div>
            <div>
                <label for="email">
                    {{ $t('message.email') }}
                </label>
                <input class="email-input" name="email"
                    v-model="email"
                    placeholder="Email address" required/>
                <div class="email-text-msg">
                  {{ email === '' ? '': emailTextMsg }}
                </div>

            
                <label for="password">{{ $t('message.password') }}</label>
                <div class="password-input-area">
                    <input class="password" name="password" maxlength="20" 
                        :type="showPassword? 'text': 'password'"
                        v-model="password" required />
                    <button @click="showPassword=!showPassword">
                        <img v-if="!showPassword" src="@/assets/img/auth/hidden.png" 
                            alt="hidden" class="password-hidden" />
                        <img v-else src="@/assets/img/auth/show.png" 
                            alt="visible" class="password-hidden" />  
                    </button>   
                </div>

                <div id="try-again">
                {{ isPasswordWrong ? $t('message.try_again'): ''}}
                </div>
            
                <div id="forgot-password" @click="$router.push({path: 'password-reset-step1'})">
                    {{ $t('message.forgot_password') }}
                </div>
                <button type="submit" class="btn-submit" @click="login()"
                    :style="{ background: password === '' || !checkEmail(email) ? '#E8E8E8' : '#DA8E61' }"
                    :disabled= "!checkEmail(email) || password===''">
                    {{ $t('message.login') }}
                </button>
            </div>
            <div class="footer">
                <a :href="$i18n.locale==='de' ? 
                    'https://www.staedelmuseum.de/de/impressum':
                    'https://www.staedelmuseum.de/en/imprint'" target=“_blank”>
                    {{ $t('message.imprint') }}
                </a>
                <div class="separator">|</div>
                <a :href="$i18n.locale==='de' ? 
                    'https://www.staedelmuseum.de/de/datenschutz':
                    'https://www.staedelmuseum.de/en/privacy-policy'" target=“_blank”>
                    {{ $t('message.privacy_policy_word')}}
                </a>
            </div>
        </div>
        <msg-modal v-if="isMsgModalShow"
            :msg="msg"
            :type="msgType"
            @close="isMsgModalShow=false" />
    </div>

</template>
  
<script>
export default {
    name: 'Signin',
    components: {
        'msg-modal': () => import('@/components/ui/MsgModal.vue'),
        'auth-navbar': () => import('@/components/ui/AuthNavbar.vue'),
        'zoom-effect': () => import('@/components/intro/ZoomEffect.vue')
    },
    data() {
        return {
            email: '',
            password: '',
            isMsgModalShow: false,
            msg: '', // 'login...',
            msgType: 'ing',
            isPasswordWrong: false,
            unRegisteredUser: false,
            emailTextMsg: '',
            showPassword: false,
            showZoomEffect: false,
        }
    },
    methods: {
        checkEmail(email) {
            if (email === '') {
                    this.emailTextMsg = '';
                    return false;
                }
                else {
                    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    if (!emailRegExp.test(email)) {
                        this.emailTextMsg = this.$t('message.valid_email');
                        return false;
                    }
                }
                this.emailTextMsg = '';
                return true;
            },
        async login() {
            this.isMsgModalShow = true;
            try {
                const response = await this.$store.dispatch("LOGIN_USER", {
                    email: this.email,
                    password: this.password,
                });

                console.log(response);
                if (response.status === 200) {
                    console.log(response.data.game_id);
                    localStorage.setItem('game__id', response.data.game_id);
                    localStorage.setItem('player__id', response.data.player_id);
                    localStorage.setItem('selectConstellationName', response.data.constellation);
                            
                    this.isMsgModalShow = false;
                    
                    if (response.data.game_id === null || response.data.constellation === null) {
                        this.showZoomEffect = true;
                        // this.$router.push("/");
                    } 
                    else {
                        this.$router.push("/select-constellation");   
                    }  
                }
            } catch (error) {
                console.log(error)
                if (error.response.status === 400 && error.response.data.msg === "unregistered user") {
                    // this.msg = '가입되지 않은 이메일입니다.';
                    // this.msgType = 'confirm';
                    this.unRegisteredUser = true;
                    this.isMsgModalShow = false;
                    // it is not because of password but same message.
                    this.isPasswordWrong = true;
                    console.log('unregistered user')
                }
                else if (
                    error.response.status === 400 &&
                    error.response.data.msg === "password is not correct"
                ) {
                    // this.msg = '비밀번호가 일치하지 않습니다.';
                    // this.msgType = 'confirm';
                    this.isMsgModalShow = false;
                    this.isPasswordWrong = true;
                }
                else {
                    // this.msg = '로그인에 실패했습니다.';
                    this.isMsgModalShow = false;
                    this.msgType = 'confirm';
                }       
            }
        },
        async onZoomCompleted() {
			this.showZoomEffect = false;
			this.$store.dispatch('GET_LOCAL_STORE');
            console.log(this.$store.getters.checkLocalStore)
			if (this.$store.getters.checkLocalStore) {
				this.$router.push("/select-constellation");
			} 
			else {
				this.$store.dispatch('START_NEW_GAME');
				this.$router.push('/new-intro');
			}
		},
    },
}
</script>
  
<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f5f5f5;
}

.container {
    width: 100%;
    height: 110vh;
}

.form {
    width: 100%;
    height: 600px;
    left: 0px;
    top: 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10%;
}


.email-input {
    width: 100%;
    height: 44px;
    border-color:#EEEEEE;
    background-color: #EEEEEE;
    font-size: 20px;
    margin-top: 10px;
    padding: 20px;
    color: black;
    text-align: left;
    border-width: 0px;
}

.empty-space {
    height: 50px;
}

.text-area {
    font-size: 20px;
    margin-bottom: 20px;
    color: #000000;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: left;
    justify-content: left;
    align-items: left;
    justify-items: left;
}
.title {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;

}
.greetings {
    font-style: normal;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 60px;
}

.password {
    border-width: 0px;
    width: 100%;
    height: 31px;
    text-align: left;
    outline: none;
    color: #000000;
    padding-left: 16px;
}
.password-hidden {
    width: 30px;
    height: 30px;
}
.password-input-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 6px solid #EEEEEE;
    border-radius: 2px;
    background-color: #f5f5f5;
    font-size: inherit;
    margin-top: 10px;
    margin-bottom: 5px;
    color: black;
    padding: 4px;
}

label {
    color: #000000;
    font-size: 20px;
}

.btn-submit {
    color: #000000;
    font-size: 20px;
    margin-top: 60px;
    height: 50px;
    width: 100%;
}
#try-again, #unregisterd-email {
    color: #FE0000;
    font-size: 12px;
    margin-top: 10px;
    padding-left:20px;

}
#forgot-password {
    color: #000000;
    font-size: 14px;
    margin-top: 40px;
    display: flex;
    justify-content: center; 
}
.footer {
    display: flex; 
    justify-content: center; 
    margin-top: 20px;
    color:#000000;
    font-size: 17px;
}
.separator {
    margin-left: 10px;
    margin-right: 10px;

}
input {
    font-size: 20px;
}
.email-text-msg {
    height: 20px;
    margin-bottom: 40px;
    color: #FE0000;
    padding-left: 20px;
}
a {
    color: black;
}
</style>
