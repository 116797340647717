<template>
    <svg
        v-if="!isCompletedOrUnlocked"
        width="28" height="35"
        viewBox="0 0 28 35" fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M6 14V9C6 4.58172 9.58172 1 14 1V1C18.4183 1 22 4.58172 22 9V14" stroke="white" stroke-width="1.5"/>
        <rect x="1" y="14" width="26" height="20" stroke="white" stroke-width="1.5"/>
    </svg>
    <svg
        v-else
        width="28" height="41"
        viewBox="0 0 28 41" fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M6.00006 10.5723L8.90963 6.506C11.4807 2.91284 16.4778 2.08425 20.0709 4.65531V4.65531C23.6641 7.22637 24.4927 12.2235 21.9216 15.8166L19.0121 19.8829" stroke="white" stroke-width="1.5"/>
        <rect x="1" y="20" width="26" height="20" stroke="white" stroke-width="1.5"/>
    </svg>
</template>

<script>
export default {
    props: {
        isCompletedOrUnlocked: {
            type: Boolean,
            default: false,
        },
        isFound: {
            type: Boolean,
            default: false,
        },
        constellation: {
            type: String,
            default: "",
        },
    },
};
</script>

<style scoped>
.svg {
    fill: var(--primary-white);
}
</style>
