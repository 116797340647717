export default [
    {
        constellationID: 1,
        id: 1,
        name: "aries",
        group: "fire",
        order: 1,
        position: "",
    },
    {
        constellationID: 2,
        id: 2,
        name: "leo",
        group: "fire",
        order: 5,
        position: "",
    },
    {
        constellationID: 3,
        id: 3,
        name: "sagittarius",
        group: "fire",
        order: 9,
        position: "",
    },

    {
        constellationID: 4,
        id: 1,
        name: "gemini",
        group: "air",
        order: 3,
        position: "",
    },
    {
        constellationID: 5,
        id: 2,
        name: "libra",
        group: "air",
        order: 7,
        position: "",
    },
    {
        constellationID: 6,
        id: 3,
        name: "aquarius",
        group: "air",
        order: 11,
        position: "",
    },

    {
        constellationID: 7,
        id: 1,
        name: "taurus",
        group: "earth",
        order: 2,
        position: "",
    },
    {
        constellationID: 8,
        id: 2,
        name: "virgo",
        group: "earth",
        order: 6,
        position: "",
    },
    {
        constellationID: 9,
        id: 3,
        name: "capricorn",
        group: "earth",
        order: 10,
        position: "",
    },

    {
        constellationID: 10,
        id: 1,
        name: "cancer",
        group: "water",
        order: 4,
        position: "",
    },
    {
        constellationID: 11,
        id: 2,
        name: "scorpius",
        group: "water",
        order: 8,
        position: "",
    },
    {
        constellationID: 12,
        id: 3,
        name: "pisces",
        group: "water",
        order: 12,
        position: "",
    },
];

// export default [
//     {
//         id: 1,
//         name: "fire",
//         isComplete: false,
//         constellations: [
//             {
//                 constellationID: 1,
//                 id: 1,
//                 name: "aries",
//                 group: "fire",
//                 order: 1,
//                 position: "",
//             },
//             {
//                 constellationID: 2,
//                 id: 2,
//                 name: "leo",
//                 group: "fire",
//                 order: 5,
//                 position: "",
//             },
//             {
//                 constellationID: 3,
//                 id: 3,
//                 name: "sagittarius",
//                 group: "fire",
//                 order: 9,
//                 position: "",
//             },
//         ],
//     },
//     {
//         id: 2,
//         name: "air",
//         isComplete: false,
//         constellations: [
//             {
//                 constellationID: 4,
//                 id: 1,
//                 name: "gemini",
//                 group: "air",
//                 order: 3,
//                 position: "",
//             },
//             {
//                 constellationID: 5,
//                 id: 2,
//                 name: "libra",
//                 group: "air",
//                 order: 7,
//                 position: "",
//             },
//             {
//                 constellationID: 6,
//                 id: 3,
//                 name: "aquarius",
//                 group: "air",
//                 order: 11,
//                 position: "",
//             },
//         ],
//     },
//     {
//         id: 3,
//         name: "earth",
//         isComplete: false,
//         constellations: [
//             {
//                 constellationID: 7,
//                 id: 1,
//                 name: "taurus",
//                 group: "earth",
//                 order: 2,
//                 position: "",
//             },
//             {
//                 constellationID: 8,
//                 id: 2,
//                 name: "virgo",
//                 group: "earth",
//                 order: 6,
//                 position: "",
//             },
//             {
//                 constellationID: 9,
//                 id: 3,
//                 name: "capricorn",
//                 group: "earth",
//                 order: 10,
//                 position: "",
//             },
//         ],
//     },
//     {
//         id: 4,
//         name: "water",
//         isComplete: false,
//         constellations: [
//             {
//                 constellationID: 10,
//                 id: 1,
//                 name: "cancer",
//                 group: "water",
//                 order: 4,
//                 position: "",
//             },
//             {
//                 constellationID: 11,
//                 id: 2,
//                 name: "scorpius",
//                 group: "water",
//                 order: 8,
//                 position: "",
//             },
//             {
//                 constellationID: 12,
//                 id: 3,
//                 name: "pisces",
//                 group: "water",
//                 order: 12,
//                 position: "",
//             },
//         ],
//     },
// ];
