<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 185 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M129.357 9.39076C131.483 9.39076 133.206 7.66742 133.206 5.54157C133.206 3.41572 131.483 1.69238 129.357 1.69238C127.231 1.69238 125.508 3.41572 125.508 5.54157C125.508 7.66742 127.231 9.39076 129.357 9.39076Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M81.2476 68.0861C83.3735 68.0861 85.0968 66.3627 85.0968 64.2369C85.0968 62.111 83.3735 60.3877 81.2476 60.3877C79.1218 60.3877 77.3984 62.111 77.3984 64.2369C77.3984 66.3627 79.1218 68.0861 81.2476 68.0861Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M31.2008 8.42592C33.3266 8.42592 35.0499 6.70258 35.0499 4.57673C35.0499 2.45088 33.3266 0.727539 31.2008 0.727539C29.0749 0.727539 27.3516 2.45088 27.3516 4.57673C27.3516 6.70258 29.0749 8.42592 31.2008 8.42592Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M180.326 43.0656C182.452 43.0656 184.175 41.3422 184.175 39.2164C184.175 37.0905 182.452 35.3672 180.326 35.3672C178.2 35.3672 176.477 37.0905 176.477 39.2164C176.477 41.3422 178.2 43.0656 180.326 43.0656Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M140.92 103.688C143.045 103.688 144.769 101.964 144.769 99.8384C144.769 97.7126 143.045 95.9893 140.92 95.9893C138.794 95.9893 137.07 97.7126 137.07 99.8384C137.07 101.964 138.794 103.688 140.92 103.688Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M4.25544 82.5226C6.38129 82.5226 8.10463 80.7993 8.10463 78.6734C8.10463 76.5476 6.38129 74.8242 4.25544 74.8242C2.12959 74.8242 0.40625 76.5476 0.40625 78.6734C0.40625 80.7993 2.12959 82.5226 4.25544 82.5226Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M81.2476 153.733C83.3735 153.733 85.0968 152.009 85.0968 149.883C85.0968 147.758 83.3735 146.034 81.2476 146.034C79.1218 146.034 77.3984 147.758 77.3984 149.883C77.3984 152.009 79.1218 153.733 81.2476 153.733Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M31.6719 5.05664L59.5785 18.5288L70.1638 16.6042L82.6736 8.90583"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M80.7572 149.402L42.2653 134.967L30.7178 116.684L3.77344 79.1542L11.4718 64.7197L34.5669 46.4361L70.1719 52.2099L81.7195 65.682L99.0409 52.2099L119.249 42.5869L125.985 64.7197L125.023 85.8903L139.457 99.3624"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M99.0486 52.2144L82.6895 40.6668L70.1797 16.6094"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M119.258 42.5862L128.881 5.05664"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M125.984 64.7199L150.042 66.6445L179.873 39.7002"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M41.7734 134.49L68.7178 121.018"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M83.8628 10.2969C84.9735 10.2969 85.874 9.3964 85.874 8.28564C85.874 7.17489 84.9735 6.27441 83.8628 6.27441C82.752 6.27441 81.8516 7.17489 81.8516 8.28564C81.8516 9.3964 82.752 10.2969 83.8628 10.2969Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M58.8315 19.9199C59.9423 19.9199 60.8427 19.0194 60.8427 17.9087C60.8427 16.7979 59.9423 15.8975 58.8315 15.8975C57.7208 15.8975 56.8203 16.7979 56.8203 17.9087C56.8203 19.0194 57.7208 19.9199 58.8315 19.9199Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M34.7768 48.7803C35.8876 48.7803 36.788 47.8798 36.788 46.769C36.788 45.6583 35.8876 44.7578 34.7768 44.7578C33.6661 44.7578 32.7656 45.6583 32.7656 46.769C32.7656 47.8798 33.6661 48.7803 34.7768 48.7803Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M70.4018 54.5596C71.5126 54.5596 72.413 53.6591 72.413 52.5483C72.413 51.4376 71.5126 50.5371 70.4018 50.5371C69.2911 50.5371 68.3906 51.4376 68.3906 52.5483C68.3906 53.6591 69.2911 54.5596 70.4018 54.5596Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M82.894 42.0488C84.0048 42.0488 84.9052 41.1483 84.9052 40.0376C84.9052 38.9268 84.0048 38.0264 82.894 38.0264C81.7833 38.0264 80.8828 38.9268 80.8828 40.0376C80.8828 41.1483 81.7833 42.0488 82.894 42.0488Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M99.2612 53.5986C100.372 53.5986 101.272 52.6982 101.272 51.5874C101.272 50.4766 100.372 49.5762 99.2612 49.5762C98.1504 49.5762 97.25 50.4766 97.25 51.5874C97.25 52.6982 98.1504 53.5986 99.2612 53.5986Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M126.214 67.0654C127.325 67.0654 128.226 66.165 128.226 65.0542C128.226 63.9434 127.325 63.043 126.214 63.043C125.104 63.043 124.203 63.9434 124.203 65.0542C124.203 66.165 125.104 67.0654 126.214 67.0654Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M150.261 68.9922C151.372 68.9922 152.272 68.0917 152.272 66.981C152.272 65.8702 151.372 64.9697 150.261 64.9697C149.15 64.9697 148.25 65.8702 148.25 66.981C148.25 68.0917 149.15 68.9922 150.261 68.9922Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M125.246 87.2773C126.356 87.2773 127.257 86.3769 127.257 85.2661C127.257 84.1554 126.356 83.2549 125.246 83.2549C124.135 83.2549 123.234 84.1554 123.234 85.2661C123.234 86.3769 124.135 87.2773 125.246 87.2773Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M119.488 44.9355C120.599 44.9355 121.499 44.0351 121.499 42.9243C121.499 41.8136 120.599 40.9131 119.488 40.9131C118.377 40.9131 117.477 41.8136 117.477 42.9243C117.477 44.0351 118.377 44.9355 119.488 44.9355Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M69.4018 122.877C70.5126 122.877 71.413 121.976 71.413 120.866C71.413 119.755 70.5126 118.854 69.4018 118.854C68.2911 118.854 67.3906 119.755 67.3906 120.866C67.3906 121.976 68.2911 122.877 69.4018 122.877Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M42.4956 136.357C43.6063 136.357 44.5068 135.457 44.5068 134.346C44.5068 133.235 43.6063 132.335 42.4956 132.335C41.3848 132.335 40.4844 133.235 40.4844 134.346C40.4844 135.457 41.3848 136.357 42.4956 136.357Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M11.7065 67.0654C12.8173 67.0654 13.7177 66.165 13.7177 65.0542C13.7177 63.9434 12.8173 63.043 11.7065 63.043C10.5958 63.043 9.69531 63.9434 9.69531 65.0542C9.69531 66.165 10.5958 67.0654 11.7065 67.0654Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
