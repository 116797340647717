var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"art-links-container"},[_vm._m(0),_vm._l((_vm.filteredArtList),function(item,idx){return _c('router-link',{key:item.id + idx,staticClass:"single-art-link link",attrs:{"to":{
            name: 'SingleArt',
            params: {
                inventoryNumber: item.id,
            },
        }}},[_c('p',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.id)+" ")])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"single-art-link header"},[_c('h4',[_vm._v("Title")]),_c('h4',[_vm._v("inventory number")])])
}]

export { render, staticRenderFns }