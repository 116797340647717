<template>
    <div class="component-container">
        <ArtDetailsTop
            :art_img="img"
            :title="story_name"
            :keywords="rightKeywords"
            :show-circles="false"
            :isCompleted="true"
            :subtitle="title"
        />
        <Keywords :story_keywords_list="keywordsList" :detail="0" />
    </div>
</template>

<script>
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
gsap.registerPlugin(Flip);

import ArtDetailsTop from "../dragAndMatch/ArtDetailsTop.vue";
import Keywords from "../dragAndMatch/Keywords.vue";
export default {
    components: {
        ArtDetailsTop,
        Keywords,
    },
    props: {
        story_name: {
            type: String,
            default: "Story name",
        },
        title: {
            type: String,
            default: "Title",
        },
        img: {
            type: String,
            default: "ANSELM-KIEFER.png",
        },

        image: {
            type: String,
            default: "ANSELM-KIEFER.png",
        },

        rightKeywords: {
            type: Array,
        },
        keywords: {
            type: [String, Array],
        },
    },
    data() {
        return {
            userSelection: null,
        };
    },

    mounted() {
        gsap.set(".circles", {
            width: 63,
            background: "transparent",
        });

        // console.log(this.rightKeywords);
        // console.log(this.keywords);

        setTimeout(() => {
            this.matchRightKeywords();
        }, 2000);
    },

    computed: {
        keywordsList() {
            let keywords;

            if (typeof this.keywords === "string") {
                keywords = this.keywords.trim().split(",");
                // console.log(keywords);
            } else {
                keywords = this.keywords;
            }
            return keywords;
        },
    },

    methods: {
        matchRightKeywords() {
            for (let i = 0; i < this.rightKeywords.length; i++) {

                const regexp = new RegExp(' ', 'g');
                let element = document.querySelector(`.circle-${this.rightKeywords[i].keyword.trim().replace(regexp, "-")}`);
                const keywordClassName = element.className.toLowerCase();

                let keyword = null;
                let boxes = document.querySelectorAll('[class*="options selected"]');
                for (let box of boxes) {
                    const innerText = box.innerHTML.trim().replace(regexp, "-").toLocaleLowerCase();
                    // console.log('innerText', innerText)
                    // console.log('keywordClassName', keywordClassName)
                    if(keywordClassName.endsWith(innerText)) {
                        keyword = box;
                        break;
                    }
                }

                // 이전 로직
                // let keyword = document.querySelector(`.selected-${i}`);
                // let element = document.querySelector(`.circle-${this.rightKeywords[i].keyword.trim().replaceAll(" ", "-")}`);
                // console.log('element', element);
                // console.log('keyword', keyword);

                const state = Flip.getState(keyword);
                element.appendChild(keyword);

                gsap.set(".circles", {
                    width: "fit-content",
                    border: "none",
                });
                gsap.set(".keywordsback", {
                    opacity: 0,
                });

                Flip.from(state, {
                    duration: 1.5,
                    scale: true,
                    absolute: true,
                });

                element.addEventListener("click", () => {
                    this.userSelection = this.rightKeywords[i];
                    this.$store.dispatch("SHOW_RESULT", this.userSelection);
                    gsap.to(window, {
                        scrollTo: ".feedback",
                    });
                });

                this.$store.dispatch("DRAG_AND_MATCH_COMPLETED");
            }
        },
    },
};
</script>

<style scoped>
.component-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}
small {
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-left: 10rem;
}
</style>
