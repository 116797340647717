export const tutorialEnglish = [
    {
        id: 0,
        title: "",
        dsc: "If yOu are reading this m3ssage, my attempt t& send a plea for help into t§e past waS succes$ful. Our situation is dire and we urgently ne3d your he1p!!! \n",
        image: "intro_bg_1",
    },
    {
        id: 1,
        title: "",
        dsc: "Mor/ and more knowledge is vanishing =§d we d0n't know why this is happening. We Only see the consequences: SYstems collapsed and the world is in turmoil...$§nd The ONly thing we do know is that this started in our past, your time. ",
        image: "intro_bg_2",
    },
    {
        id: 2,
        title: "",
        dsc: "This why I've uSed an unest2d time-traveling techn0logy that u5es the stars for navigation through space and time to send this call for help. <br/> The 'great Oblivion' as we call it, began on several places on earth. The Städel Museum is one of them. ",
        image: "intro_bg_3",
    },
    {
        id: 3,
        title: "",
        dsc: "Help me to find the source of the 'Great Oblivi=n'. Gather the knowl3dge that is lost to us a&d send it back wit@ your device through the star signs. With your help, I might be able to reverse the proce55. ",
        image: "intro_bg_4",
    },
];
export const tutorialGerman = [
    {
        id: 0,
        title: "",
        dsc: "W3nn du d1ese Nachricht lie$t, war mein Versuch, eine B0tschaft in die Vergangenheit zu schicke%, erfolgre1ch. Unsere S1tuation ist verzweifelt &d wir brauchen dringend deine Hilfe!!!",
        image: "intro_bg_1",
    },
    {
        id: 1,
        title: "",
        dsc: "Immer mehr Wis$en geht verl=ren und wir wisseN nicht, warum. Wir sehen jedoch die Konseque/zen: Systeme sind zusammengebrochXn udn die Welt ist in Aufruhr...$as wir jedoch wissen ist, dass es in unserer Vergangenheit, deiner Gegenwart, begonnen hat.",
        image: "intro_bg_2",
    },
    {
        id: 2,
        title: "",
        dsc: 'Darum habe ich eine bisher unerprob2e Zeitreise-Techn=logie verwendet, die die Sterne als N@vigation durch Zeit und Raum nutzt, um die5en Hilferuf zu schicken. "Das große Vergessen", wie wir es nennen, begann an ver?chiedenen Orten auf der Erde. Das Städel Museum ist einer von ihnen.',
        image: "intro_bg_3",
    },
    {
        id: 3,
        title: "",
        dsc: 'Hilf mir, die Ursache des "Gr0ßen Vergessens" zu fidnen. Sammle das für uns bereits verloren gegangene Wissen/ u)d sende es mit dem Gerät durch die Sternzeichen zu un5. Mit deiner Hilfe bin ich vielleicht in der Lage den Prozess aufzuhalten.',
        image: "intro_bg_4",
    },
];
