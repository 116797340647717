<template>
    <nav class="circle-nav">
        <div ref="icon-container" class="icon-container">
            <div id="circle" class="circle" ref="circle">
                <div
                    id="navigation-dots"
                    class="navigation-dots"
                    v-for="item in signs"
                    :key="item.order"
                >
                    <div
                        class="dot"
                        :class="{
                            isDotActive:
                                selectedConstellationName === item.name,
                        }"
                        ref="dotIcon"
                    >
                        {{ $t(`message.${item.name}`) }}
                    </div>
                </div>
            </div>
        </div>
        <button @click="handleConstellationClick" class="nav-button">
            {{ $t("message.learn_more") }}
        </button>
    </nav>
</template>

<script>
import { circleSetup } from "../../helperFunctions/utils";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { mapState } from "vuex";
gsap.registerPlugin(Draggable);
export default {
    mounted() {
        this.signs = this.groups.sort((a, b) => a.order - b.order);
        gsap.set(".circle-nav", {
            opacity: 0,
        });

        setTimeout(() => {
            let selectConstellationName = localStorage.getItem(
                "selectConstellationName"
            );

            if (selectConstellationName) {
                let constellationOrder = this.signs.find(
                    (item) => item.name == selectConstellationName
                ).order;

                this.activeSlideIndex = constellationOrder - 1;

                gsap.set(".circle", {
                    rotate: (this.activeSlideIndex * 360) / -this.signs.length,
                });
                this.displayOrder(this.activeSlideIndex);
            }

            circleSetup("#circle", "#navigation-dots", -50);
            this.animation();
        }, 10);
        setTimeout(() => {
            gsap.to(".circle-nav", {
                opacity: 1,
            });
        }, 500);
    },
    data() {
        return {
            activeSlideIndex: 0,
            signs: [],
        };
    },
    computed: {
        ...mapState({
            artsByConstellation: (state) => state.gameState.artsByConstellation,
            selectedConstellationName: (state) =>
                state.gameState.selectedConstellationName,
            groups: (state) => state.gameState.GROUPS_DETAILS, // Todo
        }),
    },
    methods: {
        animation() {
            let activeSlideIndex = this.activeSlideIndex;
            let activeSlideIndexSnap = this.activeSlideIndex;

            let self = this;
            let oneItem = 360 / this.signs.length;
            Draggable.create(".circle", {
                type: "rotation",
                liveSnap: true,
                snap: function (endValue) {
                    activeSlideIndexSnap = Math.round(endValue / oneItem);
                    return Math.round(
                        (activeSlideIndexSnap * 360) / self.signs.length
                    );
                },
                allowEventDefault: true,
                onDrag: function () {
                    let maxRotation = gsap.utils.wrap(0, 360, this.rotation);

                    activeSlideIndex = Math.abs(
                        Math.floor(maxRotation / oneItem) - self.signs.length
                    );

                    if (activeSlideIndex === self.signs.length) {
                        self.displayOrder(0);
                    } else {
                        self.displayOrder(activeSlideIndex);
                        self.activeSlideIndex = activeSlideIndex;
                    }
                },
            });
        },
        handleConstellationClick() {
            this.$store.dispatch("TOGGLE_LONG_MODAL");
        },
        displayOrder(activeSlideIndex) {
            this.$store.dispatch(
                "SET_ACTIVE_CONSTELLATION",
                this.signs[activeSlideIndex]
            );
            // this.artsByConstellation.forEach((star, idx) => {
            //     setTimeout(() => {
            //         circleSetup(`#big-dot-${idx}`, `#histories-${idx}`, -74);
            //     }, 100);
            // });
        },
    },
};
</script>

<style scoped>
.icon-container {
    position: fixed;
    width: 50px;
    height: 50px;
    border: 0;
    outline: transparent;
    background-color: white;
    background: transparent;
    display: grid;
    place-content: center;
    border-radius: 50%;
    left: 50%;
    bottom: -50px;
    transform: translate(-50%, -50%);
}

.circle {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -40%) scale(1);
    width: 350px;
    height: 350px;
    transform-origin: center center;
    background: white;
    border: 2px solid white;
    border-radius: 50%;
    display: grid;
    place-content: center;
    z-index: -1;
    background: transparent;
    background-size: contain;
}

/* dots */
.navigation-dots {
    display: flex;
    gap: 5px;
    position: absolute;
}

.dot {
    height: 30px;
    width: 80px;
    background: black;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    color: white;
}
.isDotActive {
    background: rgb(224, 162, 15);
}

.nav-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 200px;
    height: 50px;
    transform: translateX(-50%);
    padding: 1rem 2rem;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background: var(--primary-white);
    border: 1px solid var(--primary-gray);
    font-size: 1rem;
    text-transform: none;
}
</style>
