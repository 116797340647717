<template>
    <div>
        <div class="under-line"></div>

        <main class="main-container">
            <BackgroundStars />

            <div v-if="coreArt && coreArt.constellation" class="constellation-info">
                <p class="constellation-name">
                    {{ $t(`message.${coreArt.constellation.toLowerCase()}`) }}
                </p>
                <p class="constellation-topic">{{ coreArt.topic_name }}</p>
            </div>

            <div class="art-wrapper" v-if="showStart">
                <div v-for="item in artsByConstellation" :key="item.inventory_number">
                    <SingleArtConstellation :item="item" :counter="numberOfArtFound" />
                </div>
            </div>
            <!-- Information Modals -->
            <NewArtStory
                v-if="showNewArtStory"
                :competences="activity.competence"
                :numberOfKeyFound="numberOfKeyFound"
                :keysMessage="anotherKeyOrCoreArt"
                @NewArtStoryModalClick="newButtonClick"
            />
            <!-- Core Art Complete Modal -->
            <WheelModal
                v-if="showWheel"
                :showCoreArtCompleted="true"
                @sendMessage="newSendMessage"
            />
        </main>
    </div>
</template>

<script>
import { gsap } from "gsap";
import gameApi from "../../api/gameApi";
import BackgroundStars from "../../components/ui/BackgroundStars.vue";
import {
    // ArtCompetences,
    // ArtsKeyAndPadlock,
    // ArtStory,
    NewArtStory,
    SingleArtConstellation,
} from "../../components";
import { mapState } from "vuex";

import { windowHeight, sendProgressLog } from "../../helperFunctions/utils";
import WheelModal from "../../components/progress/WheelModal.vue";
export default {
    components: {
        // ArtCompetences,
        // ArtsKeyAndPadlock,
        // ArtStory,
        NewArtStory,
        BackgroundStars,
        SingleArtConstellation,
        WheelModal,
    },

    data() {
        return {
            // new version
            showNewArtStory: true,
            showWheel: false,
            // old version
            logSended: false,
            showStory: false,
            storyProgressCheck: true,
            wheelOfColorSendBack: false,
            showCompetences: false,
            competencesProgressCheck: false,
            showKey: false,
            keysProgressCheck: false,
            showCoreArtCompleted: false,
            showContinueButton: true,
            numberOfArtFound: 0,
            totalNumberOfKeys: 0,
            numberOfKeyFound: 0,
            canShowModal: false,
            showStart: false,
            allStoriesCompleted: false,
            allProgressChecked: false,
            currentArtStoriesCompleted: 0,
        };
    },
    mounted() {
        console.log('ConstellationProgress.vue mounted');
        windowHeight();
        this.pageSetUp();
    },
    computed: {
        ...mapState({
            groups: (state) => state.gameState.GROUPS_DETAILS,
            artsByConstellation: (state) => state.gameState.artsByConstellation,
            selectedConstellationName: (state) =>
                state.gameState.selectedConstellationName,
            currentArt: (state) => state.gameState.currentArt,
            activity: (state) => state.activitiesState.artActivity,
            coreArt: (state) => state.gameState.coreArt,
            anotherKeyOrCoreArt() {
                return this.totalNumberOfKeys == this.numberOfKeyFound
                    ? this.$t("message.key_message_two")
                    : this.$t("message.key_message_one");
            },
        }),
    },

    methods: {
        // new version
        newButtonClick() {
            if (this.currentArt.title === this.coreArt.title) {
                if (this.coreArt.stories_count === this.coreArt.completed_stories) {
                    this.showNewArtStory = false;
                    this.showWheel = true;
                } else {
                    this.$router.push({ 
                        name: "StoryList",
                        params: {
                            zodiacName: this.coreArt.constellation.toLowerCase(),
                            inventoryNumber: this.coreArt.inventory_number,
                        },
                    });
                }
            } else {
                if (this.currentArt.is_completed) {
                    this.$router.push({ 
                        name: "SelectConstellation",
                    });
                } else {
                    this.$router.push({ 
                        name: "StoryList",
                        params: {
                            zodiacName: this.currentArt.constellation.toLowerCase(),
                            inventoryNumber: this.currentArt.inventory_number,
                        },
                    });
                }
            }
        },
        newSendMessage() {
            this.showWheel = false;
            this.$router.push({
                name: "BookReward",
                params: { zodiacName: this.coreArt.constellation },
            });
        },
        wheelAnimation(viewName) {
            this.$refs.wheelModalRef.$el.style.zIndex = 1;
            gsap.to("#wheelContainerWrapper", {
                yPercent: 0,
                duration: 1,
                onComplete: () => {
                    setTimeout(() => {
                        this.$router.push({ name: viewName });
                    }, 1000);
                },
            });
        },

        async pageSetUp() {
            await this.$store.dispatch("FETCH_ARTS_BY_GROUP_AND_CONSTELLATION");
            await this.$store.dispatch("FETCH_SINGLE_ACTIVITY");

            this.$store.dispatch("GET_SINGLE_ART_DETAILS");

            this.setNumberOfKeys();
            this.artProgress();

            this.showStart = true;

            const payload = {
                id: localStorage.getItem("activity_id"),
                lang: this.$i18n.locale,
                all_stories_completed: this.allStoriesCompleted,
                is_core_completed:
                    this.currentArt.core_art &&
                    this.currentArt.completed_stories ===
                        this.currentArt.stories_count
                        ? true
                        : false,
                log: "story",
            };
            // console.log('payload', payload)
            const status = await sendProgressLog(payload);
            // console.log('status', status)

            if (status === 200 || status == 201) {
                // this.showWheel = true;
                // this.showWheel = true;
                this.logSended = true;

                if(payload.is_core_completed) {
                    this.getBook(this.currentArt.constellation.toLowerCase());
                }
            }
        },

        setNumberOfKeys() {
            this.totalNumberOfKeys = this.artsByConstellation.length - 1;
            this.artsByConstellation.forEach((art) => {
                if (art.isFound) {
                    this.numberOfArtFound++;
                }
                if (!art.core_art) {
                    if (
                        art.activity_games.find(
                            (activity) => activity.is_completed
                        )
                    ) {
                        ++this.numberOfKeyFound;
                    }
                }
            });
        },

        artProgress() {
            if (
                this.currentArt.completed_stories ===
                this.currentArt.stories_count
            ) {
                this.allStoriesCompleted = true;
            }
        },

        // 테스트를 위해서 새로 추가
        // 여기에서 미리 부르면 레벨이 넘어가지 않는 문제를 해결할 수도 있음
        async getBook(zodiacName) {
            try {
                const res = await gameApi.post("game/book-of-knowledge", { constellation: zodiacName, });
                console.log("-----", res.data);
                if (res.data.is_level_completed) {
                    this.getLevelData(res.data.level);
                }
            } catch (error) {
                console.log(error);
            }
        },

        // 테스트를 위해서 새로 추가
        // 여기에서 미리 부르면 레벨이 넘어가지 않는 문제를 해결할 수도 있음
        async getLevelData(level) {
            const res = await gameApi.post("game/get-level-details", { level: level, });
            console.log('====', res.data);
        },

    },
};
</script>

<style scoped>
main {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
}

.constellation-info {
    text-align: center;
    margin-top: 1rem;
}
.constellation-name {
    font-size: 2.5rem;
    display: inline;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    font-family: var(--primary-font-medium);
    text-transform: capitalize;
}

.constellation-topic {
    font-style: normal;
    font-weight: 350;
    font-size: 1.25rem;
    line-height: 100%;
}

.navigation-dots {
    position: relative;
    padding: 1.5rem;
}

.bottom-wrapper {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}
.art-wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    /* gap: 1rem; */
    gap: 0.5;
    flex-direction: column;
    margin-top: 1rem;
    position: relative;
    height: 68%;
}
</style>
