<template>
    <main v-if="bookOfKnowledge">
        <div class="under-line"></div>
        <!-- 별자리 완료시 보이는 화면 -->
        <div v-if="showKnowledge" class="contents-wrapper">
            <!-- constellation name -->
            <h1>{{ $t(`message.${bookOfKnowledge.name}`) }}</h1>
            <!-- core art image -->
            <div class="book-img-box">
                <img :src="coreArt.digital_collection" alt="book" />
            </div>
            <!-- book of knowledge -->
            <div class="knowledge-box">
                <p class="head-line" v-html="knowledgeTitle" /><br/>
                <p v-html="knowledgeDesc"></p>
            </div>
            <!-- button -->
            <button class="btn continue" @click="handleClick">{{ $t("message.continue") }}</button>
        </div>
        <!-- 레벨 완료시 추가로 보이는 화면 -->
        <div v-else
                class="contents-wrapper level-done" :class="[level == 4 ? 'game-done':'', bgImgClass]">
            <div v-if="level == 4" class="image-box">
                <img src="@/assets/img/last-level-back.png" alt="background" />
            </div>
            <div class="item-wrap">
                <p>{{ levelQuestion.question }}</p>
            </div>
            <!-- button -->
            <div class="btn-wrap">
                <button class="btn" @click="handleClick(levelQuestion.btns[0])">{{ levelQuestion.btns[0] }}</button>
                <!-- <button class="btn" @click="handleClick(levelQuestion.btns[1])">{{ levelQuestion.btns[1] }}</button> -->
            </div>
        </div>
    </main>
</template>

<script>
import gameApi from "../../api/gameApi";
import { mapState } from "vuex";
import {
    lastQuestionEnglish,
    lastQuestionGerman,
} from "../../utils/level";

export default {
    data() {
        return {
            bookOfKnowledge: null,
            knowledgeDesc: '',
            knowledgeTitle: '',
            level: null,
            constellationName: this.$route.params.zodiacName,
            isLevelCompleted: false,
            showKnowledge: true,
            levelData: null,
            levelQuestion: [], // last question
        };
    },
    mounted() {
        this.getBook();
    },
    computed: {
        ...mapState ({
            coreArt: (state) => state.gameState.coreArt,
        }),
        constellationTitle() {
            return this.constellationName.toUpperCase();
        },
        bgImgClass () {
            if(this.level == 1) return 'bg1';
            else if(this.level == 2) return 'bg2';
            else if(this.level == 3) return 'bg3';
            else return 'bg4';
        },
    },
    methods: {
        async getBook() {
            try {
                const res = await gameApi.post("game/book-of-knowledge", {
                    constellation: this.$route.params.zodiacName,
                });
                this.bookOfKnowledge = res.data.book;
                let temp = this.bookOfKnowledge.book_of_knowledge.split('[head]');
                this.knowledgeTitle = temp[0];
                this.knowledgeDesc = temp[1];
                this.isLevelCompleted = res.data.is_level_completed;
                this.level = res.data.level;

                // console.log("-----", res.data);

                if (this.isLevelCompleted) {
                    this.getLevelData();
                }
            } catch (error) {
                console.log(error);
            }
        },
        
        async getLevelData() {
            const res = await gameApi.post("game/get-level-details", {
                level: this.level,
            });
            this.levelData = res.data;

            // console.log('====', this.levelData);
        },

        handleClick(btnType) {
            if (this.isLevelCompleted) {

                // // 레벨 완료 배경을 동적으로 생성
                // const element = document.querySelectorAll(".level-done")[0];
                // console.log('-', element);
                // if(element != null) {
                //     // 배경 이미지 URL 설정
                //     const backgroundImageUrl = `@/assets/img/opbg_${this.level}.png`;
                //     // element.style.backgroundImage = backgroundImageUrl;
                //     element.style.backgroundImage = `url(${backgroundImageUrl})`;
                // }



                if (this.showKnowledge) {
                    if (this.$i18n.locale === "de") {
                        this.levelQuestion = lastQuestionGerman[this.level - 1];
                    } else {
                        this.levelQuestion = lastQuestionEnglish[this.level - 1];
                    }
                    this.showKnowledge = false;
                    return;
                }
                else {
                    if (this.level == 4) {
                        this.$router.push({name: "Ending",params: { type: btnType.toLowerCase() },});
                    } else {
                        this.$router.push({name: "Level", params: { level: Number(this.level) + 1 },});
                    }
                }
            }
            else {
                this.$router.push({name: "SelectConstellation",});
            }
        },
    },
};
</script>

<style scoped>
main {
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.contents-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    gap: 2.5rem;
}
.contents-wrapper.level-done {
    height: calc(100vh - 80px);
    padding: 3rem 0;
    /* background-image: url("@/assets/img/opbg_1.png"); */
    /* background-image: url('../../assets/img/opbg_1.png'); */
}
.bg1 {
    background-image: url('../../assets/img/opbg_1.png');
    background-size: cover;
}
.bg2 {
    background-image: url('../../assets/img/opbg_2.png');
    background-size: cover;
}
.bg3 {
    background-image: url('../../assets/img/opbg_3.png');
    background-size: cover;
}
.bg4 {
    background-image: url('../../assets/img/opbg_4.png');
    background-size: cover;
}
.contents-wrapper.level-done.game-done {
    padding: 0;
    padding-bottom: 3rem;
}
.item-wrap {
    width: 90%;
    border: 1px dashed var(--primary-white);
    border-radius: 10px;
    padding: 2rem;
    margin: 0 auto;
}
.btn.continue {
    width: 90%;
}
.btn-wrap {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 1rem;
}
/* img */
.book-img-box {
    width: 100%;
}
.image-box {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
}
img {
    width: 100%;
    height: 100%;
}
/* knowledge */
.knowledge-box {
    width: 100%;
    padding: 0 2.5rem;
}
h1 {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-light);
    font-size: 4rem;
    font-style: normal;
    line-height: 100%; /* 40px */
    text-transform: capitalize;
}
p {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: 140%;
    word-break: keep-all;
}
.head-line {
    font-family: var(--primary-font-semibold);
    font-size: 2.2rem;
}
</style>
