<template>
    <div class="container">
        <auth-navbar />
        <div class="form">
            <div v-if="!isComplete">
                <div class="title">{{ $t('message.verify_complete')}}</div>
                <div class="greetings">
                    {{ $t('message.create_input_password')}}
                </div>
                <div> 
                    <label for="password">{{ $t('message.password')}}</label>
                    <div class="password-input-area">
                        <input class="password" name="password1" maxlength="20" 
                            :type="showPassword1? 'text': 'password'"
                            v-model="password1" required />
                        <button @click="showPassword1=!showPassword1">
                            <img v-if="!showPassword1" src="@/assets/img/auth/hidden.png" 
                                alt="hidden" class="password-hidden" />
                            <img v-else src="@/assets/img/auth/show.png" 
                                alt="visible" class="password-hidden" />  
                        </button>    
                    </div>
                    <label for="password">{{ $t('message.confirm_password')}}</label>
                    <div class="password-input-area">
                        <input class="password" name="password2" maxlength="20" 
                            :type="showPassword2? 'text': 'password'"
                            v-model="password2" required />
                        <button @click="showPassword2=!showPassword2">
                            <img v-if="!showPassword2" src="@/assets/img/auth/hidden.png" 
                                alt="hidden" class="password-hidden" />
                            <img v-else src="@/assets/img/auth/show.png" 
                                alt="visible" class="password-hidden" />  
                        </button>  
                    </div>
                    <div class="password-match-text" 
                        :style="{ 
                            color: password1 === '' || password1 !== password2 
                                ? '#FC635A' : '#4BC500', 
                            height: '20px' }">
                        {{ password1 === '' && password2 === '' ? 
                            '': password1 !== password2 ? 
                                $t('message.not_matched') : $t('message.matched') }}
                    </div>
                    <button type="submit" class="btn-submit" @click="signup()"
                        :style="{ background: password1 === '' || password1 !== password2 ? '#E8E8E8' : '#DA8E61' }"
                        :disabled = "password1 === '' || password1 !== password2">
                        {{ $t('message.continue')}}
                    </button>
                </div>
            </div>      
            <div v-else>
                <div class="title">
                    {{ $t('message.congratulations')}}
                </div>
                <div class="text">
                    {{ $t('message.create_account')}}
                </div>
                <button type="submit" class="btn-submit" @click="$router.push('/login')">
                    {{ $t('message.login')}}
                </button>
            </div>
        </div>
   
        <msg-modal v-if="isMsgModalShow"
            :msg="msg"
            :type="msgType"
            @close="isMsgModalShow=false" />

    </div>
  
</template>

<script>
export default {
    name: 'JoinStep2',
    components: {
        'msg-modal': () => import('@/components/ui/MsgModal.vue'),
        'auth-navbar': () => import('@/components/ui/AuthNavbar.vue')
    },
    data() {
        return {
            password1: '',
            password2: '',
            code: this.$route.query.code,
            isMsgModalShow: false,
            msg: 'joining...',
            msgType: 'ing',
            isComplete: false,
            showPassword1: false,
            showPassword2: false
            
        }
    },
    methods: {
        async signup() {
            try {
                const res = await this.$store.dispatch('JOIN_USER', {
                code: this.$route.query.code,
                password: this.password1
                });

                if (res.status === 200) {
                    this.isComplete = true;
                    //this.$router.push("/login");
                }
            } catch (error) {
                console.log(error);
                if (error.response.data.msg === "EmailAuth record is already used") {
                    this.msg = "Already used code. <br>Please try again.";
                    this.msgType = "confirm";
                    this.isMsgModalShow = true;
                }
                else if (error.response.data.msg === "EmailAuth record is expired") {
                    this.msg = "Email authentication time out. <br>Please try again.";
                    this.msgType = "confirm";
                    this.isMsgModalShow = true;
                }
                else {
                    this.msg = this.$t('message.try_again');
                    this.msgType = "confirm";
                    this.isMsgModalShow = true;
                }
            }
        },
    }
}
</script>
    
<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #f5f5f5;
    color:black;
}

.container {
    width: 100%;
    height: 100vh;
}

.container > .form {
    width: 100%;
    height: 600px;
    left: 0px;
    top: 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10%;
}


.inputbox {
  width: 100%;
  height: 44px;
  border-color:#EEEEEE;
  background-color: #EEEEEE;
  font-size: 20px;
  margin-top: 10px;
  padding: 20px;
  color: black;
  text-align: left;
  border-width: 0px;
}

.empty-space {
  height: 50px;
}

.text-area {
  font-size: 20px;
  margin-bottom: 20px;
  color: #000000;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: left;
  align-items: left;
  justify-items: left;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 20px;

}
.greetings {
  font-style: normal;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 60px;
}

.password {
  border-width: 0px;
  width: 100%;
  height: 31px;
  text-align: left;
  outline: none;
  color: #000000;
  padding-left: 16px;
}
.password-hidden {
  width: 30px;
  height: 30px;
}
.password-input-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 6px solid #EEEEEE;
    border-radius: 2px;
    background-color: #f5f5f5;
    font-size: inherit;
    margin-top: 10px;
    margin-bottom: 5px;
    color: black;
    padding: 4px;
}

.email-area {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    height: 100px;
}
.btn-submit {
    color: #000000;
    font-size: 20px;
    margin-top: 40px;
    height: 50px;
    width: 100%;
    background: #DA8E61;
}

.term {
    font-size: 17px;
}

label {
  color: #000000;
  font-size: 20px;
}
a {
    color: blue;
    text-decoration: underline;
}
.text-msg {
    color: red;
    font-size: 12x;
    margin-top: 10px;
    padding-left: 20px
}
.after-send-email-1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
}
.after-send-email-2 {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 130%;
}
.after-send-email-3 {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 130%;
}
.after-send-email-4 {
    font-size: 15px;
    line-height: 130%;
    margin-bottom: 30px;
}
.user-email {
    text-decoration: underline;
}
.password-match-text {
  color: #FC635A;
  font-size: 14px;
  text-align: left;
  margin-left: 10px;
}
input {
  font-size: 20px;
}
</style>
