<template>
    <div class="activity-image-container">
        <div class="paint-details">
            <section class="paint-details">
                <img class="img" v-if="art_img" :src="image" :alt="title" />
                <slot name="circles"> </slot>
                <span
                    v-for="(circle, idx) in keywords"
                    :key="idx"
                    :class="`circle-${circle.keyword
                        .trim()
                        .replaceAll(' ', '-')}`"
                    class="circles"
                >
                </span>
                <div
                    class="fake-circle-container"
                    v-if="fakeCircles && fakeCircles.length > 0"
                >
                    <span
                        v-for="(circle, idx) in fakeCircles"
                        :key="idx + 'fake'"
                        class="circles"
                        :id="'fakeCircles' + idx"
                    >
                    </span>
                </div>
            </section>

            <h2 class="subtile">{{ message }}</h2>
            <!-- {{ imageWidth }}, {{ imageHeight }} -->
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
export default {
    props: {
        art_img: {
            type: String,
        },
        title: {
            type: String,
            default: "",
        },
        keywords: {
            type: Array,
        },
        fakeCircles: {
            type: Array,
        },
        subtitle: {
            type: String,
            default: "Title",
        },
        isCompleted: {
            type: Boolean,
            default: false,
        },
        setCircleTime: {
            type: Number,
            default: 2000,
        },
    },

    data() {
        return {
            imageWidth: 0,
            imageHeight: 0,
        };
    },

    mounted() {
        // 이미지가 완전히 로드 된 후에 스타일을 잡아야 사파리 등에서 잘 나옴
        let imgElement = document.querySelector('img');
        imgElement.addEventListener('load', () => {
            imgElement.style.width = 'auto';
            imgElement.style.maxWidth = '100%';
        });

        gsap.set(".circles", { opacity: 0 });
        setTimeout(() => {
            this.setCirclePosition();
        }, this.setCircleTime);
    },

    computed: {
        image() {
            return this.art_img.startsWith("http")
                ? this.art_img
                : require(`../../assets/img/art-img/${this.art_img}`);
        },
        message() {
            return this.isCompleted
                ? "Click word on the image to learn more"
                : this.$t("message.drag_right_words");
        },
    },
    methods: {
        setCirclePosition() {
            let artImg = document.querySelector(".img");
            this.imageWidth = artImg.offsetWidth;
            this.imageHeight = artImg.offsetHeight;

            this.keywords.forEach((element) => {
                let { top, left } = element.position;

                if (typeof element.position == "string") {
                    let values = element.position.trim().split(",");
                    top = +values[0].split(":")[1];
                    left = +values[1].split(":")[1];
                }

                let topPosition = Math.floor((top * this.imageHeight) / 100);
                let leftPosition = Math.floor((left * this.imageWidth) / 100);
                // fakeCircles

                const spaceRex = new RegExp(" ", "g");
                const keyword = element.keyword.trim().replace(spaceRex, "-");
                // let keyword = element.keyword.trim().replaceAll(" ", "-");

                return gsap.to(
                    `.circle-${keyword}`,
                    {
                        translateX: leftPosition,
                        translateY: topPosition,
                        opacity: 1,
                        duration: 1,
                    }
                );
            });

            if (!this.fakeCircles || this.fakeCircles.length === 0) return;

            this.fakeCircles.forEach((element, idx) => {
                let { top, left } = element;

                let topPosition = Math.floor((top * this.imageHeight) / 100);
                let leftPosition = Math.floor((left * this.imageWidth) / 100);

                return gsap.to(`#fakeCircles${idx}`, {
                    translateX: leftPosition,
                    translateY: topPosition,
                    opacity: 1,
                    duration: 1,
                });
            });
        },
    },
};
</script>

<style scoped>
.activity-image-container {
    height: 100%;
    margin-top: 0;
    border: none;
}
.paint-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: auto;
}

.paint-details .img {
    height: auto;
    max-height: 300px;
    object-fit: contain;
    max-width: 100%;
    border-radius: 10px;
}

.subtile {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-medium);
    font-size: 1.6rem;
    font-style: normal;
    line-height: 140%; /* 22.4px */
    padding-top: 1rem;
}

.circles {
    background: rgba(185, 185, 185, 0.2);
    border: 2px dashed var(--primary-white);
    top: 0;
    left: 0;
    bottom: unset;
}
</style>
