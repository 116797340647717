<template>
    <main>
        <ConstellationsBooks
            :constellations="constellations"
            @handleBookClick="handleBookClick"
        />
    </main>
</template>

<script>
import { mapState } from "vuex";
import { ConstellationsBooks } from "@/components/index.js";
export default {
    components: {
        ConstellationsBooks,
    },

    data() {
        return {
            selectedBook: {},
            popUpMessage: "",
        };
    },
    computed: {
        btnText() {
            return this.selectedBook.name ? "Read more" : "Find arts";
        },
        ...mapState({
            constellations: (state) => state.gameState.GROUPS_DETAILS,
        }),
    },
    methods: {
        handleBookClick(constellation) {
            this.selectedBook = constellation;
            localStorage.setItem("selectConstellationName", constellation.name);
            this.$router.push({
                name: "SelectImage",
                params: {
                    group: this.selectedBook.group,
                    zodiacName: this.selectedBook.name,
                },
            });
        },
    },
};
</script>

<style scoped></style>
