<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 43 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M35.8863 33.5948C36.0328 33.0975 35.7484 32.5757 35.2511 32.4292C34.7538 32.2827 34.232 32.5671 34.0855 33.0644C33.939 33.5617 34.2234 34.0836 34.7207 34.23C35.218 34.3765 35.7399 34.0921 35.8863 33.5948Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M23.0192 26.6182C23.1656 26.121 22.8812 25.5991 22.3839 25.4526C21.8866 25.3062 21.3648 25.5906 21.2183 26.0879C21.0719 26.5851 21.3563 27.107 21.8535 27.2535C22.3508 27.3999 22.8727 27.1155 23.0192 26.6182Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M16.017 28.1856C16.0935 27.9257 15.9449 27.6531 15.6851 27.5765C15.4253 27.5 15.1526 27.6486 15.0761 27.9084C14.9995 28.1683 15.1481 28.4409 15.408 28.5175C15.6678 28.594 15.9405 28.4454 16.017 28.1856Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M1.07951 34.0606C1.15603 33.8007 1.00743 33.5281 0.747598 33.4515C0.487764 33.375 0.215093 33.5236 0.138566 33.7834C0.062039 34.0433 0.210637 34.3159 0.47047 34.3925C0.730303 34.469 1.00298 34.3204 1.07951 34.0606Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M34.9779 7.84181C35.0545 7.58198 34.9059 7.30931 34.646 7.23278C34.3862 7.15626 34.1135 7.30485 34.037 7.56468C33.9605 7.82451 34.1091 8.09719 34.3689 8.17372C34.6287 8.25025 34.9014 8.10164 34.9779 7.84181Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M0.460938 34.0039L15.984 27.812L22.3147 26.2517L34.8928 33.3811"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.234665"
            stroke-miterlimit="10"
        />
        <path
            d="M22.3125 26.2461L34.6486 7.61788"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.234665"
            stroke-miterlimit="10"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
