export const tutorialEnglish = [
    {
        id: 0,
        title: "Your competences in the universe of art",
        dsc: "Here you can see which art skills you have already acquired. You can sort them by categories and frequency.",
        image: "competences_tutorial_1",
    },
    {
        id: 1,
        title: "How can I acquire competencies?",
        dsc: "The ability to read images is a key to understanding our visual world. You acquire these skills automatically as you solve the tasks related to the artworks. When you complete a skill, you will be rewarded with a fascinating celestial body.",
        image: "competences_tutorial_2",
    },
];
export const tutorialGerman = [
    {
        id: 0,
        title: "Deine Kompetenzen im Universum der Kunst",
        dsc: "Hier wird angezeigt, welche Fähigkeiten im Umgang mit Kunst du bereits erworben hast. Du kannst sie nach Kategorien und Häufigkeit soriteren.",
        image: "competences_tutorial_1",
    },
    {
        id: 1,
        title: "Wie kann ich Kompetenzen erwerben?",
        dsc: "Die Fähigkeit Bilder zu lesen ist ein Schlüssel zum Verständnis unserer visuellen Welt. Du erwirbst dir diese Kompetenzen automatisch, während du die Aufgaben zu den Kunstwerken löst. Hast du eine Kompetenz vollständig erspielt, wirst du mit einem faszinierenden Himmelskörper belohnt.",
        image: "competences_tutorial_2",
    },
];
