<template>
    <div class="modal-main-wrapper">
        <!-- <button class="close-icon" @click="$emit('closeModal')">x</button> -->
        <div class="modal-content">
            <h2 class="modal-text-intro">
                The menhir-like sculpture 'Gate II' resembles a found object
                from another world or a meteorite from an unknown galaxy.
            </h2>
            <img
                class="art-img-intro"
                src="https://sammlung.staedelmuseum.de/images/105651/thumb-xl.jpg"
                alt=""
            />
        </div>
        <router-link to="art-found" class="btn" id="modalBtn"
            >I Found it</router-link
        >
    </div>
</template>

<script>
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
gsap.registerPlugin(TextPlugin);
export default {
    emits: ["closeModal"],
    mounted() {
        gsap.set(".art-img-intro, #modalBtn", { opacity: 0, y: -10 });
        let tl = gsap.timeline({});
        tl.from(".modal-text-intro", {
            duration: 5,
            text: {
                value: "",
                delimiter: "",
            },
        })
            .to(".art-img-intro", {
                opacity: 1,
                stagger: 0.6,
                y: 0,
            })
            .to("#modalBtn", {
                opacity: 1,
                stagger: 0.6,
                y: 0,
            });
    },
};
</script>

<style scoped>
.modal-main-wrapper {
    position: fixed;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
}

.art-img-intro {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
}
</style>
