<template>
    <div class="constellation-info" v-if="selectedConstellation">
        <h2>{{ $t(`message.${selectedConstellation.name}`) }}</h2>
        <p>
            {{ $t("message.total_stories") }}:
            {{ selectedConstellation.stories_count }}
        </p>
        <p>
            {{ $t("message.completed") }}:{{
                selectedConstellation.completed_stories
            }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        selectedConstellation: Object,
    },
};
</script>

<style scoped>
.constellation-info {
    text-align: center;
    text-transform: capitalize;
}
h2 {
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 100%;
    text-transform: capitalize;
}
p {
    font-family: var(--primary-font-light);
}
</style>
