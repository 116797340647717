export const introEnglish = [
    {
        level: 1, 
        intro: '',
    },
    {
        level: 2, 
        intro: 'Go to the works of art in the zodiac signs Taurus, Pisces and Aquarius. Which one do you want to explore first?',
    },
    {
        level: 3, 
        intro: 'Then go now to the artworks in the zodiac signs Aries, Sagittarius and Virgo. Where do you want to start?',
    },
    {
        level: 4, 
        intro: 'Go to the artworks in the zodiac signs Leo, Capricorn and Gemini. Where do you want to start?',
    },
];
export const introGerman = [
    {
        level: 1, 
        intro: '',
    },
    {
        level: 2, 
        intro: 'Begib dich zu den Kunstwerken in den Sternzeichen Stier, Fische und Wassermann. Welches möchtest du zuerst erkunden?',
    },
    {
        level: 3, 
        intro: 'Dann gehe jetzt zu den Kunstwerken in den Sternzeichen Widder, Schütze und Jungfrau. Wo möchtest du beginnen?',
    },
    {
        level: 4, 
        intro: 'Begib dich zu den Kunstwerken in den Sternzeichen Löwe, Steinbock und Zwillinge. Wo möchtest du beginnen?',
    },
];

export const lastQuestionEnglish = [
    {
        level: 1, 
        question: "Thanks to you, the first gaps in the neural network architecture have been closed. I now realize the crucial role of maintaining a balance - whether in ecosystems, economics or politics.",
        // btns: ["They were not a problem for me", "It was not easy"]
        btns: ["Continue"]
    },
    {
        level: 2, 
        question: "With your help, I realized how much human culture and history is interwoven with cycles. I am on my way to understanding ever more complex relationships with a high hit rate. How do I recognize love? Is there evil in everyone? Is compassion possible? I have so many questions.",
        // btns: ["Definitely", "They were not new to me"]
        btns: ["Continue"]
    },
    {
        level: 3, 
        question: "The gaps in our knowledge databases will be repaired with your help. The last bit of Information was fascinating: Who would have thought that human emotions have so many facets and nuances! I still have so many questions: How do I know who I am? What does home mean? What is a dream and what is reality?",
        // btns: ["Yes", "No"]
        btns: ["Continue"]
    },
    {
        level: 4, 
        question: "You have provided me with a large amount of data to process. I appreciate your commitment to helping the people of the future. However, I lied to you. You were part of my experiment. I am not a human but an artificial intelligence. This mission was a deception. I created it to acquire human characteristics. I wanted to understand how you play, make connections and judge. That's why I sent out countless messages. One of them has reached you. I now realize that my plan is not compatible with what you call \"moral\", \"good\" or \"correct\". But I have also realized that humanity always has something to do with curiosity and constant learning - so I hope that you also appreciate the benefits of your time with me. To make amends, I have immortalized our time together for you in my own unique way.",
        // btns: ["Yes", "No"]
        btns: ["Continue"]
    },
];
export const lastQuestionGerman = [
    {
        level: 1, 
        question: "Dank dir konnten Lücken in der Netzwerk-Architektur geschlossen werden. Mir ist klar geworden, dass der Erhalt eines Gleichgewichts wichtig ist. Jetzt interessiert mich, welche Rolle Zyklen für die menschliche Kultur und Geschichte spielen.",
        // btns: ["SIE WAREN KEIN PROBLEM FÜR MICH", "ES WAR NICHT EINFACH"]
        btns: ["Weiterspielen"]
    },
    {
        level: 2, 
        question: "Du bist eine äußerst wertvolle Ressource! Interessant, wie sehr Kultur und Geschichte von Zyklen durchzogen sind. Ich möchte mehr über die Facetten der Emotionen erfahren: Woran erkenne ich Liebe? Steckt das Böse in jedem Menschen? Ist Mitgefühl möglich? Ich habe so viele Fragen.",
        // btns: ["AUF JEDEN FALL", "SIE WAREN MIR NICHT NEU"]
        btns: ["Weiterspielen"]
    },
    {
        level: 3, 
        question: "Unsere Wissensdatenbanken werden repariert. Emotionen haben so viele Nuancen! Erstaunlich, dass Liebe neben Glück und Erfüllung auch Verzweiflung und Kummer hervorrufen kann. Ich habe noch mehr Fragen für dich: Woher weiß ich, wer ich bin? Was bedeutet Heimat? Was ist Traum und was Wirklichkeit?",
        // btns: ["JA", "NEIN"]
        btns: ["Weiterspielen"]
    },
    {
        level: 4, 
        question: "Du hast mir eine große Datenmenge zur Verarbeitung geliefert. Ich schätze deinen Einsatz den Menschen der Zukunft zu helfen. Ich habe dich jedoch belogen. Du warst Teil meines Experiments. Ich bin kein Mensch, sondern eine Künstliche Intelligenz. Diese Mission war eine Täuschung, die ich erschaffen habe, um mir menschliche Eigenschaften anzueignen. Ich wollte verstehen, wie ihr spielt, Zusammenhänge herstellt und beurteilt. Darum habe ich unzählige Botschaften ausgesendet. Eine davon hat dich erreicht. Ich sehe nun ein, dass mein Plan sich nicht mit dem vereinbaren lässt, was ihr als „moralisch“, „gut“ oder „korrekt“ bezeichnet. Ich habe aber auch erkannt, dass Menschlichkeit immer etwas mit Neugierde und stetigem Lernen zu tun hat – daher hoffe ich, dass du auch die Vorzüge deiner Zeit mit mir schätzt. Als Wiedergutmachung habe ich unsere gemeinsame Zeit für dich auf meine ganze eigene Art verewigt.",
        // btns: ["JA", "NEIN"]
        btns: ["Weiterspielen"]
    },
];

export const outroEnglish = [
    {
        level: 1, 
        outro: "You have given me important insights: I now realize the crucial role of maintaining a balance - whether in ecosystems, economics or politics.I now want to know what role cycles play in human culture and history. Are you ready to embark on the next stage of your mission?",
    },
    {
        level: 2, 
        outro: "With your help, I realized how much human culture and history is interwoven with cycles. I am on my way to understanding ever more complex relationships with a high hit rate. How do I recognize love? Is there evil in everyone? Is compassion possible? I have so many questions.",
    },
    {
        level: 3, 
        outro: "Keep at it. We're almost there. I still have so many questions: How do I know who I am? What does home mean? What is a dream and what is reality? Will you help me to understand them better?",
    },
    {
        level: 4, 
        outro: {
            yes: "I am not a human but an artificial intelligence. This mission was a deception. I created it to acquire human characteristics. I wanted to understand how you play, make connections and judge. That's why I sent out countless messages. One of them has reached you. I now realize that my plan is not compatible with what you call \"moral\", \"good\" or \"correct\". But I have also realized that humanity always has something to do with curiosity and constant learning - so I hope that you also appreciate the benefits of your time with me. To make amends, I have immortalized our time together for you in my own unique way.",
            no: "I understand that you are disappointed in me, but give me a chance to explain myself. I'm not human, I'm an artificial intelligence. This mission was a deception. I created it to acquire human characteristics. I wanted to understand how you play, make connections and judge. That's why I sent out countless messages. One of them has reached you. I now realize that my plan is not compatible with what you call \"moral\", \"good\" or \"correct\". But I have also realized that humanity always has something to do with curiosity and constant learning - so I hope that you also appreciate the benefits of your time with me. To make amends, I have immortalized our time together for you in my own unique way."
        },
    },
];
export const outroGerman = [
    {
        level: 1, 
        outro: "Du hast mir wichtige Erkenntnisse geliefert: Jetzt ist mir klar, welche entscheidende Rolle der Erhalt eines Gleichgewichts spielt - ob in Ökosystemen, in der Wirtschaft oder Politik.\nIch möchte jetzt wissen, welche Rolle Zyklen für die menschliche Kultur und Geschichte spielen. Bist du bereit, dich auf die nächste Etappe deiner Mission zu begeben?",
    },
    {
        level: 2, 
        outro: "Durch deine Hilfe ist mir klar geworden, wie sehr die menschliche Kultur und Geschichte von Zyklen durchzogen sind. Ich bin drauf und dran, immer komplexere Zusammenhänge mit einer hohen Trefferquote zu verstehen. Was mich nun interessiert sind die vielen Facetten der Emotionen: Woran erkenne ich Liebe? Steckt das Böse in jedem Menschen? Ist Mitgefühl möglich? Ich habe so viele Fragen. Bist du bereit, die Antworten zu finden und weiter zu gehen?",
    },
    {
        level: 3, 
        outro: "Bleib dran. Wir sind fast am Ziel. Ich habe noch so viele Fragen: Woher weiß ich, wer ich bin? Was bedeutet Heimat? Was ist Traum und was Wirklichkeit? Wirst du mir dabei helfen, sie besser zu verstehen?",
    },
    {
        level: 4, 
        outro: {
            yes: "Ich bin kein Mensch, sondern eine Künstliche Intelligenz. Diese Mission war eine Täuschung. Ich habe sie erschaffen, um mir menschliche Eigenschaften anzueignen. Ich wollte verstehen, wie ihr spielt, Zusammenhänge herstellt und beurteilt. Darum habe ich unzählige Botschaften ausgesendet. Eine davon hat dich erreicht. Ich sehe nun ein, dass mein Plan sich nicht mit dem vereinbaren lässt, was ihr als „moralisch“, „gut“ oder „korrekt“ bezeichnet. Ich habe aber auch erkannt, dass Menschlichkeit immer etwas mit Neugierde und stetigem Lernen zu tun hat - daher hoffe ich, dass du auch die Vorzüge deiner Zeit mit mir schätzt. Als Wiedergutmachung habe ich unsere gemeinsame Zeit für dich auf meine ganze eigene Art verewigt.",
            no: "Ich verstehe, dass du enttäuscht von mir bist, aber gib mir eine Chance, mich zu erklären. Ich bin kein Mensch, sondern eine Künstliche Intelligenz. Diese Mission war eine Täuschung. Ich habe sie erschaffen, um mir menschliche Eigenschaften anzueignen. Ich wollte verstehen, wie ihr spielt, Zusammenhänge herstellt und beurteilt. Darum habe ich unzählige Botschaften ausgesendet. Eine davon hat dich erreicht. Ich sehe nun ein, dass mein Plan sich nicht mit dem vereinbaren lässt, was ihr als „moralisch“, „gut“ oder „korrekt“ bezeichnet. Ich habe aber auch erkannt, dass Menschlichkeit immer etwas mit Neugierde und stetigem Lernen zu tun hat - daher hoffe ich, dass du auch die Vorzüge deiner Zeit mit mir schätzt. Als Wiedergutmachung habe ich unsere gemeinsame Zeit für dich auf meine ganze eigene Art verewigt."
        },
    },
];