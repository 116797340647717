<template>
    <div>
        <h2>{{ title }}</h2>
        <form>
            <div class="form-control">
                <label>username</label>
                <input class="form-input" v-model="username" v-if="!isUser" />
                <label>email</label>
                <input class="form-input" v-model="email" />
                <label>password</label>
                <input class="form-input" v-model="password" />
            </div>
            <button @click.prevent="handleClick" class="btn">Submit</button>
        </form>
        <p
            @click="isUser = !isUser"
            class="message"
            v-html="registerOrLogin"
        ></p>
        <div class="errors" v-if="authErrors">
            <p v-for="(error, idx) in authErrors" :key="idx">{{ error }}</p>
        </div>
    </div>
</template>

<script>
// import axios from "axios";
export default {
    data() {
        return {
            username: "",
            password: "",
            email: "",
            isUser: true,
            errors: [],
        };
    },
    computed: {
        title() {
            return this.isUser ? "Login" : "Register";
        },
        authErrors() {
            return this.$store.state.authState.authErrors;
        },
        registerOrLogin() {
            return !this.isUser
                ? "have an account? <span style='color:red; margin-left:0.2rem'>Login.</span>"
                : "not at user? <span style='color:red; margin-left:0.2rem'>Register.</span>";
        },
    },
    methods: {
        async handleClick() {
            this.errors = [];
            if (this.isUser) {
                const res = this.$store.dispatch("LOGIN_USER", {
                    // username: this.username,
                    email: this.email,
                    password: this.password,
                });
                if (res.status === 200) {
                    localStorage.setItem("game__id", res.data.game_id);
                    this.$router.push("/");
                }
            } else {
                const res1 = await this.$store.dispatch("REGISTER_USER", {
                    username: this.username,
                    password: this.password,
                    email: this.email,
                    game_id: this.$store.state.gameState.game_id,
                    player_id: this.$store.state.gameState.player_id,
                });
                if (res1.status === 201) {
                    const res2 = await this.$store.dispatch("LOGIN_USER", {
                        // username: this.username,
                        email: this.email,
                        password: this.password,
                    });
                    if (res2.status === 200) {
                        this.$router.push("/");
                    }
                }

            }
        },
    },
};
</script>

<style scoped>
.form-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-height: 600px;
    margin: 0 auto;
    margin-bottom: 2rem;
}
.form-input {
    width: 90%;
}
.email-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.message {
    margin-top: 1rem;
    text-align: center;
}
.errors p {
    text-align: center;
    color: red;
    font-size: 1.25rem;
    margin-top: 0.3rem;
}
</style>
