<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 175 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M79.8414 8.65834C81.9672 8.65834 83.6906 6.935 83.6906 4.80915C83.6906 2.6833 81.9672 0.959961 79.8414 0.959961C77.7155 0.959961 75.9922 2.6833 75.9922 4.80915C75.9922 6.935 77.7155 8.65834 79.8414 8.65834Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M3.84919 92.3712C5.97504 92.3712 7.69838 90.6479 7.69838 88.522C7.69838 86.3962 5.97504 84.6729 3.84919 84.6729C1.72334 84.6729 0 86.3962 0 88.522C0 90.6479 1.72334 92.3712 3.84919 92.3712Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M36.5601 129.93C38.686 129.93 40.4093 128.206 40.4093 126.081C40.4093 123.955 38.686 122.231 36.5601 122.231C34.4343 122.231 32.7109 123.955 32.7109 126.081C32.7109 128.206 34.4343 129.93 36.5601 129.93Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M106.81 111.656C108.936 111.656 110.659 109.933 110.659 107.807C110.659 105.681 108.936 103.958 106.81 103.958C104.684 103.958 102.961 105.681 102.961 107.807C102.961 109.933 104.684 111.656 106.81 111.656Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M170.318 12.5392C172.444 12.5392 174.167 10.8159 174.167 8.69001C174.167 6.56416 172.444 4.84082 170.318 4.84082C168.192 4.84082 166.469 6.56416 166.469 8.69001C166.469 10.8159 168.192 12.5392 170.318 12.5392Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M40.644 72.4033C41.7548 72.4033 42.6552 71.5028 42.6552 70.3921C42.6552 69.2813 41.7548 68.3809 40.644 68.3809C39.5333 68.3809 38.6328 69.2813 38.6328 70.3921C38.6328 71.5028 39.5333 72.4033 40.644 72.4033Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M53.144 68.5507C54.2548 68.5507 55.1552 67.6503 55.1552 66.5395C55.1552 65.4288 54.2548 64.5283 53.144 64.5283C52.0333 64.5283 51.1328 65.4288 51.1328 66.5395C51.1328 67.6503 52.0333 68.5507 53.144 68.5507Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M59.8706 116.667C60.9813 116.667 61.8818 115.766 61.8818 114.656C61.8818 113.545 60.9813 112.645 59.8706 112.645C58.7598 112.645 57.8594 113.545 57.8594 114.656C57.8594 115.766 58.7598 116.667 59.8706 116.667Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M110.902 80.1005C112.013 80.1005 112.913 79.2001 112.913 78.0893C112.913 76.9786 112.013 76.0781 110.902 76.0781C109.791 76.0781 108.891 76.9786 108.891 78.0893C108.891 79.2001 109.791 80.1005 110.902 80.1005Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M101.269 31.9814C102.38 31.9814 103.28 31.0809 103.28 29.9702C103.28 28.8594 102.38 27.959 101.269 27.959C100.158 27.959 99.2578 28.8594 99.2578 29.9702C99.2578 31.0809 100.158 31.9814 101.269 31.9814Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M126.285 47.3867C127.395 47.3867 128.296 46.4862 128.296 45.3755C128.296 44.2647 127.395 43.3643 126.285 43.3643C125.174 43.3643 124.273 44.2647 124.273 45.3755C124.273 46.4862 125.174 47.3867 126.285 47.3867Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M147.457 40.6435C148.567 40.6435 149.468 39.743 149.468 38.6323C149.468 37.5215 148.567 36.6211 147.457 36.6211C146.346 36.6211 145.445 37.5215 145.445 38.6323C145.445 39.743 146.346 40.6435 147.457 40.6435Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M64.7065 124.364C65.8173 124.364 66.7177 123.464 66.7177 122.353C66.7177 121.242 65.8173 120.342 64.7065 120.342C63.5958 120.342 62.6953 121.242 62.6953 122.353C62.6953 123.464 63.5958 124.364 64.7065 124.364Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M69.9956 68.0751C71.1063 68.0751 72.0068 67.1747 72.0068 66.0639C72.0068 64.9532 71.1063 64.0527 69.9956 64.0527C68.8848 64.0527 67.9844 64.9532 67.9844 66.0639C67.9844 67.1747 68.8848 68.0751 69.9956 68.0751Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M3.59375 89.1556L41.1233 69.9096L52.6709 66.0605H69.9922L100.786 29.4932L126.768 44.8899L110.409 77.608L107.522 107.439L69.9922 66.0605"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M36.3281 126.685L59.4233 114.176L65.197 122.836L107.538 107.439"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M126.773 44.8876L147.944 38.1515L170.077 8.32031"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M100.788 29.4861L79.6172 5.42871"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
