<template>
    <div id="wocp" class="wrapper main-container">
        <div class="under-line"></div>
        <section v-if="artsByConstellation.length > 0" class="contents-wrapper">
            <div class="title-box">
                <h2>{{ $t(`message.${selectedConstellation.name}`) }}</h2>
            </div>

            <WheelOfColors
                :artsByConstellation="artsByConstellation"
                :selectedConstellation="selectedConstellation"
                @handleWheelRelease="handleWheelRelease"
                @handleButtonClick="handleWheelButtonClick"
            />

            <LongModal
                v-if="showModalFeedBack"
                :title="selectedConstellation.name.toUpperCase()"
                :showBtn="true"
                :subtitle="selectedConstellation.group"
                :content="modalContent"
            />
        </section>
    </div>
</template>

<script>
import { WheelOfColors, } from "../../components/";
import LongModal from "../../components/modals/LongModal.vue";
import { checkShowModal, modalAlreadySeem, } from "../../helperFunctions/modalChecks";
import { mapState } from "vuex";
import gameApi from "../../api/gameApi";

export default {
    components: {
        WheelOfColors,
        LongModal,
    },
    data() {
        return {
            artsByConstellation: [],
            selectedConstellation: {},
            rotation: 2,
            showModal: false,
            modalContent: "",
            showTutorial: true,
            tutorialArray: [],
        };
    },
    mounted() {
        this.getUserProgress();

        this.showTutorial = checkShowModal({
            name: "wheelOfColorsTutorial",
            storageName: "tutorials",
        });
    },

    computed: {
        ...mapState({
            showModalFeedBack: (state) => state.gameState.showModalFeedBack,
            groups: (state) => state.gameState.GROUPS_DETAILS,
        }),
    },

    methods: {
        handleWheelRelease(item) {
            this.selectedConstellation = item;
            let constellation = this.groups.find(
                (el) => el.name.toLowerCase() === item.name.toLowerCase()
            );

            this.$store.dispatch("SET_ACTIVE_CONSTELLATION", constellation);
        },
        async getUserProgress() {
            try {
                const { data } = await gameApi.post(
                    "game/constellations-progress"
                );

                this.artsByConstellation = data.art_by_constellations;

                // this.selectedConstellation = data.art_by_constellations[0];

                let constellationName = localStorage.getItem(
                    "selectConstellationName"
                );
                if (!constellationName) constellationName = "aries";

                this.selectedConstellation = this.artsByConstellation.find(
                    (item) => item.name === constellationName.toLowerCase()
                );
                console.log(this.selectedConstellation);

                this.$store.dispatch(
                    "SET_ACTIVE_CONSTELLATION",
                    this.selectedConstellation
                );
            } catch (error) {
                console.log(error);
            }
        },

        handleWheelButtonClick(value) {
            if (value == false) {
                this.$store.dispatch("TOGGLE_LONG_MODAL");
                this.modalContent = `
                You had completed  ${this.selectedConstellation.core_art_progress.completed_stories} of ${this.selectedConstellation.core_art_progress.core_art_stories} stories of the core art, complete all of them to send the message.
                `;
            } else {
                if (this.selectedConstellation.is_core_message_collected) {
                    this.$router.push({
                        name: "BooksArchive",
                    });
                    return;
                }

                this.$router.push({
                    name: "BookReward",
                    params: { zodiacName: this.selectedConstellation.name },
                });
            }
        },

        handleTutorialCompleted() {
            modalAlreadySeem({
                name: "wheelOfColorsTutorial",
                storageName: "tutorials",
            });
            this.showTutorial = !this.showTutorial;
        },
    },
};
</script>

<style scoped>
.wrapper {
    width: 100vw;
    height: 100%;
    position: relative;
    background: var(--primary-black);
    overflow-x: hidden;
}
.contents-wrapper {
    width: 100%;
    min-height: calc(100vh - 80px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 1.5rem;
}
/* title */
.title-box {
    width: 100%;
    text-align: center;
    text-transform: capitalize;
}
.title-box h2 {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-semibold);
    font-size: 4rem;
    font-style: normal;
    line-height: 100%; /* 40px */
    text-transform: capitalize;
}
</style>
