<template>
    <div class="activity-wrapper">
        <DragImage :options="options" />
        <div v-show="showResult" class="right-keyword-result">
            <p
                :class="word.class"
                class="result-word"
                v-for="(word, idx) in rightKeywords"
                :key="idx"
                :id="idx + 1"
            >
                {{ word.keyword }}
            </p>
        </div>
    </div>
</template>

<script>
import DragImage from "../activity/DragImage.vue";
import { mapState } from "vuex";

export default {
    components: { DragImage },
    props: {
        story_name: {
            type: String,
            default: "Story name",
        },
        title: {
            type: String,
            default: "title",
        },
        activityOptions: {
            type: Array,
        },
        options: {
            type: Array,
        },
        keywords: {
            type: Array,
        },
        rightKeywords: {
            type: Array,
        },
    },
    mounted() {
        this.$store.dispatch("SHOW_RESULT", this.selectedOptions);
    },

    data() {
        return {
            showTotalVotes: false,
            currentOptionIndex: 0,
            dragMessageAni: null,
            draggableInstance: [],
        };
    },

    computed: {
        imageActivity() {
            return (img) =>
                img.startsWith("http")
                    ? img
                    : require(`../../assets/img/art-img/${img}`);
        },
        ...mapState({
            selectedOptions: (state) => state.activitiesState.selectedOptions,
            showResult: (state) => state.activitiesState.showResult,
        }),
    },
    methods: {},
};
</script>

<style scoped>
.activity-wrapper {
    overflow: hidden;
}
p {
    text-align: center;
}

.right-keyword-result {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.result-word {
    background: var(--primary-white);
    border-radius: 5px;
    padding: 8px 12px;
    color: var(--primary-black);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
}
</style>
