<template>
    <div class="wrapper">
        <BackgroundStars bgColor="#1C72D0" />
        <div class="bottom-area">
            <div
                v-for="constellation in artByConstellation"
                :key="constellation.name"
                :style="{
                    opacity:
                        setProgress(constellation) == 0
                            ? 0.6
                            : `${setProgress(constellation)}%`,
                }"
                ref="progressBar"
                class="progress"
                :class="constellation.name"
            >
                <!-- height:
                        setProgress(constellation) == 0
                            ? '5%'
                            : `${setProgress(constellation) + 5}%`, -->
                <!-- {{ setProgress(constellation) }} -->
            </div>
            <div class="button-circle"></div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import BackgroundStars from "../../components/ui/BackgroundStars.vue";
import gameApi from "../../api/gameApi";
export default {
    components: {
        BackgroundStars,
    },
    data() {
        return { artByConstellation: [], rotation: 0 };
    },
    mounted() {
        this.getUserProgress();
    },
    computed: {
        setProgress() {
            return (el) => {
                let progress = Math.floor(
                    (el.completed_stories * 100) / el.stories_count
                );
                return isNaN(progress) ? 0 : progress;
            };
        },
    },

    methods: {
        async getUserProgress() {
            try {
                const { data } = await gameApi.post("game/portal-progress");

                this.artByConstellation = data.art_by_constellations;

                setTimeout(() => {
                    this.animateBars();
                }, 80);
            } catch (error) {
                console.log(error);
            }
        },

        animateBars() {
            gsap.set(this.$refs.progressBar, {
                visibility: "visible",
            });
            this.$refs.progressBar.forEach((el) => {
                gsap.from(el, {
                    scaleY: 0,
                    duration: 1,
                    transformOrigin: "bottom right",
                });
            });
        },
    },
};
</script>

<style scoped>
.wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    text-align: center;
    height: 90vh;
    overflow: hidden;
}
.progress {
    width: 22px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 20px;
    z-index: 2;
}

.top-area .progress {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.bottom-area {
    margin-bottom: 4rem;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0.2rem;
    text-align: center;
}

.button-circle {
    width: 100%;
    height: 50%;
    position: fixed;
    background: var(--primary-gray);
    background: url("../../assets/img/staedel-circles.png") center/cover;
    bottom: -7%;
    filter: brightness(0.99);
}
</style>
