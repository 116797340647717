<template>
    <div class="story-wrapper">
        <StoryTop :story="story" :showImg="false" />

        <div
            v-for="(item, index) in story.options"
            :key="index"
            class="options-wrapper"
        >
            <div class="option-title">
                <h3>Options</h3>
                <button @click="removeOption(index)">remove</button>
            </div>
            <input type="hidden" v-model="item.id" />
            <label>Option</label>
            <input v-model="item.option" />
            <label>img</label>
            <input v-model="item.img" />
            <label>short feedback</label>
            <textarea v-model="item.shortFeedBack" />
            <label>long feedback</label>
            <textarea v-model="item.longFeedBack" />
        </div>
        <button @click="addOption" type="button" class="btn">+</button>
    </div>
</template>

<script>
import StoryTop from "../activityGames/StoryTop.vue";
export default {
    components: {
        StoryTop,
    },
    props: {
        story: {
            type: Object,
        },
    },
    data() {
        return {
            id: "id",
            option: "option",
            img: "",
            shortFeedBack: "shortFeedBack",
            longFeedBack: "longFeedBack",
        };
    },

    methods: {
        addOption() {
            const option = {
                id: this.story.options ? this.story.options.length + 1 : 0,
                option: "",
                img: "",
                votes: 0,
                shortFeedBack: "",
                longFeedBack: "",
            };

            this.story.options.push(option);
        },
        removeOption(index) {
            this.story.options = this.story.options.filter(
                (option, idx) => index !== idx
            );
        },
    },
};
</script>

<style scoped>
.option-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0;
}
.story-wrapper,
.options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
input {
    display: flex;
    height: 30px;
    width: 100%;
    padding: 0.3rem;
}

.single-option {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    align-items: center;
}
.btn {
    width: fit-content;
}

textarea {
    font-family: initial;
    font-size: inherit;
    width: 100%;
    min-height: 200px;
    padding: 0.3rem;
}
</style>
