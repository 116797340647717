<template>
    <div class="founder-wrapper">
        <div
            v-for="(item, idx) in artsByConstellation"
            :key="`${idx}indicator`"
            :id="`${item.artist.replace(/\s/g, '')}indicator`"
        >
            <FoundIndicator
                class="indicator"
                :isCoreArt="item.core_art"
                :isFound="item.isFound"
                :counter="counter"
            />
        </div>
    </div>
</template>

<script>
import FoundIndicator from "../selectImage/FoundIndicator.vue";
// import gsap from "gsap";
export default {
    components: {
        FoundIndicator,
    },
    props: {
        artsByConstellation: {
            type: Array,
        },
        counter: {
            type: Number,
        },
    },
};
</script>

<style scoped>
.founder-wrapper {
    /* background: red; */
    position: relative;
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}
.indicator {
    position: relative;
    top: unset;
    right: unset;
}
</style>
