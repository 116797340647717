import router from '../../router';
import group_data from '../../utils/group_data';
import gameApi from '../../api/gameApi';

const gameState = {
	state: () => ({
		ART_DATA: [],
		GROUPS_DETAILS: JSON.parse(JSON.stringify(group_data)),
		artsByConstellation: [],
		currentArt: {},
		selectedConstellationName: '',
		coreArt: {},
		showModalFeedBack: false,
		isGameReady: false,

		game_id: '',
		player_id: null,
	}),

	mutations: {
		START_NEW_GAME: (state, payload) => {
			const initial = gameState.state();
			Object.keys(initial).forEach((key) => {
				state[key] = initial[key];
			});

			// CLEAR LOCAL STORAGE

			localStorage.removeItem('tutorials');
			localStorage.removeItem('constellationMessage');
			localStorage.removeItem('aboutStarSignModal');
			localStorage.removeItem('game__id');
			localStorage.removeItem('selectConstellationName');

			// SET selectConstellationName
			localStorage.setItem('selectConstellationName', 'cancer');

			//////

			const { lang, player_id, game_id } = payload;

			state.player_id = player_id;
			state.game_id = game_id;
			state.isGameReady = true;

			localStorage.setItem('player__id', player_id);
			localStorage.setItem('game__id', game_id);
			localStorage.setItem('lang', lang);

			// router.push("/read-message");
			// ZoomEffect가 실행되고 나서 이동하도록 수정
			// setTimeout(() => {
			// 	router.push('/new-intro');
			// }, 7000);
		},
		FETCH_ARTS: (state, payload) => {
			state.ART_DATA = payload;
		},

		GET_LOCAL_STORE: (state) => {
			let player_id = localStorage.getItem('player__id');
			if (player_id) state.player_id = player_id;
			console.log("GET_LOCAL_STORE: player_id = " + state.player_id)
			
			let game_id = localStorage.getItem('game__id');
			if (game_id) state.game_id = game_id;	
			console.log("GET_LOCAL_STORE: game_id = " + state.game_id)

			let constellationName = localStorage.getItem('selectConstellationName');
			if (constellationName !== null && constellationName !== 'null') {
				state.selectedConstellationName = constellationName;
			}
			else {
				state.selectedConstellationName = null;
			}
			if (state.player_id !== null && state.game_id !== null && state.selectedConstellationName !== null) {
				state.isGameReady = true;
			}
			else {
				state.isGameReady = false;
			}
		},

		FETCH_SINGLE_ART: (state, payload) => {
			state.currentArt = payload;
		},

		GET_SINGLE_ART_DETAILS: (state, payload) => {
			let inventory_number = payload ? payload.inventory_number : router.currentRoute.params.inventoryNumber;
			state.currentArt = state.artsByConstellation.find((art) => art.inventory_number === inventory_number);
		},

		FETCH_ARTS_BY_GROUP_AND_CONSTELLATION: (state, payload) => {
			state.artsByConstellation = payload;

			state.selectedConstellationName = payload[0].constellation;
			state.coreArt = payload.find((art) => art.core_art);

			localStorage.setItem('selectConstellationName', payload[0].constellation);
		},

		SET_ACTIVE_CONSTELLATION: (state, payload) => {
			state.artsByConstellation = state.ART_DATA.filter((art) => art.constellation.replace(/\s/g, '').toLowerCase() == payload.name);

			localStorage.setItem('selectConstellationName', payload.name);
			state.selectedConstellationName = payload.name;
			state.coreArt = state.artsByConstellation.find((art) => art.core_art);
		},

		TOGGLE_LONG_MODAL: (state) => {
			state.showModalFeedBack = !state.showModalFeedBack;
		},
	},

	actions: {
		START_NEW_GAME: async (context) => {
			try {
				const res = await gameApi.post('play/get-or-create-player');

				const { lang, player_id, game_id } = res.data;

				context.commit('START_NEW_GAME', {
					lang,
					player_id,
					game_id,
				});
			} catch (error) {
				console.log(error);
			}
		},
		FETCH_ARTS: async (context) => {
			try {
				const res = await gameApi.post('game/get-arts');

				const selectConstellationName = localStorage.getItem('selectConstellationName');

				const constellations = context.state.GROUPS_DETAILS;

				const payload = !selectConstellationName ? constellations[0] : constellations.find((item) => item.name === selectConstellationName);

				context.state.ART_DATA = res.data;

				context.commit('SET_ACTIVE_CONSTELLATION', payload);
			} catch (error) {
				console.log(error);
			}
		},

		FETCH_SINGLE_ART: async (context, payload) => {
			try {
				const res = await gameApi.post('game/get-single-art', {
					inventory_number: payload,
				});

				context.commit('FETCH_SINGLE_ART', res.data[0]);
			} catch (error) {
				console.log(error);
			}
		},

		FETCH_ARTS_BY_GROUP_AND_CONSTELLATION: async (context) => {
			const res = await gameApi.post('game/arts-by-constellation', {
				constellation: localStorage.getItem('selectConstellationName'),
			});
			const payload = res.data;
			context.commit('FETCH_ARTS_BY_GROUP_AND_CONSTELLATION', payload);
		},

		GET_LOCAL_STORE: (context) => {
			context.commit('GET_LOCAL_STORE');
		},

		GET_SINGLE_ART_DETAILS: (context, payload) => {
			context.commit('GET_SINGLE_ART_DETAILS', payload);
		},
		SET_ACTIVE_CONSTELLATION: (context, payload) => {
			context.commit('SET_ACTIVE_CONSTELLATION', payload);
		},

		TOGGLE_LONG_MODAL: (context, payload) => {
			context.commit('TOGGLE_LONG_MODAL', payload);
		},

		SAVE_START_CORE_ART: (context) => {
			const payload = {
				player_id: context.state.player_id,
				game_id: context.state.game_id,
				constellation: context.state.selectedConstellationName,
				level: 1
			};
			gameApi.post("/play/save-start-core-art-log", payload).then((res) => {
				console.log(res);		
			}).catch((error) => {
				console.log(error);
			})
		}
	},

	getters: {
		checkLocalStore: (state) => {	
			return state.player_id !== null 
				&& state.game_id !== null 
				&& state.selectedConstellationName !== null;
		},
	
	}
};

export default gameState;
