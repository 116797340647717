var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"single-art-wrapper",class:{
        ['is-completed']: _vm.item.is_completed,
    }},[_c('div',{staticClass:"circle-constellation"}),_c('div',{staticClass:"section-wrap"},[_c('section',{staticClass:"section-right",class:`right-item-${_vm.idx}`},[_c('div',{staticClass:"story-circle",class:{
                    ['is-core-art']: _vm.item.core_art,
                },style:(_vm.item.isFound &&
                    `borderColor: var(--${_vm.item.constellation}-color); background:var(--${_vm.item.constellation}-color)`)},[_c('FoundIndicator',{staticClass:"indicator",attrs:{"isCoreArt":_vm.item.core_art,"isFound":_vm.item.isFound,"absolute":false,"counter":_vm.counter,"constellation":_vm.item.constellation}})],1)]),_c('div',{staticClass:"section-left"},[_c('div',{staticClass:"img-wrapper"},[(_vm.item.core_art && (_vm.counter < 2))?_c('div',{staticClass:"not-found-yet"}):_vm._e(),_c('img',{staticClass:"art-img",style:(_vm.item.isFound &&
                        `borderColor: var(--${_vm.item.constellation}-color)`),attrs:{"src":_vm.item.digital_collection}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }