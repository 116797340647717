<template>
    <div class="activity-wrapper">
        <section class="activity-image-container top-section">
            <img :src="imageActivity" />
        </section>
        <section class="options-text">
            <div v-if="!showTotalVotes" class="slide-container">
                <div class="slide" v-if="options.length > 0">
                    <p class="slide-left">
                        {{ options[0].option }}
                    </p>
                    <input type="range" v-model="sliderValue" class="slide-center" />
                    <p class="slide-right">
                        {{ options[1].option }}
                    </p>
                </div>
                <button class="btn submit-btn" @click="handleClick(options[answerRange])">
                    {{ $t("message.continue") }}
                </button>
            </div>

            <div v-else class="slide-result-container">
                <div class="activity-buttons">
                    <div
                        ref="btns"
                        v-for="(art, idx) in options"
                        :key="idx"
                        class="btn activity-btn"
                        :class="selectTitle == art.option && 'is-selected'"
                    >
                        <ActivityButton
                            :art="art"
                            :showTotalVotes="showTotalVotes"
                            :totalVotes="totalVotes"
                            :isSelected="selectTitle == art.option"
                        />
                    </div>
                </div>
                <h3 class="vote-count">{{ $t("message.total_votes") }}: {{ totalVotes }}</h3>
            </div>
        </section>
    </div>
</template>

<script>
import ActivityButton from "../activity/ActivityButton";
export default {
    components: {
        ActivityButton,
    },
    props: {
        story_name: {
            type: String,
        },
        title: {
            type: String,
        },
        img: {
            type: String,
        },

        options: {
            type: Array,
        },
    },

    data() {
        return {
            totalVotes: 0,
            showTotalVotes: false,
            answerRange: 0,
            sliderValue: 50,
            selectTitle: null,
        };
    },
    computed: {
        imageActivity() {
            return this.img.startsWith("http")
                ? this.img
                : require(`../../assets/img/art-img/${this.img}`);
        },
    },

    watch: {
        sliderValue(value) {
            let id = value >= 50 ? 1 : 0;
            // console.log(id);
            return (this.answerRange = id);
        },
    },
    methods: {
        handleClick(item) {
            console.log(item);

            this.totalVotes = this.options.reduce((acc, cur) => {
                acc += cur.votes;
                return acc;
            }, 0);

            ++item.votes;
            ++this.totalVotes;
            this.$emit("updateVotes", {
                id: item.id,
                totalVotes: this.totalVotes,
            });

            this.showTotalVotes = true;
            this.selectTitle = item.option;
        },
    },
};
</script>

<style scoped>
.section-container {
    position: relative;
    width: 100%;
    padding: 1rem;
}
.image-container {
    width: 100%;
    height: 100%;
    position: relative;
}
img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
}
/* option */
.options-text {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    position: relative;
}
.slide-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 200px;
}
.slide {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    gap: 1rem;
}
.slide p {
    width: 100%;
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
}
.slide-left {
    text-align: left;
}
.slide-right {
    text-align: right;
}
.slide input {
    width: 100%;
    accent-color: var(--primary-white);
    background: transparent;
    cursor: pointer;
}

.submit-btn {
    width: 90%;

    position: fixed;
    bottom: 2rem;
    left: 2rem;

    /* margin-top: 80px; */
}

.slide-result-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    gap: 0.5rem;
    margin: 1rem 0;
}

.slide-single-result {
    height: 30px;
    position: relative;
    text-align: start;
    justify-content: space-between;
}

.activity-buttons {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 18px;
}

.activity-btn {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    padding: 1.8rem 1rem;
    background-color: var(--primary-gray);
    border: 1px solid var(--primary-white);
    overflow: hidden;
}

.vote-count {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary-white);
    font-family: var(--primary-font-light);
    font-size: 1.2rem;
    font-style: normal;
    line-height: normal;
    padding-top: 1.5rem;
}
.is-selected {
    border: 2px solid var(--primary-white);
}
</style>
