<template>
	<div class="main-container">
		<!-- text boxies -->
		<div class="text-wrap">
			<!-- message box -->
			<div class="receive-message" ref="receiveMessage">
				<h4 v-if="lastText" v-html="en ? nextText.en : nextText.de"></h4>
				<h4 v-else-if="AIstart" v-html="AItext"></h4>
				<h4 v-else v-html="receiveText"></h4>
				<!-- tooltip -->
				<div v-if="showToolTip" class="tooltip-wrap">
					<button @click="closeBtn" class="close-icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M19 1L1 19" stroke="white" />
							<path d="M0.999999 1L19 19" stroke="white" />
						</svg>
					</button>
					<div>
						<p v-html="en ? currnetToolTip.desc.en : currnetToolTip.desc.de"></p>
					</div>
				</div>
			</div>
			<!-- go next -->
			<div class="go-next-wrap">
				<button v-if="!AIstart" :class="{ active: totalWordsToMatch || (step === 2 && lastText) }" @click="goNext">{{en ? 'Next' : 'Weiter'}}</button>
				<router-link v-if="AIstart" :to="{ name: 'Level', params: { level: 1 } }">{{en ? 'I’m ready!': 'Ich bin bereit!'}}</router-link>
			</div>
		</div>
		<div v-if="showSevenStars" class="btn-wrap">
			<div v-if="step === 1">
				<button v-for="num in 2" :key="num" class="star-btn" :class="`star${num}`" :data-id="num" :style="wordsList[num - 1].check ? 'animation: none;' : ''" @click="wordsControl(num)" />
			</div>
			<div v-else>
				<button v-for="num in 2" :key="num" class="star-btn" :class="`star${num + 2}`" :data-id="num" :style="wordsList2[num - 1].check ? 'animation: none' : ''" @click="wordsControl(num)" />
			</div>
		</div>
		<div v-if="!showSevenStars" class="btn-wrap"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			en: true, // 언어 선택을 위한 변수

			// flow
			step: 1,
			startBtn: true, // 시작 버튼을 보이게 하기 위한 변수
			AIstart: false, // 처음 시작 할 때 AI의 말이 보이게 하기 위한 변수
			showSevenStars: true, // 별이 보이게 하기 위한 변수
			lastText: false, // 마지막 텍스트만 보이게 하기 위한 변수
			AItext: 'Are you ready to accept the mission? ', // AI의 말을 보여주기 위한 변수
			receiveText: '', // 받은 텍스트를 보여주기 위한 변수
			hideText: '', // 받은 텍스트를 숨기기 위한 변수

			// step1
			rawText: {
				en: 'Hello, my name is Al. I am speaking to you from the future. This ■■■ can transcend ■■■. I hope my message has reached you this way and is in the right hands with you.',
				de: 'Hallo, mein Name ist Al. Ich spreche zu dir aus der Zukunft. Dieses ■■■ kann ■■■ überwinden. Ich hoffe meine Botschaft hat dich auf diesem Weg erreicht und ist bei dir in den richtigen Händen.'
			},
			
			transText: {
				en: 'Hello, my name is Al. I am speaking to you from the future. This  star portal can transcend space and time. I hope my message has reached you this way and is in the right hands with you.',
				de: 'Hallo, mein Name ist Al. Ich spreche zu dir aus der Zukunft. Dieses Sternenportal kann Raum und Zeit überwinden. Ich hoffe meine Botschaft hat dich auf diesem Weg erreicht und ist bei dir in den richtigen Händen.'
			},

			wordsList: [
				{ id: 1, word: {en: 'star portal', de: 'Sternenportal'}, check: false },
				{ id: 2, word: {en: 'space and time', de: 'Raum und Zeit'}, check: false },
				// { id: 3, word: {en: 'The universe of art', de: 'Universum der Kunst'}, check: false },
				// { id: 4, word: {en: 'zodiac signs', de: 'Sternzeichen'}, check: false },
			],

			// step2
			rawText2: {
				en: 'Only an archive of knowledge from your time can help us: ■■■. Use the ■■■ as a guide and collect information. Use it to fill your color wheel and generate energy: the light waves of the colors transport your collected data into the future.',
				de: 'Nur ein Wissensarchiv aus deiner Zeit kann uns helfen: Das ■■■. Orientiere dich an den ■■■ und sammle Informationen. Fülle damit deinen Farbkreis und erzeuge Energie: Die Lichtwellen der Farben transportieren deine gesammelten Daten in die Zukunft.',
			},

			transText2: {
				en: 'Only an archive of knowledge from your time can help us: The universe of art. Use the zodiac signs as a guide and collect information. Use it to fill your color wheel and generate energy: the light waves of the colors transport your collected data into the future.',
				de: 'Nur ein Wissensarchiv aus deiner Zeit kann uns helfen: Das Universum der Kunst. Orientiere dich an den Sternzeichen und sammle Informationen. Fülle damit deinen Farbkreis und erzeuge Energie: Die Lichtwellen der Farben transportieren deine gesammelten Daten in die Zukunft.'
			},

			wordsList2: [
				{ id: 1, word: {en: 'The universe of art', de: 'Universum der Kunst'}, check: false },
				{ id: 2, word: {en: 'zodiac signs', de: 'Sternzeichen'}, check: false },
				// { id: 1, word: 'The universe of art', check: false },
				// { id: 2, word: 'zodiac signs', check: false },
			],

			toolTipText: [
				{
					id: 1,
					word: 'star portal',
					desc: {
						en: 'From ancient myths to science fiction: people have always dreamed of transcending space and time.',
						de: 'Von antiken Mythen bis zu Science-Fiction: Seit jeher träumen die Menschen davon, Raum und Zeit zu überwinden.'
					},
				},
				{
					id: 2,
					word: 'space and time',
					desc: {
						en: 'Without space there is no time and without time there is no space: scientifically, the two form a space-time continuum. According to the general theory of relativity, a massive object, such as a black hole, can bend space and time through its gravitational force. On this basis, time travel is within the realm of possibility.',
						de: 'Ohne Raum keine Zeit und ohne Zeit kein Raum: Wissenschaftlich bilden beide ein Raum-Zeit-Kontinuum. Laut der allgemeinen Relativitätstheorie kann ein massereiches Objekt, wie ein Schwarzes Loch, durch seine Gravitationskraft Raum und Zeit krümmen. Auf dieser Grundlage liegen Zeitreisen im Bereich des Möglichen.'
					}
				},
				{
					id: 3,
					word: 'The universe of art',
					desc: {
						en: 'Knowledge is stored in the universe of art: Looking into the past can tell us a lot about the present and the future. The ability to read images is a key to understanding our world.',
						de: 'Im Universum der Kunst ist Wissen gespeichert: Der Blick in die Vergangenheit kann uns viel über Gegenwart und Zukunft erzählen. Die Fähigkeit, Bilder zu lesen ist ein Schlüssel zum Verständnis unserer Welt.'
					}
				},
				{
					id: 4,
					word: 'zodiac signs',
					desc: {
						en: 'The night sky is like a global map of human cultural history. The constellations of the stars have found their way into images and stories. In Europe, they are assigned to the twelve months, the four seasons or the elements water, fire, earth and air.',
						de: 'Der Nachthimmel ist wie eine globale Karte der kulturellen Menschheitsgeschichte. Die Sternenkonstellationen fanden Eingang in Bilder und Erzählungen. Die zyklische Wiederkehr der Sternzeichen diente der Orientierung: In Europa sind sie den zwölf Monaten, den vier Jahreszeiten oder auch den Elementen Wasser, Feuer, Erde und Luft zugeordnet.'
					}
				},
			],
			showToolTip: false,
			currnetToolTip: null,
			totalWordsToMatch: false,
			nextText: {
				en: 'We are losing more and more knowledge: there is an infinite amount of data available, but the connections can no longer be made. This "great forgetting" has begun in your time.<br /> Help me to stop this destructive process!',
				de: 'Uns geht immer mehr Wissen verloren: Es liegen unendlich viele Daten vor, aber die Zusammenhänge können nicht mehr hergestellt werden. Dieses „große Vergessen“ hat in deiner Zeit begonnen.<br />Hilf mir, diesen zerstörerischen Prozess aufzuhalten!'
			},
			checkedIds: [],
			matchingIds: [],
			lastClickedId: null,
		};
	},
	mounted() {
		// this.extractButtonDataIds();

		if(this.$i18n.locale === 'en') {
			this.en = true;
			this.AItext = 'Are you ready to accept the mission?'
		}
		else {
			this.en = false;
			this.AItext = 'Bist du bereit, die Mission anzunehmen?'
		}

		// ref  v-html="receiveText" 내의 버튼 클릭 이벤트 위임 처리
		this.$refs.receiveMessage.addEventListener('click', (e) => {
			if (e.target.tagName === 'BUTTON') {
				let id = Number(e.target.dataset.id);
				// 스텝2일때는 toolTopText id +2
				if (this.step === 2) {
					id += 2;
				}
				// console.log('id', id, typeof id);
				const tooltip = this.toolTipText.find((tip) => tip.id === id);

				if (tooltip) {
					//tooltip update
					this.currnetToolTip = tooltip;
					// console.log('currnetToolTip', this.currnetToolTip);
					//tooltip show
					this.showToolTip = !this.showToolTip;
				}
			}
		});

		// this.AItext = this.$t('message.fisrtText')
		this.receiveText = this.en === true ? this.rawText.en : this.rawText.de;
		this.hideWords();
	},
	updated() {
		this.$nextTick(() => {
			this.extractButtonDataIds();
		});
	},
	methods: {
		extractButtonDataIds() {
			// receive-message 내의 모든 버튼을 찾음
			const buttons = this.$refs.receiveMessage.querySelectorAll('button');
			// 각 버튼의 data-id 값을 추출
			const buttonDataIds = Array.from(buttons).map((button) => button.dataset.id);

			// checkedIds와 일치하는지 확인하고 싶은 로직을 여기에 구현
			const wordsList = this.step === 1 ? this.wordsList : this.wordsList2;
			this.checkedIds = wordsList.filter((word) => word.check).map((word) => word.id.toString());

			// checkedIds와 buttonDataIds의 교집합 찾기
			this.matchingIds = this.checkedIds.filter((id) => buttonDataIds.includes(id));

			console.log('match', this.checkedIds, this.matchingIds); // 일치하는 data-id 값들

			if (this.matchingIds && this.matchingIds.length > 1) {
				this.totalWordsToMatch = true;
				this.matching = true;
			}
		},

		hideWords() {
			const textToHide = this.step === 1 ? this.transText : this.transText2;
			this.hideText = this.en === true ? textToHide.en: textToHide.de;
			// console.log('hideText', this.hideText);

			const wordsList = this.step === 1 ? this.wordsList : this.wordsList2;
			wordsList.forEach((words) => {
				const word = words.word[this.en ? 'en' : 'de'];
				this.hideText = this.hideText.replace(word, '■■■');
			});
			this.receiveText = this.hideText;
		},

		// 공통 로직 함수
		updateWordButton(id) {
			const splitText = this.hideText.split('■■■');
			// console.log('splitText', splitText, 'id', id);

			// step2일때는 wordsList2를 사용
			const wordsList = this.step === 1 ? this.wordsList : this.wordsList2;
			const wordItem = wordsList.find((word) => word.id === id);
			if (wordItem) {
				const styleMatchingWord = `<button data-id="${id}" style="display:inline-block;margin-bottom:2px;padding:2px 10px;font-size:1.7rem;background-color:var(--primary-black);border-radius:34px;color: var(--primary-white);">${this.en ? wordItem.word.en : wordItem.word.de}</button>`;
				// console.log('splitText', id, typeof id, splitText, splitText[id]);
				this.receiveText = this.receiveText.replace('■■■' + splitText[id], styleMatchingWord + splitText[id]);
			}

			// console.log('wordItem', wordItem.id, wordItem.check);
		},

		wordsControl(id) {
			// const styleMatchingWord = `<button data-id="${id}" style="display:inline-block;margin-bottom:2px;padding:2px 10px;font-size:1.7rem;background-color:var(--primary-black);border-radius:34px;color: var(--primary-white);">${
			// 	this.wordsList[id - 1].word
			// }</button>`;
			// // console.log('splitText', id, typeof id, splitText, splitText[id]);
			// this.receiveText = this.receiveText.replace("■■■" + splitText[id], styleMatchingWord + splitText[id]);
			// this.wordsList[id - 1].check = true;
			const wordsList = this.step === 1 ? this.wordsList : this.wordsList2;
			const wordItem = wordsList.find((word) => word.id === id);
			if (!wordItem || wordItem.check) {
				return;
			}
			this.updateWordButton(id);
			this.$set(wordsList, id - 1, { ...wordItem, check: true });
			// this.lastClickedId = id;
		},

		closeBtn() {
			this.showToolTip = !this.showToolTip;
		},

		goNext() {
			// this.lastText = false;
			if (this.step === 2 && this.lastText) {
				this.lastText = false;
				this.receiveText = this.en ? this.rawText2.en : this.rawText2.de;
				// console.log('receiveText', this.receiveText);
				this.hideText = this.en ? this.transText2.en : this.transText2.de;
				this.hideWords();
				// wordsList의 check 상태 초기화
				const wordsList = this.step === 1 ? this.wordsList : this.wordsList2;
				wordsList.forEach((word) => {
					word.check = false;
				});
				this.matchingIds = [];
				this.totalWordsToMatch = false;
				this.showSevenStars = true;
			}
			else if (this.totalWordsToMatch) {
				this.showSevenStars = false;
				if (this.step === 1) {
					this.lastText = true;
					this.step = 2;
				} else if (this.step === 2) {
					this.AIstart = true;
				}
			}
		},
	},
};
</script>

<style scoped>
.main-container {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 3fr 4fr;
	gap: 5px;
	color: var(--primary-black);
	background-color: var(--primary-white);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 250;
}
/* text */
.text-wrap {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: linear-gradient(#111, #fff);
	padding: 20px 25px 6px;
}
.receive-message {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 500px;
	min-height: 210px;
	border-radius: 7px;
	border: 2px dashed #333;
	background-color: var(--primary-white);
	padding: 25px;
}
.receive-message h4 {
	text-align: left;
	font-family: var(--primary-light-font);
	font-size: 1.8rem;
	font-weight: 500;
	line-height: 150%;
	word-break: keep-all;
}

.tooltip-wrap {
	position: fixed;
	z-index: 301;
	top: 50%;
	left: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 35rem;
	/* height: 100%; */
	min-height: 200px;
	color: var(--primary-white);
	font-size: 2.2rem;
	background-color: var(--primary-black);
	border: none;
	border-radius: 10px;
	transform: translate(-50%, -50%);
}

.tooltip-wrap .close-icon {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	padding: 10px;
	background-color: var(--primary-black);
	border-radius: inherit;
}

.tooltip-wrap > div {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px 35px;
}

/* go next */
.go-next-wrap {
	width: 100%;
	max-width: 36rem;
	height: 68px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5% 0;
	border: 1px solid #9c9c9c;
	border-radius: 8px;
	background-color: #d0d0d0;
}

.go-next-wrap button,
.go-next-wrap a {
	width: 100%;
	height: 100%;
	font-size: 2.4rem;
	font-weight: 500;
	font-family: var(--primary-font);
	color: #9c9c9c;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.go-next-wrap a {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--primary-black);
	background-color: var(--primary-white);
	border-radius: inherit;
}

.go-next-wrap button.active {
	color: var(--primary-black);
	background-color: var(--primary-white);
	border-radius: inherit;
}

.only-yes-box {
	width: 100%;
	max-width: 500px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 14px;
	border: 1px dashed #000;
	padding: 30px 35px;
	margin: 5% 0;
}
.only-yes-box h5 {
	text-align: left;
	font-size: 1.2rem;
	font-weight: 500;
	font-family: var(--primary-font);
}
.only-yes-box button {
	text-align: right;
	font-size: 1.2rem;
	font-weight: 500;
	font-family: var(--primary-font);
	color: var(--primary-black);
	text-decoration: underline;
	border: none;
	background: transparent;
}
/* btn */
.btn-wrap {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: transparent;
	background-image: url('../../assets/img/new-intro.png');
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	padding: 15px 12px;
}

.btn-wrap > div {
	position: relative;
	top: 10vh;
	width: 100%;
	height: 100%;
}
.star-btn {
	position: absolute;
	width: 40%;
	height: 30%;
	background-color: transparent;
	background-image: url('../../assets/img/new-intro-btn.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	border: none;
	z-index: 300;
	animation: blink-effect 2s ease-in-out infinite;
}
.star-btn.star1 {
	/* width: 40%;
	height: 30%; */
	top: 0;
	left: 10%;
}
.star-btn.star3 {
	/* width: 40%;
	height: 30%; */
	top: 9%;
	right: 20%;
}
.star-btn.star2 {
	/* width: 40%;
	height: 30%; */
	top: 52%;
	right: 18%;
}
.star-btn.star4 {
	/* width: 30%;
	height: 20%; */
	top: 35%;
	right: 55%;
}
.star-btn.star5 {
	width: 30%;
	height: 20%;
	top: 58%;
	right: 20%;
}
.star-btn.star6 {
	width: 30%;
	height: 20%;
	top: 70%;
	right: 40%;
}
.star-btn.star7 {
	width: 30%;
	height: 20%;
	top: 80%;
	right: 10%;
}
/* background image */
.new-intro-image {
	width: 100%;
	max-width: 428px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}
/* animation */
@keyframes blink-effect {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
