<template>
    <div class="wheel-container">
        <Disk
            :list="artsByConstellation"
            :activeSlideIndex="activeSlideIndex"
        />
    </div>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import Disk from "../portal/Disk.vue";
gsap.registerPlugin(Draggable);
export default {
    components: {
        Disk,
    },
    props: {
        artsByConstellation: {
            type: Array,
        },
        selectedConstellation: {
            type: Object,
        },
        canRotate: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            activeSlideIndex: 0,
        };
    },
    mounted() {
        this.initialSetup();
    },

    computed: {
        coreArtProgress() {
            return Math.floor(
                (this.selectedConstellation.core_art_progress
                    .completed_stories *
                    100) /
                    this.selectedConstellation.core_art_progress
                        .core_art_stories
            );
        },
    },

    methods: {
        initialSetup() {
            this.activeSlideIndex = this.artsByConstellation.findIndex(
                (item) => item.name === this.selectedConstellation.name
            );

            if (this.canRotate) this.dragFunc(this.artsByConstellation);
        },

        dragFunc(array) {
            let activeSlideIndex = this.activeSlideIndex;
            let activeSlideIndexSnap = this.activeSlideIndex;

            let oneItem = 360 / array.length;
            let self = this;

            Draggable.create(".wheel-constellations", {
                type: "rotation",
                trigger: ".wheel-container",
                liveSnap: true,
                snap: function (endValue) {
                    activeSlideIndexSnap = Math.round(endValue / oneItem);
                    return Math.round(
                        (activeSlideIndexSnap * 360) / array.length - 15
                    );
                },
                onDrag: function () {
                    let maxRotation = gsap.utils.wrap(0, 360, this.rotation);

                    activeSlideIndex = Math.abs(
                        Math.floor(maxRotation / oneItem) - array.length
                    );

                    if (activeSlideIndex === array.length) {
                        self.activeSlideIndex = 0;
                    } else {
                        self.activeSlideIndex = activeSlideIndex;
                    }
                    self.onRelease();
                },
            });
        },
        onRelease() {
            let index = this.activeSlideIndex - 1;
            if (index < 0) {
                index = this.artsByConstellation.length - 1;
            }

            this.$emit("handleWheelRelease", this.artsByConstellation[index]);
        },
    },
};
</script>

<style scoped>
.wrapper {
    overflow: hidden;
    display: grid;
    place-content: center;
}
.wheel-container {
    width: 26em;
    height: 26em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.wheel {
    border-radius: 50%;
    overflow: hidden;
}

.wheel-message {
    max-width: 300px;
    margin: 0 auto;
    position: absolute;
    bottom: 15px;
    font-size: 14px;
}

.arrow-icon {
    fill: var(--primary-white);
    transform: rotate(-90deg);
    display: block;
    margin: 0 auto;
    position: absolute;
    top: -5px;
}
p {
    text-align: center;
}
.progress-bar-btn {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
    height: 45px;
    background: #797979;
    border: 3px solid var(--primary-white);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-size: var(--font-size-2);
    font-weight: normal;
    line-height: 29px;
    font-family: var(--primary-font);

    text-transform: uppercase;
    color: #f4f4f4;
    margin-top: 0.5rem;
}

.progress-bar {
    width: 100%;
    background: var(--green-matrix-2);
    color: var(--primary-gray);
    height: 100%;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
}
</style>
