import { render, staticRenderFns } from "./SelectImage.vue?vue&type=template&id=bc074992&scoped=true&%3Akey=zodiacName&"
import script from "./SelectImage.vue?vue&type=script&lang=js&"
export * from "./SelectImage.vue?vue&type=script&lang=js&"
import style0 from "./SelectImage.vue?vue&type=style&index=0&id=bc074992&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc074992",
  null
  
)

export default component.exports