<template>
<section class="home-overlay">

	<video autoplay muted loop playsinline>
		<source src="@/assets/logo.mp4" type="video/mp4">
		Your browser does not support the video tag.
	</video>

	<!-- <figure>
		<img class="logo-img" src="@/assets/img/splash-logo.png" alt="Staedel Museum" />
	</figure> -->
	<!-- <figure>
		<img class="logo-img" src="@/assets/img/su.gif" alt="Staedel Museum" />
	</figure> -->

</section>
</template>

<script>
export default {
	name: 'SplashImg',
	props: {
		showSplash: {
			type: Boolean,
		},
	},
};
</script>

<style scoped>
.home-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

figure {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200%;
	height: 100%;
	/* max-width: 105px; */
	/* max-height: 228px; */
}

video {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200%;
	height: 100%;
	/* max-width: 105px; */
	/* max-height: 228px; */
}

.logo-img {
	/* filter: invert(1); */
	/* object-fit: contain; */
}

img {
	width: 100%;
	z-index: 100;
}
</style>
