<template>
    <div class="activity-wrapper">
        <section class="activity-image-container top-section">
            <div
                class="image-container"
                v-for="(art, idx) in options"
                :key="idx"
            >
                <img
                    v-if="art.img"
                    :src="imageActivity(art.img)"
                    :alt="title"
                />
                <button class="btn activity-btn" @click="handleClick(art, idx)">
                    {{ art.option }}
                </button>
            </div>
        </section>
        <div v-if="showTotalVotes" class="result-section">
            <section class="activity-buttons">
                <button
                    ref="btns"
                    v-for="(art, idx) in options"
                    :key="idx"
                    class="btn activity-btn"
                    @click="handleClick(art, idx)"
                >
                    <ActivityButton
                        :art="art"
                        :showTotalVotes="showTotalVotes"
                        :totalVotes="totalVotes"
                        :isSelected="selectNum == idx"
                    />
                </button>
            </section>
            <h3 class="vote-count" v-if="showTotalVotes">{{ $t("message.total_votes") }}: {{ totalVotes }}</h3>
        </div>
    </div>
</template>

<script>
import ActivityButton from "../activity/ActivityButton";

export default {
    components: {
        ActivityButton,
    },
    props: {
        story_name: {
            type: String,
            default: "Story name",
        },
        title: {
            type: String,
            default: "Title",
        },
        options: {
            type: Array,
            default: () => [
                {
                    id: 1,
                    option: "Answer 1",
                    img: "https://sammlung.staedelmuseum.de/images/1068/thumb-xl.jpg",
                    votes: 120, // coming from backend
                },
                {
                    id: 2,
                    option: "Answer 2",
                    img: "https://sammlung.staedelmuseum.de/images/1373/thumb-xl.jpg",
                    votes: 20, // coming from backend
                },
            ],
        },
    },
    mounted() {
        this.totalVotes = this.options.reduce((acc, cur) => {
            acc += cur.votes;
            return acc;
        }, 0);
    },
    data() {
        return {
            totalVotes: 0,
            showTotalVotes: false,
            selectNum: null,
        };
    },
    computed: {
        imageActivity() {
            return (img) =>
                img.startsWith("http")
                    ? img
                    : require(`../../assets/img/art-img/${img}`);
        },
    },

    methods: {
        handleClick(item, idx) {
            this.totalVotes = this.options.reduce((acc, cur) => {
                acc += cur.votes;
                return acc;
            }, 0);

            if (!this.showTotalVotes) {
                ++item.votes;
                ++this.totalVotes;
                this.$emit("updateVotes", {
                    id: item.id,
                    totalVotes: this.totalVotes,
                });

                this.showTotalVotes = true;
            }
            this.selectNum = idx;
        },
    },
};
</script>

<style scoped>
.activity-image-container {
    height: 500px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}
.image-container {
    width: 100%;
    height: 100%;
    max-height: 350px;
    position: relative;
}
img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
}
.image-container .activity-btn {
    font-size: 1.5rem;
    text-transform: capitalize;
}

@media screen and (min-width: 960px) {
    .image-container {
        max-height: 350px;
    }
    .image-container img {
        object-fit: contain;
    }
}
.activity-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 13px;
    margin-top: 0.6rem;
}

.activity-btn {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    padding: 1.8rem 1rem;
    background-color: var(--primary-gray);
    border: 1px solid var(--primary-white);
    overflow: hidden;
}
.vote-count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary-white);
    font-family: var(--primary-font-light);
    font-size: 1.2rem;
    font-style: normal;
    line-height: normal;
    padding-top: 1.5rem;
}
.is-selected {
    border: 2px solid var(--primary-white);
}
</style>
