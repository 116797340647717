<template>
    <div class="art-links-container">
        <div class="single-art-link header">
            <h4>Title</h4>
            <h4>inventory number</h4>
        </div>
        <router-link
            class="single-art-link link"
            v-for="(item, idx) in filteredArtList"
            :key="item.id + idx"
            :to="{
                name: 'SingleArt',
                params: {
                    inventoryNumber: item.id,
                },
            }"
        >
            <p>
                {{ item.title }}
            </p>
            <p>
                {{ item.id }}
            </p>
        </router-link>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            filteredArtList: (state) => state.adminState.filteredArtList,
        }),
    },
};
</script>

<style scoped>
.single-art-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem auto;
    transition: 0.3s all linear;
    font-family: var(--primary-font);
    font-size: 300;
}
.link:hover {
    color: red;
}
.header {
    background: var(--primary-white);
    color: var(--primary-gray);
    padding: 0.5rem;
}
</style>
