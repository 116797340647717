<template>
    <div v-if="list.length > 0" class="wheel-container">
        <div class="single-wheel wheel-list" v-for="item in 3" :key="item">
            <ul ref="wheelContainer" class="wheel wheel-constellations" :class="`wheel-${item}`">
                <li
                    v-for="constellation in list"
                    :key="constellation.name"
                    :id="
                        `id` +
                        constellation.arts[item - 1].inventory_number
                            .replaceAll(' ', '')
                            .replaceAll('.', '') +
                        `art`
                    "
                    :class="[
                        `art-${item - 1}`,
                        constellation.arts[item - 1].core_art
                            ? 'is-core-art'
                            : '',
                        constellation.can_play &&
                        !constellation.arts[item - 1].isFound
                            ? 'can-play'
                            : '',
                        !constellation.can_play &&
                        !constellation.arts[item - 1].isFound
                            ? 'can-not-play'
                            : '',
                        constellation.arts[item - 1].isFound
                            ? `${constellation.name}`
                            : '',
                    ]"
                    :style="{ opacity:
                        setProgress(constellation.arts[item - 1]) == 0 ? 0.1 : `${setProgress(constellation.arts[item - 1])}%`,}"
                >
                    <div class="constellation-icon" v-if="constellation.arts[item - 1].core_art">
                        <component
                            :is="constellation.name"
                            :canPlay="constellation.can_play && !constellation.arts[item - 1].isFound"
                            :isFound="constellation.arts[item - 1].isFound"
                        />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import ZodiacIcon from "../iconsConstellations/base.vue";

import Aries from "../iconsConstellations/Aries.vue";
import Taurus from "../iconsConstellations/Taurus.vue";
import Gemini from "../iconsConstellations/Gemini.vue";
import Cancer from "../iconsConstellations/Cancer.vue";

import Capricorn from "../iconsConstellations/Capricorn.vue";
import Scorpius from "../iconsConstellations/Scorpius.vue";
import Leo from "../iconsConstellations/Leo.vue";
import Sagittarius from "../iconsConstellations/Sagittarius.vue";

import Aquarius from "../iconsConstellations/Aquarius.vue";
import Libra from "../iconsConstellations/Libra.vue";
import Pisces from "../iconsConstellations/Pisces.vue";
import Virgo from "../iconsConstellations/Virgo.vue";

// aquarius: Aries,
// libra: Aries,
// pisces: Aries,
// virgo: Aries,
export default {
    components: {
        /* eslint-disable vue/no-unused-components */
        ZodiacIcon,
        aries: Aries,
        cancer: Cancer,
        gemini: Gemini,
        taurus: Taurus,

        capricorn: Capricorn,
        scorpius: Scorpius,
        leo: Leo,
        sagittarius: Sagittarius,

        aquarius: Aquarius,
        libra: Libra,
        pisces: Pisces,
        virgo: Virgo,
    },
    props: {
        list: {
            type: Array,
        },
        activeSlideIndex: {
            type: Number,
        },
    },
    data() {
        return {
            wheels: [{ id: 2, name: "wheel-constellations" }],
        };
    },
    mounted() {
        this.$refs.wheelContainer.forEach((el) => {
            this.setItemsPosition(el.querySelectorAll("li"));
        });

        setTimeout(() => {
            gsap.set(".wheel-constellations", {
                rotate: (this.activeSlideIndex * -360) / this.list.length - 15,
            });
            // gsap.to(".wheel-bg", { rotate: 360, duration: 12, repeat: -1 });
        });
    },
    computed: {
        setProgress() {
            return (el) => {
                let progress = Math.floor(
                    (el.completed_stories * 100) / el.stories_count
                );
                return isNaN(progress) ? 0 : progress;
            };
        },
    },
    methods: {
        setItemsPosition(bgItems) {
            bgItems.forEach((el, idx) => {
                if (el.classList.contains("is-core-art") && el.firstChild) {
                    gsap.set(el.firstChild, {
                        skewY: 63,
                        skewX: -14,
                        left: 14,

                        position: "absolute",
                    });
                }
                gsap.set(el, {
                    rotate: idx * 30,
                    skewY: -60,
                });
            });
        },
    },
};
</script>

<style scoped>
.wheel-container {
    position: relative;
}

.single-wheel {
    width: 100%;
    height: 100%;
    inset: 0 0 0 0;
    position: absolute;

    /* width: 25em;
    height: 25em; */
    width: 75vw;
    height: 75vw;

    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.wheel-list {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.wheel-wrapper {
    width: 100%;
    height: 100%;
}
.wheel {
    border-radius: 50%;
    overflow: hidden;
}

.wheel-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 83%;
    height: 83%;
    z-index: -1;
}

.wheel-constellations {
    position: relative;
    border: 0.5px solid var(--primary-white);
    width: 100%;
    height: 100%;
    background: var(--primary-gray);
}

li {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 50%;
    transform-origin: 0% 100%;
    border: 1px solid var(--primary-white);
}

.wheel-2 {
    width: 75%;
    height: 75%;
}
.wheel-3 {
    width: 50%;
    height: 50%;
}

.is-core-art {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.constellation-icon {
    width: 40px;
    height: 40px;
    text-align: center;
}

.can-play {
    background: var(--primary-white);
    opacity: 1 !important;
}

.can-not-play {
    opacity: 1 !important;
    background: var(--primary-gray);
}

li svg {
    transform: scale(0.7);
}

.aries {
    background: var(--aries-color);
}
.leo {
    background: var(--leo-color);
}
.sagittarius {
    background: var(--sagittarius-color);
}
.pisces {
    background: var(--pisces-color);
}
.cancer {
    background: var(--cancer-color);
}
.scorpius {
    background: var(--scorpius-color);
}
.capricorn {
    background: var(--capricorn-color);
}
.virgo {
    background: var(--virgo-color);
}
.taurus {
    background: var(--taurus-color);
}
.aquarius {
    background: var(--aquarius-color);
}
.libra {
    background: var(--libra-color);
}
.gemini {
    background: var(--gemini-color);
}
</style>
