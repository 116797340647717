<template>
    <div class="story-top-wrapper">
        <label class="single-option">
            name
            <input v-model="story.story_name" />
        </label>
        <label class="single-option">
            title
            <input v-model="story.title" />
        </label>
        <div v-if="!showTwoImg">
            <div v-if="showImg">
                <label> image</label>
                <input class="single-option" v-model="story.img" />
            </div>
        </div>
        <div v-else>
            <label>images</label>
            <div v-for="option in story.options" :key="option.id + 'img'">
                <input class="single-option" v-model="option.img" />
            </div>
        </div>

        <label>Competence</label>
        <input v-model="tempCompetence" @keyup.ctrl="handleNewCompetence" />
        <div class="pills-container">
            <div
                @click="deleteCompetence(competence)"
                class="single-pill"
                v-for="competence in story.competence"
                :key="competence"
            >
                {{ competence }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        story: {
            type: Object,
        },
        showKeywords: {
            type: Boolean,
            default: false,
        },
        showImg: {
            type: Boolean,
            default: false,
        },
        showTwoImg: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tempCompetence: "",
        };
    },
    methods: {
        handleNewCompetence(e) {
            if (!this.story.competence) this.story.competence = [];
            if (e.key === "," && this.tempCompetence) {
                if (!this.story.competence.includes(this.tempCompetence)) {
                    this.story.competence.push(this.tempCompetence);
                    this.tempCompetence = "";
                }
            }
        },
        deleteCompetence(competence) {
            this.story.competence = this.story.competence.filter(
                (comp) => comp != competence
            );
        },
    },
};
</script>

<style scoped>
.single-option {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.pills-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 0.5rem;
}
.single-pill {
    background: var(--primary-gray);
    padding: 0.3rem;
    border-radius: 5px;
    cursor: pointer;
}
.single-pill:hover {
    opacity: 0.9;
}
</style>
