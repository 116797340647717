<template>
    <div>
        <select v-model="selected">
            <option v-for="(option, idx) in activitiesList" :key="idx">
                {{ option }}
            </option>
        </select>

        <main :key="componentKEY">
            <ActivityDescription v-if="activity.story_name" />
            <Timer v-if="!showResult && !timeOutModal && showTimer" />
            <ZoomComponent />
            <ActivityModal
                v-if="timeOutModal && !showResult"
                :modalTitle="modalTitle"
                @closeTimeOutModal="closeModal"
            />
            <component
                v-if="activity.name"
                v-bind="activity"
                :is="activity.name"
                :key="activity.name + componentKEY"
                @updateVotes="updateVotes"
            />
            <ZoomImage v-if="showZoomImg" :activity="activity" />
            <FeedbackSection
                v-if="showResult && selectedOptions.shortFeedBack"
            />
            <LongModal
                v-if="showModalFeedBack"
                :title="activity.story_name"
                :subtitle="currentArt.constellation"
                :content="selectedOptions.longFeedBack"
            />
        </main>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {
    ZoomImage,
    ActivityModal,
    ActivityDescription,
    FeedbackSection,
    DragPoll,
    PollTwoOptions,
    PollManyOptions,
    // PollManyOptions as Quiz,
    QuizActivity as Quiz,
    SlideManyOptions as SliderManyOptions,
    SlideTwoOptions as SliderTwoOptions,
    DragCommon,
    DragAndMatch,
    LongModal,
    Timer,
} from "../../components";
// import MagnifyingGlass from "../../components/icons/MagnifyingGlass.vue";
import resultButtonsAnimation from "../../helperFunctions/animations/activitiesAnimations";
gsap.registerPlugin(ScrollToPlugin);
import activitiesTestData from "../../utils/activitiesTestData";
import ZoomComponent from "../../components/ui/ZoomComponent.vue";

export default {
    /* eslint-disable vue/no-unused-components */
    components: {
        ActivityDescription,
        ActivityModal,
        DragAndMatch,
        DragCommon,
        DragPoll,
        FeedbackSection,
        // MagnifyingGlass,
        ZoomComponent,
        PollManyOptions,
        PollTwoOptions,
        Quiz,
        SliderManyOptions,
        SliderTwoOptions,
        ZoomImage,
        LongModal,
        Timer,
    },
    mounted() {
        this.selectedData = activitiesTestData.find(
            (item) => item.name === this.selected
        );

        let activity = this.selectedData.data;
        this.$store.dispatch("SET_ART_ACTIVITIES_ADMIN_TESTING", activity);

        setTimeout(() => {
            console.log(this.activity);
        }, 1000);
    },
    data() {
        return {
            selected: "PollTwoOptions",
            componentKEY: "",
            selectedData: {},
            showActivity: true,
            activitiesList: [
                "SliderManyOptions",
                "PollManyOptions",
                "SliderTwoOptions",
                "DragAndMatch",
                "DragCommon",
                "DragPoll",
                "PollTwoOptions",
                "Quiz",
            ],
        };
    },

    watch: {
        selected(value) {
            this.$store.dispatch("RESET_SELECTED");
            this.selectedData = activitiesTestData.find(
                (item) => item.name === value
            );
            let activity = this.selectedData.data;

            this.$store.dispatch("SET_ART_ACTIVITIES_ADMIN_TESTING", activity);
            this.$store.dispatch("HIDE_TIMER");
        },
    },

    computed: {
        ...mapState({
            activity: (state) => state.activitiesState.artActivity,
            showModalFeedBack: (state) => state.gameState.showModalFeedBack,
            currentArt: (state) => state.gameState.currentArt,
            showContinueButton: (state) =>
                state.activitiesState.showContinueButton,
            showResult: (state) => state.activitiesState.showResult,
            showZoomImg: (state) => state.activitiesState.showZoomImg,
            showTimer: (state) => state.activitiesState.showTimer,
            selectedOptions: (state) => state.activitiesState.selectedOptions,
            timeOutModal: (state) => state.activitiesState.timeOutModal,
            modalTitle(state) {
                return state.activitiesState.artActivity.name === "DragAndMatch"
                    ? "out of tries"
                    : "time out";
            },
        }),
    },

    methods: {
        closeModal() {
            this.$store.dispatch("TOGGLE_TIMEOUT_MODAL", false);
            if (this.activity.name === "DragAndMatch") {
                this.componentKEY = this.activity + this.remainingTime;
                ++this.remainingTime;
            }
        },
        updateVotes({ id, totalVotes }) {
            let userSelection = this.activity.options.find(
                (option) => option.id === id
            );
            this.$store.dispatch("SHOW_RESULT", userSelection);
            resultButtonsAnimation(this.activity.options, totalVotes);
        },
    },
};
</script>

<style scoped>
main {
    position: relative;
}

.activity-wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
}
.activity-image-container {
    width: 100%;
    height: 290px;
    position: relative;
    border-bottom: 0.5px solid var(--primary-white);
    border-top: 0.5px solid var(--primary-white);
    margin: 1rem 0;
}
.activity-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* TODO repeated */
.activity-btn {
    position: relative;
    min-height: 45px;
    text-align: start;
    justify-content: space-between;
    height: fit-content;
    border: 0.5px solid var(--primary-white);
    border-radius: 100px;
    overflow: hidden;
    background: transparent;
    color: var(--primary-white);
    font-size: 18px;
}
</style>
