<template>
	<div>
		<main>
			<!-- 스플래시 화면이 필요합니다. -->
			<SplashImg v-if="showSplash" :showSplash="showSplash" />
			<!-- <SplashImg v-if="showSplash" :showSplash="showSplash" /> -->
			<div v-else class="game-options">
				<SwitchLanguage @localeChanged="setLanguage" />
				<HomeButtons :isAnonymous="!isJoinedPlayer"
					@startGame="onJustPlay" 
					@login="onLogin"
					@join="onJoin" />
				<!-- <img src="../assets/img/staedel-front.png" alt="" /> -->
			</div>
		</main>
		<BackgroundStars v-if="!showSplash" />
		<ZoomEffect2 v-if="showZoomEffect" @zoomCompleted="onZoomCompleted()" />
	</div>
</template>

<script>
// import gameApi from '../api/gameApi';
import {
	SplashImg, BackgroundStars, SwitchLanguage, HomeButtons, ZoomEffect2
} from '../components/';

export default {
	components: {
		SplashImg,
		ZoomEffect2,
		BackgroundStars,
		SwitchLanguage,
		HomeButtons,
	},

	data() {
		return {
			// alreadyPlaying: false,
			// showOverlay: true,
			currentGameLocale: '',
			showZoomEffect: false,
			showSplash: true,
			isJoinedPlayer: false,
		};
	},

	methods: {
		setLanguage(locale) {
			// let htmlLang = document.querySelector('html').lang;
			// this.currentGameLocale = localStorage.getItem('locale');
			// this.alreadyPlaying = this.currentGameLocale == htmlLang;
			this.$i18n.locale = locale;
			localStorage.setItem('locale', locale);
		},
		// async checkGameExist() {
		// 	try {
		// 		await gameApi.post('play/check-existing-game');
		// 		this.alreadyPlaying = true;
		// 		console.log('has game');
		// 	} catch (error) {
		// 		this.alreadyPlaying = false;
		// 		console.log('no game');
		// 		console.log(error.response.data.msg);
		// 	}
		// },
		async onZoomCompleted() {
			this.showZoomEffect = false;
			this.$store.dispatch('GET_LOCAL_STORE');
			if (this.$store.getters.checkLocalStore) {
				this.$router.push("/select-constellation");
			} 
			else {
				this.$store.dispatch('START_NEW_GAME');
				this.$router.push('/new-intro');
			}
		},
		onJustPlay() {

			// localStorage.setItem('locale', this.$i18n.locale);
			// this.showZoomEffect = true;

			
			console.log("just play")
			localStorage.setItem('locale', this.$i18n.locale);
			this.$store.dispatch('GET_LOCAL_STORE');
			console.log("check local store", this.$store.getters.checkLocalStore)
			if (this.$store.getters.checkLocalStore) {
				this.$router.push("/select-constellation");
			}
			else {
				this.showZoomEffect = true;
				console.log("showZoomEffect")
			}
		},
		onLogin() {
			this.$router.push('/login');
		},
		onJoin() {
			this.$router.push('/join-step1');
		},	
	},
	mounted() {
		setTimeout(() => {
			this.showSplash = false;
		}, 5000);

		let payload = {
			player_id: localStorage.getItem("player__id")
		};
               
		this.$store.dispatch(
			'CHECK_JOINING', payload
		).then((res) => {
			console.log(res)
			if (res.status === 200) {
				this.isJoinedPlayer = true;	
				console.log("joined player");		
			}
		}).catch(() => {
			this.isJoinedPlayer = false;
			console.log("anonymous player");
			// console.log(error);
		});
		// this.setLanguage();
		// this.checkGameExist();
	},
};
</script>

<style scoped>
main {
	height: 100vh;
	position: relative;
}
.game-options {
	height: 100%;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;
	gap: 3rem;
}
img {
	width: 100%;
	z-index: 100;
}
</style>
