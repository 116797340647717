<template>
    <div class="activity-wrapper">
        <section
            v-if="activity.name && activity.name !== 'PollTwoOptions'"
            class="activity-image-container top-section"
        >
            <div>
                <img
                    v-if="activity.name && activity.name !== 'DragPoll'"
                    :src="imageActivity(img)"
                    :alt="title"
                />
            </div>
        </section>
        <section
            v-else-if="activity.name && activity.name == 'PollTwoOptions'"
            class="section-container top-section activity-image-container"
        >
            <div
                class="image-container"
                v-for="(art, idx) in options"
                :key="idx"
            >
                <img :src="imageActivity(art.img)" :alt="title" />
                <p>{{ art.option }}</p>
            </div>
        </section>
        <section v-if="options.length" class="result-section">
            <div class="activity-buttons">
                <button
                    ref="btns"
                    v-for="(art, idx) in options"
                    :key="idx"
                    class="btn activity-btn"
                    :class="art.isSelected && 'is-selected'"
                >
                    <ActivityButton
                        :art="art"
                        :showTotalVotes="true"
                        :totalVotes="totalVotes"
                        :options="options"
                    />
                </button>
            </div>
            <h3 class="vote-count">{{ $t("message.total_votes") }}: {{ totalVotes }}</h3>
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex";
import resultButtonsAnimation from "../../helperFunctions/animations/activitiesAnimations";
import ActivityButton from "../activity/ActivityButton";
export default {
    components: {
        ActivityButton,
    },
    props: {
        story_name: {
            type: String,
        },
        title: {
            type: String,
        },
        img: {
            type: String,
        },
        options: {
            type: Array,
        },
    },

    mounted() {
        this.totalVotes = this.options.reduce((acc, cur) => {
            acc += cur.votes;
            return acc;
        }, 0);

        this.$store.dispatch("SHOW_RESULT", this.selectedOptions);
        resultButtonsAnimation(this.options, this.totalVotes);

        this.options.map = this.options.map((option) => {
            if (option.option === this.selectedOptions.option) {
                option.isSelected = true;
            }
            return option;
        });
    },

    computed: {
        imageActivity() {
            return (img) => {
                return img.startsWith("http")
                    ? img
                    : require(`./../../assets/img/art-img/${img}`);
            };
        },

        ...mapState({
            activity: (state) => state.activitiesState.artActivity,
            selectedOptions: (state) => state.activitiesState.selectedOptions,
        }),
    },

    data() {
        return {
            totalVotes: 0,
            resultOptions: [],
        };
    },
};
</script>

<style scoped>
.activity-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 13px;
    margin-top: 0.6rem;
}
.activity-btn {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    padding: 1.8rem 1rem;
    background-color: var(--primary-gray);
    border: 2px solid var(--primary-white);
    overflow: hidden;
}
.vote-count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary-white);
    font-family: var(--primary-font-light);
    font-size: 1.2rem;
    font-style: normal;
    line-height: normal;
    padding-top: 1.5rem;
}

.section-container {
    position: relative;
    height: 400px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 1rem;
}

.is-selected {
    background: 2px solid var(--primary-white);
    color: var(--primary-black);
}
</style>
