import axios from "axios";
import router from "../../router";

const authState = {
    state: () => ({
        isAuthenticated: false,
        token: "",
        authErrors: [],
    }),
    getters: {},

    mutations: {
        LOGIN_USER2: (state, payload) => {
            axios.defaults.headers.common["Authorization"] = "Token " + payload;
            state.token = payload;
            localStorage.setItem("token", payload);
            state.isAuthenticated = true;
            router.push("/profile");
        },
        LOG_OUT: () => {
            axios.defaults.headers.common["Authorization"] = "";
            localStorage.removeItem("token");
            window.location.reload();
        },
        DISCONNECT_ACCOUNT: () => {
            axios.defaults.headers.common["Authorization"] = "";
            localStorage.clear();
            window.location.reload();
        },
        AUTH_ERRORS: (state, payload) => {
            state.authErrors = payload;
            setTimeout(() => {
                state.authErrors = [];
            }, 10000);
        },
        CHECK_USER: (state) => {
            if (localStorage.getItem("token")) {
                state.token = localStorage.getItem("token");
                state.isAuthenticated = true;
            } else {
                state.token = "";
                state.isAuthenticated = false;
            }
        },
        LOGIN_USER: (state, payload) => {
            //axios.defaults.headers.common["Authorization"] = "Token " + payload.token;
            // state.token = payload.token;
            // localStorage.setItem("token", payload.token);
            state.isAuthenticated = true;
            localStorage.setItem("game__id", payload.game_id);
            localStorage.setItem("player__id", payload.player_id);
            // router.push("/");
        },
        JOIN_USER: (state, payload) => {
            // axios.defaults.headers.common["Authorization"] = "Token " + payload;
            // state.token = payload;
            // localStorage.setItem("token", payload);
            state.isAuthenticated = true;
            console.log(payload)
            // router.push("/");
        },
    },

    actions: {
        CHECK_USER: (context) => {
            context.commit("CHECK_USER");
        },
        LOGIN_USER: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/login/", payload).then((res) => {
                    context.commit("LOGIN_USER", res.data);
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            });
        },
        SEND_AUTH_EMAIL: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/send-auth-email/", payload).then((res) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        JOIN_USER: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/join/", payload).then((res) => {
                    context.commit("JOIN_USER", res.data);
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            });
        },
        SEND_PASSWORD_RESET_EMAIL: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/send-password-reset-email/", payload).then((res) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        RESET_PASSWORD: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/reset-password/", payload).then((res) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            })
        },


        REGISTER_USER: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/register/", payload).then((res) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            });
            /*
            const errors = [];
            try {
                const res = await axios.post("/user/register/", payload);

                if (res.status === 201) {
                    context.dispatch("LOGIN_USER", {
                        email: this.email,
                        password: this.password,
                    });
                }
            } catch (error) {
                if (error.response) {
                    for (const property in error.response.data) {
                        errors.push(
                            `${property}: ${error.response.data[property]}`
                        );
                    }
                } else {
                    errors.authErrors.push(
                        "Something went wrong. Please try again"
                    );

                    console.log(JSON.stringify(error));
                }
                context.commit("AUTH_ERRORS", errors);
            }
            */
        },

        LOGIN_USER2: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/login/", payload).then((res) => {
                    context.commit("LOGIN_USER", res.data.auth_token);
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            });
            /*
            // const errors = [];
            try {
                //const res = await axios.post("/user/login/", payload);
                // localStorage.setItem("game__id", res.data.game_id);
                // this.$router.push("/");
                // gameState.dispatch("SET_LOCAL_STORE", {
                //     game_id: res.data.game_id,
                // });
                // context.commit("LOGIN_USER", res.data.auth_token);
            } catch (error) {
                console.log(error)
                
                if (error.response) {
                    for (const property in error.response.data) {
                        errors.push(
                            `${property}: ${error.response.data[property]}`
                        );
                    }
                } else {
                    errors.authErrors.push(
                        "Something went wrong. Please try again"
                    );

                    console.log(JSON.stringify(error));
                }
                console.log(error);
                context.commit("AUTH_ERRORS", errors);
                
            }
            */
        },
        /*
        REGISTER_USER: async (context, payload) => {
            const errors = [];
            try {
                const res = await axios.post("/auth/users/", payload);

                if (res.status === 201) {
                    context.dispatch("LOGIN_USER", {
                        username: payload.username,
                        password: payload.password,
                    });
                }
            } catch (error) {
                if (error.response) {
                    for (const property in error.response.data) {
                        errors.push(
                            `${property}: ${error.response.data[property]}`
                        );
                    }
                } else {
                    errors.authErrors.push(
                        "Something went wrong. Please try again"
                    );

                    console.log(JSON.stringify(error));
                }
                context.commit("AUTH_ERRORS", errors);
            }
        },
        LOGIN_USER: async (context, payload) => {
            const errors = [];
            try {
                const res = await axios.post("/auth/token/login/", payload);
                context.commit("LOGIN_USER", res.data.auth_token);
            } catch (error) {
                if (error.response) {
                    for (const property in error.response.data) {
                        errors.push(
                            `${property}: ${error.response.data[property]}`
                        );
                    }
                } else {
                    errors.authErrors.push(
                        "Something went wrong. Please try again"
                    );

                    console.log(JSON.stringify(error));
                }
                context.commit("AUTH_ERRORS", errors);
            }
        },
        */
        LOG_OUT: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/logout/", payload).then((res) => {
                    context.commit("LOG_OUT");
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            });
        },
        CHECK_JOINING: async (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post("/user/check-joining/", payload).then((res) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            });
        }
    },
    
};

export default authState;
