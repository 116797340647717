<template>
    <div class="container">
        <auth-navbar  :back-to="'/login'" />
        <div class="form">
            <div v-if="!sendEmailFlag">
                <div class="title">{{ $t('message.forgot_title')}}</div>
                <div class="greetings">
                    {{ $t('message.forgot_desc')}}      
                </div>
                <div>
                <label for="email">{{ $t('message.email')}}</label>
                <input class="email-input" name="email" placeholder="Email address"
                    v-model="email" 
                    @input="unRegisteredEmailFlag=false;tryAgainFlag=false"
                    required/>  
                <div class="text-msg">
                    {{ unRegisteredEmailFlag ? 
                        'Unregistered email address.' : 
                        tryAgainFlag ? 
                            $t('message.try_again') : 
                            email === '' ? '' : textMsg 
                    }}
                </div>
                <button type="submit" class="btn-submit" @click="sendPasswordResetEmail()"
                    :style="{ background: email === '' || !checkEmail(email) ? '#E8E8E8' : '#DA8E61' }"
                        :disabled = "email==='' || !checkEmail(email) || sendEmailFlag">
                    {{ $t('message.reset_password') }}
                </button>
                </div>
            </div>
          
            <div v-else>
                <div class="after-send-email-1">
                    {{ $t('message.we_send_email', {email_address: email}) }}
                </div>
                
                <div class="after-send-email-3">
                    {{ $t('message.forgot_just_click') }}
                </div>
                <div class="after-send-email-4">
                        {{ $t('message.resend_email') }}
                </div>
                <div class="after-send-email-5" @click="sendEmailFlag=false;email=''">
                        {{ $t('message.resend_email_link') }}
                </div>
            </div>
        
            <msg-modal v-if="isMsgModalShow"
                :msg="msg"
                :type="msgType"
                @close="isMsgModalShow=false" />

        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordResetStep1',
    components: {
        'msg-modal': () => import('@/components/ui/MsgModal.vue'),
        'auth-navbar': () => import('@/components/ui/AuthNavbar.vue')
    },
    data() {
        return {
            email: '',
            isMsgModalShow: false,
            msg: '', //'Sending...',
            msgType: 'ing',
            textMsg: '',
            sendEmailFlag: false,
            unRegisteredEmailFlag: false,
            tryAgainFlag: false
        }
    },

    methods: {
        checkEmail(email) {
            if (email === '') {
                this.textMsg = '';
                this.isMsgModalShow = false;
                return false;
            }
            else {
                const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!emailRegExp.test(email)) {
                    this.textMsg = this.$t('message.valid_email');
                    this.isMsgModalShow = false;
                    return false;
                }
            }
            this.textMsg = '';
            return true;
        },
        getEmailMessages() {
            let emailMessages = {};
            let i = 0;
            this.$t('message.forgot_email_intro').split('\n').forEach((line) => {
                emailMessages['top_line_' + (i + 1)] = line;
                i++;
            });
            emailMessages['button'] = this.$t('message.reset_password');
            emailMessages['bottom_line_1'] = this.$t('message.forgot_email_outro');
            return emailMessages;
        },

        async sendPasswordResetEmail() {
          this.isMsgModalShow = true;
          try {
            const res = await this.$store.dispatch("SEND_PASSWORD_RESET_EMAIL", {
                email: this.email.trim(),
                subject: this.$t('message.forgot_email_subject'),
                messages: this.getEmailMessages()
            });
            if (res.status === 200) {
              this.isMsgModalShow = false;
              this.sendEmailFlag = true;
            }
          } catch (error) {
            if (error.response.status === 400 
                && error.response.data.msg === 'email is not registered') {
              //this.texMsg = 'Unregistered email address.';
              this.unRegisteredEmailFlag = true;
              this.isMsgModalShow = false;
            }
            else {
              //this.textMsg = 'Please try again.';
              this.isMsgModalShow = false;
              this.tryAgainFlag = true;
            }
          }
        },
    }
}
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #f5f5f5;
    color:black
}

.container {
    width: 100%;
    height: 100vh;
}

.form {
    width: 100%;
    height: 600px;
    left: 0px;
    top: 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10%;
}


.email-input {
    width: 100%;
    height: 44px;
    border-color:#EEEEEE;
    background-color: #EEEEEE;
    font-size: 20px;
    margin-top: 10px;
    padding: 20px;
    color: black;
    text-align: left;
    border-width: 0px;
}

.text-area {
    font-size: 20px;
    margin-bottom: 20px;
    color: #000000;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: left;
    justify-content: left;
    align-items: left;
    justify-items: left;
}
.title {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;
}
.greetings {
    font-style: normal;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 60px;
}


.email-area {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    height: 100px;
}
.btn-submit {
    color: #000000;
    font-size: 20px;
    margin-top: 40px;
    height: 50px;
    width: 100%;
}

.term {
    font-size: 17px;
}

label {
  color: #000000;
  font-size: 20px;
}
a {
    color: blue;
    text-decoration: underline;
}
.text-msg {
    color: red;
    font-size: 12x;
    margin-top: 10px;
    padding-left: 20px
}
.after-send-email-1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
}
.after-send-email-2 {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 130%;
}
.after-send-email-3 {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 130%;
}
.after-send-email-4 {
    font-size: 15px;
    line-height: 130%;
}
.after-send-email-5 {
    font-size: 15px;
    line-height: 130%;
    color: blue;
    text-decoration: none;
}
.user-email {
    text-decoration: underline;
}
input {
  font-size: 20px;
}
.text-msg {
  height: 20px;
}
</style>
