<template>
    <main>
        <div class="main-container">
            <div class="under-line"></div>
            <div class="message-box">
                <p v-if="isEng" class="font-medium">
                    Hello, my name is Al. I am speaking to you from the future. This star portal can transcend space and time. I hope my message has reached you this way and is in the right hands with you. 
                    <br/><br/>
                    We are losing more and more knowledge: there is an infinite amount of data available, but the connections can no longer be made. This "great forgetting" has begun in your time.
                    <br/>
                    Help me to stop this destructive process!
                    <br/><br/>
                    Only an archive of knowledge from your time can help us: The universe of art. Use the zodiac signs as a guide and collect information. Use it to fill your color wheel and generate energy: the light waves of the colors transport your collected data into the future.
                </p>
                <p v-else class="font-medium">
                    Hallo, mein Name ist Al. Ich spreche zu dir aus der Zukunft. Dieses Sternenportal kann Raum und Zeit überwinden. Ich hoffe meine Botschaft hat dich auf diesem Weg erreicht und ist bei dir in den richtigen Händen.
                    <br/><br/>
                    Uns geht immer mehr Wissen verloren: Es liegen unendlich viele Daten vor, aber die Zusammenhänge können nicht mehr hergestellt werden. Dieses „große Vergessen“ hat in deiner Zeit begonnen.
                    <br/>
                    Hilf mir, diesen zerstörerischen Prozess aufzuhalten!
                    <br/><br/>
                    Nur ein Wissensarchiv aus deiner Zeit kann uns helfen: Das Universum der Kunst. Orientiere dich an den Sternzeichen und sammle Informationen. Fülle damit deinen Farbkreis und erzeuge Energie: Die Lichtwellen der Farben transportieren deine gesammelten Daten in die Zukunft.
                </p>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            isEng: true,
        };
    },
    mounted() {
        this.isEng = this.$i18n.locale === "en" ? true : false;
    },
}
</script>

<style scoped>
main {
    width: 100%;
    height: 100%;
}
.message-box {
    padding: 30px 40px;
}
p {
    font-size: 2.2rem;
}
</style>
import { mount } from '@vue/test-utils';
