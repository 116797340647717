<template>
    <div class="activity-wrapper">
        <DragImage v-if="options.length > 0" :options="options" />
        <section class="activity-buttons result-section">
            <button
                ref="btns"
                v-for="(art, idx) in options"
                :key="art.id"
                class="btn activity-btn"
                @click="handleClick(art, idx)"
            >
                <ActivityButton
                    v-if="showTotalVotes"
                    :art="art"
                    :showTotalVotes="showTotalVotes"
                    :totalVotes="totalVotes"
                    :options="options"
                    :isSelected="selectNum === idx"
                />
                <span v-else>{{ art.option }}</span>
            </button>
        </section>
        <h3 class="vote-count" v-if="showTotalVotes">{{ $t("message.total_votes") }}: {{ totalVotes }}</h3>
    </div>
</template>

<script>
import ActivityButton from "../activity/ActivityButton";
import DragImage from "../activity/DragImage.vue";
export default {
    components: {
        ActivityButton,
        DragImage,
    },
    props: {
        title: {
            type: String,
            default: "title",
        },
        story_name: {
            type: String,
            default: "Story name",
        },

        options: {
            type: Array,
        },
    },
    mounted() {},

    computed: {
        imageActivity() {
            return (img) =>
                img.startsWith("http")
                    ? img
                    : require(`../../assets/img/art-img/${img}`);
        },
    },
    data() {
        return {
            totalVotes: 0,
            showTotalVotes: false,
            selectNum: null,
        };
    },
    methods: {
        handleClick(item, idx) {
            this.totalVotes = this.options.reduce((acc, cur) => {
                acc += cur.votes;
                return acc;
            }, 0);
            
            ++item.votes;
            ++this.totalVotes;
            this.$emit("updateVotes", {
                id: item.id,
                totalVotes: this.totalVotes,
            });
            this.showTotalVotes = true;
            this.selectNum = idx;
        },
    },
};
</script>

<style scoped>

.activity-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 13px;
    margin-top: 0.6rem;
}

.activity-btn {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    padding: 1.8rem 1rem;
    background-color: var(--primary-gray);
    border: 1px solid var(--primary-white);
    overflow: hidden;
}

.vote-count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary-white);
    font-family: var(--primary-font-light);
    font-size: 1.2rem;
    font-style: normal;
    line-height: normal;
    padding-top: 1.5rem;
}
</style>
