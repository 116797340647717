<template>
    <div class="modal-overlay-wrapper">
        <div class="progress-bg">
            <h2 class="progress-modal-title">{{ topMessage }}</h2>
            <div class="item-img">
                <img
                    v-if="!isCoreUnlock"
                    src="../../assets/img/key.png"
                    alt="key"
                />
                <img v-else src="../../assets/img/padlock.png" alt="padlock" />
            </div>
            <section class="bottom-section">
                <div class="progress-modal-msg">
                    <p v-html="bottomMessage"></p>
                </div>
                <button @click="handleClick" class="btn-round">
                    {{ $t("message.continue") }}
                </button>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        competences: {
            type: Array,
        },
        allStoriesCompleted: {
            type: Boolean,
        },
        numberOfKeyFound: {
            type: Number,
        },
        keysMessage: {
            type: String,
        },
    },

    data() {
        return {
            isCoreUnlock: false,
        };
    },

    computed: {
        topMessage() {
            let message = this.$t("message.new_key");

            return !this.isCoreUnlock
                ? message
                : this.$t("message.core_art_unlocked");
        },
        bottomMessage() {
            let message = this.$t("message.you_unlocked_the_core_art");

            return this.isCoreUnlock ? message : this.keysMessage;
        },
        keyOrPadlock() {
            // check if using
            return this.isCoreUnlock ? "key" : "padlock";
        },
    },

    methods: {
        handleClick() {
            if (this.numberOfKeyFound !== 2 || this.isCoreUnlock)
                this.$emit("closeKeyAndPadlock");

            if (this.numberOfKeyFound === 2) {
                this.isCoreUnlock = true;
                return;
            }
        },
    },
};
</script>

<style scoped>
.modal-overlay-wrapper {
    overflow: scroll;
}
.progress-bg {
    gap: 0;
}
.progress-modal-title {
    font-size: 1.5rem;
}
section {
    width: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
}
.progress-modal-title {
    text-transform: uppercase;
}

.progress-bg {
    height: unset;
}

.bottom-section {
    gap: 3rem;
}

.item-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-img img {
    width: 100%;
    max-width: 339px;
}
</style>
