<template>
    <div class="container">
        <div class="wrapper">
            <div @click="$store.dispatch('TOGGLE_ZOOM_COMPONENT')">
                <MagnifyingGlass />
            </div>
        </div>
        <SwitchImagesZoom v-if="showOptions" />
        <ZoomImage v-if="showZoomImg" :activity="activity" />
    </div>
</template>

<script>
import { mapState } from "vuex";

import SwitchImagesZoom from "../ui/SwitchImagesZoom.vue";
import ZoomImage from "../activity/ZoomImage.vue";
import MagnifyingGlass from "../icons/MagnifyingGlass.vue";
export default {
    components: {
        SwitchImagesZoom,
        ZoomImage,
        MagnifyingGlass,
    },

    computed: {
        ...mapState({
            activity: (state) => state.activitiesState.artActivity,
            showZoomImg: (state) => state.activitiesState.showZoomImg,
            showOptions: (state) => {
                let name = state.activitiesState.artActivity.name;
                let displaySwitcher = false;

                if (
                    name === "DragCommon" ||
                    name === "PollTwoOptions" ||
                    name === "DragPoll"
                ) {
                    displaySwitcher = true;
                }

                return displaySwitcher;
            },
        }),
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    gap: 0.6rem;
}
.wrapper {
    border-radius: 5px;
    background-color: var(--primary-white);
    padding: 5px;
}
</style>
