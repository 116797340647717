const de = {
    message: {
        CODE: "de",

        just_play: "Jetzt spielen",
        login_play: "Anmelden",
        sign_up: "Registrieren",
        privacy_policy: "Indem du dich bei diesem Spiel anmeldest, erklärst du dich mit der {privacy_policy_link} einverstanden.",
        privacy_policy_word: "Datenschutzrichtlinie",
        privacy_policy_link: "<a href='https://www.staedelmuseum.de/de/datenschutz' target='_blank' style='color:blue'>Datenschutzrichtlinie</a>",
        next: "Weiter",
    
        level: "Level",
        go_to_the_star_signs: "Zu den Sternzeichen",
        start: "Start",
    
        libra: "Waage",
        scorpius: "Skorpion",
        cancer: "Krebs",
        aquarius: "Wassermann",
        pisces: "Fische",
        taurus: "Stier",
        virgo: "Jungfrau",
        sagittarius: "Schütze",
        aries: "Widder",
        gemini: "Zwillinge",
        capricorn: "Steinbock",
        leo: "Löwe",
    
        wait_core_art_title: "Nicht so eilig!",
        wait_core_art_message: "Sammle zuerst die Schlüssel der beiden Kunstwerke dieser Sternenkonstellation, um dieses Kuntwerk zu öffnen.",
        
        title_Al_message: "Botschaft von Al",
        title_constellation_book: "Sternzeichen",
        title_color_wheel: "Farbkreis",
        competences: "Kompetenzen",
        book_of_knowledge: "Buch der Erkenntnis",
        title_discover: "Mehr zum Städel",
        title_creators: "Impressum",
        title_my_page: "Datenschutz",
        locked_level: "Dieses Level ist<br/>noch gesperrt.<br/>Um es freizuschalten,<br/>spiele das aktuelle Level zuende.",
        core_art_story: "core art story",
        rotate_wheel_message: "Drehe das Rad, um andere Sternenzeichen auszuwählen.",
        lock_art_progress: "Spielfortschritt",
        message: "Botschaft",
        locked_constellation: "Dieses Sternzeichen ist<br/>noch gesperrt.<br/>Entschlüssele zuerst, alle Kunstwerke des aktuellen Levels.",
        
        form_art_title: "Das Kunstwerk findest du hier:",
        show_me_code: "Klick für Inventarnummer",
        art_id: "Inventarnummer",
        continue: "Weiter",
        story_list_title_top: "Wähle eine Story",
    
        time_out: "Zeit abgelaufen",
        answer_popup: "Ups! Leider falsch.",
        fail_popup: "Das war wohl nichts. Willst du es nochmal versuchen?",
        answer_popup_button_one: "Zurück zu den Stories",
        answer_popup_button_two: "Erneut versuchen",
    
        select_art_to_zoom: "Wähle ein Bild für den Zoom.",
        zoom_double_tap: "Für den Zoom doppelt tippen oder pinchen",
        drag_right_words: "Ziehe die passenden Begriffe hierhin.",
        total_votes: "Gesamte Stimmen",
        learn_more: "Mehr erfahren",
    
        story_cleared: "Geschafft!",
        popup_get_title: "Spielfortschritt",
        new_key: "Neuer Schlüssel",
        popup_core_unlock: "Schlüsselwerk freigespielt",
    
        core_art_cleared: "Sternzeichen abgeschlossen",
        get_page: "Du hast eine Buchseite erhalten.",
        send_text: "Jetzt kannst du eine Botschaft in die Zukunft senden.",
        get_a_page_btn: "Buchseite aufrufen",
        transmit_data: "Daten jetzt übertragen",
    
        send_message_Al: "Eine Nachricht an Al senden",
    
        intro_title: "Willkommen",
        login_desc: "Tauche ein ins Städel Universum!",
        email: "E-Mail-Adresse",
        password: "Passwort",
        try_again: "Versuche es später noch einmal",
        forgot_password: "Passwort vergessen?",
        login: "Anmelden",
        imprint: "Impressum",
    
        create_desc: "Wenn Sie ein Konto erstellt haben, können Sie das Game jederzeit weiter spielen",
        valid_email: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
        already_use_email: "Diese E-Mail-Adresse wird bereits verwendet.",
        plz_verify_email: "Bitte bestätigen Sie Ihre E-Mail-Adresse.",
        we_send_email: "Wir haben eine E-Mail an {email_address} gesendet.",
        create_just_click: "Öffnen Sie Ihr E-Mail Postfach und klicken Sie auf den Bestätigungslink, um Ihre Anmeldung abzuschließen.",
        resend_email: "Du hast keine E-Mail von uns erhalten?",
        resend_email_link: "Erneut senden",
        join_email_subject: "STÄDEL UNIVERSE: Bestätige deine E-Mail-Adresse",
        create_email_intro: "Hallo. \nDanke für deine Anmeldung! \nBevor du loslegen kannst, musst du deine E-Mail bestätigen.",
        verify_email_btn: "Bestätige deine E-Mail",
        create_email_outro: "Wenn Sie Probleme beim Klicken auf den Button \"E-Mail bestätigen\" haben, kopieren Sie die URL und fügen Sie sie in Ihren Webbrowser ein.",
        verify_complete: "Verifizierung abgeschlossen!",
        create_input_password: "Fast geschafft. Erstelle bitte dein Passwort.",
        confirm_password: "Passwort bestätigen",
        not_matched: "Passwort stimmt nicht überein.",
        matched: "Passwort stimmt überein. ",
        congratulations: "Geschafft!",
        create_account: "Ihr Konto wurde erfolgreich erstellt. Bitte melden Sie sich an, um in das Städel Universum einzutauchen.",
        
        forgot_title: "Hast du dein Passwort vergessen?",
        forgot_desc: "Bitte geben Sie Ihre E-Mail-Adresse ein. Sie erhalten einen Link zur Zurücksetzung Ihres Passworts per E-Mail.",
        forgot_just_click: "Klicken Sie einfach auf den Link in dieser E-Mail, um Ihr Passwort zurückzusetzen.",
        forgot_email_subject: "STÄDEL UNIVERSE: Setze dein Passwort zurück",
        forgot_email_intro: "Hallo.\nMöchten Sie Ihr Passwort jetzt zurücksetzen?\nWenn Sie dies nicht waren, ignorieren Sie bitte diese E-Mail.",
        reset_password: "Passwort zurücksetzen",
        forgot_email_outro: "Wenn Sie Probleme beim Klicken auf den Button 'Passwort zurücksetzen' haben, kopieren Sie die URL unten und fügen Sie sie in Ihren Webbrowser ein.",
        reset_password_title: "Setze dein Passwort zurück",
        plz_enter_password: "Bitte gib dein neues Passwort ein.",
        new_password: "Neues Passwort",
        reset_complete_title: "Zurücksetzen des Passworts abgeschlossen",
        reset_complete_desc: "Ihr Passwort wurde zurückgesetzt. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.",
        
        discover_headline: "Entdecken Sie noch mehr Städel!",
        discover_ticket_shop_btn: "ZUM TICKET-SHOP",
        discover_ticket_shop_desc: "Große Sonderausstellungen und 700 Jahre Kunst unter einem Dach",
        discover_visit_plan_btn: "JETZT BESUCH PLANEN",
        discover_visit_plan_desc: "Direkt am Mainufer gelegen – mit Skulpturengarten, Shop, Gastronomie und der neuen Dachterrasse „Städel Dach“",
        discover_now_btn: "JETZT ENTDECKEN",
        discover_now_desc: "Von Führungen und Workshops bis zu Events – genießen Sie unser vielfältiges Programm!",
        discover_digital_offer_btn: "ZU DEN DIGITALEN ANGEBOTEN",
        discover_digital_offer_desc: "Noch tiefer eintauchen mit Digitorial, Online-Kurs, Digitaler Sammlung und mehr",

        outro_name_libra: "Waage",
        outro_name_scorpius: "Skorpion",
        outro_name_cancer: "Krebs",
        outro_name_aquarius: "Wassermann",
        outro_name_pisces: "Fische",
        outro_name_taurus: "Stier",
        outro_name_virgo: "Jungfrau",
        outro_name_sagittarius: "Schütze",
        outro_name_aries: "Widder",
        outro_name_gemini: "Zwillinge",
        outro_name_capricorn: "Steinbock",
        outro_name_leo: "Löwe",
        
        outro_text_libra: "Alles in Ordnung?", 
        outro_text_scorpius: "Wann habe ich genug?",
        outro_text_cancer: "Arbeite hart und du wirst reich belohnt",
        outro_text_aquarius: "Welche Chancen stecken<br/> in einer Katastrophe?",
        outro_text_pisces: "Bringt mich Neugier weiter?",
        outro_text_taurus: "Wohin vergeht die Zeit?",
        outro_text_virgo: "Ist Mitgefühl möglich?",
        outro_text_sagittarius: "Erkenne ich die Liebe?",
        outro_text_aries: "Steckt das Böse in jedem von uns?",
        outro_text_gemini: "Woher weiß ich, wer ich bin?",
        outro_text_capricorn: "Wieviel Heimat brauche ich?",
        outro_text_leo: "Darf etwas nicht wahr sein?",
    },
};

export default de;
