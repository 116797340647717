<template>
    <div class="activity-wrapper">
        <section class="activity-image-container top-section">
            <img v-if="img" :src="imageActivity" :alt="title" />
        </section>
        <section
            v-show="showTotalVotes"
            class="slide-result-container result-section"
        >
            <h3>Total Votes: {{ totalVotes }}</h3>
            <div class="activity-buttons" v-if="options.length > 0">
                <div
                    ref="btns"
                    v-for="art in options"
                    :key="art.id"
                    class="activity-btn btn"
                >
                    <ActivityButton
                        :art="art"
                        :showTotalVotes="showTotalVotes"
                        :totalVotes="totalVotes"
                    />
                </div>
            </div>
        </section>
        <section v-show="!showTotalVotes" class="slide-container">
            <h2 v-if="options.length > 0">
                {{ options[answerRange].option }}
            </h2>
            <input type="range" v-model="sliderValue" />
            <button
                v-if="options.length > 0"
                class="btn"
                @click="handleClick(options[answerRange])"
            >
                Submit
            </button>
        </section>
    </div>
</template>

<script>
import ActivityButton from "../activity/ActivityButton";

export default {
    components: {
        ActivityButton,
    },
    props: {
        story_name: {
            type: String,
        },
        title: {
            type: String,
        },
        img: {
            type: String,
        },

        options: {
            type: Array,
        },
    },
    mounted() {},
    data() {
        return {
            showTotalVotes: false,
            answerRange: 0,
            sliderValue: 0,
            totalVotes: 0,
        };
    },
    computed: {
        imageActivity() {
            return this.img.startsWith("http")
                ? this.img
                : require(`../../assets/img/art-img/${this.img}`);
        },
    },
    watch: {
        sliderValue(value) {
            this.options.forEach((element, idx) => {
                let percentage = (idx * 100) / this.options.length;
                if (value > percentage) {
                    return (this.answerRange = idx);
                }
            });
        },
    },
    methods: {
        handleClick(item) {
            // add styles to selectedButton
            this.$refs.btns[this.answerRange]
                .querySelector(".options-quiz-result")
                .classList.add("is-selected");

            this.totalVotes = this.options.reduce((acc, cur) => {
                acc += cur.votes;
                return acc;
            }, 0);

            ++item.votes;
            ++this.totalVotes;
            this.$emit("updateVotes", {
                id: item.id,
                totalVotes: this.totalVotes,
            });
            this.showTotalVotes = true;
        },
    },
};
</script>

<style scoped>
.slide-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem;
}

.slide-result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.activity-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 18px;
}

.activity-btn {
    position: relative;
    min-height: 45px;
    text-align: start;
    justify-content: space-between;
    height: fit-content;
    border: 0.5px solid var(--primary-white);
    border-radius: 100px;
    overflow: hidden;
    background: transparent;
    color: var(--primary-white);
    font-size: 18px;
}
.options-quiz-result {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    width: 100%;
    background: var(--primary-white);
    color: var(--primary-gray);
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

.vote-count {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}
.showTotalVotes {
    visibility: visible;
}
</style>
