<template>
    <div class="modal-wrapper">
        <div class="modal-wrapper-content">
            <div @click="$emit('closeBtn')" class="close-icon">X</div>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    emits: ["closeBtn"],
};
</script>

<style></style>
