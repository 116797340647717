<template>
    <div
        :style="startPosition"
        class="dot dot-constellation"
        :id="`dot-${idx}`"
        ref="dotIcon"
        :class="{ isActive: item.isFound }"
    >
        <div class="big-dot" :id="`big-dot-${idx}`">
            <div
                v-for="h in item.activity_games"
                :key="h.id"
                :id="storyName(h.story_name)"
            >
                <div
                    :id="`histories-${idx}`"
                    class="histories"
                    :class="{
                        isActive: h.is_completed,
                    }"
                ></div>
            </div>
        </div>
        <FoundIndicator
            class="indicator"
            :isCoreArt="item.core_art"
            :isFound="item.isFound"
            :counter="counter"
        />
        <div class="art-title">
            <p>{{ item.title }}</p>
        </div>
    </div>
</template>

<script>
import FoundIndicator from "../../components/selectImage/FoundIndicator.vue";
export default {
    components: {
        FoundIndicator,
    },
    props: {
        item: {
            type: Object,
        },
        idx: {
            type: Number,
        },
        counter: {
            type: Number,
        },
    },
    computed: {
        startPosition() {
            return `top: ${(this.idx + 1) / 1}00px; right:${
                this.idx + 1 * 3
            }0%`;
        },
        storyName() {
            return (story_name) =>
                story_name.replace(/\s/g, "").replaceAll("?", "");
        },
    },
    data() {
        return {
            ConstellationID: this.$route.params.id,
            starIcon: "",
        };
    },
};
</script>

<style scoped>
.star-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
    padding: 0.5rem;
    border-radius: 50%;
}

.histories {
    width: 10px;
    height: 10px;
    background: white;
    opacity: 0.5;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.dot {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    z-index: 20;
    opacity: 0.9;

    filter: brightness(1.2) drop-shadow(0 0 10px rgb(83, 127, 128));
}
.isActive {
    background: var(--primary-white);
    filter: brightness(1.2) drop-shadow(0 0 10px var(--primary-white));
    opacity: 1;
}

@keyframes twinkle {
    50% {
        transform: scale(0.8);
        opacity: 0.5;
    }
}

.isDotActive {
    filter: brightness(1.2) drop-shadow(0 0 10px #fff);
    background: rgba(255, 255, 255, 0.5);
    zoom: 2;
    animation: none;
    animation: rotate 25s infinite;
    z-index: 21;
    opacity: 1;
}
.isDotActive .star-img {
    transform: scale(2);
}
@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}
.big-dot {
    animation: twinkle 2s infinite;
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 2px solid transparent;
}
.indicator {
    margin-top: 2rem;
}

.art-title {
    position: relative;
    margin-bottom: 7rem;

    width: fit-content;
    display: flex;
}
.art-title p {
    color: white;
    top: 0;
    width: 200px;
}
</style>
