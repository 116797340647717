<template>
    <main>
        <div class="under-line"></div>
        <div class="knowledge-container">
            <div class="title-section">
                <div @click="handlePageNation('left')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                        <path d="M9 1L1 8.5L9 16" stroke="white"/>
                    </svg>
                </div>
                <h1>{{ $t(`message.${constellations[selected].name}`) }}</h1>
                <div @click="handlePageNation('right')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                        <path d="M1 1L9 8.5L1 16" stroke="white"/>
                    </svg>
                </div>
            </div>
            <div v-if="imageRander" class="image-section">
                <img v-if="constellations[selected].is_completed" :src="artsByConstellation[0].digital_collection" alt="constellation">
                <div v-else class="padlock-image">
                    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="108" viewBox="0 0 82 108" fill="none">
                        <path d="M81.9944 41.0011H67.675V25.2105C67.675 11.3147 56.4741 0 42.7168 0H39.262C25.5052 0 14.3038 11.3141 14.3038 25.2105V41.0011H0V108H82V41.0011H81.9944ZM18.8616 25.2105C18.8616 13.8412 28.0223 4.58813 39.2696 4.58813H42.7244C53.9799 4.58813 63.1324 13.8335 63.1324 25.2105V41.0011H18.8608L18.8616 25.2105ZM77.4602 103.421H4.53461V45.5802H77.4522L77.4602 103.421Z" fill="white"/>
                    </svg>
                </div>
            </div>
            <div class="text-section">
                <h1 v-if="!constellations[selected].can_play" v-html="$t('message.locked_level')"></h1>
                <h1 v-else-if="constellations[selected].can_play && !constellations[selected].is_completed" v-html="$t('message.locked_constellation')" />

                <div v-else class="knowledge-box">
                    <p class="head-line" v-html="knowledgeTitle" /><br/>
                    <p v-html="knowledgeDesc"></p>
                </div>

                <!-- <p v-else v-html="constellations[selected].book_of_knowledge"></p> -->
            </div>
        </div>
    </main>
</template>

<script>
import { mapState } from "vuex";
import gameApi from "../../api/gameApi";
export default {
    data() {
        return {
            constellations: [
                { 
                    about_star_signs: '', 
                    book_of_knowledge: '', 
                    can_play: false, 
                    group: '',
                    id: 0,
                    is_completed: false,
                    level: '',
                    name: '',
                    order: 0,
                }
            ],
            selected: 0,

            knowledgeTitle: '',
            knowledgeDesc: '',
        };
    },
    mounted() {
        this.getAllBooks();
    },
    computed: {
        ...mapState({
            artsByConstellation: (state) => state.gameState.artsByConstellation,
        }),
        imageRander() {
            return this.constellations[this.selected].name != "";
        },
    },
    methods: {

        async getAllBooks() {
            try {
                const res = await gameApi.post("game/all-books-of-knowledge");
                this.constellations = res.data;

                const selectedConstellation = localStorage.getItem( "selectConstellationName" );
                const index = this.constellations.findIndex(
                    (starSign) => starSign.name === selectedConstellation
                );
                this.selected = index;

                this.parseBookOfKnowledge( this.constellations[this.selected].book_of_knowledge );

                // console.log(this.constellations[0]);
            } catch (error) {
                console.log(error);
            }
        },

        handlePageNation( moveTo ) {
            if (moveTo === 'left') {
                if (this.selected == 0) {
                    this.selected = this.constellations.length - 1;
                } else {
                    this.selected--;
                }
            }
            else if (moveTo === 'right') {
                if (this.selected == this.constellations.length - 1) {
                    this.selected = 0;
                } else {
                    this.selected++;
                }
            }

            this.parseBookOfKnowledge( this.constellations[this.selected].book_of_knowledge );

            localStorage.setItem( "selectConstellationName", this.constellations[this.selected].name );
            this.$store.dispatch("FETCH_ARTS_BY_GROUP_AND_CONSTELLATION");
            console.log('constellation', this.artsByConstellation[0])
        },

        parseBookOfKnowledge( bookOfKnowledge ) {
            let temp = bookOfKnowledge.split('[head]');
            this.knowledgeTitle = temp[0];
            this.knowledgeDesc = temp[1];
        },
    },
};
</script>

<style scoped>
main {
    width: 100%;
    min-height: calc(100vh - 78px);
}
.knowledge-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
/* title section */
.title-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}
.title-section h1 {
    text-align: center;
    font-size: 4rem;
    font-style: normal;
    line-height: 100%; /* 40px */
    text-transform: capitalize;
}
/* image section */
.image-section {
    width: 100%;
}
.image-section img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.padlock-image {
    width: 100%;
    height: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* text section */
.text-section {
    width: 100%;
    padding: 2rem;
}
.text-section p {
    font-size: 2rem;
    font-style: normal;
    line-height: 150%;
    word-break: keep-all;
}
.text-section h1 {
    font-size: 4rem;
    text-transform: none;
}
.head-line {
    font-family: var(--primary-font-semibold);
    font-size: 2.2rem;
}

</style>
