<template>
    <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="14.5"
            cy="14.5"
            r="14.125"
            :stroke="isActive"
            stroke-width="0.75"
        />
        <circle cx="14.5" cy="14.5" r="7.5" :fill="isActive" />
    </svg>
</template>

<script>
export default {
    props: {
        isIconActive: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isActive() {
            return this.isIconActive
                ? "var(--primary-gray)"
                : "var(--primary-white)";
        },
    },
};
</script>

<style scoped></style>
