<template>
    <div class="constellations-wrapper">
        <div
            v-for="constellation in constellations"
            :key="constellation.order"
            :class="[constellation.group, constellation.name]"
            :id="constellation.is_completed ? 'completed' : ''"
            class="constellation"
            @click="$emit('handleBookClick', constellation)"
        >
            {{ $t(`message.${constellation.name}`) }}
        </div>

        <router-link class="book" :to="{ name: 'BooksArchive' }"
            ><h2>
                {{ $t("message.book_of_knowledge") }}
            </h2>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        constellations: {
            type: Array,
        },
    },
    emits: ["handleBookClick"],
};
</script>

<style scoped>
.constellation {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    opacity: 0.5;
}
#completed {
    opacity: 1;
}
.constellations-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100vh;
}
.fire {
    background: red;
}
.water {
    background: blue;
}
.earth {
    background: green;
}
.air {
    background: lightblue;
}

.aries {
    grid-area: 1 / 1 / 2 / 2;
}
.leo {
    grid-area: 1 / 2 / 2 / 3;
}
.sagittarius {
    grid-area: 2 / 1 / 3 / 2;
}
.gemini {
    grid-area: 1 / 3 / 2 / 4;
}
.libra {
    grid-area: 1 / 4 / 2 / 5;
}
.aquarius {
    grid-area: 2 / 4 / 3 / 5;
}
.taurus {
    grid-area: 3 / 1 / 4 / 2;
}
.virgo {
    grid-area: 4 / 1 / 5 / 2;
}
.capricorn {
    grid-area: 4 / 2 / 5 / 3;
}
.cancer {
    grid-area: 3 / 4 / 4 / 5;
}
.scorpius {
    grid-area: 4 / 4 / 5 / 5;
}
.pisces {
    grid-area: 4 / 3 / 5 / 4;
}
.book {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: 2 / 2 / 4 / 4;
    font-size: 1.5rem;
    padding: 1rem;
}
</style>
