<template>
    <div class="modal-overlay-wrapper">
        <!-- <div class="modal-bg" v-if="showAboutInfo"> -->
        <div class="modal-bg">
            <div class="close-btn" @click="handleCloseClick">
                <CloseIcon />
            </div>
            <div class="top-section">
                <h2>{{ $t("message.about_star_sign") }}</h2>
                <div class="division"></div>
                <p>{{ $t(`message.${constellation.constellation}`) }}</p>
            </div>

            <section class="content-wrapper" v-if="constellation">
                <div class="constellation-img">
                    <img
                        v-if="true"
                        :src="
                            require(`../../assets/img/constellation-image/${constellation.constellation}.png`)
                        "
                        alt=""
                    />
                </div>
                <div class="text-wrapper">
                    <p class="constellation-info">
                        <span v-html="constellation.about_star_sign"></span>
                    </p>
                </div>
            </section>
        </div>
        <!-- <div
            class="modal-indication"
            @click="$emit('handleAboutStarSignClick')"
        >
            <div class="circle-dashed">
                <p class="constellation-name">
                    {{
                        $t(
                            `message.${constellation.constellation.toLowerCase()}`
                        )
                    }}
                </p>
            </div>

            <p class="info-text">
                {{ $t("message.constellation_popup") }}
            </p>

            <div class="close-btn" @click="$emit('handleAboutStarSignClick')">
                <CloseIcon />
            </div>
        </div> -->
    </div>
</template>

<script>
import CloseIcon from "../icons/CloseIcon.vue";

export default {
    props: {
        constellation: {
            type: Object,
        },
    },
    components: {
        CloseIcon,
    },
    data() {
        return {
            showAboutInfo: true,
        };
    },
    mounted() {},
    methods: {
        handleCloseClick() {
            const hasSeemMessage = localStorage.getItem("constellationMessage");
            if (hasSeemMessage) {
                this.$emit("handleAboutStarSignClick");
            } else {
                this.showAboutInfo = false;
                localStorage.setItem("constellationMessage", "hasSeem");
            }
        },
    },
};
</script>

<style scoped>
.modal-overlay-wrapper {
    padding: 25px 20px;
    color: var(--primary-white);
}

.modal-bg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    min-height: 100%;
    align-items: center;
    background: var(--primary-gray);
    border: 1px solid var(--primary-white);
    box-shadow: 0px 30px 15px rgba(0, 0, 0, 0.5);
    padding: 15px;
    max-width: 600px;
    margin: 0 auto;
    /* overflow: scroll; */
}

.modal-indication {
    width: 100%;
    min-height: 100%;
    position: relative;
    padding: 15px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding-top: 42px;
    gap: 0.8125rem;
}

.circle-dashed {
    width: 208px;
    height: 100px;
    border-radius: 50%;
    border: 1px dashed var(--primary-white);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.constellation-name {
    font-size: 2.5rem;
    font-family: var(--primary-font-medium);
    line-height: 100%;
    text-transform: capitalize;
}

.info-text {
    font-weight: 350;
    font-size: 20px;
    line-height: 120%;
    /* or 24px */

    text-align: center;

    color: var(--primary-white);
}

.close-btn {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
}

.top-section {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}

.top-section h2 {
    font-size: 2.1875rem;
    font-weight: 500;
    line-height: 100%;
    font-family: var(--primary-font-medium);

    margin-bottom: 1.5625rem;
    margin-top: 1rem;
}
.division {
    height: 1px;
    width: 100%;
    background: var(--primary-white);
    margin-bottom: 1rem;
}
.top-section p {
    font-weight: 700;
    font-size: 1.5625rem;
    line-height: 2rem;
    text-transform: capitalize;
    color: var(--primary-white);
    font-family: var(--primary-font-semibold);
}
.indicator-bar {
    margin-top: 1rem;
}

.content-wrapper {
    height: 100%;
    display: grid;
    grid-template-rows: 294px 150px;
    gap: 15px;
    overflow-y: auto;
}

.text-wrapper {
    /* overflow-y: auto; */
}

.constellation-title {
    font-size: 1.5625rem;
    line-height: 2rem;
    margin-bottom: 1.25rem;
}
.constellation-info {
    font-style: normal;
    font-weight: 350;
    font-size: 1.25rem;
    line-height: 1.625rem;
    padding-bottom: 2rem;
}
.constellation-img {
    width: 100%;
    height: 100%;
    /* background: #010101; */
    border-radius: 10px;
}
.constellation-img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 310px;
    max-height: 289px;
    margin: 0 auto;
    object-fit: contain;
}
</style>
