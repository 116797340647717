<template>
    <div class="main-wrapper">
        <div class="modal-content">
            <img class="img" :src="currentArt.digital_collection" alt="" />
            <h4>{{ currentArt.title }}</h4>
            <router-link
                v-if="!allStoriesCompleted"
                class="btn"
                :to="{
                    name: 'StoryList',
                    params: {
                        zodiacName: currentArt.constellation,
                        inventoryNumber: currentArt.inventory_number,
                    },
                }"
                >{{ $t("message.play_another_story") }}</router-link
            >
            <div v-else>
                <h3>Completed!!!</h3>
            </div>
            <router-link
                v-if="!currentArt.core_art && !isCompleted"
                class="btn"
                :to="{
                    name: 'SelectImage',
                    params: {
                        zodiacName: currentArt.constellation,
                    },
                }"
                >{{ buttonText }}</router-link
            >
            <router-link
                v-if="!currentArt.coreArt && isCompleted"
                class="btn"
                :to="{
                    name: 'WheelOfColors',
                }"
                >Wheel of colors</router-link
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        buttonText: {
            type: String,
        },
        allStoriesCompleted: {
            type: Boolean,
        },
        currentArt: {
            type: Object,
        },
        isCompleted: {
            type: Boolean,
        },
    },
};
</script>

<style scoped>
.main-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.modal-content {
    background: var(--main-color-3);
    width: 90%;
    max-width: 500px;
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    border-radius: 5px;
}
.img {
    max-height: 200px;
    object-fit: contain;
}

h2 {
    color: var(--primary-white);
}
h4 {
    margin: 0.5rem auto;
}
</style>
