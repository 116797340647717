<template>
    <nav
        v-if="showNav"
        :class="$route.name == 'ConstellationProgress' && 'canNotClick'"
    >
        <div class="nav"> 
            <!-- new version -->
            <div class="nav-item">
                <Logo :isIconActive="$route.name == 'Home'"/>
            </div>
            <div class="nav-title font-light">
                <p v-if="$route.name === 'MessageFromAl'">{{ $t('message.title_Al_message') }}</p>
                <p v-else-if="$route.name === 'ConstellationBook'">{{ $t('message.title_constellation_book') }}</p>
                <p v-else-if="$route.name === 'Competences'">{{ $t('message.competences') }}</p>
                <p v-else-if="$route.name === 'WheelOfColors'">{{ $t('message.title_color_wheel') }}</p>
                <p v-else-if="$route.name === 'BooksArchive'">{{ $t('message.book_of_knowledge') }}</p>
                <p v-else-if="$route.name === 'Discover'">{{ $t("message.title_discover") }}</p>
                <p v-else-if="$route.name === 'Creators'">{{ $t("message.title_creators") }}</p>
                <p v-else-if="$route.name === 'MyPage'">{{ $t('message.title_my_page') }}</p>
                
            </div>
            <div class="nav-wrap">
                <router-link class="nav-item" :to="{ name: 'SelectConstellation' }">
                    <ConstellationsIcon :newStar="true" />
                </router-link>
                <div class="nav-item" @click="goNext">
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none">
                        <path d="M0.5 1H29" stroke="white" stroke-width="1.5"/>
                        <path d="M0.5 12H29" stroke="white" stroke-width="1.5"/>
                        <path d="M0.5 23H29" stroke="white" stroke-width="1.5"/>
                    </svg>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import Logo from "./Logo.vue";
import ConstellationsIcon from "../icons/ConstellationsIcon.vue";
export default {
    components: {
        Logo,
        ConstellationsIcon,
    },
    data() {
        return {
            isNavOpen: false,
            showNav: true,
        };
    },
    mounted() {
        if (this.$route.name == "Home" || 
            this.$route.name == "Activities" || 
            this.$route.name == "StoryList" ||
            this.$route.name == "Level"
        ) {
            this.showNav = false;
        }
    },
    watch: {
        $route(route) {
            if (
                route.name === "Home" ||
                route.name === "ReadMessage" ||
                route.name === "Activities" ||
                route.name === "ActivityCompleted" ||
                route.name === "Level" ||
                route.name === "StoryList"
            ) {
                this.showNav = false;
                return;
            } else {
                this.showNav = true;
                return;
            }
        },
    },
    computed: {
        isSpecialColor() {
            return this.$route.name === "Reward" ||
                this.$route.name === "BooksArchive"
                ? true
                : false;
        },
    },
    methods: {
        goNext() {
            if (this.$route.name === "BurgerMenu") {
                this.$router.go(-1);
            } else {
                this.$router.push({ name: "BurgerMenu" });
            }
        },
    },
};
</script>

<style scoped>
nav {
    position: relative;
    background: var(--primary-black);
}
.nav {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding: 1.2rem 0;
    z-index: 201;
}
.nav-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1.2rem;
    gap: 1.2rem;
}
.nav-item {
    width: 100%;
    max-width: 65px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--transition-1);
}
.nav-title {
    width: 100%;
    display: flex;
    align-items: center;
}
.nav-title p {
    font-size: 2.5rem;
    color: white;
}
/* finger */
.finger-item {
    position: relative;
}
.canNotClick {
    pointer-events: none;
    background: var(--primary-black);
}
.canNotClick > * {
    pointer-events: none;
}
.specialColor {
    background: var(--main-color-3);
}
</style>