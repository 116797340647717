<template>
    <div>
        <h2 style="margin-bottom: 2rem">Games</h2>
        <div
            class="my-games-container"
            @click="getGame(game.id)"
            v-for="(game, idx) in games"
            :key="idx"
        >
            <h3 :class="{ active: game_id === game.game_id }">
                {{ formatTime(game.created_at) }}
            </h3>
        </div>
        <div v-if="!connected" class="logout-area">
            <button @click="connectAccount" class="btn">Sync your games</button>
            <p>
                Future games are not going to be save in your profile unless you
                are log-in
            </p>
        </div>
        <div v-if="!noPlayerFound" class="logout-area">
            <router-link to="/" class="btn">Start a game</router-link>
            <p>You have not started any game yet.</p>
        </div>
        <div class="logout-area">
            <button @click="handleLogOut" class="btn">Logout</button>
        </div>
        <div class="logout-area">
            <button @click="handleDisconnect" class="btn">
                Disconnect Profile
            </button>
            <p>
                Future games are not going to be save in your profile unless you
                are logged-in
            </p>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import gameApi from "../../api/gameApi";
import axios from "axios";

export default {
    mounted() {
        this.getMyProfile();
    },
    data() {
        return {
            games: [],
            connected: true,
            noPlayerFound: true,
        };
    },
    computed: {
        formatTime() {
            return (time) => {
                const date = new Date(time);
                let options = {
                    weekday: "long",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                };

                return date.toLocaleTimeString("en-us", options);
            };
        },
        ...mapState({
            game_id: (state) => state.gameState.game_id,
        }),
    },
    methods: {
        async handleLogOut() {
            // this.$store.commit("LOG_OUT");
            this.$store.dispatch("LOG_OUT", {
                game_id: localStorage.getItem("game__id"),
                player_id: localStorage.getItem("player__id"),
            });
        },
        async handleDisconnect() {
            this.$store.commit("DISCONNECT_ACCOUNT");
        },
        async getMyProfile() {
            const res = await gameApi.post("/user/my-profile/", {
                token: localStorage.getItem("token"),
            });

            if (res.data.msg === "not connected") {
                this.connected = false;
                return;
            } else if (res.data.msg === "not game player found") {
                this.noPlayerFound = false;
                return;
            }

            this.games = res.data.player_games.reverse();
            this.connected = true;
            if (
                this.games.length > 0 &&
                (!this.games.find((game) => game.id == this.game_id) ||
                    !this.game_id)
            ) {
                let game_id = this.games[0].id;
                this.getGame(game_id);
            }
        },
        async getGame(id) {
            const res = await axios.get(`/user/game-by-id/${id}`);

            const { player_id, game_id, lang } = res.data;

            localStorage.setItem("game__id", game_id);
            localStorage.setItem("player__id", player_id);

            document.querySelector("html").lang = lang;
            localStorage.setItem("locale", lang);

            this.$i18n.locale = lang;

            this.$store.commit("GET_LOCAL_STORE");
        },
        async connectAccount() {
            const res = await gameApi.post("/user/connect-account/", {
                token: localStorage.getItem("token"),
            });
            if (res.status === 200) {
                this.getMyProfile();
            }
        },
    },
};
</script>

<style scoped>
.my-games-container {
    text-align: center;
    margin-bottom: 1rem;
}
.active {
    background: var(--main-color-3);
    cursor: pointer;
    max-width: 320px;
    margin: 0 auto;
}
.logout-area {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-direction: column;
    margin-top: 2rem;
}

.logout-area p {
    max-width: 300px;
    text-align: center;
}
</style>
