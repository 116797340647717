<template>
    <div
        class="read-message-container main-container"
        :style="{
            'background-image': 'url(' + backgroundImage + ')',
        }"
    >
        <div class="content">
            <section class="intro-content" v-if="currentMessage">
                <div class="text-wrapper">
                    <MessageIndicator
                        @handleIndicatorClick="handleIndicatorClick"
                        :dataLength="messageData.length"
                        :currentIndex="messageIndex"
                    />

                    <p class="message-text" :id="`text${currentMessage.id}`">
                        <span v-html="currentMessage.dsc"></span>
                    </p>
                </div>
            </section>
            <section class="intro-navigation">
                <div @click="handleBtnClick('prev')">
                    <div
                        v-show="messageIndex > 0 && !canStartGame"
                        class="tutorial-btn"
                    >
                        <ArrowIconOutline :isLeft="true" />
                    </div>
                </div>
                <button
                    v-if="canStartGame"
                    @click="handleBtnClick('next')"
                    class="btn can-start-game"
                >
                    {{ $t("message.start") }}!
                </button>
                <div @click="handleBtnClick('next')">
                    <div
                        class="tutorial-btn"
                        v-show="messageIndex !== messageData.length - 1"
                    >
                        <ArrowIconOutline />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import ArrowIconOutline from "../../components/icons/ArrowIconOutline";
import MessageIndicator from "../../components/ui/MessageIndicator";

import { gsap } from "gsap";
import { windowHeight } from "../../helperFunctions/utils";
import gameApi from "../../api/gameApi";

import {
    tutorialEnglish,
    tutorialGerman,
} from "../../utils/tutorial/intro-tutorial";

gsap.defaults({ ease: "none" });

export default {
    components: {
        MessageIndicator,
        ArrowIconOutline,
    },
    data() {
        return {
            messageIndex: 0,
            currentMessage: null,
            currentAnimation: null,
            messageData: [],
            artsByConstellation: [],
        };
    },

    mounted() {
        if (this.$i18n.locale === "en") {
            this.messageData = tutorialEnglish;
        } else {
            this.messageData = tutorialGerman;
        }

        windowHeight();
        gsap.set(".content", { opacity: 0 });
        this.currentMessage = this.messageData[this.messageIndex];
        this.getUserProgress();

        let tl = gsap.timeline({});

        tl.to(".content", {
            opacity: 1,
            duration: 1,
        });
    },
    computed: {
        canStartGame() {
            return this.messageIndex === this.messageData.length - 1;
        },
        backgroundImage() {
            let bg = "";
            if (this.currentMessage) {
                bg = require(`../../assets/img/tutorials/opening/opening-${this.currentMessage.id}.png`);
            }

            return bg;
        },
    },
    methods: {
        handleIndicatorClick(idx) {
            this.messageIndex = idx;
            this.currentMessage = this.messageData[this.messageIndex];
        },
        handleBtnClick(type) {
            if (type === "next") {
                ++this.messageIndex;
            } else {
                --this.messageIndex;
            }
            this.currentMessage = this.messageData[this.messageIndex];

            if (this.messageIndex === this.messageData.length) {
                this.$router.push({ name: "Level", params: { level: 1 } });
            }
        },

        async getUserProgress() {
            try {
                const { data } = await gameApi.post(
                    "game/constellations-progress"
                );
                this.artsByConstellation = data.art_by_constellations;

                let constellationName = localStorage.getItem(
                    "selectConstellationName"
                );

                let selectedConstellation = this.artsByConstellation.find(
                    (item) => item.name === constellationName.toLowerCase()
                );

                this.$store.dispatch(
                    "SET_ACTIVE_CONSTELLATION",
                    selectedConstellation
                );
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.main-container {
    height: calc(var(--vh, 1vh) * 100);
}
.content {
    opacity: 0;
    justify-content: space-between;
    height: 100%;
    padding: 1rem;
}
.intro-content {
    /* height: 100%; */
    margin-bottom: 1rem;
    border: 1px solid var(--primary-white);
    background: var(--primary-gray);
}
.read-message-container {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.text-wrapper {
    padding: 1rem;
}
.message-text {
    font-family: var(--primary-font-light);
    font-style: normal;
    font-weight: 350;
    font-size: 1.56rem;
    margin-bottom: 3rem;
    line-height: 140%;
}

.image-wrapper {
    width: 100%;
    margin: 0 auto;
    height: 200px;
    border: 2px dashed var(--primary-white);
}

.wheel-container-wrapper {
    display: block;
    width: 50%;
    margin: 0 auto;
}
.wheel-container-wrapper .img {
    max-width: unset;
    max-height: unset;
}

.intro-navigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tutorial-btn {
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--primary-white);
}
.btn {
    width: 180px;
    height: 45px;

    background: var(--primary-gray);
    border: 1px solid var(--primary-white);
    color: var(--primary-white);
    border-radius: 1.6rem;
    font-style: normal;
    font-size: 1.25rem;
}
.can-start-game {
    text-transform: uppercase;
    background: var(--primary-white);
    border: 1px solid var(--primary-gray);
    color: var(--primary-gray);

    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
}
</style>
