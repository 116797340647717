<template>
	<div class="main-container">
		<!-- text boxies -->
		<div class="title-wrap">
			<!-- title -->
			<h2>PER KIRKEBY</h2>
			<!-- artist -->
			<h3>Tor II, 1987 (Guss 1991)</h3>
			<!-- description -->
			<span v-if="isEng">A stone fragment from outer space? Or an ancient place of worship? The sculpture is a portal between space and time and holds many secrets.</span>
			<span v-else>Ein steinernes Fragment aus dem Weltall? Oder eine uralte Kultstätte? Die Skulptur ist ein Portal zwischen Raum und Zeit und birgt viele Geheimisse.</span>
		</div>
		<!-- star buttons -->
		<div class="btn-wrap">
			<router-link class="star-btn" to="/new-intro-message" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isEng: true,
		};
	},
	mounted() {
		if (this.$i18n.locale == 'en') {
			this.isEng = true;
		} else {
			this.isEng = false;
		}
	},
	methods: {},
};
</script>

<style scoped>
.main-container {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 3fr 4fr;
	gap: 5px;
	color: var(--primary-white);
	background-color: var(--primary-black);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 250;
}
/* title */
.title-wrap {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 40px 25px 6px;
	font-family: var(--primary-font-medium);
}

.title-wrap h2 {
	font-family: var(--primary-font-light);
	font-size: 4rem;
}

.title-wrap h3 {
	margin-bottom: 20px;
	font-size: 2rem;
}

.title-wrap span {
	font-size: 2.2rem;
	text-align: center;
}
.receive-message {
	width: 100%;
	max-width: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 7px;
	border: 1px solid #333;
	background-color: white;
	padding: 25px;
}
.receive-message h4 {
	text-align: left;
	font-family: var(--primary-font);
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 140%;
	word-break: keep-all;
}
.only-yes-box {
	width: 100%;
	max-width: 500px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 14px;
	border: 1px dashed #000;
	padding: 30px 35px;
	margin: 5% 0;
}
.only-yes-box h5 {
	text-align: left;
	font-size: 1.2rem;
	font-weight: 500;
	font-family: var(--primary-font);
}
.only-yes-box button {
	text-align: right;
	font-size: 1.2rem;
	font-weight: 500;
	font-family: var(--primary-font);
	color: black;
	text-decoration: underline;
	border: none;
	background: transparent;
}
/* btn */
.btn-wrap {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: transparent;
	background-image: url('../../assets/img/new-intro.png');
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	padding: 15px 12px;
}
.star-btn {
	width: 45%;
	height: 45%;
	background-color: transparent;
	background-image: url('../../assets/img/new-intro-btn.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	border: none;
	z-index: 300;
	animation: blink-effect 2s ease-in-out infinite;
}
.star-btn.star1 {
	width: 40%;
	height: 30%;
	position: absolute;
	top: 0;
	left: 10%;
}
.star-btn.star2 {
	width: 40%;
	height: 30%;
	position: absolute;
	top: 8%;
	right: 12%;
}
.star-btn.star3 {
	width: 30%;
	height: 20%;
	position: absolute;
	top: 35%;
	right: 18%;
}
.star-btn.star4 {
	width: 30%;
	height: 20%;
	position: absolute;
	top: 45%;
	right: 45%;
}
.star-btn.star5 {
	width: 30%;
	height: 20%;
	position: absolute;
	top: 58%;
	right: 20%;
}
.star-btn.star6 {
	width: 30%;
	height: 20%;
	position: absolute;
	top: 70%;
	right: 40%;
}
.star-btn.star7 {
	width: 30%;
	height: 20%;
	position: absolute;
	top: 80%;
	right: 10%;
}
/* background image */
.new-intro-image {
	width: 100%;
	max-width: 428px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}
/* animation */
@keyframes blink-effect {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
import { locale } from 'core-js';

