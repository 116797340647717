<template>
    <div class="modal-wrapper">
        <div class="modal-wrapper-content">
            <img v-if="showBook" :src="img" alt="" />
            <div @click="$emit('closeBtn')" class="close-icon">X</div>
            <h3>{{ modalContent.title }}</h3>
            <p class="modal-msg">
                {{ modalContent.text }}
            </p>

            <button @click="handleModalClick" class="btn">
                {{ modalContent.btnText }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modalContent: {
            type: Object,
        },
        isLink: {
            type: Boolean,
            default: true,
        },
        showBook: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["closeBtn"],
    data() {
        return {
            img: require("../../assets/img/tutorials/book.jpeg"),
        };
    },

    methods: {
        handleModalClick() {
            if (this.isLink) {
                this.$router.push(this.modalContent.btnAction);
            }
            this.$emit("closeBtn", { isButton: true });
        },
    },
};
</script>

<style></style>
