<template>
	<div class="btn-container">
		<button class="btn-home font-semibold" @click="$emit('startGame')">
			{{ $t('message.just_play') }}
		</button>
		<button class="btn-home font-semibold" @click="$emit('login')">
			{{ $t('message.login_play') }}
		</button>
		<button v-if="isAnonymous" class="btn-home font-semibold" @click="$emit('join')">
			{{ $t('message.sign_up') }}
		</button>
		<!-- <router-link class="btn-home" to="/activity-test"
            >Activities</router-link
        >
        <router-link class="btn-home" to="/circle"
            >DragAndMatch setup</router-link
        > -->
	</div>
</template>

<script>
export default {
	// props: {
	// 	alreadyPlaying: {
	// 		type: Boolean,
	// 	},
	// },
	props: {
		isAnonymous: {
			type: Boolean,
		},
	}
};
</script>

<style scoped>
.btn-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5rem;
	gap: 2rem;
}

.btn-home {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	border-radius: 3px;
	border: 0.5px solid var(--primary-white);
	color: var(--primary-white);
	text-align: center;
	font-size: 2rem;
	text-transform: capitalize;
	padding: 2rem 0;
}
</style>
