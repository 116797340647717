<template>
    <div class="modal-overlay-wrapper">
        <div class="modal-bg">
            <div class="close-btn" @click="$emit('handleTutorialIconClick')">
                <CloseIcon />
            </div>
            <div class="top-section">
                <h2>{{ $t("message.tutorial") }}</h2>
                <p>{{ $t(`message.${tutorialName}`) }}</p>
                <MessageIndicator
                    class="indicator-bar"
                    @handleIndicatorClick="handleIndicatorClick"
                    :dataLength="tutorialArray.length"
                    :currentIndex="messageIndex"
                />
            </div>
            <section
                ref="contentWrapper"
                class="content-wrapper"
                v-if="currentMessage"
            >
                <p class="tutorial-title">
                    <span v-html="currentMessage.title"></span>
                </p>

                <div class="tutorial-img" v-if="currentMessage.image">
                    <img
                        :src="
                            require(`../../assets/img/tutorials/modals/${currentMessage.image}.png`)
                        "
                        alt=""
                    />
                </div>

                <div class="text-wrapper">
                    <p class="tutorial-text">
                        <span v-html="currentMessage.dsc"></span>
                    </p>
                </div>
            </section>
            <section class="tutorial-arrows">
                <div @click="handleBtnClick('prev')">
                    <div v-show="messageIndex > 0" class="tutorial-btn">
                        <ArrowIconOutline :isLeft="true" />
                    </div>
                </div>
                <div @click="handleBtnClick('next')">
                    <div
                        class="tutorial-btn"
                        v-show="messageIndex !== tutorialArray.length - 1"
                    >
                        <ArrowIconOutline />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import CloseIcon from "../icons/CloseIcon.vue";
import MessageIndicator from "../ui/MessageIndicator";
import ArrowIconOutline from "../../components/icons/ArrowIconOutline";

export default {
    props: {
        tutorialArray: {
            type: Array,
            default: () => [],
        },
        tutorialName: {
            type: String,
            default: "wheel_of_colors",
        },
    },
    components: {
        MessageIndicator,
        CloseIcon,
        ArrowIconOutline,
    },
    data() {
        return {
            messageIndex: 0,
            currentMessage: null,
        };
    },
    mounted() {
        this.setCurrentMessage();
    },
    methods: {
        handleBtnClick(type) {
            if (type === "next") {
                ++this.messageIndex;
            } else {
                --this.messageIndex;
            }
            if (this.messageIndex <= this.tutorialArray.length - 1) {
                this.setCurrentMessage();
            } else {
                this.$emit("tutorialCompleted");
            }

            this.$refs.contentWrapper.scrollTop = 0;
        },

        handleIndicatorClick(idx) {
            this.messageIndex = idx;
            this.setCurrentMessage();
        },
        setCurrentMessage() {
            this.currentMessage = this.tutorialArray[this.messageIndex];
        },
    },
};
</script>

<style scoped>
.modal-overlay-wrapper {
    overflow-y: scroll;
    padding: 25px 20px;
    color: var(--primary-white);
}

.modal-bg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    min-height: 100%;
    align-items: center;
    background: var(--primary-gray);
    border: 1px solid var(--primary-white);
    box-shadow: 0px 30px 15px rgba(0, 0, 0, 0.5);
    padding: 15px;
    max-width: 600px;
    margin: 0 auto;
}

.close-btn {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
}

.top-section {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
}

.top-section h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 100%;
    font-family: var(--primary-font-medium);
    /* identical to box height, or 40px */

    text-align: center;
    text-transform: capitalize;
}
.top-section p {
    font-size: 13px;
    line-height: 1rem;
    text-transform: capitalize;
    font-family: var(--primary-font);
    margin-top: 3px;
}
.indicator-bar {
    margin-top: 1rem;
}

.content-wrapper {
    text-align: center;
    height: 100%;
    overflow-y: auto;
}

.tutorial-title {
    font-size: 1.5625rem;
    line-height: 2rem;
    margin-bottom: 1.25rem;
    font-family: var(--primary-font-semibold);
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
}

.text-wrapper {
    margin: 20px 0;
}

.constellation-title {
    font-size: 1.5625rem;
    line-height: 2rem;
    margin-bottom: 1.25rem;
}
.tutorial-text {
    font-style: normal;
    text-align: start;
    font-weight: 350;
    font-size: 1.25rem;
    line-height: 1.625rem;
}

.tutorial-img {
    width: 100%;
    height: 246px;
    background: #010101;
    border-radius: 10px;
}
.tutorial-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tutorial-arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.tutorial-btn {
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--primary-white);
}
</style>
