import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

/////////////////////// Intro ////////////////////////////////
import ReadMessage from '../views/intro/ReadMessage';
import NewIntroMessage from '../views/intro/NewIntroMessage';
import NewIntro from '../views/intro/NewIntro';

/////////////////////// AUTH ////////////////////////////////
import Auth from "../views/auth/Auth";
import Profile from "../views/auth/Profile";
import Login from "../views/auth/Login";
import JoinStep1 from "../views/auth/JoinStep1";
import JoinStep2 from "../views/auth/JoinStep2";
import PasswordResetStep1 from "../views/auth/PasswordResetStep1";
import PasswordResetStep2 from "../views/auth/PasswordResetStep2";


/////////////////////// GAME ////////////////////////////////
import Home from '../views/Home';
import Level from '../views/game/Level';
import NavBurgerMenu from '../views/game/NavBurgerMenu';
import SelectConstellation from '../views/game/SelectConstellation';
import SelectImage from '../views/game/SelectImage';
import StoryList from '../views/game/StoryList';
import Activities from '../views/game/Activities';

import BookReward from "../views/game/BookReward";
import BooksArchive from "../views/game/BooksArchive";
import ActivityCompleted from "../views/game/ActivityCompleted";
import PortalPage from "../views/game/PortalPage";
import Ending from "../views/game/Ending";
import GameDone from "../views/game/GameDone";
import WheelOfColors from "../views/game/WheelOfColorsPage";
import Competences from "../views/game/Competences";
import ConstellationBook from "../views/game/ConstellationBook";
import AlMessage from "../views/game/AlMessages";
import MyPage from "../views/game/MyPage";
import Discover from "../views/game/Discover";
import Creators from "../views/game/Creators";

import ConstellationProgress from '../views/progress/ConstellationProgress';

/////////////////////// ADMIN ////////////////////////////////
import Dashboard from '../views/admin/Dashboard';
import SingleArt from '../views/admin/SingleArt';
import TestStory from '../views/admin/TestStory';
import ActivitiesTest from '../views/admin/ActivitiesTest';
import ActivitiesList from '../views/admin/ActivitiesList';
import Stats from '../views/admin/Stats';
import CircleSetUp from '../views/testViews/CircleSetUp';

/////////////////////// TEST VIEW ///////////////////////////
import Test from '../views/testViews/Test';
import TestPage from '../views/testViews/TestPage';
import TestPortal from '../views/testViews/TestPortal';
import Translation from '../views/Translation';

import BooksOfKnowledge from '../views/game/BooksOfKnowledge';

/////////////////////// NOT FOUND ///////////////////////////
import PageNotFound from '../views/PageNotFound';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	/////////////////////////// ADMIN ///////////////////////////
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
	},
	{
		path: '/art/:inventoryNumber',
		name: 'SingleArt',
		component: SingleArt,
	},
	{
		path: '/test-story/:storyName/:lang',
		name: 'TestStory',
		component: TestStory,
	},
	{
		path: '/circle',
		name: 'CircleSetUp',
		component: CircleSetUp,
	},
	{
		path: '/activity-test',
		name: 'ActivitiesTest',
		component: ActivitiesTest,
	},
	{
		path: '/admin/stats',
		name: 'Stats',
		component: Stats,
	},
	{
		path: '/admin/activities-list',
		name: 'ActivitiesList',
		component: ActivitiesList,
	},
	/////////////////////////// INTRO ///////////////////////////
	{
		path: '/read-message',
		name: 'ReadMessage',
		component: ReadMessage,
	},
	{
		path: '/new-intro-message',
		name: 'NewIntroMessage',
		component: NewIntroMessage,
	},
	{
		path: '/new-intro',
		name: 'NewIntro',
		component: NewIntro,
	},

	/////////////////////////// Auth ///////////////////////////

	{
		path: '/auth/',
		name: 'Auth',
		component: Auth,
		meta: {
			requireLogin: false,
		},
	},

    {
        path: "/profile/",
        name: "Profile",
        component: Profile,
        meta: {
            requireLogin: true,
        },
    },

    {
        path: "/login/",
        name: "Login",
        component: Login,
        meta: {
            requireLogin: false,
        },
    },

    {
        path: "/join-step1/",
        name: "JoinStep1",
        component: JoinStep1,
        meta: {
            requireLogin: false,
        },
    },
    {
        path: "/join-step2/",
        name: "JoinStep2",
        component: JoinStep2,
        meta: {
            requireLogin: false,
        },
    },
    {
        path: "/password-reset-step1/",
        name: "PasswordResetStep1",
        component: PasswordResetStep1,
        meta: {
            requireLogin: false,
        },
    },
    {
        path: "/password-reset-step2/",
        name: "PasswordResetStep2",
        component: PasswordResetStep2,
        meta: {
            requireLogin: false,
        },
    },
	
    
	/////////////////////////// GAME ///////////////////////////
	{
		path: '/level/:level',
		name: 'Level',
		component: Level,
	},
	{
		path: '/nav-burger-menu',
		name: 'BurgerMenu',
		component: NavBurgerMenu,
	},
	{
		path: '/select-constellation',
		name: 'SelectConstellation',
		component: SelectConstellation,
	},
	{
		path: '/stories/:zodiacName/:inventoryNumber',
		name: 'StoryList',
		component: StoryList,
	},
	{
		path: '/constellation-progress/:inventoryNumber/:storyName/',
		name: 'ConstellationProgress',
		component: ConstellationProgress,
	},
	{
		path: '/activities/:inventoryNumber/:storyName/',
		name: 'Activities',
		component: Activities,
	},
    {
        path: "/books-of-knowledge",
        name: "BooksOfKnowledge",
        component: BooksOfKnowledge,
    },
    {
        path: "/book-reward/:zodiacName",
        name: "BookReward",
        component: BookReward,
    },
    {
        path: "/books-archive/",
        name: "BooksArchive",
        component: BooksArchive,
    },
    {
        path: "/activity-completed/:inventoryNumber/:storyName",
        name: "ActivityCompleted",
        component: ActivityCompleted,
    },
    {
        path: "/portal",
        name: "PortalPage",
        component: PortalPage,
    },
    {
        path: "/ending/:type",
        name: "Ending",
        component: Ending,
    },
	{
        path: "/game-done",
        name: "GameDone",
        component: GameDone,
    },
    {
        path: "/wheel-of-colors",
        name: "WheelOfColors",
        component: WheelOfColors,
    },
    {
        path: "/competences",
        name: "Competences",
        component: Competences,
    },
    {
        path: "/constellation-book",
        name: "ConstellationBook",
        component: ConstellationBook,
    },
    {
        path: "/al-messages",
        name: "MessageFromAl",
        component: AlMessage,
    },
    {
        path: "/my-page",
        name: "MyPage",
        component: MyPage,
    },
    {
        path: "/more-discover",
        name: "Discover",
        component: Discover,
    },
    {
        path: "/creators",
        name: "Creators",
        component: Creators,
    },

	/////////////////////////// NOT USING ///////////////////////////
	{
		path: '/choose-image/:zodiacName',
		name: 'SelectImage',
		component: SelectImage,
	},
	/////////////////////////// TEST VIEW ///////////////////////////

	{
		path: '/test-page',
		name: 'TestPage',
		component: TestPage,
	},
	{
		path: '/test-portal',
		name: 'TestPortal',
		component: TestPortal,
	},

	{
		path: '/test',
		name: 'Test',
		component: Test,
	},

	{
		path: '/translation',
		name: 'Translation',
		component: Translation,
	},

	//////// ERROR PAGE

	{ path: '*', component: PageNotFound },
];

store.dispatch('CHECK_USER');
const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
});

/*
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requireLogin) && !store.state.authState.isAuthenticated) {
		next({ name: 'Auth', query: { to: to.path } });
	} else if (to.matched.some((record) => record.meta.requireLogin === false) && store.state.authState.isAuthenticated) {
		next({ name: 'Profile', query: { to: to.path } });
	} else {
		next();
	}
});
*/

export default router;
