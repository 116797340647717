<template>
    <svg v-if="newStar" xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
        <path d="M16.2689 14.7954C14.2998 15.4225 13.2401 17.5106 13.8596 19.456C14.4791 21.4014 16.5823 22.5085 18.5277 21.8891C20.4732 21.2696 21.5803 19.1663 20.9532 17.1972C20.3262 15.228 18.238 14.1684 16.2689 14.7954Z" fill="white"/>
        <path d="M23.3825 10.633C22.3353 10.9664 21.7718 12.0769 22.1012 13.1115C22.4307 14.1461 23.5492 14.7348 24.5838 14.4054C25.6184 14.0759 26.2071 12.9574 25.8737 11.9102C25.5402 10.863 24.4297 10.2995 23.3825 10.633Z" fill="white"/>
        <path d="M12.2153 23.9636C11.4606 24.2039 11.0545 25.0042 11.2919 25.7499C11.5293 26.4955 12.3355 26.9198 13.0811 26.6824C13.8267 26.4449 14.2511 25.6388 14.0107 24.8841C13.7704 24.1294 12.9701 23.7232 12.2153 23.9636Z" fill="white"/>
        <path d="M12.4328 11.3356C12.827 11.2101 13.2417 11.4285 13.3657 11.8179C13.4898 12.2073 13.2744 12.6316 12.885 12.7556C12.4955 12.8796 12.0713 12.6642 11.9458 12.2701C11.8202 11.8759 12.0386 11.4611 12.4328 11.3356Z" fill="white"/>
        <path d="M17.5 0.599609L19.3872 16.2124L35 18.0996L19.3872 19.9868L17.5 35.5996L15.6128 19.9868L0 18.0996L15.6128 16.2124L17.5 0.599609Z" fill="white"/>
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <path d="M14.5 0L16.0637 12.9363L29 14.5L16.0637 16.0637L14.5 29L12.9363 16.0637L0 14.5L12.9363 12.9363L14.5 0Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        isIconActive: {
            type: Boolean,
            default: false,
        },
        newStar: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // isActive() {
        //     return this.isIconActive
        //         ? "var(--primary-gray)"
        //         : "var(--primary-white)";
        // },
    },
};
</script>

<style scoped></style>
