<template>
	<div>
		<div class="images-container">
			<img class="single-img" v-for="item in images" :key="item.id" :src="item.img" alt="" />
		</div>
	</div>
</template>

<script>
import { gsap } from 'gsap';
export default {
	data() {
		return {
			images: [
				// { id: 10, img: require("../../assets/img/intro/intro10.png") },
				// { id: 9, img: require("../../assets/img/intro/intro9.png") },
				// { id: 0, img: require("../../assets/img/intro/intro8.png") },
				// { id: 1, img: require("../../assets/img/intro/intro7.png") },
				// { id: 2, img: require("../../assets/img/intro/intro6.png") },
				{ id: 3, img: require('../../assets/img/intro/intro6.png') },
				{ id: 4, img: require('../../assets/img/intro/intro5.png') },
				{ id: 5, img: require('../../assets/img/intro/intro4.png') },
				{ id: 6, img: require('../../assets/img/intro/intro3.png') },
				{ id: 7, img: require('../../assets/img/intro/intro2.png') },
				{ id: 8, img: require('../../assets/img/intro/intro1.png') },
			],
		};
	},
	mounted() {
		let images = gsap.utils.toArray('.single-img').reverse();
		setTimeout(() => {
			gsap.to(images, {
				scale: (i) => (i < images.length - 1 ? 10 : 3),
				opacity: (i) => i < images.length - 1 && 0,
				stagger: 1.2,
				duration: 1.1,
				// duration: 1.1,
				onComplete: () => this.$emit('zoomCompleted'),
			});
		}, 1000);
	},
};
</script>

<style scoped>
.images-container {
	position: relative;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.single-img {
	position: absolute;
	width: 100%;
	object-fit: cover;
	object-position: center;
	height: 100%;
	z-index: 10;
	/* scale: 0; */
}
</style>
