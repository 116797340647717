<template>
    <div class="feedback-section">
        <div class="feedback" v-show="!failsQuiz">
            <p v-html="selectedOptions.shortFeedBack"></p>
            <button
                v-if="selectedOptions.longFeedBack"
                @click="$store.dispatch('TOGGLE_LONG_MODAL')"
                class="learn-more"
            >
                {{ $t("message.learn_more") }} >
            </button>
        </div>
        <button
            v-if="showContinueButton"
            class="btn"
            @click="handleClick"
        >
            {{ $t("message.continue") }}
        </button>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            currentArt: (state) => state.gameState.currentArt,
            showContinueButton: (state) =>
                state.activitiesState.showContinueButton,
            selectedOptions: (state) => state.activitiesState.selectedOptions,
            failsQuiz: (state) => state.activitiesState.failsQuiz,
        }),
    },
    methods: {
        handleClick() {
            // TEST ACTIVITIES

            const { inventoryNumber, storyName } = this.$route.params;

            if (this.$route.name === "TestStory") {
                this.$router.push({
                    name: "ActivitiesList",
                    // query: { story: storyName },
                });
                return;
            }
            if (this.$route.name === "ActivitiesTest") {
                return;
            }

            if (this.$route.name === "ActivityCompleted") {
                this.$router.push({
                    name: "StoryList",
                    params: {
                        zodiacName: this.currentArt.constellation,
                        storyName,
                    },
                });
                return;
            }

            if (this.failsQuiz) {
                this.$store.dispatch("TOGGLE_TIMEOUT_MODAL", true);
                return;
            }

            this.$router.push({
                name: "ConstellationProgress",
                params: {
                    inventoryNumber,
                    storyName,
                },
            });
        },
    },
};
</script>

<style scoped>
.feedback-section {
    width: 100%;
}
.feedback {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}
.feedback p {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 1.8rem;
    font-style: normal;
    line-height: 140%; /* 22.4px */
    word-break: keep-all;
}

.learn-more {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background: transparent;
    border: none;
    padding-right: 1rem;
    color: var(--primary-white);
    text-align: right;
    font-family: var(--primary-font-semibold);
    font-size: 1.5rem;
    font-style: normal;
    line-height: normal;
    text-transform: none;
}
</style>
