<template>
    <section class="keyword-section">
        <!-- all keywords -->
        <div class="keywords">
            <span
                v-for="(keyword, idx) in story_keywords_list"
                :key="idx"
                :class="`selected-${idx}`"
                class="options"
                id="keyword"
                :style="`order: ${keyword.order ? keyword.order : idx}`"
            >
                {{ keyword }}
            </span>
        </div>
        <!-- end of all keywords -->
        <div class="keywords keywordsback">
            <span
                v-for="(keyword, idx) in story_keywords_list"
                :key="idx"
                :class="`selected-${idx}`"
                class="options-back"
                :style="`order: ${keyword.order ? keyword.order : idx}`"
            >
                {{ keyword }}
            </span>
        </div>
        <!-- end of all keywords -->
    </section>
</template>

<script>
import { gsap } from "gsap";
export default {
    props: {
        story_keywords_list: {
            type: Array,
        },
        detail: {
            type: Number,
            default: 2,
        },
    },
    mounted() {
        let tl = gsap.timeline({ delay: this.detail });
        tl.set(".keywords", { opacity: 1 })
            .from("#keyword", {
                yPercent: 100,
                stagger: 0.05,
            })
            .set(".options-back", { opacity: 1 });
    },
};
</script>

<style scoped>
.keyword-section {
    position: relative;
    display: flex;
}

.keywords,
.keywordsback {
    width: 100%;
    display: flex;
    align-items: center;
    place-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    /* margin: 1rem; */
    opacity: 0;
    max-width: 400px;
}
.keywordsback {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.options,
.options-back {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: white;
    border: 1px solid var(--primary-black);
    border-radius: 5px;
    color: var(--primary-black);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
    padding: 0.8rem 1.2rem;
}

.options-back {
    background: transparent;
    border: 1px dashed var(--primary-white);
    color: transparent;
    opacity: 0;
}
.options {
    z-index: 1;
}
.options.matched {
    border: 2px solid var(--primary-black);
    background: green;
    color: var(--primary-white);
}
</style>
