import axios from "axios";

const BASE_URL = "https://staedel.nolple.com/api/v1/";
// const BASE_URL = "http://ec2-54-180-101-134.ap-northeast-2.compute.amazonaws.com:8000/api/v1/";
// const BASE_URL = "http://127.0.0.1:8000/api/v1/";

axios.defaults.baseURL = BASE_URL;

const gameApi = axios.create({
    baseURL: BASE_URL,
    transformRequest: [
        (data, headers) => {
            headers["Accept-Language"] = localStorage.getItem("locale");
            return {
                player_id: localStorage.getItem("player__id"),
                game_id: localStorage.getItem("game__id"),
                ...data,
            };
        },
        ...axios.defaults.transformRequest,
    ],
});

export default gameApi;
