<template>
    <svg
        width="20"
        height="30"
        viewBox="0 0 20 30"
        :fill="isActive"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.8808 22.8468C17.2231 21.7897 18.2129 18.7614 7.62438 15.2626C4.07931 14.0953 0.920179 12.3802 0.920179 8.40493C0.920179 3.66442 4.91966 0.674805 9.6817 0.674805C12.3491 0.674805 15.281 1.62172 17.301 2.53587V8.15182H16.9959C15.4335 2.5716 12.956 1.11253 9.64124 1.11253C7.16373 1.11253 5.41454 2.5716 5.37408 4.53987C5.37408 8.58955 13.0307 9.82827 17.0675 13.3301C20.0399 15.9207 19.2774 20.9143 17.1049 23.0314L16.8777 22.8498L16.8808 22.8468ZM10.0241 26.4945C3.58443 26.3128 1.03223 19.6011 0.879718 18.142H0.5L0.842368 24.5977C3.96726 26.0925 7.4314 27.0424 10.2886 26.822L10.021 26.4945H10.0241ZM18.991 29.2578H0.839256V29.9248H19.0003V29.2578H18.991Z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        isIconActive: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isActive() {
            return this.isIconActive
                ? "var(--primary-gray)"
                : "var(--primary-white)";
        },
    },
};
</script>
