const activitiesTestData = [
    {
        name: "SliderManyOptions",
        data: {
            art_inventory_number: "St.P650",
            isCompleted: false,
            story_name: "Round and Round",
            name: "SliderManyOptions",
            competence: [
                "visual perception",
                "religious context",
                "history of ideas",
                "cultural historical context",
            ],
            title: "Which of these terms do you think best describes the work of art?",
            img: "https://sammlung.staedelmuseum.de/images/102649/thumb-xl.jpg",
            options: [
                {
                    id: 1,
                    option: "cycle",
                    votes: 0,
                    shortFeedBack:
                        "The cords make tracks in the sand in an eternal circle. The work is reminiscent of the cycle of becoming and passing away.",
                    longFeedBack:
                        'The artist calls the work <i>"a kinetic memento mori"</i>. The Latin expression means to be aware of one\'s own mortality. For Uecker, sand is the ultimate symbol of the earth. The Old Testament says, "For you are dust and to dust you shall become". With the words, God indicated that the first man, Adam, will return to what he was created from. In the Christian Church there is also the burial formula "Earth to earth, ashes to ashes, dust to dust."',
                },
                {
                    id: 2,
                    option: "sandbox",
                    votes: 0,
                    shortFeedBack:
                        "The cords make tracks in the sand in an eternal circle. The work is reminiscent of the cycle of becoming and passing away.",
                    longFeedBack:
                        'The artist calls the work <i>"a kinetic memento mori"</i>. The Latin expression means to be aware of one\'s own mortality. For Uecker, sand is the ultimate symbol of the earth. The Old Testament says, "For you are dust and to dust you shall become". With the words, God indicated that the first man, Adam, will return to what he was created from. In the Christian Church there is also the burial formula "Earth to earth, ashes to ashes, dust to dust."',
                },
                {
                    id: 3,
                    option: "plowing",
                    votes: 0,
                    shortFeedBack:
                        "The cords make tracks in the sand in an eternal circle. The work is reminiscent of the cycle of becoming and passing away.",
                    longFeedBack:
                        'The artist calls the work <i>"a kinetic memento mori"</i>. The Latin expression means to be aware of one\'s own mortality. For Uecker, sand is the ultimate symbol of the earth. The Old Testament says, "For you are dust and to dust you shall become". With the words, God indicated that the first man, Adam, will return to what he was created from. In the Christian Church there is also the burial formula "Earth to earth, ashes to ashes, dust to dust."',
                },
                {
                    id: 4,
                    option: "circle",
                    votes: 0,
                    shortFeedBack:
                        "The cords make tracks in the sand in an eternal circle. The work is reminiscent of the cycle of becoming and passing away.",
                    longFeedBack:
                        'The artist calls the work <i>"a kinetic memento mori"</i>. The Latin expression means to be aware of one\'s own mortality. For Uecker, sand is the ultimate symbol of the earth. The Old Testament says, "For you are dust and to dust you shall become". With the words, God indicated that the first man, Adam, will return to what he was created from. In the Christian Church there is also the burial formula "Earth to earth, ashes to ashes, dust to dust."',
                },
            ],
        },
    },
    {
        name: "PollManyOptions",
        data: {
            art_inventory_number: "St.P650",
            isCompleted: false,
            story_name: "Round and Round",
            name: "PollManyOptions",
            competence: [
                "visual perception",
                "religious context",
                "history of ideas",
                "cultural historical context",
            ],
            title: "Which of these terms do you think best describes the work of art?",
            img: "https://sammlung.staedelmuseum.de/images/102649/thumb-xl.jpg",
            options: [
                {
                    id: 1,
                    option: "cycle",
                    votes: 0,
                    shortFeedBack:
                        "The cords make tracks in the sand in an eternal circle. The work is reminiscent of the cycle of becoming and passing away.",
                    longFeedBack:
                        'The artist calls the work <i>"a kinetic memento mori"</i>. The Latin expression means to be aware of one\'s own mortality. For Uecker, sand is the ultimate symbol of the earth. The Old Testament says, "For you are dust and to dust you shall become". With the words, God indicated that the first man, Adam, will return to what he was created from. In the Christian Church there is also the burial formula "Earth to earth, ashes to ashes, dust to dust."',
                },
                {
                    id: 2,
                    option: "sandbox",
                    votes: 0,
                    shortFeedBack:
                        "The cords make tracks in the sand in an eternal circle. The work is reminiscent of the cycle of becoming and passing away.",
                    longFeedBack:
                        'The artist calls the work <i>"a kinetic memento mori"</i>. The Latin expression means to be aware of one\'s own mortality. For Uecker, sand is the ultimate symbol of the earth. The Old Testament says, "For you are dust and to dust you shall become". With the words, God indicated that the first man, Adam, will return to what he was created from. In the Christian Church there is also the burial formula "Earth to earth, ashes to ashes, dust to dust."',
                },
                {
                    id: 3,
                    option: "plowing",
                    votes: 0,
                    shortFeedBack:
                        "The cords make tracks in the sand in an eternal circle. The work is reminiscent of the cycle of becoming and passing away.",
                    longFeedBack:
                        'The artist calls the work <i>"a kinetic memento mori"</i>. The Latin expression means to be aware of one\'s own mortality. For Uecker, sand is the ultimate symbol of the earth. The Old Testament says, "For you are dust and to dust you shall become". With the words, God indicated that the first man, Adam, will return to what he was created from. In the Christian Church there is also the burial formula "Earth to earth, ashes to ashes, dust to dust."',
                },
                {
                    id: 4,
                    option: "circle",
                    votes: 0,
                    shortFeedBack:
                        "The cords make tracks in the sand in an eternal circle. The work is reminiscent of the cycle of becoming and passing away.",
                    longFeedBack:
                        'The artist calls the work <i>"a kinetic memento mori"</i>. The Latin expression means to be aware of one\'s own mortality. For Uecker, sand is the ultimate symbol of the earth. The Old Testament says, "For you are dust and to dust you shall become". With the words, God indicated that the first man, Adam, will return to what he was created from. In the Christian Church there is also the burial formula "Earth to earth, ashes to ashes, dust to dust."',
                },
            ],
        },
    },
    {
        name: "SliderTwoOptions",
        data: {
            art_inventory_number: "St.P650",
            isCompleted: false,
            story_name: "infinite loop",
            name: "SliderTwoOptions",
            competence: [
                "visual perception",
                "cultural historical context",
                "mythological context",
            ],
            title: "What does the circular movement remind you of?",
            img: "https://sammlung.staedelmuseum.de/images/102649/thumb-xl.jpg",
            options: [
                {
                    id: 1,
                    option: "everyday routine",
                    votes: 0,
                    shortFeedBack:
                        'The movement of the cords is uniform. The "sand mill" is reminiscent of the daily grind or the proverbial work mill.',
                    longFeedBack:
                        "The work is reminiscent of the painstakingly evenly drawn furrows in the earth into which people have been sowing seeds for thousands of years. Sowing ends in harvest and begins again with sowing. The work also evokes associations with a figure from Greek mythology: Sisyphus, who has to roll a boulder up a mountain as punishment. Arriving at the summit, the stone keeps rolling down into the valley, so that Sisyphus has to start all over again. Today, a task without a foreseeable end is called a Sisyphean task.",
                },
                {
                    id: 1,
                    option: "cycle of life",
                    votes: 0,
                    shortFeedBack:
                        'The movement of the cords is uniform. The "sand mill" is reminiscent of the daily grind or the proverbial work mill.',
                    longFeedBack:
                        "The work is reminiscent of the painstakingly evenly drawn furrows in the earth into which people have been sowing seeds for thousands of years. Sowing ends in harvest and begins again with sowing. The work also evokes associations with a figure from Greek mythology: Sisyphus, who has to roll a boulder up a mountain as punishment. Arriving at the summit, the stone keeps rolling down into the valley, so that Sisyphus has to start all over again. Today, a task without a foreseeable end is called a Sisyphean task.",
                },
            ],
        },
    },
    {
        name: "Quiz",
        data: {
            art_inventory_number: "1559",
            isCompleted: false,
            story_name: "In the mirror",
            name: "Quiz",
            competence: ["visual perception", "artistic biography"],
            title: "Besides the sky, what else is reflected on the water surface?",
            img: "https://sammlung.staedelmuseum.de/images/1545/thumb-xl.jpg",
            rightOption: "Moon",
            options: [
                {
                    id: 1,
                    option: "Moon",
                    rightOption: true,
                    shortFeedBack:
                        "The silvery white color could be an indication that the moon is reflected in the water. However, it cannot be seen in the sky. It is not clear what time of day it is, morning or evening.",
                    longFeedBack:
                        "It is a barren high mountain landscape through which a shepherdess drives her flock of sheep. People and animals can hardly be distinguished from the landscape. Giovanni Segantini translates the clear light of the Alps and the mighty mountains into shimmering threads of color. The foreground is so rich in detail that the eye wanders restlessly around the picture and a peculiar restlessness arises.\nLike many artists at the end of the 19th century, Giovanni Segantini withdrew into nature. In 1894, at the age of 36, he moved into a house in the Alps with his partner Luigia, known as Bice, and their three sons.",
                },
                {
                    id: 2,
                    option: "Cloud",
                    rightOption: false,
                    shortFeedBack:
                        "The silvery white color could be an indication that the moon is reflected in the water. However, it cannot be seen in the sky. It is not clear what time of day it is, morning or evening.",
                    longFeedBack:
                        "It is a barren high mountain landscape through which a shepherdess drives her flock of sheep. People and animals can hardly be distinguished from the landscape. Giovanni Segantini translates the clear light of the Alps and the mighty mountains into shimmering threads of color. The foreground is so rich in detail that the eye wanders restlessly around the picture and a peculiar restlessness arises.\nLike many artists at the end of the 19th century, Giovanni Segantini withdrew into nature. In 1894, at the age of 36, he moved into a house in the Alps with his partner Luigia, known as Bice, and their three sons.",
                },
                {
                    id: 3,
                    option: "Sun",
                    rightOption: false,
                    shortFeedBack:
                        "The silvery white color could be an indication that the moon is reflected in the water. However, it cannot be seen in the sky. It is not clear what time of day it is, morning or evening.",
                    longFeedBack:
                        "It is a barren high mountain landscape through which a shepherdess drives her flock of sheep. People and animals can hardly be distinguished from the landscape. Giovanni Segantini translates the clear light of the Alps and the mighty mountains into shimmering threads of color. The foreground is so rich in detail that the eye wanders restlessly around the picture and a peculiar restlessness arises.\nLike many artists at the end of the 19th century, Giovanni Segantini withdrew into nature. In 1894, at the age of 36, he moved into a house in the Alps with his partner Luigia, known as Bice, and their three sons.",
                },
            ],
        },
    },
    {
        name: "DragAndMatch",
        data: {
            art_inventory_number: "SG 1278",
            isCompleted: false,
            story_name: "Medea",
            rightKeywords: [
                {
                    id: 1,
                    keyword: "dress",
                    position: { top: 8, left: 43 },
                    shortFeedBack:
                        "The dress soiled with ash is a wedding dress, but it does not belong to Medea.",
                    longFeedBack:
                        "Jason and Medea married, had two sons and lived happily together for many years. But when the king of Corinth offers Jason to marry his daughter and take over the city, the story takes a tragic turn: Medea, who had betrayed her family for Jason and left her homeland, is to be banished abroad – whereupon she forges a cruel revenge plan. She sends Jason's new bride a wedding dress made out of poisoned fabric.",
                },
                {
                    id: 2,
                    keyword: "snakeskin",
                    position: { top: 9, left: 60 },
                    shortFeedBack:
                        "Medea takes revenge on her husband Jason, who wanted to leave her for someone else, by killing his new bride and their children. After the deed, she flees on the chariot of the sun god, which was drawn by flying serpents",
                    longFeedBack:
                        "The snake is a symbol of renewal: Medea begins a new chapter in her story. Nothing remains of her old life with Jason.",
                },
                {
                    id: 3,
                    keyword: "childrens dresses",
                    position: { top: 64, left: 35 },
                    shortFeedBack:
                        "The little white dresses stand for the killed children of Medea and Jason.",
                    longFeedBack:
                        "When Medea learns that Jason plans to leave her for another woman, she plots a terrible act of revenge. First she poisoned Jason's new bride. But to take everything from Jason - just as he took everything from her - she goes to the extreme. In her madness she kills her own children.",
                },
                {
                    id: 4,
                    keyword: "teeth",
                    position: { top: 24, left: 41 },
                    shortFeedBack:
                        "In the Argonaut saga, enchanted dragon teeth transform into warriors that Jason must fight.",
                    longFeedBack:
                        "When Jason demands that the King of Colchis hand over the Golden Fleece, he presents her with an impossible task: The young hero is to sow enchanted dragon teeth from which.",
                },
                {
                    id: 5,
                    keyword: "gold",
                    position: { top: 63, left: 48 },
                    shortFeedBack:
                        "In the Argonaut saga, enchanted dragon teeth transform into warriors that Jason must fight.",
                    longFeedBack:
                        "When Jason demands that the King of Colchis hand over the Golden Fleece, he presents her with an impossible task: The young hero is to sow enchanted dragon teeth from which.",
                },
            ],
            name: "DragAndMatch",
            competence: [
                "visual perception",
                "material as a form of expression",
            ],
            title: "Anselm Kiefer's work deals with the Medea myth. The story begins with Jason and Princess Medea falling in love. Find out more by matching the terms!",
            img: "https://sammlung.staedelmuseum.de/images/4138/thumb-xl.jpg",
            keywords: [
                "dress",
                "snakeskin",
                "childrens dresses",
                "teeth",
                "gold",
            ],
        },
    },
    {
        name: "DragCommon",
        data: {
            art_inventory_number: "2087",
            isCompleted: false,
            story_name: "A question of believe",
            options: [
                {
                    id: 1,
                    img: "https://sammlung.staedelmuseum.de/images/1068/thumb-xl.jpg",
                    // img: "https://sammlung.staedelmuseum.de/images/2071/thumb-xl.jpg",
                },
                {
                    id: 2,
                    img: "https://sammlung.staedelmuseum.de/images/930/thumb-xl.jpg",
                    // img: "https://sammlung.staedelmuseum.de/images/2740/thumb-xl.jpg",
                },
            ],
            rightKeywords: [
                {
                    id: 1,
                    keyword: "wooden cross",
                    shortFeedBack:
                        "The painter Cornelis de Vos shows the terrible test that Abraham had to pass in all its drama: the head of the boy Isaac lies on a stack of logs on a sacrificial altar.",
                    longFeedBack:
                        "Traditionally, offerings are burned. The boy had collected the wood himself without realizing that he himself was to be the victim. Isaac folded his hands as if in prayer. A rope can be seen on his arms, tied behind his back. The arrangement of the wood is reminiscent of a cross. With this, the painter Cornelis de Vos refers to the sacrificial death of Jesus, who died on a cross for the sins of mankind.",
                },
                {
                    id: 2,
                    keyword: "loincloth",
                    shortFeedBack:
                        "As in the depictions of the crucifixion of the Son of God, Isaac is also undressed apart from a white loincloth around his hips. God required Abraham to sacrifice his son, just as God sent his son Jesus to earth to die a sacrificial death.",
                    longFeedBack: "",
                },
            ],
            name: "DragCommon",
            competence: ["visual perception", "religious context"],
            title: "What elements in the picture connect Isaac to Jesus? Compare the two pictures and find the similarities!",
            keywords: ["wooden cross", "loincloth", "golden background", "ram"],
        },
    },
    {
        name: "PollTwoOptions",
        data: {
            art_inventory_number: "807",
            isCompleted: false,
            story_name: "Heartfelt affection",
            options: [
                {
                    votes: 0,
                    id: 1,
                    img: "https://sammlung.staedelmuseum.de/images/804/thumb-xl.jpg",
                    option: "Barnaba da Modena",
                    shortFeedBack:
                        "At Barnaba da Modena, Maria holds her son with one hand and supports his little feet with the other. The relationship between mother and child is shown in a strikingly intimate manner.",
                    longFeedBack:
                        "The image of the Madonna was modeled on Christian cult images, called icons. The icon of Mary was revered as particularly holy because it went back to an archetype of Christianity: a real portrait of the Mother of God, which the evangelist Luke is said to have painted. Madonna portraits created according to this model only show the baby Jesus in outline and as a small adult. According to Bible history, the Son of God was born fully formed, which is why he resembles an adult rather than an infant in medieval images. Barnaba da Modena, on the other hand, shows him very close to life: with a childlike gesture, the infant Jesus grabs his foot and clings to his mother. That he was not a normal child is shown by the fact that Maria can only hold him with one hand.",
                },
                {
                    votes: 0,
                    id: 2,
                    img: "https://upload.wikimedia.org/wikipedia/commons/6/61/SalusPopuliRomani2018.jpg",
                    option: "Salus Populi Romani",
                    shortFeedBack:
                        "At Barnaba da Modena, Maria holds her son with one hand and supports his little feet with the other. The relationship between mother and child is shown in a strikingly intimate manner.",
                    longFeedBack:
                        "The image of the Madonna was modeled on Christian cult images, called icons. The icon of Mary was revered as particularly holy because it went back to an archetype of Christianity: a real portrait of the Mother of God, which the evangelist Luke is said to have painted. Madonna portraits created according to this model only show the baby Jesus in outline and as a small adult. According to Bible history, the Son of God was born fully formed, which is why he resembles an adult rather than an infant in medieval images. Barnaba da Modena, on the other hand, shows him very close to life: with a childlike gesture, the infant Jesus grabs his foot and clings to his mother. That he was not a normal child is shown by the fact that Maria can only hold him with one hand.",
                },
            ],
            name: "PollTwoOptions",
            competence: ["empathy", "religious context", "history of motivs"],
            title: "In both pictures, the Virgin Mary holds her son Jesus in her arms. Which representation do you think expresses more affection and intimacy?",
        },
    },
    {
        name: "DragPoll",
        data: {
            art_inventory_number: "2316",
            isCompleted: false,
            story_name: "The forgotten star",
            options: [
                {
                    id: 1,
                    img: "https://sammlung.staedelmuseum.de/images/102185/thumb-xl.jpg",
                    option: "Jean-Léon Gérôme",
                    shortFeedBack:
                        "Jean-Leon Gérôme was a successful and respected artist of his time, exhibiting regularly at the Paris Salon.",
                    longFeedBack:
                        "This exhibition was the central art event of the year in the 19th century. Only those who could convince the strict jury were allowed to show their pictures there and hope for public attention and financial success. Jean-Léon Gérôme was one of the most successful French artists of the 19th century - but later he fell into obscurity. While the works of the Salon painters were soon considered conservative and outdated, the pictures of the Impressionists, which were still considered scandalous during their lifetime, were celebrated as the epitome of modernism. It is only recently that museums have once again been paying attention to the painter of the Paris Salon.",
                },
                {
                    id: 2,
                    img: "https://sammlung.staedelmuseum.de/images/3018/thumb-xl.jpg",
                    option: "Impressionists",
                    shortFeedBack:
                        "Jean-Leon Gérôme was very successful in his time, but today the pictures of the Impressionists shape our idea of ​​the art of that time.",
                    longFeedBack:
                        "The painting of the Impressionists broke with the rules of the academic art world - they regularly caused scandals with their painting style. Impressionist paintings were often not admitted to the Paris Salon exhibitions. This art exhibition was considered the central art event of the year in Europe in the 19th century. Only the artists represented there could hope for financial success. Among them was Jean-Léon Gérôme - one of the most successful French artists of the 19th century, who later fell into obscurity. While the works of the Salon painters were soon considered conservative and outdated, the paintings of the Impressionists were hailed as the epitome of modernism. It is only recently that museums have once again been paying attention to the painter of the Paris Salon.",
                },
            ],
            name: "DragPoll",
            competence: ["art historiography", "history of reception"],
            title: "Hard to believe: the two paintings were created at about the same time. When Gérôme painted the picture in 1874, the first exhibition of the Impressionists was taking place in Paris. Who do you think was more successful in his time: a salon painter like Jean-Léon Gérôme or the Impressionists, which included Claude Monet, Auguste Renoir and Edgar Degas, for example?",
        },
    },
];

export default activitiesTestData;
