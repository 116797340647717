import { render, staticRenderFns } from "./Competences.vue?vue&type=template&id=4b12efed&scoped=true&"
import script from "./Competences.vue?vue&type=script&lang=js&"
export * from "./Competences.vue?vue&type=script&lang=js&"
import style0 from "./Competences.vue?vue&type=style&index=0&id=4b12efed&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b12efed",
  null
  
)

export default component.exports