<template>
    <svg
        width="29" height="13"
        viewBox="0 0 29 13" fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22.387 0.627352C19.5086 0.625372 17.1112 2.74789 16.6707 5.50376L1.03706 5.49472C0.531486 5.49438 0.122267 5.9036 0.122615 6.40917L0.126393 11.8998C0.126741 12.4054 0.536524 12.8152 1.0421 12.8155C1.54768 12.8159 1.9569 12.4067 1.95655 11.9011L1.9534 7.3259L5.61371 7.32842L5.61686 11.9036C5.61721 12.4092 6.02699 12.819 6.53257 12.8193C7.03815 12.8196 7.44737 12.4104 7.44702 11.9049L7.44387 7.32968L16.6723 7.33603C17.1174 10.0934 19.5177 12.2192 22.3952 12.2203C25.5854 12.2225 28.1899 9.61799 28.1876 6.42799C28.1854 3.23787 25.5773 0.629784 22.3873 0.627697L22.387 0.627352ZM22.3882 2.45751C24.5891 2.45902 26.3547 4.2246 26.3572 6.42647C26.3587 8.62738 24.5955 10.3905 22.3937 10.39C20.1928 10.3885 18.4272 8.62288 18.4247 6.42101C18.4232 4.22009 20.1864 2.45694 22.3882 2.45751Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        isCompletedOrUnlocked: {
            type: Boolean,
            default: false,
        },
        constellation: {
            type: String,
            default: "",
        },
    },
};
</script>

<style scoped></style>
