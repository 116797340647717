<template>
    <div class="wrapper">
        <WheelOfColors
            v-if="artByConstellation.length > 0"
            :artByConstellation="artByConstellation"
        />
    </div>
</template>

<script>
import { WheelOfColors } from "../../components/";
import gameApi from "../../api/gameApi";

export default {
    components: {
        WheelOfColors,
    },
    data() {
        return { artByConstellation: [], rotation: 2 };
    },
    mounted() {
        this.getUserProgress();
    },

    methods: {
        async getUserProgress() {
            try {
                const { data } = await gameApi.post(
                    "game/constellations-progress"
                );
                this.artByConstellation = data.art_by_constellations;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped></style>
