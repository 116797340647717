<template>
    <main :key="componentKEY" :class="componentKEY">
        <ActivityDescription v-if="activity.story_name" />
        <ZoomComponent />

        <!-- <div @change="handleChange" v-if="activity.name == 'DragPoll'">
            <select v-model="selectedArtIdx">
                <option value="1">1</option>
                <option value="2">2</option>
            </select>
        </div> -->

        <component
            v-bind="activity"
            :is="activity.name"
            :key="activity.name + componentKEY"
        />

        <FeedbackSection v-if="showResult && selectedOptions.shortFeedBack" />

        <LongModal
            v-if="showModalFeedBack"
            :title="activity.story_name"
            :subtitle="currentArt.constellation"
            :content="selectedOptions.longFeedBack"
        />
    </main>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {
    ActivityModal,
    ActivityDescription,
    FeedbackSection,
    ActivityResultButtons as DragPoll,
    ActivityResultButtons as PollTwoOptions,
    ActivityResultButtons as PollManyOptions,
    ActivityResultButtons as Quiz,
    ActivityResultButtons as SliderManyOptions,
    ActivityResultButtons as SliderTwoOptions,
    DragCommonResult as DragCommon,
    DragAndMatchCompleted as DragAndMatch,
    Timer,
    LongModal,
} from "../../components";
import ZoomComponent from "../../components/ui/ZoomComponent.vue";

gsap.registerPlugin(ScrollToPlugin);
export default {
    /* eslint-disable vue/no-unused-components */
    components: {
        ZoomComponent,
        ActivityDescription,
        ActivityModal,
        DragAndMatch,
        DragCommon,
        DragPoll,
        FeedbackSection,
        PollManyOptions,
        PollTwoOptions,
        SliderManyOptions,
        SliderTwoOptions,
        Timer,
        Quiz,
        LongModal,
    },
    mounted() {
        this.$store.dispatch("GET_ACTIVITY_LOG");
        this.$store.dispatch("FETCH_SINGLE_ACTIVITY");
    },
    data() {
        return {
            componentKEY: null,
            selectedArtIdx: 1,
        };
    },

    computed: {
        ...mapState({
            activity: (state) => state.activitiesState.artActivity,
            currentArt: (state) => state.gameState.currentArt,
            showModalFeedBack: (state) => state.gameState.showModalFeedBack,
            showContinueButton: (state) =>
                state.activitiesState.showContinueButton,
            showResult: (state) => state.activitiesState.showResult,
            showZoomImg: (state) => state.activitiesState.showZoomImg,
            selectedOptions: (state) => state.activitiesState.selectedOptions,
        }),
    },
    // /api/v1/play/player-activity-log
    methods: {
        handleChange() {
            this.$store.dispatch("CURRENT_OPTION", this.selectedArtIdx - 1);
        },
    },
};
</script>

<style scoped>
main {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: var(--primary-black);
    padding: 2.0rem 1.0rem;
    overflow: scroll;
    overflow-x: hidden;
}
.timer {
    top: 0;
    z-index: 1;
    height: 20px;
    width: 100%;
    display: none;
    position: sticky;
    background: var(--primary-white);
}
.activity-wrapper {
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
}
.activity-image-container {
    width: 100%;
    height: 290px;
    position: relative;
    border-bottom: 0.5px solid var(--primary-white);
    border-top: 0.5px solid var(--primary-white);
    margin: 1rem 0;
}
.activity-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* TODO repeated */
.activity-btn {
    position: relative;
    min-height: 45px;
    text-align: start;
    justify-content: space-between;
    height: fit-content;
    border: 0.5px solid var(--primary-white);
    border-radius: 100px;
    overflow: hidden;
    background: transparent;
    color: var(--primary-white);
    font-size: 18px;
}
</style>
