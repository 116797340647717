<template>
    <svg
        width="40"
        height="40"
        viewBox="0 0 147 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M44.482 141.349C46.6078 141.349 48.3312 139.625 48.3312 137.5C48.3312 135.374 46.6078 133.65 44.482 133.65C42.3562 133.65 40.6328 135.374 40.6328 137.5C40.6328 139.625 42.3562 141.349 44.482 141.349Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M57.0133 82.6447C59.1391 82.6447 60.8624 80.9213 60.8624 78.7955C60.8624 76.6696 59.1391 74.9463 57.0133 74.9463C54.8874 74.9463 53.1641 76.6696 53.1641 78.7955C53.1641 80.9213 54.8874 82.6447 57.0133 82.6447Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M42.7065 56.9277C43.8173 56.9277 44.7177 56.0272 44.7177 54.9165C44.7177 53.8057 43.8173 52.9053 42.7065 52.9053C41.5958 52.9053 40.6953 53.8057 40.6953 54.9165C40.6953 56.0272 41.5958 56.9277 42.7065 56.9277Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M2.3081 4.96381C3.41886 4.96381 4.31927 4.06336 4.31927 2.95261C4.31927 1.84185 3.41886 0.941406 2.3081 0.941406C1.19735 0.941406 0.296875 1.84185 0.296875 2.95261C0.296875 4.06336 1.19735 4.96381 2.3081 4.96381Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M144.714 107.935C145.825 107.935 146.726 107.034 146.726 105.923C146.726 104.813 145.825 103.912 144.714 103.912C143.604 103.912 142.703 104.813 142.703 105.923C142.703 107.034 143.604 107.935 144.714 107.935Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M1.83594 2.47559L44.177 56.3642L57.6492 79.4594L44.177 137.197"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M57.6484 79.4561L145.217 106.4"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
