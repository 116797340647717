<template>
    <main>
        <div class="main-container">
            <div class="under-line"></div>
            <div class="contents-wrap">
                <img src="@/assets/img/discover/staedel-logo.png" alt="staedel logo" 
                    style="width:105px;height:228px;margin:auto"/>
                <h1>{{ $t('message.discover_headline') }}</h1>
                <div class="section">
                    <img src="@/assets/img/discover/more-to-discover-1.png" alt="image1" class="image" />
                    <div class="text">
                        {{ $t('message.discover_ticket_shop_desc') }}
                    </div>
                    <button class="btn-orange" @click="clickTicketBtn">
                        {{ $t('message.discover_ticket_shop_btn') }}
                    </button>
                </div>
                
                <div class="section">
                    <img src="@/assets/img/discover/more-to-discover-2.png" alt="image2" class="image" />
                    <div class="text">
                        {{ $t('message.discover_visit_plan_desc') }}
                    </div>
                    <button class="btn-orange" @click="clickVisitPlanBtn">
                        {{ $t('message.discover_visit_plan_btn') }}
                    </button>
                </div>
        
                <div class="section">
                    <img src="@/assets/img/discover/more-to-discover-3.png" alt="image3" class="image"/>
                    <div class="text">
                        {{ $t('message.discover_now_desc') }}
                    </div>
                    <button class="btn-orange" @click="clickNowBtn">
                        {{ $t('message.discover_now_btn') }}
                    </button>
                </div>

                <div class="section">
                    <img src="@/assets/img/discover/more-to-discover-4.png" alt="image4" class="image"/>
                    <div class="text">
                        {{ $t('message.discover_digital_offer_desc') }}
                    </div>
                    <button class="btn-orange" style="margin-bottom: 0px" @click="clickDigitalOfferBtn">
                        {{ $t('message.discover_digital_offer_btn') }}
                    </button>
                </div>

                <div class="line"></div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            en: true,
        };
    },

    mounted() {
        if (this.$i18n.locale == 'en') {
            this.en = true;
        }
        else {
            this.en = false;
        }
    },

    methods: {
        clickTicketBtn() {
            const link = this.en ? 'https://www.staedelmuseum.de/en/the-staedel':'https://www.staedelmuseum.de/de/das-staedel';
            window.open(link, '_blank');
        },

        clickVisitPlanBtn() {
            const link = this.en ? 'https://www.staedelmuseum.de/en/visit':'https://www.staedelmuseum.de/de/besuch';
            window.open(link, '_blank');
        },

        clickNowBtn() {
            const link = this.en ? 'https://www.staedelmuseum.de/en/exhibitions-programme':'https://www.staedelmuseum.de/de/ausstellungen-programm';
            window.open(link, '_blank');
        },

        clickDigitalOfferBtn() {
            const link = this.en ? 'https://www.staedelmuseum.de/en/digital':'https://www.staedelmuseum.de/de/digital';
            window.open(link, '_blank');
        },
    },
}
</script>

<style scoped>
main,
.contents-wrap {
    width: 100%;
}
.contents-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 94px 29px 100px 29px;
    margin-bottom: 100px;
}
h1 {
    margin-top: 50px;
    width: 249px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.5;
}
.section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    max-width: 500px;
    width: 235px;
}
.image {
    width: 100%;/*235px;*/
    /*height:187px;*/
    margin:auto;
}
.text {
    margin-top: 20px;
    font-size: 18px;
}
.btn-orange {
    background-color: var(--primary-orange);
    color: var(--primary-white);
    border: none;
    border-radius: 5px;
    /*padding: 5px 100px;*/
    padding: 5px 20px;
    margin-top: 30px;
    width: 100%;
    height: 60px;
    font-size: 15px;
    margin-bottom: 60px;
}
.line {
    width: 40%;
    height: 4px;
    background-color: #111111;
    border-width: 1px;
    border-color: #111111;
    border-radius: 2px;
    /*border-width: 1px;*/
    border-style: solid;
    margin-top: 30px;
    margin-bottom: 100px;
}
</style>