<template>
    <main :key="componentKEY" :class="componentKEY">
        <ActivityDescription v-if="activity.story_name" />
        <Timer v-if="!showResult && !timeOutModal && showTimer" />
        <ActivityModal
            v-if="timeOutModal"
            :modalTitle="modalTitle"
            @closeTimeOutModal="closeModal"
        />
        <ZoomComponent />
        <component
            v-if="activity.name"
            v-bind="activity"
            :is="activity.name"
            :activityOptions="activityOptions"
            :key="activity.name + componentKEY"
            @updateVotes="updateVotes"
        />
        <FeedbackSection v-if="showResult" />
        <LongModal
            v-if="showModalFeedBack"
            :title="activity.story_name"
            subtitle=""
            :content="selectedOptions.longFeedBack"
        />
    </main>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import {
    ActivityModal,
    ActivityDescription,
    FeedbackSection,
    // activities
    QuizActivity as Quiz,
    PollTwoOptions,
    // PollManyOptions as Quiz,
    DragPoll,
    PollManyOptions,
    SlideManyOptions as SliderManyOptions,
    SlideTwoOptions as SliderTwoOptions,
    DragCommon,
    DragAndMatch,
    // end of  activities
    Timer,
    LongModal,
} from "../../components";

import ZoomComponent from "../../components/ui/ZoomComponent.vue";

gsap.registerPlugin(ScrollToPlugin);
export default {
    /* eslint-disable vue/no-unused-components */
    components: {
        ZoomComponent,
        ActivityDescription,
        ActivityModal,
        FeedbackSection,
        DragAndMatch,
        DragCommon,
        DragPoll,
        PollManyOptions,
        PollTwoOptions,
        Quiz,
        SliderManyOptions,
        SliderTwoOptions,
        Timer,
        LongModal,
    },
    mounted() {
        this.$store.dispatch("FETCH_SINGLE_ACTIVITY");
    },

    data() {
        return {
            componentKEY: "",
            userSelection: null,
            remainingTime: 0,
        };
    },

    computed: {
        ...mapState({
            activity: (state) => state.activitiesState.artActivity,
            activityOptions: (state) => state.activitiesState.activityOptions,
            showModalFeedBack: (state) => state.gameState.showModalFeedBack,
            showContinueButton: (state) =>
                state.activitiesState.showContinueButton,
            showResult: (state) => state.activitiesState.showResult,
            showTimer: (state) => state.activitiesState.showTimer,
            selectedOptions: (state) => state.activitiesState.selectedOptions,
            timeOutModal: (state) => state.activitiesState.timeOutModal,
            modalTitle(state) {
                let text = "";

                if (state.activitiesState.artActivity.name === "DragAndMatch") {
                    text = this.$t("message.fail_popup");
                } else if (state.activitiesState.artActivity.name === "Quiz") {
                    if (
                        this.userSelection &&
                        this.userSelection.rightOption == false
                    ) {
                        text = this.$t("message.answer_popup");
                    } else {
                        text = this.$t("message.time_out");
                    }
                }
                return text;
            },
        }),
    },

    methods: {
        closeModal() {
            this.$store.dispatch("TOGGLE_TIMEOUT_MODAL", false);

            this.componentKEY = this.activity.name + this.remainingTime;
            ++this.remainingTime;
            this.userSelection = null;
        },
        updateVotes({ id, totalVotes }) {
            this.userSelection = this.activity.options.find(
                (option) => option.id === id
            );

            let updatedVotes = this.activity.options.map((item) => {
                return { option: item.option, votes: item.votes };
            });

            this.$store.dispatch("SHOW_RESULT", this.userSelection);

            if (
                this.activity.name === "Quiz" &&
                !this.userSelection.rightOption
            ) {
                this.$store.dispatch("FAIL_QUIZ_RIGHT_ANSWER", {
                    totalVotes: totalVotes,
                    options: updatedVotes,
                });
                this.$store.dispatch("TOGGLE_TIMEOUT_MODAL", true);
                return;
            }
            this.$store.dispatch("SEND_ACTIVITY_LOG");
            this.$store.dispatch("BACKEND_UPDATE_VOTES", {
                votes_data: updatedVotes,
                story_name: this.activity.story_name,
                totalVotes: totalVotes,
            });
        },
    },
};
</script>

<style scoped>
main {
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--primary-black);
    padding: 2.0rem 1.0rem;
    overflow: scroll;
    overflow-x: hidden;
}
.timer {
    top: 0;
    z-index: 1;
    height: 20px;
    width: 100%;
    display: none;
    position: sticky;
    background: var(--primary-white);
}
.activity-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

/* TODO repeated */
.activity-btn {
    position: relative;
    min-height: 45px;
    text-align: start;
    justify-content: space-between;
    height: fit-content;
    border: 0.5px solid var(--primary-white);
    border-radius: 100px;
    overflow: hidden;
    background: transparent;
    color: var(--primary-white);
    font-size: 18px;
}
</style>
