<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 156 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M104.584 128.239C106.709 128.239 108.433 126.516 108.433 124.39C108.433 122.264 106.709 120.541 104.584 120.541C102.458 120.541 100.734 122.264 100.734 124.39C100.734 126.516 102.458 128.239 104.584 128.239Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M151.771 55.1036C153.897 55.1036 155.62 53.3803 155.62 51.2545C155.62 49.1286 153.897 47.4053 151.771 47.4053C149.645 47.4053 147.922 49.1286 147.922 51.2545C147.922 53.3803 149.645 55.1036 151.771 55.1036Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M19.9742 7.92884C22.1 7.92884 23.8234 6.2055 23.8234 4.07966C23.8234 1.95381 22.1 0.230469 19.9742 0.230469C17.8483 0.230469 16.125 1.95381 16.125 4.07966C16.125 6.2055 17.8483 7.92884 19.9742 7.92884Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M9.34138 60.8331C11.4672 60.8331 13.1906 59.1098 13.1906 56.984C13.1906 54.8581 11.4672 53.1348 9.34138 53.1348C7.21553 53.1348 5.49219 54.8581 5.49219 56.984C5.49219 59.1098 7.21553 60.8331 9.34138 60.8331Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M110.38 102.249C112.506 102.249 114.23 100.526 114.23 98.4C114.23 96.2741 112.506 94.5508 110.38 94.5508C108.255 94.5508 106.531 96.2741 106.531 98.4C106.531 100.526 108.255 102.249 110.38 102.249Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M131.56 57.0206C133.686 57.0206 135.409 55.2973 135.409 53.1715C135.409 51.0456 133.686 49.3223 131.56 49.3223C129.434 49.3223 127.711 51.0456 127.711 53.1715C127.711 55.2973 129.434 57.0206 131.56 57.0206Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M2.79245 38.0429C3.9032 38.0429 4.80364 37.1425 4.80364 36.0317C4.80364 34.921 3.9032 34.0205 2.79245 34.0205C1.68169 34.0205 0.78125 34.921 0.78125 36.0317C0.78125 37.1425 1.68169 38.0429 2.79245 38.0429Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M50.9174 16.8671C52.0282 16.8671 52.9286 15.9667 52.9286 14.8559C52.9286 13.7452 52.0282 12.8447 50.9174 12.8447C49.8067 12.8447 48.9062 13.7452 48.9062 14.8559C48.9062 15.9667 49.8067 16.8671 50.9174 16.8671Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M73.0503 30.3349C74.161 30.3349 75.0615 29.4345 75.0615 28.3237C75.0615 27.2129 74.161 26.3125 73.0503 26.3125C71.9395 26.3125 71.0391 27.2129 71.0391 28.3237C71.0391 29.4345 71.9395 30.3349 73.0503 30.3349Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M99.9956 42.8496C101.106 42.8496 102.007 41.9491 102.007 40.8383C102.007 39.7276 101.106 38.8271 99.9956 38.8271C98.8848 38.8271 97.9844 39.7276 97.9844 40.8383C97.9844 41.9491 98.8848 42.8496 99.9956 42.8496Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M41.3003 73.6484C42.411 73.6484 43.3115 72.7479 43.3115 71.6372C43.3115 70.5264 42.411 69.626 41.3003 69.626C40.1895 69.626 39.2891 70.5264 39.2891 71.6372C39.2891 72.7479 40.1895 73.6484 41.3003 73.6484Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M70.1597 79.4189C71.2704 79.4189 72.1708 78.5184 72.1708 77.4077C72.1708 76.2969 71.2704 75.3965 70.1597 75.3965C69.0489 75.3965 68.1484 76.2969 68.1484 77.4077C68.1484 78.5184 69.0489 79.4189 70.1597 79.4189Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M125.972 79.4189C127.083 79.4189 127.983 78.5184 127.983 77.4077C127.983 76.2969 127.083 75.3965 125.972 75.3965C124.861 75.3965 123.961 76.2969 123.961 77.4077C123.961 78.5184 124.861 79.4189 125.972 79.4189Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M110.098 99.0623L69.6811 76.9295L40.8122 71.1557L9.05639 56.7213L2.32031 36.513L19.6417 3.79492L50.4352 14.3802L72.568 27.8523L100.475 41.3245L131.268 52.8721L126.457 76.9295L110.098 99.0623Z"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M104.328 125.038L110.102 99.0557"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M150.519 51.9023L131.273 52.8646"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
