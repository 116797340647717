<template>
    <main class="test-container" :key="componentKEY" :class="componentKEY">
        <!-- not using props check -->
        <ActivityDescription v-if="activity.story_name" />
        <Timer v-if="!showResult && !timeOutModal && showTimer" />
        <ActivityModal
            v-if="timeOutModal"
            :modalTitle="
                userSelection && userSelection.rightOption == false
                    ? modalTitle
                    : 'Up, time is up!'
            "
            @closeTimeOutModal="closeModal"
        />
        <ZoomComponent />
        <component
            v-if="activity.name"
            v-bind="activity"
            :is="activity.name"
            :activityOptions="activityOptions"
            :key="activity.name + componentKEY"
            @updateVotes="updateVotes"
        />
        <FeedbackSection v-if="showResult" />

        <LongModal
            v-if="showModalFeedBack"
            :title="activity.activity_name"
            subtitle=""
            :content="selectedOptions.longFeedBack"
        />
    </main>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import {
    ActivityModal,
    ActivityDescription,
    FeedbackSection,
    // activities
    QuizActivity as Quiz,
    PollTwoOptions,
    // PollManyOptions as Quiz,
    DragPoll,
    PollManyOptions,
    SlideManyOptions as SliderManyOptions,
    SlideTwoOptions as SliderTwoOptions,
    DragCommon,
    DragAndMatch,
    // end of  activities
    Timer,
    LongModal,
} from "../../components";
import resultButtonsAnimation from "../../helperFunctions/animations/activitiesAnimations";
import ZoomComponent from "../../components/ui/ZoomComponent.vue";

gsap.registerPlugin(ScrollToPlugin);
export default {
    /* eslint-disable vue/no-unused-components */
    components: {
        ZoomComponent,
        ActivityDescription,
        ActivityModal,
        FeedbackSection,
        DragAndMatch,
        DragCommon,
        DragPoll,
        PollManyOptions,
        PollTwoOptions,
        Quiz,
        SliderManyOptions,
        SliderTwoOptions,
        Timer,
        LongModal,
    },
    mounted() {
        this.$store.dispatch("FETCH_SINGLE_ACTIVITY_DEV");
    },

    data() {
        return {
            componentKEY: "",
            userSelection: null,
            remainingTime: 0,
        };
    },

    computed: {
        ...mapState({
            activity: (state) => state.activitiesState.artActivity,
            activityOptions: (state) => state.activitiesState.activityOptions,
            showModalFeedBack: (state) => state.gameState.showModalFeedBack,
            showContinueButton: (state) =>
                state.activitiesState.showContinueButton,
            showResult: (state) => state.activitiesState.showResult,
            showTimer: (state) => state.activitiesState.showTimer,
            selectedOptions: (state) => state.activitiesState.selectedOptions,
            timeOutModal: (state) => state.activitiesState.timeOutModal,
            modalTitle(state) {
                let text = "";
                if (state.activitiesState.artActivity.name === "DragAndMatch") {
                    text = "out of tries";
                } else if (state.activitiesState.artActivity.name === "Quiz") {
                    text = "Ups! That was not the right answer.";
                }

                return text;
            },
        }),
    },

    methods: {
        closeModal() {
            this.$store.dispatch("TOGGLE_TIMEOUT_MODAL", false);
            if (this.activity.name === "DragAndMatch") {
                this.componentKEY = this.activity + this.remainingTime;
                ++this.remainingTime;
            }
        },
        updateVotes({ id, totalVotes }) {
            let userSelection = this.activity.options.find(
                (option) => option.id === id
            );
            this.$store.dispatch("SHOW_RESULT", userSelection);
            resultButtonsAnimation(this.activity.options, totalVotes);
        },
    },
};
</script>

<style scoped>
main {
    position: relative;
    max-width: 390px;
    margin: 0 auto;
}
.timer {
    top: 0;
    z-index: 1;
    height: 20px;
    width: 100%;
    display: none;
    position: sticky;
    background: var(--primary-white);
}
.activity-wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
}
.activity-image-container {
    width: 100%;
    height: 290px;
    position: relative;
    border-bottom: 0.5px solid var(--primary-white);
    border-top: 0.5px solid var(--primary-white);
    margin: 1rem 0;
}
.activity-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* TODO repeated */
.activity-btn {
    position: relative;
    min-height: 45px;
    text-align: start;
    justify-content: space-between;
    height: fit-content;
    border: 0.5px solid var(--primary-white);
    border-radius: 100px;
    overflow: hidden;
    background: transparent;
    color: var(--primary-white);
    font-size: 18px;
}
</style>
