<template>
    <div class="story-wrapper">
        <StoryTop :story="story" :showImg="true" />
        <label>Keyword</label>
        <input v-model="tempKeyword" @keyup.ctrl="handleNewKeywords" />
        <div class="pills-container">
            <div
                @click="deleteKeywords(keyword)"
                class="single-pill"
                v-for="keyword in story.keywords"
                :key="keyword"
            >
                {{ keyword }}
            </div>
        </div>
        <div>
            <label>fake circles</label>
            <button style="margin-left: 20px" @click="handleShowCircle('fake')">
                add fake circles
            </button>
        </div>
        <input
            :value="parsePosition(story.fakeCircles)"
            @change="addFakeCircles"
        />
        <div
            v-for="(item, index) in story.rightKeywords"
            :key="index"
            class="options-wrapper"
        >
            <div class="option-title">
                <div class="option-title">
                    <h3>Options</h3>
                    <button type="button" @click="removeOption(index)">
                        remove
                    </button>
                    <button type="button" @click="handleShowCircle">
                        set circle
                    </button>
                </div>
            </div>
            <input type="hidden" v-model="item.id" />
            <label>Option</label>
            <input v-model="item.keyword" />
            <label>position</label>
            <p class="error" v-if="message">{{ message }}</p>
            <input
                @input="onChangeValue($event, item)"
                :value="parsePosition(item.position)"
            />
            <label>short feedback</label>
            <textarea v-model="item.shortFeedBack" />
            <label>long feedback</label>
            <textarea v-model="item.longFeedBack" />
        </div>

        <SetStoryCircles
            :dragImage="dragImage"
            @keyWordPositionCopied="showSetCircle = false"
            v-if="showSetCircle"
        />

        <SetStoryCirclesFake
            :dragImage="dragImage"
            @keyWordPositionCopied="showSetFakeCircles = false"
            v-if="showSetFakeCircles"
        />

        <button type="button" @click="addOption" class="btn">+</button>
    </div>
</template>

<script>
import SetStoryCirclesFake from "@/components/admin/SetStoryCirclesFake.vue";

import SetStoryCircles from "@/components/admin/SetStoryCircles.vue";
import StoryTop from "../activityGames/StoryTop.vue";
export default {
    components: {
        StoryTop,
        SetStoryCirclesFake,
        SetStoryCircles,
    },
    props: {
        story: {
            type: Object,
        },
    },

    computed: {
        parsePosition() {
            return (item) => JSON.stringify(item);
        },
    },

    data() {
        return {
            newRow: {
                key: "",
            },
            showSetCircle: false,
            showSetFakeCircles: false,
            fakeCircles: "",
            name: "name",
            title: "title",
            img: "img",

            id: "id",
            keyword: "option",
            position: {
                top: 20,
                left: 20,
            },
            shortFeedBack: "shortFeedBack",
            longFeedBack: "longFeedBack",
            message: "",
            tempKeyword: "",
        };
    },

    methods: {
        onChangeValue(e, item) {
            let newPosition;
            try {
                newPosition = JSON.parse(e.target.value);
                let tempStories = this.story.rightKeywords.map((option) => {
                    if (option.keyword === item.keyword) {
                        return { ...option, position: newPosition };
                    }
                    return option;
                });
                this.message = "";
                this.story.rightKeywords = tempStories;
            } catch (error) {
                this.message =
                    "There is an error with position, please try again.";
            }
        },
        addFakeCircles(e) {
            try {
                this.story.fakeCircles = JSON.parse(e.target.value);
            } catch (error) {
                this.story.fakeCircles = [];
            }
        },
        addOption() {
            let newOptions = {
                id: this.story.rightKeywords
                    ? this.story.rightKeywords.length + 1
                    : 0,
                keyword: "option",
                position: {
                    top: 20,
                    left: 20,
                },
                shortFeedBack: "shortFeedBack",
                longFeedBack: "longFeedBack",
            };
            this.story.rightKeywords.push(newOptions);
        },
        removeOption(index) {
            this.story.rightKeywords = this.story.rightKeywords.filter(
                (option, idx) => index !== idx
            );
        },
        handleShowCircle(value) {
            this.dragImage = this.story.img;
            if (value === "fake") {
                this.showSetFakeCircles = true;
            } else {
                this.showSetCircle = true;
            }
        },
        handleNewKeywords(e) {
            if (!this.story.keywords) this.story.keywords = [];
            if (e.key === "," && this.tempKeyword) {
                if (!this.story.keywords.includes(this.tempKeyword)) {
                    this.story.keywords.push(this.tempKeyword);
                    this.tempKeyword = "";
                }
            }
        },
        deleteKeywords(keyword) {
            this.story.keywords = this.story.keywords.filter(
                (comp) => comp != keyword
            );
        },
    },
};
</script>

<style scoped>
.option-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0;
}
.story-wrapper,
.options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
input {
    display: flex;
    height: 30px;
    width: 100%;
    padding: 0.3rem;
}

.single-option {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn {
    width: fit-content;
}

textarea {
    font-family: initial;
    font-size: inherit;
    width: 100%;
    min-height: 200px;
    padding: 0.3rem;
}
.options-wrapper {
    display: flex;
    flex-direction: column;
}

.error {
    font-size: 1.5rem;
    color: yellowgreen;
}

.pills-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 0.5rem;
}
.single-pill {
    background: var(--primary-gray);
    padding: 0.3rem;
    border-radius: 5px;
    cursor: pointer;
}
.single-pill:hover {
    opacity: 0.9;
}
</style>
