<template>
    <section class="home-overlay">
        <div v-if="$i18n.locale === 'de'" class="text-wrapper">
            <h3>
                Antworte auf einen <br />
                Hilferuf aus der Zukunft!
            </h3>
            <h3>
                Nutze die Sternbilder zur <br />
                Navigation im Städel Universum.
            </h3>
            <h3>
                Sammle Geschichten und Erkenntnisse,<br />
                die in den Kunstwerken im Städel Museum stecken!
            </h3>
            <h3>
                Kannst du alle Aufgaben lösen <br />
                und die Zukunft sichern?
            </h3>
        </div>
        <div v-else class="text-wrapper">
            <h3>
                In Stadel Universe, <br />
                Find the connections! <br />
            </h3>
            <h3>
                Find the Solutions <br />
                for the Future! <br />
            </h3>
            <h3>
                Can you find all <br />
                the connections? <br />
            </h3>
            <h3>
                Can you solve the mysteries <br />
                of the Future?
            </h3>
        </div>
        <button @click="$emit('overlayClick')" class="btn font-semibold">
            {{ $t("message.continue") }}
        </button>
        <img src="../../assets/img/staedel-front.png" alt="" />
    </section>
</template>

<script>
export default {};
</script>

<style scoped>
.home-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.text-wrapper {
    width: 80%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
}
h3 {
    font-family: var(--primary-font-light);
    color: var(--primary-white);
    text-align: center;
    font-size: 1.25rem;
}

img {
    width: 100%;
    z-index: 100;
}

.btn {
    width: 80%;
}
</style>
