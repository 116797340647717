import gsap from "gsap";
import axios from "axios";

export const circleSetup = (circle, items, percentageValue) => {
    let mainCicle = document.querySelector(circle);
    let circleItem = gsap.utils.toArray(items);

    let radius = mainCicle.offsetWidth / 2;
    let center = mainCicle.offsetWidth / 2;
    let total = circleItem.length;
    let slice = (2 * Math.PI) / total;

    circleItem.forEach((item, i) => {
        let angle = i * slice;

        let x = center + radius * Math.sin(angle);
        let y = center - radius * Math.cos(angle);

        gsap.set(item, {
            rotation: angle + "_rad",
            xPercent: percentageValue,
            yPercent: percentageValue,
            x: x,
            y: y,
        });
    });
};

export const orderSlides = (array, activeIndex) => {
    array.forEach((element, idx) => {
        element.position = "nextSlide";
        if (idx === activeIndex) {
            element.position = "activeSlide";
        } else if (
            idx === activeIndex - 1 ||
            (activeIndex === 0 && idx === array.length - 1)
        ) {
            element.position = "lastSlide";
        }
    });
};

export const windowHeight = () => {
    setTimeout(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, 1000);
};

export const sendLog = async (log) => {
    let player_id = localStorage.getItem("player__id");
    let game_id = localStorage.getItem("game__id");
    if (!player_id) {
        throw new Error("No used player_id");
    }
    try {
        const { data } = await axios.post("play/logs", {
            log,
            player_id,
            game_id,
        });

        console.log(data.msg);
    } catch (error) {
        console.log(error);
    }
};
export const sendProgressLog = async (payload) => {
    let player_id = localStorage.getItem("player__id");
    let game_id = localStorage.getItem("game__id");

    if (!player_id) {
        throw new Error("No user uuii");
    }
    if (!game_id) {
        throw new Error("No game_id");
    }
    try {
        const res = await axios.post(
            "play/save-log",
            {
                ...payload,
                player_id,
                game_id,
            },
            {
                headers: {
                    "Accept-Language": payload.lang,
                },
            }
        );
        console.log(res.data.msg);
        return res.status;
    } catch (error) {
        window.alert(
            "Something when wrong, check you internet connection and try again"
        );
        console.log(error);
    }
};
