<template>
    <div id="app" v-if="isGameReady">
        <Navbar />
        <router-view />
    </div>
    <div v-else>
        <router-view />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { Navbar } from "./components/";
import { windowHeight } from "./helperFunctions/utils";
export default {
    components: {
        Navbar,
    },
    beforeCreate() {
        this.$store.dispatch("CHECK_USER");
    },
    mounted() {
        // this.$store.dispatch("GET_LOCAL_STORE");
    },
    created() {
        window.addEventListener("resize", this.resize);
    },

    computed: {
        ...mapState({
            isGameReady: (state) => state.gameState.isGameReady,
        }),
    },
    data() {
        return {
            showNavBar: true,
        };
    },

    methods: {
        resize() {
            windowHeight();
        },
    },
};
</script>

<style>
/* #app {

  height: 80vh;
  margin: 0 auto;
} */
</style>
