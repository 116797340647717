<template>
    <section class="activity-desc">
        <h3 v-html="activity.title" />
    </section>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            activity: (state) => state.activitiesState.artActivity, // TODO CHECK
        }),
    },
};
</script>

<style scoped>
.activity-desc {
    padding: 1rem;
}
.activity-desc h3 {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    font-style: normal;
    line-height: normal;
    word-break: keep-all;
}
</style>
