import i18n from "../../i18n";
import { getUniqueValues } from "../../helperFunctions/getUniqueValues";
import gameApi from "../../api/gameApi";

const adminState = {
    state: () => ({
        artList: [],
        filteredArtList: [],
        groups: [],
        constellations: [],

        filters: {
            group: "all",
            constellation: "all",
            name: "",
        },
    }),
    getters: {},

    mutations: {
        GET_ART_LIST: (state, payload) => {
            state.artList = payload.artList;
            state.filteredArtList = payload.filteredArtList;

            state.groups = getUniqueValues(payload.artList, "group");
            state.constellations = getUniqueValues(
                payload.artList,
                "constellation"
            );
        },
        SET_FILTERS: (state, payload) => {
            state.filteredArtList = payload;
        },
    },

    actions: {
        GET_ART_LIST: async (context) => {
            localStorage.setItem("locale", i18n.locale);
            const res = await gameApi("game/art/group-details/");
            let tempList = [];

            for (const [key, value] of Object.entries(res.data)) {
                console.log(key);
                tempList.push(value);
            }
            let artList = tempList.flat();
            let filteredArtList = tempList.flat();

            let payload = {
                artList,
                filteredArtList,
            };

            context.commit("GET_ART_LIST", payload);
            context.dispatch("SET_FILTERS");
        },
        SET_FILTERS: (context) => {
            let tempList = [...context.state.artList];
            const { group, constellation, name } = context.state.filters;

            if (name !== "") {
                tempList = tempList.filter(
                    (item) =>
                        item.title
                            .toLowerCase()
                            .startsWith(name.toLowerCase()) ||
                        item.id.toLowerCase().startsWith(name.toLowerCase())
                );
            }
            if (group !== "all") {
                tempList = tempList.filter((item) => item.group == group);
            }
            if (constellation !== "all") {
                tempList = tempList.filter(
                    (item) => item.constellation == constellation
                );
            }
            context.commit("SET_FILTERS", tempList);
        },
    },
};

export default adminState;
