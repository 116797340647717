<template>
    <article class="story-wrapper">
        <div @click="$emit('handleStoryClick')">
            <h2 v-if="item.story_name">
                {{ item.story_name }}
            </h2>
            <h2 v-else>Click to start editing</h2>
        </div>
        <div class="activity-top">
            <h2>{{ item.name }}</h2>
            <button type="button" @click="$emit('removeStory')">Delete</button>
            <button type="button" @click="$emit('testStory')">Test</button>
        </div>
        <component :is="item.name" :story="item" />
    </article>
</template>

<script>
import DragPollAdmin from "../../components/admin/activityGames/DragPollAdmin.vue";

import EditDragCommon from "../../components/admin/activityEdit/EditDragCommon.vue";
import EditMultiOptions from "../../components/admin/activityEdit/EditMultiOptions.vue";
import EditQuiz from "../../components/admin/activityEdit/EditQuiz.vue";
import EditDragAndMatch from "../../components/admin/activityEdit/EditDragAndMatch.vue";
export default {
    props: ["item"],
    components: {
        PollTwoOptions: DragPollAdmin,
        // done
        SliderManyOptions: EditMultiOptions,
        DragPoll: DragPollAdmin,
        DragCommon: EditDragCommon,
        PollManyOptions: EditMultiOptions,
        SliderTwoOptions: EditMultiOptions,
        Quiz: EditQuiz,
        DragAndMatch: EditDragAndMatch,
    },
    emits: ["handleStoryClick"],
};
</script>

<style scoped>
.activity-top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    gap: 1rem;
}
</style>
