<template>
    <div>
        <!-- <h5>{{ $t("message.drag_bar_left_or_right_to_compare") }}</h5> -->
        <div class="drag-area">
            <h2 class="drag-label">{{ $t("message.drag_right_words") }}</h2>
        </div>
        <div class="words-container">
            <p
                v-for="(word, idx) in keywords"
                :key="idx"
                :class="word.class"
                class="single-word"
                :id="idx + 1"
            >
                {{ word }}
            </p>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);
export default {
    props: {
        keywords: {
            type: Array,
        },
        rightKeywords: {
            type: Array,
        },
        competence: {
            type: Array,
        },
    },
    mounted() {
        this.dragWord();
    },
    beforeDestroy() {
        this.draggableInstance.forEach((element) => {
            element[0].kill();
        });
    },
    data() {
        return { draggableInstance: [] };
    },
    methods: {
        dragWord() {
            let self = this;
            let singleWord = gsap.utils.toArray(".single-word");
            singleWord.forEach((word) => {
                let allDraggable = Draggable.create(word, {
                    onDrag: function () {
                        if (this.hitTest(".drag-area", "80%")) {
                            word.classList.add("matched");
                        } else {
                            word.classList.remove("matched", "scored");
                        }
                    },
                    onRelease: function () {
                        gsap.set(".drag-label", { display: "none" });

                        if (!word.classList.contains("matched")) {
                            let tl = gsap.timeline();
                            tl.to(this.target, {
                                x: 0,
                                y: 0,
                            });
                        } else if (!word.classList.contains("scored")) {
                            let isRightKeyword = self.rightKeywords.find(
                                (k) => k.id == word.id
                            );
                            if (isRightKeyword) {
                                word.classList.add("scored");
                                let correctArray =
                                    gsap.utils.toArray(".scored");

                                if (
                                    self.rightKeywords.length ===
                                    correctArray.length
                                ) {
                                    // TODO currently all DragAndMatch have one feedback but it my change
                                    let selectedOptions = self.rightKeywords[0];
                                    self.$store.dispatch(
                                        "SHOW_RESULT",
                                        selectedOptions
                                    );
                                    self.$store.dispatch("SEND_ACTIVITY_LOG");
                                    self.showResult = true;

                                    let tl = gsap.timeline({});
                                    tl.from(".result-word", {
                                        opacity: 1,
                                        yPercent: 200,
                                        stagger: true,
                                    }).to(window, {
                                        scrollTo: ".feedback",
                                    });

                                    self.draggableInstance.forEach(
                                        (element) => {
                                            element[0].kill();
                                        }
                                    );
                                }
                            } else {
                                setTimeout(() => {
                                    let wordsMatch = [];
                                    let tl = gsap.timeline();
                                    tl.to(this.target, {
                                        x: 0,
                                        y: 0,
                                        onComplete: () => {
                                            word.classList.remove("matched");
                                            wordsMatch =
                                                gsap.utils.toArray(".matched");
                                        },
                                    });

                                    if (wordsMatch.length === 0) {
                                        gsap.set(".drag-label", {
                                            display: "block",
                                        });
                                    }
                                }, 1000);
                            }
                        }
                    },
                });
                this.draggableInstance.push(allDraggable);
            });
        },
    },
};
</script>

<style scoped>
.drag-area {
    width: 90%;
    height: 120px;
    position: relative;
    border-radius: 3px;
    border: 1px solid var(--primary-white);
    margin: 2rem auto;
}
.drag-area h2 {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
}

.words-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.single-word,
.result-word {
    background: var(--primary-white);
    border-radius: 5px;
    padding: 8px 12px;
    color: var(--primary-black);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
}
.matched {
    background-color: white;
}
</style>
