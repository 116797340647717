<template>
    <div>
        <ZoomEffect />
    </div>
</template>

<script>
import { ZoomEffect } from "../components";
export default {
    components: {
        ZoomEffect,
    },
};
</script>

<style lang="scss" scoped></style>
