import router from "../../router";
import resultButtonsAnimation from "../../helperFunctions/animations/activitiesAnimations";
import gameApi from "../../api/gameApi";

const activitiesState = {
    state: () => ({
        artActivity: {},
        activityOptions: [],
        selectedOptions: {},
        showResult: false,
        showZoomImg: false,
        showContinueButton: true,
        optionIndex: 0,
        timeOutModal: false,
        showTimer: false,
        failsQuiz: false,
    }),
    mutations: {
        CLEAR_STORE: (state) => {
            const initial = activitiesState.state();
            Object.keys(initial).forEach((key) => {
                state[key] = initial[key];
            });
        },

        SET_ART_ACTIVITIES_ADMIN_TESTING: (state, payload) => {
            state.artActivity = payload;

            if (state.artActivity.name === "DragAndMatch") {
                state.showContinueButton = false;
                return;
            }
            if (
                state.artActivity.name === "DragPoll" ||
                state.artActivity.name === "DragCommon"
            )
                return;

            if (state.artActivity.name === "Quiz") {
                state.showTimer = true;
            }
        },

        FAIL_QUIZ_RIGHT_ANSWER: (state, payload) => {
            state.failsQuiz = true;
            resultButtonsAnimation(payload.options, payload.totalVotes);
        },

        BACKEND_UPDATE_VOTES: async (state, payload) => {
            resultButtonsAnimation(state.artActivity.options, payload);
        },
        RESET_SELECTED: (state) => {
            state.selectedOptions = {};
            state.showResult = false;
        },
        GET_ACTIVITY_LOG: (state, payload) => {
            state.selectedOptions = payload;
        },
        SHOW_RESULT: (state, payload) => {
            state.showResult = true;
            state.selectedOptions = payload;
        },
        DRAG_AND_MATCH_COMPLETED: (state) => {
            state.showContinueButton = true;
        },
        CURRENT_OPTION: (state, payload) => {
            state.optionIndex = payload;
        },
        TOGGLE_ZOOM_COMPONENT: (state) => {
            state.showZoomImg = !state.showZoomImg;
        },
        TOGGLE_TIMEOUT_MODAL: (state, payload) => {
            state.timeOutModal = payload;
            state.showResult = false;
            state.failsQuiz = false;
        },
        HIDE_TIMER: (state) => {
            state.showTimer = false;
        },

        FETCH_SINGLE_ACTIVITY: (state, payload) => {
            const { artActivity, answerData } = payload;

            state.artActivity = artActivity.activity_data;
            state.artActivity.id = artActivity.id;
            localStorage.setItem("activity_id", artActivity.id);

            if (state.artActivity.name === "DragAndMatch") {
                state.showContinueButton = false;
                return;
            }
            if (state.artActivity.name === "DragCommon") {
                return;
            }
            if (state.artActivity.name === "Quiz") {
                state.showTimer = true;
            }

            if (answerData && answerData.length == 0) {
                state.artActivity.options.forEach((element) => {
                    element.votes = 0;
                });
            } else {
                state.artActivity.options = state.artActivity.options.map(
                    (item) => {
                        const votes = answerData.find(
                            (vote) => vote.option === item.option
                        );
                        return votes
                            ? { ...item, votes: votes.votes }
                            : { ...item, votes: 0 };
                    }
                );
            }
        },
    },
    actions: {
        HIDE_TIMER: (context) => {
            context.commit("HIDE_TIMER");
        },
        CLEAR_STORE: (context) => {
            context.commit("CLEAR_STORE");
        },
        SET_ART_ACTIVITIES_ADMIN_TESTING: (context, payload) => {
            context.commit("SET_ART_ACTIVITIES_ADMIN_TESTING", payload);
        },
        FETCH_SINGLE_ACTIVITY: async (context) => {
            try {
                const res = await gameApi.post("game/get-single-activity", {
                    activity_name: router.currentRoute.params.storyName,
                    inventory_number:
                        router.currentRoute.params.inventoryNumber,
                });

                const data = res.data;

                context.commit("FETCH_SINGLE_ACTIVITY", {
                    artActivity: data.activity,
                    answerData: data.votes,
                });
            } catch (error) {
                console.log(error);
            }
        },
        FETCH_SINGLE_ACTIVITY_DEV: async (context) => {
            try {
                const res = await gameApi.post("game/activity-dev", {
                    activity_name: router.currentRoute.params.storyName,
                    lang: router.currentRoute.params.lang,
                });

                const data = res.data;

                context.commit("FETCH_SINGLE_ACTIVITY", {
                    artActivity: data.activity,
                    answerData: data.votes,
                });
            } catch (error) {
                console.log(error);
            }
        },

        SEND_ACTIVITY_LOG: async (context) => {
            try {
                const res = await gameApi.post("play/create-activity-log", {
                    activity_name: context.state.artActivity.story_name,
                    activity_data: JSON.stringify(
                        context.state.selectedOptions
                    ),
                });

                console.log(res.data.msg);
            } catch (error) {
                console.log(error);
            }
        },
        GET_ACTIVITY_LOG: async (context) => {
            try {
                const res = await gameApi.post("play/player-activity-log", {
                    activity_name: router.currentRoute.params.storyName,
                });

                const payload = res.data.activity_data;
                context.commit("GET_ACTIVITY_LOG", payload);
            } catch (error) {
                console.log(error.response.data.msg);
            }
        },
        FAIL_QUIZ_RIGHT_ANSWER: (context, payload) => {
            context.commit("FAIL_QUIZ_RIGHT_ANSWER", payload);
        },
        BACKEND_UPDATE_VOTES: async (context, payload) => {
            const { votes_data, story_name, totalVotes } = payload;

            try {
                await gameApi.post("game/update-create-votes", {
                    story_name,
                    votes_data,
                });
                context.commit("BACKEND_UPDATE_VOTES", totalVotes);
            } catch (error) {
                console.log(error);
            }
        },
        CURRENT_OPTION: (context, payload) => {
            context.commit("CURRENT_OPTION", payload);
        },
        RESET_SELECTED: (context) => {
            context.commit("RESET_SELECTED");
        },
        DRAG_AND_MATCH_COMPLETED: (context) => {
            context.commit("DRAG_AND_MATCH_COMPLETED");
        },
        SHOW_RESULT: (context, payload) => {
            context.commit("SHOW_RESULT", payload);
        },
        TOGGLE_ZOOM_COMPONENT: (context) => {
            context.commit("TOGGLE_ZOOM_COMPONENT");
        },
        TOGGLE_TIMEOUT_MODAL: (context, payload) => {
            context.commit("TOGGLE_TIMEOUT_MODAL", payload);
        },
    },
};

export default activitiesState;
