<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 126 148"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.34919 92.5763C6.47504 92.5763 8.19838 90.853 8.19838 88.7271C8.19838 86.6013 6.47504 84.8779 4.34919 84.8779C2.22334 84.8779 0.5 86.6013 0.5 88.7271C0.5 90.853 2.22334 92.5763 4.34919 92.5763Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M36.0914 7.89955C38.2172 7.89955 39.9406 6.17621 39.9406 4.05036C39.9406 1.92451 38.2172 0.201172 36.0914 0.201172C33.9655 0.201172 32.2422 1.92451 32.2422 4.05036C32.2422 6.17621 33.9655 7.89955 36.0914 7.89955Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M101.584 12.6886C103.709 12.6886 105.433 10.9653 105.433 8.83942C105.433 6.71357 103.709 4.99023 101.584 4.99023C99.4577 4.99023 97.7344 6.71357 97.7344 8.83942C97.7344 10.9653 99.4577 12.6886 101.584 12.6886Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M97.7398 147.42C99.8657 147.42 101.589 145.697 101.589 143.571C101.589 141.445 99.8657 139.722 97.7398 139.722C95.614 139.722 93.8906 141.445 93.8906 143.571C93.8906 145.697 95.614 147.42 97.7398 147.42Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M121.763 80.09C123.889 80.09 125.612 78.3666 125.612 76.2408C125.612 74.1149 123.889 72.3916 121.763 72.3916C119.637 72.3916 117.914 74.1149 117.914 76.2408C117.914 78.3666 119.637 80.09 121.763 80.09Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M40.1909 57.1962C41.3016 57.1962 42.2021 56.2958 42.2021 55.185C42.2021 54.0743 41.3016 53.1738 40.1909 53.1738C39.0801 53.1738 38.1797 54.0743 38.1797 55.185C38.1797 56.2958 39.0801 57.1962 40.1909 57.1962Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M89.269 132.259C90.3798 132.259 91.2802 131.358 91.2802 130.248C91.2802 129.137 90.3798 128.236 89.269 128.236C88.1583 128.236 87.2578 129.137 87.2578 130.248C87.2578 131.358 88.1583 132.259 89.269 132.259Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M3.125 90.3167L40.6546 54.7117L36.8054 3.70996L101.279 8.52145L121.488 76.8445L88.7695 129.771L97.4301 143.243"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M121.479 76.8445L36.7969 3.70996"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
