import { render, staticRenderFns } from "./DragCommonResult.vue?vue&type=template&id=34724cad&scoped=true&"
import script from "./DragCommonResult.vue?vue&type=script&lang=js&"
export * from "./DragCommonResult.vue?vue&type=script&lang=js&"
import style0 from "./DragCommonResult.vue?vue&type=style&index=0&id=34724cad&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34724cad",
  null
  
)

export default component.exports