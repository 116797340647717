<template>
    <main>
        <div
            v-if="currentArt && currentArt.digital_collection"
            class="container"
            :style="{ backgroundImage: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%, rgba(0,0,0,0) 100%), url('${image}')` }"
        >
            <router-link class="close-icon" :to="{ name: 'SelectConstellation' }">
                <CloseIcon />
            </router-link>
            <div class="art-info">
                <h2>{{ this.currentArt.artist }}</h2>   <!-- {{ BottomMessage }} -->
                <h3>{{ this.currentArt.title }}</h3>   <!-- {{ topMessage }} -->
            </div>

            <div class="congratulation-container">
                <button v-if="currentArt.connection_star_sign != ''" class="btn-info" :class="showCongratulation ? 'top-35':''" type="button" @click="showAboutStart = !showAboutStart">i</button>
                <h2 v-if="!showCongratulation && currentArt.activity_games" class="choose-story-text">
                    {{ $t("message.story_list_title_top") }}
                </h2>
                <StoriesButtons
                    v-if="!showCongratulation && currentArt.activity_games"
                    :stories="currentArt.activity_games"
                    :playInOrder="currentArt.play_in_order"
                />
                <button v-else class="btn" @click="continueClick">{{ $t("message.continue") }}</button>
            </div>

            <ConnectionStarSign
                v-if="showAboutStart && currentArt.connection_star_sign != ''"
                @handleConnectionStar="showAboutStart = !showAboutStart"
            />
        </div>
    </main>
</template>

<script>
import { mapState } from "vuex";
import { StoriesButtons } from "../../components";
import CloseIcon from "../../components/icons/CloseIcon.vue";
import ConnectionStarSign from "../../components/modals/ConnectionStarSign.vue";
import { windowHeight } from "../../helperFunctions/utils";

export default {
    components: {
        StoriesButtons,
        CloseIcon,
        ConnectionStarSign,
    },
    mounted() {
        windowHeight();
        this.getData();
    },

    data() {
        return {
            showCongratulation: false,
            showAboutStart: true,
        };
    },

    computed: {
        image() {
            return this.currentArt.digital_collection.startsWith("http")
                ? this.currentArt.digital_collection
                : require(`../../assets/img/art-img/${this.currentArt.digital_collection}`);
        },
        topMessage() {
            return this.showCongratulation
                ? this.currentArt.title
                : this.$t("message.story_list_title_top");
        },
        BottomMessage() {
            return this.showCongratulation
                ? this.currentArt.artist
                : this.currentArt.title;
        },
        ...mapState({
            currentArt: (state) => state.gameState.currentArt,
        }),
    },
    methods: {
        async getData() {
            this.showCongratulation = true;
            await this.$store.dispatch(
                "FETCH_SINGLE_ART",
                this.$route.params.inventoryNumber
            );

            this.$store.dispatch("CLEAR_STORE"); // TODO CHECK

            let isStoryCompleted = this.currentArt.activity_games.filter(
                (story) => story.is_completed
            );
            if (isStoryCompleted.length > 0) {
                this.showCongratulation = false;
                this.showAboutStart = false;
            }
        },
        continueClick() {
            this.showCongratulation = false;
            this.showAboutStart = false;
        }
    },
    handleConnectionStarClick() {
        windowHeight();
    },
};
</script>

<style scoped>
main {
    max-width: 100%;
}
.container {
    max-width: 100%;
    height: 100vh;
    position: relative;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* overflow: hidden; */
    padding-bottom: 3.3rem;
    padding: 20px;
}

.close-icon {
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}

.art-info {
    width: 100%;
    padding: 2.5rem;
    color: var(--primary-white);
    text-align: center;
    text-shadow: 0px 0px 15px var(--primary-black);
}

.art-info h2 {
    font-family: var(--primary-font-light);
    font-size: 4rem;
    font-style: normal;
    line-height: 100%; /* 40px */
}
.art-info h3 {
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
    padding: 5px;
}

.congratulation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.choose-story-text {
    color: var(--primary-white);
    text-align: center;
    text-shadow: 0 0 4px var(--primary-black);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: 100%; /* 20px */
    margin-bottom: 1.5rem;
}

.btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.btn-info {
    position: absolute;
    top: -5px;
    right: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid var(--primary-white);
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    color: white;
    font-family: var(--primary-font);
}
.btn-info.top-35 {
    top: -35px;
}
</style>
