<template>
    <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.5 0L15.1225 5.14978C15.6751 9.72159 19.2784 13.3249 23.8502 13.8775L29 14.5L23.8502 15.1225C19.2784 15.6751 15.6751 19.2784 15.1225 23.8502L14.5 29L13.8775 23.8502C13.3249 19.2784 9.72159 15.6751 5.14979 15.1225L0 14.5L5.14978 13.8775C9.72159 13.3249 13.3249 9.72159 13.8775 5.14979L14.5 0Z"
            stroke="white"
        />
    </svg>
</template>

<script>
export default {};
</script>

<style scoped>
/* svg {
    stroke: var(--green-matrix-2);
} */
</style>
