<template>
    <div v-if="level == 1" class="main-container">
        <!-- leve title -->
        <div class="item-container">
            <h1 class="font-light">{{ $t("message.level") }}: {{ level }}</h1>
        </div>
        <!-- wheel image -->
        <img
            :class="`level-${level}`"
            class="img"
            :src="wheelImage"
            alt=""
        />
        <!-- constellation names -->
        <div class="item-container">
            <h1 class="font-light">{{ $t("message.go_to_the_star_signs") }}</h1>
            <div class="constellations-names" v-if="levelData">
                <div v-for="(c, idx) in levelData.constellations" :key="idx" class="name-box">
                    <p class="font-light">{{ $t(`message.${c}`) }}</p>
                </div>
            </div>
        </div>
        <button @click="handleClick(0)" class="btn">{{ $t('message.start') }}</button>
    </div>
    <div v-else class="main-container">
        <!-- leve intro -->
        <div class="item-wrap">
            <p>{{ introText }}</p>
        </div>
        <!-- level title -->
        <div class="item-container">
            <h1 class="font-light">{{ $t("message.level") }} {{ level }}</h1>
        </div>
        <!-- wheel image -->
        <img
            :class="`level-${level}`"
            class="img"
            :src="wheelImage"
            alt="wheelImage"
        />
        <!-- button -->
        <div class="btn-wrap" v-if="levelData">
            <button v-for="(c, idx) in levelData.constellations" :key="idx" class="btn" @click="handleClick(idx)">
                {{ $t(`message.${c}`) }}
            </button>
        </div>
    </div>
</template>

<script>
import gameApi from "../../api/gameApi";
import { introEnglish, introGerman } from "../../utils/level";

export default {
    data() {
        return {
            level: 0,
            levelData: null,
            introText: ''
        };
    },
    mounted() {
        this.level = this.$route.params.level;
        if (this.$i18n.locale === "en") {
            this.introText = introEnglish[this.level - 1].intro;
        } else {
            this.introText = introGerman[this.level - 1].intro;
        }
        this.getLevelData();
    },
    watch: {
        $route() {
            this.getLevelData();
        },
    },
    computed: {
        wheelImage() {
            let url;
            url = require(`@/assets/img/wheel-levels/level_${this.level}.png`);
            return url;
        },
    },
    methods: {
        async getLevelData() {
            const res = await gameApi.post("game/get-level-details", {
                level: this.level,
            });
            this.levelData = res.data;
            console.log(this.levelData);
            if (this.levelData) {
                console.log(this.levelData.is_completed);
            }
            else {
                console.log('no data');
            }
        },
        handleClick(idx) {
            if (this.levelData === null) {
                this.$router.push({ name: "SelectConstellation" });
            }
            else {
                if (this.levelData.is_completed) {
                    if (this.level == 4) {
                        this.$router.push({
                            name: "PortalPage",
                        });
                    } else {
                        this.$router.push({
                            name: "Level",
                            params: { level: this.level },
                        });
                    }
                } else {
                    localStorage.setItem(
                        "selectConstellationName",
                        this.levelData.constellations[idx]
                    );
                    this.$router.push({ name: "SelectConstellation" });
                }
            }
        },
    },
};
</script>

<style scoped>
.main-container {
    width: 100vw;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 1.25rem 2rem;
    overflow: scroll;
    overflow-x: hidden;
}
.item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.constellations-names {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}
.name-box {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
.name-box p {
    color: var(--primary-white);
    font-size: 20px;
    text-transform: capitalize;
}
.img {
    width: 100%;
    scale: 1;
}
.img.level-1 {
    scale: 1.2;
}
.item-wrap {
    width: 100%;
    border: 1px dashed var(--primary-white);
    border-radius: 10px;
    padding: 2rem;
}
.btn-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
h1 {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-medium);
    font-size: 35px;
    font-style: normal;
    line-height: 100%; /* 30.142px */
}
p {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
}
</style>
