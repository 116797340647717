var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home-overlay"},[(_vm.$i18n.locale === 'de')?_c('div',{staticClass:"text-wrapper"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]):_c('div',{staticClass:"text-wrapper"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('button',{staticClass:"btn font-semibold",on:{"click":function($event){return _vm.$emit('overlayClick')}}},[_vm._v(" "+_vm._s(_vm.$t("message.continue"))+" ")]),_c('img',{attrs:{"src":require("../../assets/img/staedel-front.png"),"alt":""}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Antworte auf einen "),_c('br'),_vm._v(" Hilferuf aus der Zukunft! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Nutze die Sternbilder zur "),_c('br'),_vm._v(" Navigation im Städel Universum. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Sammle Geschichten und Erkenntnisse,"),_c('br'),_vm._v(" die in den Kunstwerken im Städel Museum stecken! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Kannst du alle Aufgaben lösen "),_c('br'),_vm._v(" und die Zukunft sichern? ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" In Stadel Universe, "),_c('br'),_vm._v(" Find the connections! "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Find the Solutions "),_c('br'),_vm._v(" for the Future! "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Can you find all "),_c('br'),_vm._v(" the connections? "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Can you solve the mysteries "),_c('br'),_vm._v(" of the Future? ")])
}]

export { render, staticRenderFns }