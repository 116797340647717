<template>
    <div class="dashboard-main">
        <ArtListFilters />
        <ArtsList />
    </div>
</template>

<script>
import { ArtsList, ArtListFilters } from "../../components";

export default {
    components: {
        ArtsList,
        ArtListFilters,
    },
    mounted() {
        this.$store.dispatch("GET_ART_LIST");
    },
    data() {
        return {};
    },

    methods: {},
};
</script>

<style scoped>
.dashboard-main {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 3rem;
    font-size: 1.25rem;
    padding: 1rem;
}

@media screen and (min-width: 1200px) {
    .dashboard-main {
        display: grid;
        grid-template-columns: auto 1fr;
    }
}
</style>
