<template>
    <div class="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
            <circle cx="17.0001" cy="16.5636" r="15.5128" stroke="black" stroke-width="2"/>
            <path d="M27.7695 27.332L35.667 35.2295" stroke="black" stroke-width="2"/>
            <path d="M9.10254 16.5664H24.8974" stroke="black" stroke-width="2"/>
            <path d="M17 8.66797L17 24.4628" stroke="black" stroke-width="2"/>
        </svg>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.svg-container {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
