<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 165 139"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.0289 8.25404C21.1547 8.25404 22.8781 6.5307 22.8781 4.40485C22.8781 2.279 21.1547 0.555664 19.0289 0.555664C16.903 0.555664 15.1797 2.279 15.1797 4.40485C15.1797 6.5307 16.903 8.25404 19.0289 8.25404Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M4.58355 48.6759C6.7094 48.6759 8.43274 46.9526 8.43274 44.8267C8.43274 42.7009 6.7094 40.9775 4.58355 40.9775C2.4577 40.9775 0.734375 42.7009 0.734375 44.8267C0.734375 46.9526 2.4577 48.6759 4.58355 48.6759Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M160.474 138.176C162.6 138.176 164.323 136.453 164.323 134.327C164.323 132.201 162.6 130.478 160.474 130.478C158.348 130.478 156.625 132.201 156.625 134.327C156.625 136.453 158.348 138.176 160.474 138.176Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M106.623 106.443C108.748 106.443 110.472 104.72 110.472 102.594C110.472 100.468 108.748 98.7451 106.623 98.7451C104.497 98.7451 102.773 100.468 102.773 102.594C102.773 104.72 104.497 106.443 106.623 106.443Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M86.4117 88.1495C88.5375 88.1495 90.2609 86.4262 90.2609 84.3004C90.2609 82.1745 88.5375 80.4512 86.4117 80.4512C84.2858 80.4512 82.5625 82.1745 82.5625 84.3004C82.5625 86.4262 84.2858 88.1495 86.4117 88.1495Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M56.7924 38.3847C57.9032 38.3847 58.8036 37.4843 58.8036 36.3735C58.8036 35.2628 57.9032 34.3623 56.7924 34.3623C55.6817 34.3623 54.7812 35.2628 54.7812 36.3735C54.7812 37.4843 55.6817 38.3847 56.7924 38.3847Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M70.2768 60.5146C71.3876 60.5146 72.288 59.6141 72.288 58.5034C72.288 57.3926 71.3876 56.4922 70.2768 56.4922C69.1661 56.4922 68.2656 57.3926 68.2656 58.5034C68.2656 59.6141 69.1661 60.5146 70.2768 60.5146Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M70.2768 73.9892C71.3876 73.9892 72.288 73.0887 72.288 71.978C72.288 70.8672 71.3876 69.9668 70.2768 69.9668C69.1661 69.9668 68.2656 70.8672 68.2656 71.978C68.2656 73.0887 69.1661 73.9892 70.2768 73.9892Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M81.7768 73.9892C82.8876 73.9892 83.788 73.0887 83.788 71.978C83.788 70.8672 82.8876 69.9668 81.7768 69.9668C80.6661 69.9668 79.7656 70.8672 79.7656 71.978C79.7656 73.0887 80.6661 73.9892 81.7768 73.9892Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M73.1674 86.496C74.2782 86.496 75.1786 85.5956 75.1786 84.4848C75.1786 83.3741 74.2782 82.4736 73.1674 82.4736C72.0567 82.4736 71.1562 83.3741 71.1562 84.4848C71.1562 85.5956 72.0567 86.496 73.1674 86.496Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M58.7143 80.7265C59.8251 80.7265 60.7255 79.8261 60.7255 78.7153C60.7255 77.6045 59.8251 76.7041 58.7143 76.7041C57.6036 76.7041 56.7031 77.6045 56.7031 78.7153C56.7031 79.8261 57.6036 80.7265 58.7143 80.7265Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M153.027 119.218C154.138 119.218 155.038 118.317 155.038 117.207C155.038 116.096 154.138 115.195 153.027 115.195C151.916 115.195 151.016 116.096 151.016 117.207C151.016 118.317 151.916 119.218 153.027 119.218Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M19.25 4.61719L56.7796 36.373L70.2517 58.5058V71.978H81.7993L87.5731 84.4878L106.819 101.809L153.009 117.206L160.708 134.527"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M4.32812 44.5498L59.1791 79.1925L73.6135 84.9663L87.5668 84.4851"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
