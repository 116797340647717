<template>
    <div class="selection-container">
        <select v-if="createBTN" v-model="selected">
            <option>PollManyOptions</option>
            <option>SliderTwoOptions</option>
            <option>SliderManyOptions</option>
            <option>DragPoll</option>
            <option>DragAndMatch</option>
            <option>DragCommon</option>
            <option>Quiz</option>
            <option>PollTwoOptions</option>
        </select>
        <button type="button" @click="handleClick" class="btn">
            {{ btnText }}
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selected: "",
            createBTN: false,
        };
    },
    computed: {
        btnText() {
            return this.selected ? "Create" : "New Story";
        },
    },
    methods: {
        handleClick() {
            this.createBTN = !this.createBTN;
            if (this.selected) {
                this.$emit("addNewStory", this.selected);
                this.selected = "";
                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight);
                });
            }
        },
    },
};
</script>

<style scoped>
.selection-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 2rem;
}
select {
    width: 50%;
    height: 30px;
    margin: 0 auto;
}
</style>
