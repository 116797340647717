<template>
    <div>
        <h2>Template</h2>
        <div class="items-wrapper">
            <div
                @click="selected = item"
                class="single-item"
                v-for="item in 10"
                :key="item"
            >
                {{ item }}
            </div>
        </div>
        <h2>{{ selected }}</h2>
        <pre>{{ geolocation }}</pre>
    </div>
</template>

<script>
export default {
    mounted() {
        this.geolocation = navigator.geolocation.coords;
        // console.log(navigator.geolocation);

        const successCallback = (position) => {
            this.geolocation = position;

            console.log(JSON.stringify(position));
        };

        const errorCallback = (error) => {
            console.log(error);
        };

        navigator.geolocation.getCurrentPosition(
            successCallback,
            errorCallback
        );
    },
    data() {
        return {
            selected: null,
            geolocation: null,
        };
    },
};
</script>

<style scoped>
.items-wrapper {
    display: flex;
    gap: 2rem;
    overflow: auto;
    max-width: 320px;
    margin: 0 auto;
}

@media screen and (max-width: 900px) {
    .items-wrapper::-webkit-scrollbar {
        display: none;
    }
}

.single-item {
    width: 100px;
    height: 120px;
    flex-shrink: 0;
    border: 1px solid var(--primary-white);
}
</style>
