<template>
    <div
        class="art-container"
        :class="{ isArtFound: art.isFound }"
        :id="canDrag.container"
    >
        <section class="section-top">
            <h4 v-if="!art.isFound">{{ art.room }}</h4>
            <div v-else>
                <p class="art_title">{{ art.title }}</p>
                <p class="art_artist">{{ art.artist }}</p>
            </div>
            <FoundIndicator
                :isCoreArt="art.core_art"
                :isFound="art.isFound"
                :counter="counter"
            />
        </section>
        <div class="img-container">
            <img
                :key="art.digital_collection"
                class="img"
                :id="canDrag.dragImg"
                :src="art.digital_collection"
                :alt="art.title"
            />
        </div>
        <StoriesIndicator :activity_games="art.activity_games" :isDragArt="true" />
    </div>
</template>

<script>
import StoriesIndicator from "./StoriesIndicator.vue";
import FoundIndicator from "./FoundIndicator.vue";
export default {
    components: {
        StoriesIndicator,
        FoundIndicator,
    },
    props: {
        art: {
            type: Object,
        },
        counter: {
            type: Number,
        },
        player_progress: {
            type: Array,
        },
    },

    computed: {
        canDrag() {
            let dynamicID = {
                dragImg: "",
                container: "",
            };
            if (!this.art.isFound) {
                dynamicID.dragImg = "draggable";
                dynamicID.container = "container";
            }
            return dynamicID;
        },
    },
};
</script>

<style scoped>
.art-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
}

.section-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 10;
    height: fit-content;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1.25rem;
}
.section-top h4 {
    color: white;
    text-align: left;
    text-shadow: 0px 0px 15px black;
    font-family: var(--primary-font-light);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 350;
    line-height: 130%; /* 32.5px */
}
.section-top .found-indicator {
    position: relative;
    top: 0;
    left: 0;
}

.img-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.img {
    width: 100%;
    object-fit: cover;
}

.isArtFound .img-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(231, 138, 87, 0.80);
}

.art_title {
    color: white;
    font-family: var(--primary-font-light);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 350;
    line-height: 130%; /* 32.5px */
}
.art_artist {
    color: white;
    font-family: var(--primary-font-light);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 350;
    line-height: 130%; /* 23.4px */
}
</style>
