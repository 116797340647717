<template>
    <div>
        <BackgroundStars />
        <div class="under-line"></div>
        <main class="main-container" :class="{ ['is-level-completed']: coreArt && coreArt.is_level_completed,}">
            <div class="arts-constellations" v-show="showConstellations">
                <!-- constellation name box -->
                <div v-if="coreArt && coreArt.constellation" class="constellation-info">
                    <p class="constellation-name">{{$t(`message.${coreArt.constellation.toLowerCase()}`)}}</p>
                </div>
                <!-- 작품 선택 화면 -->
                <div class="art-wrapper">
                    <div
                        v-for="(item, index) in artsByConstellation"
                        :key="item.inventory_number"
                        @click="handleStarClick(item)"
                    >
                        <!-- 열쇠 or 자물쇠 아이콘 + 작품 이미지 박스 -->
                        <SingleArtConstellation
                            :item="item"
                            :idx="index"
                            :counter="numberOfArtFound"
                        />
                    </div>
                    <!-- 아직 오픈하지 않은 레벨의 경우 선택을 막아두는 커버 -->
                    <div
                        v-if="artsByConstellation.length > 0 && !artsByConstellation[0].can_play"
                        class="level-overlay"
                    >
                        <h2>{{ $t("message.level") }} <br />{{ artsByConstellation[0].level }}</h2>
                        <p v-html="$t('message.locked_constellation')" />
                    </div>
                </div>
                <!-- 코어 아트 오픈 전 나오는 팝업 -->
                <SelectArtModal
                    v-if="showArtDetailModal"
                    :art="currentArt"
                    :counter="numberOfArtFound"
                    :canCollectBook="canCollectBook"
                    @closeBtn="closeModal"
                />
                <!-- 게임 진입 -->
                <FormInput
                    :art="currentArt"
                    @closeBtn="showEnterCodeForm = false"
                    v-if="showEnterCodeForm"
                />
                <!-- 별자리 휠 -->
                <ConstellationNav @loaded="showConstellations = true" />
            </div>
        </main>
    </div>
</template>

<script>
import { gsap } from "gsap";
import BackgroundStars from "../../components/ui/BackgroundStars.vue";
import { cardDragAnimation } from "../../helperFunctions/animations/cardDragAnimation.js";
import ConstellationNav from "../../components/constellation/ConstellationsNav.vue";
// modal check
import {
    checkShowModal,
    modalAlreadySeem,
} from "../../helperFunctions/modalChecks";
import {
    SingleArtConstellation,
    FormInput,
    SelectArtModal,
} from "../../components";
import { mapState } from "vuex";
import { windowHeight } from "../../helperFunctions/utils";

export default {
    components: {
        BackgroundStars,
        ConstellationNav,
        SingleArtConstellation,
        FormInput,
        SelectArtModal,
    },
    data() {
        return {
            // checkLoaded
            showConstellations: false,
            // Modal
            showArtDetailModal: false,
            canCollectBook: false,
            showAboutStartSign: false,

            // Tutorial
            showTutorial: true,
            constellationTutorial: [],

            //
            showEnterCodeForm: false,
            showArtModal: false,
            numberOfArtFound: 0,
            currentArt: {},
            animationTimeLine: gsap.timeline({ paused: true }),
            showDragImage: false,
        };
    },
    async mounted() {
        windowHeight();
        this.startsPosition();
        gsap.from(".arts-constellations", {
            opacity: 0,
            duration: 1,
        });
        this.animationTimeLine
            .to(
                ".drag-image-section",
                {
                    xPercent: 0,
                },
                0
            );
        this.$store.dispatch("GET_LOCAL_STORE");
        
        // this.$store.dispatch("SAVE_START_CORE_ART");
    },
    computed: {
        ...mapState({
            artsByConstellation: (state) => state.gameState.artsByConstellation,
            coreArt: (state) => state.gameState.coreArt,
            selectedConstellationName: (state) =>
                state.gameState.selectedConstellationName,
        }),
    },

    watch: {
        selectedConstellationName(value) {
            this.getNumberOfArtFound();
            this.checkShowAboutStartSign();
            return value;
        },
    },
    methods: {
        handleDragImagesClick() {
            if (!this.showDragImage) {
                this.animationTimeLine.play();
            } else {
                this.animationTimeLine.reverse();
            }

            this.showDragImage = !this.showDragImage;
            setTimeout(() => {
                if (this.showDragImage == true) {
                    cardDragAnimation();
                } else {
                    cardDragAnimation(true);
                }
            }, 100);
        },
        handleStarClick(item) {
            this.$store.dispatch("GET_SINGLE_ART_DETAILS", item);

            this.currentArt = item;
            console.log(item);

            if (item.isFound) {
                this.$router.push({
                    name: "StoryList",
                    params: {
                        zodiacName:
                            this.selectedConstellationName.toLowerCase(),
                        inventoryNumber: item.inventory_number,
                    },
                });
            } else {
                if (this.currentArt.core_art && this.numberOfArtFound !== 2) {
                    this.showArtDetailModal = true; // TODO before it was showing core art message

                    this.showEnterCodeForm = false;
                    return;
                }

                this.showEnterCodeForm = true;
            }
        },
        startsPosition() {
            gsap.set(".drag-image-section", {
                xPercent: -100,
            });
            this.$store.dispatch("FETCH_ARTS");
            this.getNumberOfArtFound();
            setTimeout(() => {
                this.checkShowAboutStartSign();
            }, 1000);

            if (this.coreArt && this.coreArt.is_completed)
                this.allArtsCompleted(); // check if using
        },

        getNumberOfArtFound() {
            this.numberOfArtFound = 0;
            this.artsByConstellation.forEach((star) => {
                if (star.isFound) {
                    this.numberOfArtFound++;
                }
            });
        },

        checkShowAboutStartSign() {
            if (this.coreArt && this.coreArt.constellation)
                this.showAboutStartSign = checkShowModal({
                    name: this.coreArt.constellation,
                    storageName: "aboutStarSignModal",
                });
        },

        allArtsCompleted() {
            this.canCollectBook = true;
        },
        handleModalClick() {
            this.showArtDetailModal = false;
        },
        closeModal() {
            this.isOpen = false;
            this.showArtDetailModal = false;
        },
        handleTutorialCompleted() {
            modalAlreadySeem({
                name: "ModalTutorial",
                storageName: "tutorials",
            });
            this.showTutorial = !this.showTutorial;
        },
        handleAboutStarSignClick() {
            this.showAboutStartSign = !this.showAboutStartSign;
            modalAlreadySeem({
                name: this.coreArt.constellation,
                storageName: "aboutStarSignModal",
            });
        },
    },
};
</script>

<style scoped>
main {
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
}
.constellation-info {
    text-align: center;
    margin: 2rem 0 4rem 0;
    position: relative;
}
.constellation-name {
    display: inline;
    font-size: 4rem;
    font-style: normal;
    line-height: 100%; /* 40px */
    font-family: var(--primary-font-light);
    text-transform: capitalize;
}
.constellation-topic {
    font-style: normal;
    font-weight: 350;
    font-size: 1.25rem;
    line-height: 100%;
    margin-top: 10px;
}
.arts-constellations {
    height: 100%;
}
.art-wrapper {
    height: 60%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    position: relative;
}
.level-overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    inset: 0 0 0 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    font-size: 2rem;
    text-align: center;
    padding: 0 1rem;
    z-index: 11;
}

.drag-image-section {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0 0 0 0;
    z-index: 20;
}
.drag-image-wrapper {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
}

.menu-icon {
    left: 15px;
}

.is-section-open {
    /* ypercent: 200; */
    opacity: 1;
    left: 0;
    top: 80px;
    background: var(--primary-gray);
}

/* See global.css Action Icons */
</style>
