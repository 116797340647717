import { gsap } from "gsap";
const resultButtonsAnimation = (options, totalVotes) => {
    let buttons = gsap.utils.toArray(".options-quiz-result");
    buttons.forEach((element, idx) => {
        let value = `${(options[idx].votes * 100) / totalVotes}%`;
        gsap.to(element, {
            clipPath: `polygon(0 0, ${value} 0%, ${value} 100%, 0% 100%)`,
            duration: 1,
        });
    });

    let tl = gsap.timeline();
    const topSection = document.querySelector(".top-section");

    tl.to(topSection, {
        opacity: 0,
        height: 0,
        transformOrigin: "top center",
        duration: 0.7,
    })
        .to(".activity-image-container", { margin: 0 }, 0)
        .to(".activity-wrapper", { display: "block" })
        .to(
            window,
            {
                scrollTo: ".feedback",
            },
            0.5
        );
};

export default resultButtonsAnimation;
