<template>
    <div class="wrapper">
        <span class="option-text">
            {{ art.option }}
        </span>
        <span class="amount">
            {{
                !showTotalVotes
                    ? ""
                    : Math.round((art.votes * 100) / totalVotes) + "%"
            }}
        </span>

        <span
            :class="isSelected && 'is-selected'"
            class="btn options-quiz-result"
        >
            <span class="option-text">
                {{ art.option }}
            </span>
            <span class="amount">
                {{
                    !showTotalVotes
                        ? ""
                        : Math.round((art.votes * 100) / totalVotes) + "%"
                }}
            </span>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        art: {
            type: Object,
        },
        showTotalVotes: {
            type: Boolean,
        },
        totalVotes: {
            type: Number,
        },
        showResultPercentage: {
            type: Boolean,
            default: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.quiz {
    justify-content: center;
}
.amount,
.option-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    text-transform: uppercase;
    line-height: normal;
}
.options-quiz-result {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: -2px;
    left: 0;
    background: #707070;
    border: none;
    border-radius: 0;
    padding: 2rem 1rem;
    color: var(--primary-white);
}
.options-quiz-result.is-selected {
    background: var(--primary-white);
    color: var(--primary-black);
}
</style>
