<template>
    <div class="container">
        <div v-for="(value, name, index) in art" :key="index">
            <div v-if="name !== 'activity_games'" class="form-row">
                <p>{{ name }}</p>
                <div class="check-marks" v-if="isCheckBox(name)">
                    <input type="checkbox" v-model="art[name]" />
                </div>
                <textarea v-else-if="isTextArea(name)" v-model="art[name]" />
                <input v-else v-model="art[name]" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        art: {
            type: Object,
        },
    },
    computed: {
        isCheckBox() {
            let isCheck;
            return (name) => {
                if (
                    name == "isFound" ||
                    name == "core_art" ||
                    name == "pay_in_order"
                ) {
                    isCheck = true;
                } else {
                    isCheck = false;
                }
                return isCheck;
            };
        },
        isTextArea() {
            let textArea;
            return (name) => {
                if (
                    name == "topic_description" ||
                    name == "topic" ||
                    name == "physical_desc" ||
                    name == "story_headline" ||
                    name == "book_of_knowledge" ||
                    name == "connection_starsign" ||
                    name == "about_starsign" ||
                    name == "info_for_physical_desc"
                ) {
                    textArea = true;
                } else {
                    textArea = false;
                }
                return textArea;
            };
        },
    },
};
</script>

<style scoped>
@media screen and (min-width: 1200px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
.form-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.2rem;
    width: 100%;
    padding: 1rem;
}

.check-marks {
    display: flex;
}
input {
    display: flex;
    height: 30px;
    width: 100%;
    padding: 0.3rem;
}
textarea {
    font-family: initial;
    font-size: inherit;
    width: 100%;
    min-height: 200px;
    padding: 0.3rem;
}
</style>
