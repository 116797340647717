import Vue from "vue";
import VueI18n from "vue-i18n";

import { en, de } from "./assets/locales/";

Vue.use(VueI18n);

const messages = {
    en,
    de,
};

let locale = localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "de";
document.querySelector("html").lang = locale;

const i18n = new VueI18n({
    locale, // set locale
    messages, // set locale messages
});

export default i18n;
