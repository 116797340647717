export const checkShowModal = ({ name, storageName }) => {
    const tutorials = localStorage.getItem(storageName);
    let showTutorial = true;
    if (tutorials && JSON.parse(tutorials).includes(name)) {
        showTutorial = false;
    }
    return showTutorial;
};

export const modalAlreadySeem = ({ name, storageName }) => {
    let tutorials = localStorage.getItem(storageName)
        ? JSON.parse(localStorage.getItem(storageName))
        : [];
    if (!tutorials.includes(name)) {
        tutorials.push(name);
        localStorage.setItem(storageName, JSON.stringify(tutorials));
    }
};
