<template>
    <div :id="activeSlideIndex" class="carousel-container">
        <div @click="$emit('closeBtn')" class="close-icon">X</div>
        <div
            class="slide"
            v-for="slide in slides"
            :class="slide.position"
            :key="slide.order"
        >
            <h3 class="title">{{ $t(`message.${slide.name}`) }}</h3>
            <p class="constellation-name">{{ $t(`message.${slide.group}`) }}</p>
            <div class="line"></div>
            <div class="img-container">
                <img :src="image(slide)" alt="" />
                <div v-if="!slide.isComplete" class="instructions">
                    <p>
                        You have not collected this book yet. <br />Complete the
                        constellation to earn it!
                    </p>

                    <router-link
                        class="btn"
                        :to="{
                            name: 'SelectImage',
                            params: {
                                id: slide.group,
                                zodiacName: slide.name,
                            },
                        }"
                    >
                        {{ $t("message.play") }}</router-link
                    >
                </div>
            </div>

            <section class="book-content">
                <div v-if="slide.isComplete">
                    <p v-html="slide.book_of_knowledge"></p>
                </div>

                <div v-else><IconPadlock /></div>
            </section>
        </div>
    </div>
</template>

<script>
import IconPadlock from "../icons/IconPadlock";
export default {
    components: {
        IconPadlock,
    },
    props: {
        activeSlideIndex: {
            type: Number,
        },
        slides: {
            type: Array,
        },
    },
    data() {
        return {
            img: require("../../assets/img/tutorials/book.jpeg"),
        };
    },
    computed: {
        image() {
            return (slide) =>
                slide.isComplete ? slide.digital_collection : this.img;
        },
    },
};
</script>

<style scoped>
.carousel-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    background: var(--main-color-3);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    color: white;
    color: var(--primary-white);
}
.slide {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 1rem;
    font-size: 1.25rem;
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    transition: 0.3s all linear;
    background: var(--primary-light);
    border-radius: var(--border-radius-1);
}

.img-container {
    position: relative;
    max-height: 300px;
    width: 100%;
    display: flex;
    border: 0.5px solid var(--primary-white);
    padding: 10px;
}
.img-container img {
    width: 100%;
    border: 0.5px solid var(--primary-white);
    object-fit: contain;
}
.instructions {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 0.5rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.6);
    /* color: var(--primary-white); */
}
.btn {
    height: 30px;
    width: fit-content;
}
.title {
    margin: 1rem 0 0.5rem 0;
}
.constellation-name {
    text-align: center;
}
.book-content {
    position: relative;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    margin-bottom: 2rem;
}
.book-content p {
    font-size: 1rem;
    margin: 0.5rem 0;
}
.book-content svg {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.close-icon {
    width: 20px;
    font-size: 2rem;
    z-index: 20;
}

.slide.activeSlide {
    opacity: 1;
    transform: translateX(0);
}
.slide.lastSlide {
    transform: translateX(-100%);
}
.slide.nextSlide {
    transform: translate(100%);
}
</style>
