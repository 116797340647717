<template>
    <div class="modal-overlay-wrapper">
        <div class="progress-bg">
            <h2 class="progress-modal-title">
                {{ $t("message.competences_upgrade") }}
            </h2>

            <div class="competences">
                <div
                    class="single-competence"
                    v-for="(competence, idx) in competences"
                    :key="idx"
                >
                    <span class="competence-circle"></span>
                    <p>
                        {{ competence }}
                    </p>
                </div>
            </div>

            <section class="bottom-section">
                <div class="progress-modal-msg">
                    <p>
                        {{ $t("message.competences_popup_text_one") }}
                        <span>
                            <CompetencesIcon />
                        </span>
                        {{ $t("message.competences_popup_text_two") }}
                    </p>
                </div>
                <button @click="collectCompetences" class="btn-round">
                    {{ $t("message.continue") }}
                </button>
            </section>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import CompetencesIcon from "../icons/CompetencesIcon.vue";

export default {
    components: { CompetencesIcon },
    props: {
        competences: {
            type: Array,
        },
    },

    computed: {
        ...mapState({
            currentArt: (state) => state.gameState.currentArt,
        }),
        completedStories() {
            return this.currentArt.activity_games.filter(
                (item) => item.is_completed
            ).length;
        },
    },

    methods: {
        collectCompetences() {
            if (!this.$refs.competenceRef) {
                this.$emit("closeCompetence");
                return;
            }
        },
    },
};
</script>

<style scoped>
.modal-overlay-wrapper {
    background: rgba(0, 0, 0, 0.85);
}

.competences {
    background: url("../../assets/img/competences-img-bg.png") center/cover
        no-repeat;
    width: 100%;
    max-width: 310px;
    margin: 0 auto;
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.single-competence {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
}
.competence-circle {
    display: block;
    width: 18px;
    height: 18px;
    background: var(--primary-white);
    border-radius: 50%;
}
.competences p {
    width: 153px;

    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;
}

.btn-round {
    margin-top: 30px;
}
</style>
