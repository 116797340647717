<template>
    <div class="wrapper">
        <div class="close-btn" @click="$emit('handleConnectionStar')">
            <CloseIcon />
        </div>

        <p class="connection-text" v-html="currentArt.connection_star_sign"></p>
    </div>
</template>

<script>
import CloseIcon from "../../components/icons/CloseIcon.vue";
export default {
    components: {
        CloseIcon,
    },
    computed: {
        currentArt() {
            return this.$store.state.gameState.currentArt;
        },
    },
};
</script>

<style scoped>
.wrapper {
    width: 90%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    background: var(--primary-black);
    border-radius: 12px;
    padding: 2rem;
    gap: 2rem;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.50);
}
.close-btn {
    width: 100%;
    height: 18px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

p {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2.2rem;
    font-style: normal;
    line-height: normal;
}
.connection-text::-webkit-scrollbar {
    display: none;
}
</style>
