var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list.length > 0)?_c('div',{staticClass:"wheel-container"},_vm._l((3),function(item){return _c('div',{key:item,staticClass:"single-wheel wheel-list"},[_c('ul',{ref:"wheelContainer",refInFor:true,staticClass:"wheel wheel-constellations",class:`wheel-${item}`},_vm._l((_vm.list),function(constellation){return _c('li',{key:constellation.name,class:[
                    `art-${item - 1}`,
                    constellation.arts[item - 1].core_art
                        ? 'is-core-art'
                        : '',
                    constellation.can_play &&
                    !constellation.arts[item - 1].isFound
                        ? 'can-play'
                        : '',
                    !constellation.can_play &&
                    !constellation.arts[item - 1].isFound
                        ? 'can-not-play'
                        : '',
                    constellation.arts[item - 1].isFound
                        ? `${constellation.name}`
                        : '',
                ],style:({ opacity:
                    _vm.setProgress(constellation.arts[item - 1]) == 0 ? 0.1 : `${_vm.setProgress(constellation.arts[item - 1])}%`,}),attrs:{"id":`id` +
                    constellation.arts[item - 1].inventory_number
                        .replaceAll(' ', '')
                        .replaceAll('.', '') +
                    `art`}},[(constellation.arts[item - 1].core_art)?_c('div',{staticClass:"constellation-icon"},[_c(constellation.name,{tag:"component",attrs:{"canPlay":constellation.can_play && !constellation.arts[item - 1].isFound,"isFound":constellation.arts[item - 1].isFound}})],1):_vm._e()])}),0)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }