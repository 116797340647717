import { render, staticRenderFns } from "./TutorialCarrousel.vue?vue&type=template&id=4f445170&scoped=true&"
import script from "./TutorialCarrousel.vue?vue&type=script&lang=js&"
export * from "./TutorialCarrousel.vue?vue&type=script&lang=js&"
import style0 from "./TutorialCarrousel.vue?vue&type=style&index=0&id=4f445170&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f445170",
  null
  
)

export default component.exports