<template>
    <svg
        width="43"
        height="44"
        viewBox="0 0 164 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M40.5112 90.8716L4.90625 138.024L52.0588 121.665L152.138 100.495L143.477 65.8519L112.684 55.2666L40.5112 90.8716Z"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M112.674 55.2626L108.344 33.6109L135.288 2.81738L160.308 12.4404"
            :stroke="canPlay ? '#333333' : '#ffffff'"
            stroke-width="0.962297"
            stroke-miterlimit="10"
        />
        <path
            d="M4.42731 141.395C6.55316 141.395 8.2765 139.671 8.2765 137.545C8.2765 135.42 6.55316 133.696 4.42731 133.696C2.30147 133.696 0.578125 135.42 0.578125 137.545C0.578125 139.671 2.30147 141.395 4.42731 141.395Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M151.732 104.236C153.858 104.236 155.581 102.513 155.581 100.387C155.581 98.2614 153.858 96.5381 151.732 96.5381C149.606 96.5381 147.883 98.2614 147.883 100.387C147.883 102.513 149.606 104.236 151.732 104.236Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M112.326 58.9992C114.452 58.9992 116.175 57.2758 116.175 55.15C116.175 53.0241 114.452 51.3008 112.326 51.3008C110.2 51.3008 108.477 53.0241 108.477 55.15C108.477 57.2758 110.2 58.9992 112.326 58.9992Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M159.459 16.6515C161.584 16.6515 163.308 14.9282 163.308 12.8023C163.308 10.6765 161.584 8.95312 159.459 8.95312C157.333 8.95312 155.609 10.6765 155.609 12.8023C155.609 14.9282 157.333 16.6515 159.459 16.6515Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M108.472 35.9941C109.583 35.9941 110.483 35.0936 110.483 33.9829C110.483 32.8721 109.583 31.9717 108.472 31.9717C107.361 31.9717 106.461 32.8721 106.461 33.9829C106.461 35.0936 107.361 35.9941 108.472 35.9941Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M135.292 4.83001C136.403 4.83001 137.304 3.92957 137.304 2.81881C137.304 1.70806 136.403 0.807617 135.292 0.807617C134.182 0.807617 133.281 1.70806 133.281 2.81881C133.281 3.92957 134.182 4.83001 135.292 4.83001Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M143.488 67.8661C144.599 67.8661 145.499 66.9657 145.499 65.8549C145.499 64.7442 144.599 63.8438 143.488 63.8438C142.377 63.8438 141.477 64.7442 141.477 65.8549C141.477 66.9657 142.377 67.8661 143.488 67.8661Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M52.0503 123.671C53.161 123.671 54.0615 122.77 54.0615 121.66C54.0615 120.549 53.161 119.648 52.0503 119.648C50.9395 119.648 50.0391 120.549 50.0391 121.66C50.0391 122.77 50.9395 123.671 52.0503 123.671Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
        <path
            d="M40.519 92.8827C41.6298 92.8827 42.5302 91.9823 42.5302 90.8715C42.5302 89.7608 41.6298 88.8604 40.519 88.8604C39.4083 88.8604 38.5078 89.7608 38.5078 90.8715C38.5078 91.9823 39.4083 92.8827 40.519 92.8827Z"
            :fill="canPlay ? '#333333' : '#ffffff'"
        />
    </svg>
</template>

<script>
export default {
    props: {
        canPlay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
