<template>
    <div class="main-wrapper">
        <!-- wheel -->
        <div class="wheel-wrapper">
            <!-- <p>{{ $t("message.core_art_story") }}</p> -->
            <h1>
                {{ selectedConstellation.core_art_progress.completed_stories }} / 
                <span>{{ selectedConstellation.core_art_progress.core_art_stories }}</span>
            </h1>
            <div class="wheel-wrapper disk-wrapper">
                <div class="arrow-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24" fill="none">
                        <path d="M8.33975 24L0.684494 3.44811C0.197723 2.14128 1.16416 0.749999 2.5587 0.749999L14.1208 0.75C15.5153 0.75 16.4818 2.14129 15.995 3.44811L8.33975 24Z" fill="white"/>
                    </svg>
                </div>
                <Disk :list="artsByConstellation" :activeSlideIndex="activeSlideIndex" />
            </div>
        </div>
        <p class="rotate-text">{{ $t('message.rotate_wheel_message') }}</p>
        <!-- button -->
        <div class="progress-container">
            <h3 v-if="coreArtProgress < 100 && selectedConstellation.can_play == true">
                {{ $t("message.lock_art_progress") }}, {{ coreArtProgress }}%
            </h3>
            <button @click="handleClick" class="progress-btn" :disabled="!selectedConstellation.can_play">
                <p>{{ $t("message.message") }}</p>
                <div
                    class="progress-bar"
                    :style="{
                        clipPath: `polygon(0 0, ${coreArtProgress}% 0%, ${coreArtProgress}% 100%, 0% 100%)`,
                    }"
                >
                    <p>{{ $t("message.message") }}</p>
                </div>
            </button>
        </div>
        <!-- <div id="moreContentIndicator">
            <svg width="30" height="40" viewBox="0 0 30 40">
                <path fill="#FFFFFF" d="M15 17.414L.293 2.707l1.414-1.414L15 14.586 28.293 1.293l1.414 1.414"></path>
                <path fill="#AAAAAA" d="M15 28.414L.293 13.707l1.414-1.414L15 25.586l13.293-13.293 1.414 1.414"></path>
                <path fill="#F3F3F3" d="M15 39.414L.293 24.707l1.414-1.414L15 36.586l13.293-13.293 1.414 1.414"></path>
            </svg>
        </div> -->
    </div>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import Disk from "../../components/portal/Disk.vue";
gsap.registerPlugin(Draggable);
export default {
    components: {
        Disk,
    },
    props: {
        artsByConstellation: {
            type: Array,
        },
        selectedConstellation: {
            type: Object,
        },
    },
    data() {
        return {
            activeSlideIndex: 0,
        };
    },
    mounted() {
        this.initialSetup();

        // var content = document.getElementById('app');
        // var indicator = document.getElementById('moreContentIndicator');
        // console.log(content.offsetHeight, window.innerHeight)

        // if (content.scrollHeight > window.innerHeight) {
        //     if (window.scrollY === 0) {
        //         indicator.style.display = 'block'; // 사용자가 맨 위에 있을 때 표시
        //     }
        //     else {
        //         indicator.style.display = 'none'; // 그렇지 않으면 표시하지 않음
        //     }
        // }
        // else {
        //     indicator.style.display = 'none'; // 콘텐츠가 화면보다 작을 때 표시하지 않음
        // }

        // window.addEventListener('scroll', this.checkContentHeight);
        // window.addEventListener('resize', this.checkContentHeight);
    },

    computed: {
        coreArtProgress() {
            return Math.floor(
                (this.selectedConstellation.core_art_progress
                    .completed_stories *
                    100) /
                    this.selectedConstellation.core_art_progress
                        .core_art_stories
            );
        },
    },

    methods: {
        initialSetup() {
            this.activeSlideIndex = this.artsByConstellation.findIndex(
                (item) => item.name === this.selectedConstellation.name
            );

            this.dragFunc(this.artsByConstellation);
        },

        handleClick() {
            let progress = this.coreArtProgress;
            let canSendMessage = false;

            if (progress && progress === 100) canSendMessage = true;
            this.$emit("handleButtonClick", canSendMessage);
        },

        dragFunc(array) {
            let activeSlideIndex = this.activeSlideIndex;
            let activeSlideIndexSnap = this.activeSlideIndex;

            let oneItem = 360 / array.length;
            let self = this;

            Draggable.create(".wheel-constellations", {
                type: "rotation",
                trigger: ".wheel-container",
                liveSnap: true,
                snap: function (endValue) {
                    activeSlideIndexSnap = Math.round(endValue / oneItem);
                    return Math.round(
                        (activeSlideIndexSnap * 360) / array.length - 15
                    );
                },
                onDrag: function () {
                    let maxRotation = gsap.utils.wrap(0, 360, this.rotation);

                    activeSlideIndex = Math.abs(
                        Math.floor(maxRotation / oneItem) - array.length
                    );

                    if (activeSlideIndex === array.length) {
                        self.activeSlideIndex = 0;
                    } else {
                        self.activeSlideIndex = activeSlideIndex;
                    }
                    self.onRelease();
                },
            });
        },

        onRelease() {
            let index = this.activeSlideIndex - 1;
            if (index < 0) {
                index = this.artsByConstellation.length - 1;
            }

            this.$emit("handleWheelRelease", this.artsByConstellation[index]);
        },

        // checkContentHeight() {
        //     var content = document.getElementById('app');
        //     var indicator = document.getElementById('moreContentIndicator');
        //     if (content.scrollHeight > window.innerHeight) {
        //         if (window.scrollY === 0) {
        //             indicator.style.display = 'block'; // 사용자가 맨 위에 있을 때 표시
        //         }
        //         else {
        //             indicator.style.display = 'none'; // 그렇지 않으면 표시하지 않음
        //         }
        //     }
        //     else {
        //         indicator.style.display = 'none'; // 콘텐츠가 화면보다 작을 때 표시하지 않음
        //     }
        // },
    },
};
</script>

<style scoped>
.main-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
}
/* wheel */
.wheel-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.wheel-wrapper h1 {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-semibold);
    font-size: 2.8rem;
    font-style: normal;
    line-height: 140%; /* 67.2px */
}
.wheel-wrapper h1 span {
    font-size: 2rem;
}
.wheel-wrapper p {
    color: #A0A0A0;
    text-align: center;
    font-family: var(--primary-font-semibold);
    font-size: 1.5rem;
    font-style: normal;
    line-height: 140%; /* 21px */
}
.disk-wrapper {
    height: 80vw;
    position: relative;
    padding-top: 1.5rem;
}
.arrow-icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    z-index: 10;
}
p {
    text-align: center;
}
/* rotate text */
.rotate-text {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 1.5rem;
    font-style: normal;
    line-height: 150%; /* 19.5px */
}
/* progress */
.progress-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0.5rem;
    gap: 2rem;
}
.progress-container h3 {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-semibold);
    font-size: 2rem;
    font-style: normal;
    line-height: 100%; /* 20px */
}
.progress-btn {
    width: 100%;
    position: relative;
    border-radius: 7px;
    border: 0.5px solid var(--primary-orange);
    background: var(--primary-white);
    padding: 2rem;
}
.progress-btn p {
    color: var(--primary-orange);
    text-align: center;
    font-family: var(--primary-font-semibold);
    font-size: 2rem;
    font-style: normal;
    line-height: 100%; /* 20px */
    text-transform: uppercase;
}
.progress-btn:disabled {
    background: #8B8B8B;
    border: 0.5px solid var(--primary-white);
    cursor: not-allowed;
}
.progress-btn:disabled p {
    color: var(--primary-gray);
}

.progress-bar {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px;
    border: 0.5px solid var(--primary-white);
    background: var(--primary-orange);
    padding: 2rem;
}
.progress-bar p {
    color: var(--primary-white);
}
#moreContentIndicator {
    position: fixed;
    bottom: 50px;
    right: calc(50% - 15px);
    /* background-color: #ffcc00; */
    /* padding: 10px; */
    /* border-radius: 5px; */
    /* 처음에는 표시되지 않음 */
    /* display: none; */
}
@keyframes lighten {
    0% {
        fill: #AAAAAA;
    }
    33% {
        fill: #FFFFFF;
    }
    66% {
        fill: #AAAAAA;
    }
    100% {
        fill: #AAAAAA;
    }
}
#moreContentIndicator svg path:nth-of-type(1) {
    animation: lighten 2s infinite;
    animation-delay: 0s;
}

#moreContentIndicator svg path:nth-of-type(2) {
    animation: lighten 2s infinite;
    animation-delay: 0.66s;
}

#moreContentIndicator svg path:nth-of-type(3) {
    animation: lighten 2s infinite;
    animation-delay: 1.32s;
}
</style>
