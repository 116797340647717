<template>
    <div class="component-container">
        <small>Fails {{ totalFails }}/{{ totalTries }}</small>
        <ArtDetailsTop
            :art_img="img"
            :title="story_name"
            :keywords="rightKeywords"
            :fakeCircles="fakeCircles"
            :show-circles="true"
            :subtitle="title"
        />
        <Keywords :story_keywords_list="keywordsList" />
    </div>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import ArtDetailsTop from "./ArtDetailsTop.vue";
import Keywords from "./Keywords.vue";
export default {
    components: {
        ArtDetailsTop,
        Keywords,
    },
    props: {
        story_name: {
            type: String,
            default: "Story name",
        },
        competence: {
            type: Array,
        },
        title: {
            type: String,
            default: "Title",
        },
        img: {
            type: String,
            default: "ANSELM-KIEFER.png",
        },

        image: {
            type: String,
            default: "ANSELM-KIEFER.png",
        },

        rightKeywords: {
            type: Array,
        },
        fakeCircles: {
            type: Array,
        },
        keywords: {
            type: [String, Array],
        },
    },
    mounted() {
        setTimeout(() => {
            this.DragMatchFunc();
        }, 2000);
    },
    beforeDestroy() {
        this.draggableInstance.forEach((element) => {
            element[0].kill();
        });
    },
    data() {
        return {
            userSelection: null,
            imageWidth: null,
            imageHeight: null,
            totalFails: 0,
            totalTries: 3,
            draggableInstance: [],
        };
    },
    computed: {
        keywordsList() {
            let keywords;
            if (typeof this.keywords === "string") {
                keywords = this.keywords.trim().split(",");
            } else {
                keywords = this.keywords;
            }
            return keywords;
        },
    },

    methods: {
        DragMatchFunc() {
            let score = 0;
            let circles = gsap.utils.toArray(".circles");
            let keywordsList = gsap.utils.toArray(".options");

            // console.log('circles', circles)
            // console.log('keywordsList', keywordsList)
            // console.log('this.rightKeywords', this.rightKeywords)

            let overlapThreshold = "30%";
            let circle = null;  // circle은 사실 키워드 박스, span 태그
            let self = this;
            let correctCircle = null;

            for (let i = 0; i < keywordsList.length; i++) {
                let allDraggable = Draggable.create(keywordsList[i], {
                    onDragStart: () => {
                        this.$store.dispatch("RESET_SELECTED");
                    },

                    onDrag: function () {

                        circle = keywordsList[i];  // circle은 사실 키워드 박스, span 태그

                        // console.log('circle', circle);  // circle은 사실 키워드 박스, span 태그
                        // console.log('circles[i]', circles[i]);
                        // console.log('this', this);  // draggable.create로 생성된 객체

                        // circles는 이미지 위에 올라간 원들, span 태그들

                        // 새로운 로직
                        for (let j = 0; j < circles.length; j++) {
                            // console.log('circle', `[${circle.innerHTML}]`)
                            // console.log('circle', typeof circle.innerHTML)
                            // console.log('circles[j]', `[${circles[j].className}]`)
                            // console.log('correct?', circles[j].className.endsWith(circle.innerHTML.trim()))

                            // 키워드 박스의 빈칸을 -로 대체 하고 소문자로 변환하고 trim
                            const regexp1 = new RegExp(' ', 'g');
                            const keywordBoxText = circle.innerHTML.trim().toLowerCase().replace(regexp1, '-');
                            // console.log('keywordBoxText', keywordBoxText)

                            // 이미지 위에 올라간 원의 클래스 이름은 소문자로 변환
                            const circleText = circles[j].className.toLowerCase();
                            // console.log('circleText', circleText)

                            const correctWord = circleText.endsWith(keywordBoxText);
                            // console.log('correctWord', correctWord)
                            if (correctWord) {
                                if(this.hitTest(circles[j], overlapThreshold)) {
                                    correctCircle = circles[j];
                                    circle.classList.add("highlight", "matched");
                                }
                                else {
                                    correctCircle = null;
                                    circle.classList.remove("highlight", "matched");
                                }                                
                            }
                        }

                        // 이전 로직은 아래와 같았음
                        // 순서가 맞아야 맞는 방식이었지만 제대로 작동하지 않았음
                        // if (circles[i]) {
                        //     if (this.hitTest(circles[i], overlapThreshold)) {
                        //         circle.classList.add("highlight", "matched");
                        //     }
                        //     else {
                        //         circle.classList.remove("highlight", "matched");
                        //     }
                        // }
                    },

                    onRelease: function () {
                        // console.log('onRelease')

                        if (!circle.classList.contains("matched")) {
                            let tl = gsap.timeline();
                            tl.to(this.target, {
                                background: "red",
                                duration: 1,
                                ease: "bounce.out",
                                yoyo: true,
                            }).to(this.target, {
                                x: 0,
                                y: 0,
                                onComplete: () => {
                                    gsap.set(this.target, {
                                        clearProps: "background",
                                    });
                                    ++self.totalFails;

                                    if (self.totalFails === self.totalTries) {
                                        self.$store.dispatch(
                                            "TOGGLE_TIMEOUT_MODAL",
                                            true
                                        );
                                    }
                                },
                            });

                            if (score < 0) {
                                score = 0;
                            }
                            circle.classList.remove("scored");
                        } else if (!circle.classList.contains("scored")) {
                            circle.classList.add("scored");
                        }
                    },

                    onDragEnd: () => {
                        // console.log('onDragEnd')

                        this.userSelection = this.rightKeywords.find((item) => {
                            return item.keyword === circle.innerHTML.trim();
                        });
                        // this.userSelection = this.rightKeywords[i];  // 이전 방식

                        let currentBox = correctCircle;
                        // let currentBox = circles[i];  // 이전 방식

                        if (!currentBox) {
                            return;
                        }
                        if (!this.userSelection) {
                            return;
                        }

                        if (circle.classList.contains("scored") && currentBox.style.visibility !== "hidden") {
                            score += 1;

                            currentBox.style.visibility = "hidden";
                            this.$store.dispatch("SHOW_RESULT", this.userSelection);

                            gsap.to(window, { scrollTo: ".feedback", });
                        }
                        else if (currentBox.style.visibility === "hidden") {
                            score -= 1;
                            currentBox.style.visibility = "visible";
                        }

                        score <= 0 ? (score = 0) : score;

                        if (score === this.rightKeywords.length) {
                            this.$store.dispatch("DRAG_AND_MATCH_COMPLETED");
                            this.$store.dispatch("SEND_ACTIVITY_LOG");
                            if (this.fakeCircles && this.fakeCircles.length > 0) {
                                gsap.set(".fake-circle-container", {
                                    display: "none",
                                });
                            }

                            this.draggableInstance.forEach((element) => {
                                element[0].kill();
                            });
                        }
                        circle = null;
                    },
                });
                this.draggableInstance.push(allDraggable);
            }
        },
    },
};
</script>

<style scoped>
.component-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}
small {
    width: 100%;
    color: var(--primary-white);
    text-align: right;
    font-family: var(--primary-font-medium);
    font-size: 1.6rem;
    font-style: normal;
    line-height: 140%; /* 22.4px */
}
</style>
