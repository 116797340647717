<template>
    <section class="home-overlay">
        <video id="intro" autoplay muted playsinline>
            <source src="@/assets/zoom.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </section>
</template>
    
<script>
export default {
    name: 'ZoomEffect2',

    mounted() {
        console.log('ZoomEffect2 mounted');

        let video = document.getElementById('intro');
        // console.log(video);
        video.addEventListener('ended', () => {
            // console.log('video ended');
            this.$emit('zoomCompleted');
        });

        // setTimeout(() => {
        //     this.$emit('zoomCompleted');
        // }, 7500);
    },
};
</script>
    
<style scoped>
.home-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200%;
    height: 100%;
}
</style>
