<template>
    <div class="long-modal-container">
        <article class="modal-wrapper-content">
            <div class="close-icon" @click="closeModal">
                <CloseIcon />
            </div>
            <div class="modal-text">
                <section v-if="contentFormat">
                    <p class="content-text" v-html="contentFormat"></p>
                </section>
            </div>
        </article>
    </div>
</template>

<script>
import CloseIcon from "../../components/icons/CloseIcon.vue";
import { gsap } from "gsap";
export default {
    components: {
        CloseIcon,
    },
    props: {
        title: {
            type: String,
        },
        subtitle: {
            type: String,
        },
        content: {
            type: String,
        },
        showBtn: {
            type: Boolean,
        },
    },
    mounted() {
        this.modalAnimation();
        this.animation.play();
    },
    data() {
        return {
            animation: gsap.timeline().pause(),
        };
    },
    computed: {
        contentFormat() {
            let text = this.content;

            // check if feedback has  Quotes
            //  move to backend TODO
            if (text.includes("<i>")) {
                text = text
                    // .replaceAll("<i>", "<br />")
                    // .replaceAll("<i/>", "<br />")
                    .replaceAll(
                        '"',
                        '<span class="quote-content" style="display: block;font-size: 3rem; margin-top:0.5rem; text-align: center; background:red;height:0">&#8221</span>'
                    );
                this.styleQuote();
            }
            return text;
        },
    },

    methods: {
        styleQuote() {
            setTimeout(() => {
                gsap.set(".quote-content:nth-of-type(1)", {
                    rotate: 180,
                    margin: "0.5rem",
                });
            }, 100);
        },
        closeModal() {
            this.animation.reverse();
            setTimeout(() => {
                this.$store.dispatch("TOGGLE_LONG_MODAL");
            }, 1000);
        },

        modalAnimation() {
            if (!this.animation.reversed()) {
                gsap.set(".long-modal-container", { clearProps: "all" });
                gsap.set(".modal-wrapper-content", { clearProps: "all" });
            }
            this.animation
                .set(".long-modal-container", {
                    top: 0,
                })
                .from(".modal-wrapper-content", {
                    yPercent: 100,
                    duration: 0.3,
                });
        },
    },
};
</script>

<style scoped>
/* 검은색 배경 */
.long-modal-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    overflow: scroll;
    z-index: 2000;
}
/* 팝업 화면 */
.modal-wrapper-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    background: var(--primary-black);
    border: 1px solid var(--primary-white);
    border-radius: 12px;
    padding: 2rem;
    word-break: keep-all;
}
.close-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    top: 0;
    right: 0;
}
/* 팝업 텍스트 묶음 */
.modal-text {
    max-height: 70vh;
    overflow-y: scroll;
}
/* 팝업 내용 */
.content-text {
    color: var(--primary-white);
    font-family: var(--primary-font-medium);
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
}
</style>
