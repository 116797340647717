<template>
    <svg
        width="25"
        height="43"
        viewBox="0 0 25 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.41421 20.0623L20.6014 0.875151C21.8613 -0.384778 24.0156 0.507553 24.0156 2.28936V40.6638C24.0156 42.4456 21.8613 43.3379 20.6014 42.078L1.41421 22.8908C0.633165 22.1097 0.633165 20.8434 1.41421 20.0623Z"
        />
    </svg>
</template>

<script>
export default {};
</script>
