import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(ScrollTrigger, Draggable);
let draggables = [];
export const cardDragAnimation = (destroy = false) => {
    if (destroy) {
        draggables.forEach((element) => {
            element[0].kill();
        });
        draggables = [];
        console.log(draggables);
        return;
    }
    console.log(draggables);
    let imageContainer = gsap.utils.toArray("#container");
    let artImg = gsap.utils.toArray("#draggable");
    let Btns = gsap.utils.toArray(".btn-navigation");

    for (let i = 0; i < imageContainer.length; i++) {
        let draggable = Draggable.create(artImg[i], {
            trigger: imageContainer[i],
            allowEventDefault: true,
            bounds: imageContainer[i],
            onPress: () => {
                if (Btns.length > 0) {
                    gsap.set(".btn-navigation", {
                        display: "none",
                    });
                }
            },
            onDragEnd() {
                gsap.set(".img", { clearProps: "zIndex" });
                if (Btns.length > 0) {
                    gsap.set(".btn-navigation", {
                        display: "block",
                    });
                }
            },
            onClick() {
                if (Btns.length > 0) {
                    gsap.set(".btn-navigation", {
                        display: "block",
                    });
                }
                gsap.set(".img", { clearProps: "zIndex" });
            },
        });
        draggables.push(draggable);
    }
};
