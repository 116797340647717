<template>
    <svg
        :class="{ ['is-left-arrow ']: isLeft }"
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.679583 18L12 9.45558V8.54443L0.679583 0L0 0.911148L10.7173 8.99988L0 17.0886L0.679583 18Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
    props: {
        isLeft: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.is-left-arrow {
    transform: rotateZ(180deg);
}
</style>
