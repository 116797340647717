<template>
    <section class="filter-section">
        <SwitchLanguage @localeChanged="$store.dispatch('GET_ART_LIST')" />
        <h4>Name or inventory number</h4>
        <input type="text" v-model="filters.name" />
        <h4>Groups</h4>
        <div class="filter-group-container">
            <button
                class="filter-btn"
                :class="{ isActive: filters.group == group }"
                @click="filters.group = group"
                v-for="group in groups"
                :key="group + 'group'"
            >
                {{ group }}
            </button>
        </div>
        <h4>Constellations</h4>
        <div class="filter-group-container">
            <button
                class="filter-btn"
                :class="{
                    isActive: filters.constellation == constellation,
                }"
                v-for="constellation in constellations"
                :key="constellation"
                @click="filters.constellation = constellation"
            >
                {{ constellation }}
            </button>
        </div>
    </section>
</template>

<script>
import { mapState } from "vuex";
import SwitchLanguage from "../ui/SwitchLanguage.vue";
export default {
    components: {
        SwitchLanguage,
    },

    props: {},
    computed: {
        ...mapState({
            filters: (state) => state.adminState.filters,
            group: (state) => state.adminState.group,
            constellation: (state) => state.adminState.constellation,
            constellations: (state) => state.adminState.constellations,
        }),
    },
    watch: {
        // filters changes, setFilter function will run
        "filters.group"() {
            this.$store.dispatch("SET_FILTERS");
        },
        "filters.constellation"() {
            this.$store.dispatch("SET_FILTERS");
        },
        "filters.name"() {
            this.$store.dispatch("SET_FILTERS");
        },
    },
};
</script>

<style scoped>
.filter-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 300px;
    margin: 0 auto;
}
.filter-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    flex-wrap: wrap;
}

.filter-btn {
    border: none;
    background: var(--primary-white);
    color: var(--primary-gray);
    border: 0.5px solid;
    padding: 0.5rem;
    opacity: 0.9;
    transition: var(--transition-1);
}
.filter-btn:hover {
    opacity: 1;
    filter: brightness(1.2);
}
.isActive {
    background: var(--primary-gray);
    color: var(--primary-white);
}
</style>
