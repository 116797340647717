<template>
    <div class="book">
        <div @click="handleCoverClick" class="book-cover move">
            <h2>The Book of knowledge</h2>
        </div>
        <div
            v-for="(constellation, idx) in constellations"
            :key="constellation.name"
            :id="constellation.name"
            :style="{ zIndex: -idx }"
            :class="idx < selected && 'move'"
            class="page"
        >
            <div @click="closeBtn" class="close-icon">X</div>
            <SingleBookContent
                @handleNextPageClick="handleNextPageClick"
                @handlePrevPageClick="handlePrevPageClick"
                :constellation="constellation"
            />
        </div>

        <div class="book-back">
            <span @click="handlePrevPageClick()" class="arrow-btn next">
                <ArrowIcon />
            </span>
            <h2>The end</h2>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";

import ArrowIcon from "../icons/ArrowIcon.vue";
import SingleBookContent from "../booksOfKnowledge/SingleBookContent.vue";
export default {
    components: {
        ArrowIcon,
        SingleBookContent,
    },
    props: {
        constellations: { type: Array },
        selected: { type: Number },
    },
    mounted() {
        gsap.set(".book", { perspective: 2000 });
        gsap.set(".page", {
            backfaceVisibility: "hidden",
            transformStyle: "preserve-3d",
        });

        const pages = gsap.utils.toArray(".move");
        this.prevPageAnimation(pages);
        this.counter = this.selected;
        setTimeout(() => {
            this.stagger = 0.4;
        }, 1200);
    },
    data() {
        return {
            counter: 0,
            ease: "none",
            duration: 0.7,
            stagger: 0.1,
        };
    },
    methods: {
        handleCoverClick() {
            this.prevPageAnimation(`.book-cover`);
        },
        handleNextPageClick() {
            if (this.counter == this.constellations.length) {
                return;
            }
            let constellation = this.constellations[this.counter];
            this.prevPageAnimation(`#${constellation.name}`);
            ++this.counter;
        },

        handlePrevPageClick() {
            if (this.counter == 0) {
                this.nextPageAnimation(".book-cover");
                return;
            }
            let constellation = this.constellations[this.counter - 1];

            this.nextPageAnimation(`#${constellation.name}`);
            --this.counter;
        },
        closeBtn() {
            const pages = gsap.utils.toArray(".page");
            let viewPages = pages
                .filter((page, idx) => idx < this.counter)
                .reverse();

            this.stagger = 0.1;
            this.nextPageAnimation(viewPages);

            setTimeout(async () => {
                this.$emit("closeBtn");
            }, 1000);
        },

        nextPageAnimation(currentPage) {
            gsap.to(currentPage, {
                rotationY: 0,
                transformOrigin: "0% 100%",
                duration: this.duration,
                stagger: this.stagger,
                ease: this.ease,
                onComplete: () => (this.stagger = 0.4),
            });
        },
        prevPageAnimation(currentPage) {
            gsap.to(currentPage, {
                rotationY: -180,
                transformOrigin: "0% 100%",
                duration: this.duration,
                stagger: this.stagger,
                ease: this.ease,
            });
        },
    },
};
</script>

<style scoped>
.book {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
}
.page,
.book-cover,
.book-back {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: var(--main-color-3);
    padding: 1rem;
    z-index: -12;
}

.book-cover {
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-size: 8px 8px;
}
.book-cover h2,
.book-back h2 {
    font-size: 12vh;
    margin-top: 5rem;
}
.book-back svg {
    fill: var(--primary-white);
}

.btn {
    height: 30px;
    width: fit-content;
}

.close-icon {
    width: 20px;
    font-size: 1rem;
    z-index: 20;
}
</style>
