import Vue from "vue";
import Vuex from "vuex";
import {
    gameState,
    activitiesState,
    adminState,
    authState,
} from "./states/index";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        gameState,
        activitiesState,
        adminState,
        authState,
    },
});
