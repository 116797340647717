<template>
    <div
        class="action-icon tutorial-icon"
        @click="$emit('handleTutorialIconClick')"
    >
        <InfoIcon />
    </div>
</template>

<script>
import InfoIcon from "../icons/InfoIcon.vue";

export default {
    components: {
        InfoIcon,
    },
    emits: ["handleTutorialIconClick"],
};
</script>

<style scoped></style>
