<template>
    <div
        class="modal-overlay-wrapper"
        :style="{ backgroundImage: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 50%, rgba(0,0,0,0) 100%), url('${currentArt.digital_collection}')` }"
    >
        <div class="progress-bg">
            <img class="stars-images" src="@/assets/img/found-story-stars.png" alt="background top stars">
            <!-- top: title -->
            <div class="top-wrapper">
                <h1>{{ $t("message.story_cleared") }}</h1>
                <p>{{ $route.params.storyName }}</p>
                <StoriesIndicator :activity_games="currentArt.activity_games" />
            </div>
            <!-- center: contents -->
            <div class="center-wrapper">
                <div class="popup-box">
                    <h1>{{ $t("message.popup_get_title") }}</h1>
                    <div class="popup-item">
                        <!-- earth img -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                            <circle cx="25" cy="25" r="24.5" stroke="black"/>
                            <path d="M9.49914 20.5C9.89914 20.1 9.6658 19 9.49914 18.5C8.66609 18.1667 6.8 17.6 6 18C5 18.5 4.99914 16 3.99914 16C2.99914 16 3.49899 19 3.99914 20C4.49922 20.9999 3.49926 22.4996 2.49925 23.9994L2.49884 24C1.4987 25.5 3.99899 26.5 2.99899 27.5C1.99899 28.5 2.99899 31.5 3.49899 32C3.99899 32.5 3.49899 31.5 4.78813 31C6.07728 30.5 6.99899 30.5 6.49899 29C5.99899 27.5 4.99914 25.5 3.99914 25C2.99914 24.5 3.99914 23 4.99914 22.5C5.99914 22 7.49914 26 8.99914 27C10.4991 28 10.4991 25.5 10.4991 28C10.4991 30.5 10.9991 30.5 11.9991 30C12.9991 29.5 12.9991 30 12.9991 31C12.9991 32 13.4991 34 13.9991 35.5C14.4991 37 13.9991 38 12.4991 38C10.9991 38 15.4991 40 15.9991 39C16.4991 38 19.4991 39.5 18.9991 37.5C18.4991 35.5 19.9991 35 20.9991 35C21.9991 35 24.9991 34.5 25.4991 34C25.9991 33.5 25.4991 33.5 23.4991 33.5C21.4991 33.5 21.9991 32.5 20.9991 31.5C19.9991 30.5 18.9991 31 17.9991 31C16.9991 31 17.9991 33 17.4991 33.5C16.9991 34 16.4991 31.5 15.9991 31.5C15.5991 31.5 15.4991 30.8333 15.4991 30.5C16.1658 30.5 17.4991 30.1 17.4991 28.5C17.4991 26.5 17.9991 26.5 17.9991 25C17.9991 23.5 15.9991 23.5 14.9991 23.5C13.9991 23.5 15.4991 21 14.4991 21C13.4991 21 13.4991 22 12.4991 23.5C11.6991 24.7 11.1658 23.6667 10.9991 23C10.6658 23.1667 9.89914 23.5 9.49914 23.5C8.99914 23.5 8.99914 21 9.49914 20.5Z" fill="#231F20"/>
                            <path d="M32 36C33.2 36.4 32.8333 35.8333 33 35.5C32.5 35.3333 33.4343 35.0657 34 34.5C34.5 34 35 34 35 34C35 34 36 33.5 36 33C36 32.5 36 32 36.5 31.5C37 31 37.5 32 37.5 33C37.5 33.8 36.8333 34.3333 36.5 34.5L35.5 36C35.8333 36 36.7 36.1 37.5 36.5C38.5 37 38 37 36.5 37.5C35 38 36 38.5 37 39.5C38 40.5 38.5 42 39 42C39.2445 42 39.5 41.5 39.5 40.5C39.5 39.5 40.5 40.5 41 39.5C41.5 38.5 42 38 42 37.5C42 37 41.5 36.5 40.5 36.5C39.5 36.5 40.5 35.5 40 34.5C39.5 33.5 40 32.5 41 32.5C42 32.5 42 31 42 30C42 29 42 28.5 41 28.5C40 28.5 38 29.5 38.5 28.5C39 27.5 37.5 26.5 37 26C36.5 25.5 37.5 25.5 38.5 25.5C39.5 25.5 38 24 37.5 24C37 24 36.5 24 36 25C35.5 26 36 26 35 26C34 26 34.5 25 35 24.5C35.5 24 35.5 23.5 34.5 23.5C33.5 23.5 34 22 35 22C36 22 37 21.5 38 21.5C39 21.5 38.5 19.5 38.5 19C38.5 17.882 38.8675 17.8162 39.5 17.5C40.5 17 40 17 41.5 18C43 19 42 19 41.5 20C41 21 40 20.5 39.5 21C39 21.5 39 22 39.5 23C40 24 40.5 24 41 24.5C41.5 25 41.5 25.5 43.5 25C45.5 24.5 45.5 25.5 46 26.5C46.5 27.5 45.5 27.5 46 29C46.5 30.5 47 31 45.5 31.5C44 32 45.5 32.5 45.5 34.5C45.5 36.5 45 35.5 43.5 37C42 38.5 42.5 39 42 40C41.5 41 40.5 41.5 40 43C39.5 44.5 37 43.5 36 43.5C35 43.5 36 41.5 35 41C34 40.5 32.5 42 31.5 43C30.5 44 28 43 26.5 43C25 43 26 42.5 26.5 41.5C27 40.5 26 38.5 25.4991 37.5C24.9983 36.5 27 37 28 37C29 37 29 37 29.5 36C30 35 30.5 35.5 32 36Z" fill="#231F20"/>
                            <path d="M22.5 30.5C22.5 29.7 23.1667 29.5 23.5 29.5C24.7 29.1 25 30.3333 25 31C25 31 26.5 30.5 27 30.5C26.8333 30.1667 26.6 29.4 27 29L28 28L26.5 26.5C26 26 27.5 26.5 28 26.5C28.5 26.5 29 27 29.5 27C29.9 27 30.3333 26.6667 30.5 26.5C30.8333 26.5 31.6 26.7 32 27.5C32.4 28.3 31.8333 28.8333 31.5 29C31.3333 28.6667 30.8 28 30 28C29 28 29 29 28.5 30C28 31 28 31.5 28 32C28 32.5 26.5 31.5 26 31.5C25.5 31.5 24.5 32 24 32C23.5 32 22.5 31.5 22.5 30.5Z" fill="#231F20"/>
                            <path d="M30.5 22V21C30.3764 19 31.5 18.5 32 18.5C32.5 18.5 32 17.5 31 17C30 16.5 32.5 16.5 33.5 16.5C34.5 16.5 35 17.5 35 18.5V20.5C35 21 34 20 33.5 20C33 20 33.5 20.5 32.5 21C31.5 21.5 32.5 22 33 22C33.5 22 32.5 24 31.5 24C30.7 24 30.5 22.6667 30.5 22Z" fill="#231F20"/>
                        </svg>
                        <p>{{ competences.length }} {{ $t("message.competences") }}</p>
                    </div>
                </div>
                <div v-if="!currentArt.core_art && currentArt.completed_stories == 1" class="popup-box">
                    <div class="popup-item">
                        <!-- key img -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                            <circle cx="25" cy="25" r="24.5" fill="white" stroke="black"/>
                            <path d="M32.7662 19.0948C30.1011 19.0929 27.8812 21.0582 27.4734 23.61L12.9978 23.6016C12.5297 23.6013 12.1508 23.9802 12.1511 24.4483L12.1546 29.5322C12.1549 30.0004 12.5343 30.3798 13.0025 30.3801C13.4706 30.3804 13.8495 30.0015 13.8492 29.5334L13.8462 25.2971L17.2354 25.2995L17.2383 29.5357C17.2387 30.0039 17.6181 30.3833 18.0862 30.3836C18.5543 30.3839 18.9333 30.005 18.9329 29.5369L18.93 25.3006L27.4749 25.3065C27.887 27.8596 30.1095 29.8279 32.7739 29.829C35.7277 29.831 38.1393 27.4194 38.1371 24.4657C38.1351 21.5119 35.7199 19.0967 32.7662 19.0948ZM32.7674 20.7894C34.8053 20.7908 36.4401 22.4256 36.4423 24.4643C36.4437 26.5022 34.8112 28.1348 32.7724 28.1342C30.7345 28.1328 29.0997 26.498 29.0975 24.4593C29.0961 22.4214 30.7286 20.7888 32.7674 20.7894Z" fill="black"/>
                        </svg>
                        <p>{{ $t("message.new_key") }}</p>
                    </div>
                </div>
                <div v-if="!currentArt.core_art && numberOfKeyFound == 2 && currentArt.completed_stories == 1" class="popup-box">
                    <div 
                        class="popup-item img-box"
                        :style="{ backgroundImage: `url('${artsByConstellation[0].digital_collection}')` }"
                    >
                        <p>{{ $t("message.popup_core_unlock") }}</p>
                    </div>
                </div>
            </div>
            <!-- bottom: button -->
            <div class="bottom-wrapper">
                <button class="btn" @click="handleClick" >{{ $t("message.continue") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import StoriesIndicator from "../selectImage/StoriesIndicator.vue";
import { mapState } from "vuex";

export default {
    props: {
        competences: {
            type: Array,
        },
        numberOfKeyFound: {
            type: Number,
        },
        keysMessage: {
            type: String,
        },
    },
    components: {
        StoriesIndicator,
    },
    computed: {
        ...mapState({
            currentArt: (state) => state.gameState.currentArt,
            artsByConstellation: (state) => state.gameState.artsByConstellation,
        }),
        completedStories() {
            return this.currentArt.activity_games.filter(
                (item) => item.is_completed
            ).length;
        },
    },
    methods: {
        handleClick() {
            this.$emit("NewArtStoryModalClick");
        },
    },
}
</script>

<style scoped>
.modal-overlay-wrapper {
    background-position: top;
    background-size: cover;
}
.progress-bg {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    border: none;
    padding: 2.2rem 1.25rem;
    gap: 0;
}
.stars-images {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
/* title wrapper */
.top-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
}
.top-wrapper h1 {
    color: var(--primary-white);
    text-align: center;
    text-shadow: 0px 0px 15px var(--primary-black);
    font-family: var(--primary-font-light);
    font-size: 4rem;
    font-style: normal;
    line-height: 100%; /* 40px */
    margin-bottom: 2rem;
}
.top-wrapper p {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-light);
    font-size: 2rem;
    font-style: normal;
    line-height: 130%; /* 26px */
}
/* popup contents */
.center-wrapper {
    width: 100%;
    max-height: 510px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
    overflow: scroll;
}
.center-wrapper::-webkit-scrollbar {
    display: none;
}
.popup-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--primary-white);
    background: var(--primary-white);
    padding: 1rem 1.2rem;
    gap: 1.8rem;
}
.popup-item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #E1E1E1;
    background: var(--primary-white);
    padding: 1.5rem 1.5rem 1.5rem 3rem;
    gap: 2.1rem;
}
.popup-box h1 {
    color: var(--primary-black);
    text-align: center;
    font-family: var(--primary-font-light);
    font-size: 4rem;
    font-style: normal;
    line-height: 100%; /* 40px */
    margin-top: 1rem;
}
.popup-item p {
    color: var(--primary-black);
    font-family: var(--primary-font-medium);
    font-size: 2.4rem;
    font-style: normal;
    line-height: 130%; /* 31.2px */
    text-transform: none;
}
.popup-item.img-box {
    width: 100%;
    height: 17rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: flex-end;
    padding: 1rem;
}
.popup-item.img-box p {
    color: var(--primary-white);
    text-shadow: 0px 0px 4px var(--primary-black);
}
.px-40 {
    padding: 0 2.5rem;
}
/* bottom */
.bottom-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
</style>