<template>
    <section class="links-container">
        <router-link
            v-if="!allCompleted"
            class="btn"
            :to="{
                name: 'StoryList',
                params: {
                    zodiacName: this.$route.params.zodiacName,
                    inventoryNumber: inventory_number,
                },
            }"
            >{{ $t("message.play_another_story") }}</router-link
        >
        <router-link
            class="btn"
            :to="{
                name: 'SelectImage',
                params: {
                    zodiacName: $route.params.zodiacName,
                },
            }"
            >{{ buttonText }}</router-link
        >
    </section>
</template>

<script>
export default {
    props: {
        allCompleted: {
            type: Boolean,
        },
        buttonText: {
            type: String,
        },
        inventory_number: {
            type: String,
        },
    },
};
</script>

<style scoped>
.links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.btn {
    border-radius: 0;
    border: 1px solid var(--primary-white);
    background: transparent;
    color: var(--primary-white);
    font-size: 1.375rem;
    width: 100%;
}
</style>
