import { render, staticRenderFns } from "./DragArt.vue?vue&type=template&id=9e78b496&scoped=true&"
import script from "./DragArt.vue?vue&type=script&lang=js&"
export * from "./DragArt.vue?vue&type=script&lang=js&"
import style0 from "./DragArt.vue?vue&type=style&index=0&id=9e78b496&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e78b496",
  null
  
)

export default component.exports