import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/global.css";
import "./assets/css/style.css";
import i18n from "./i18n";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";

// Vue.prototype.$http = axios;

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

// axios.defaults.baseURL =
//     "http://ec2-54-180-101-134.ap-northeast-2.compute.amazonaws.com:8000/api/v1/";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

Vue.config.productionTip = false;

new Vue({ i18n, store, router, axios, render: (h) => h(App) }).$mount("#app");
