<template>
    <div class="modal-wrapper">
        <div class="modal-wrapper-content">
            <div class="close-btn" @click="$emit('closeBtn')">
                <CloseIcon :color="'black'" />
            </div>
            <h2>{{ modalTitle }}</h2>
            <p>{{ modalText }}</p>
        </div>
    </div>
</template>

<script>
import CloseIcon from "../icons/CloseIcon.vue";
export default {
    emits: ["closeBtn"],
    components: {
        CloseIcon,
    },
    props: {
        art: {
            type: Object,
        },
        counter: {
            type: Number,
        },
        canCollectBook: {
            type: Boolean,
        },
    },
    mounted() {
        this.setModal();
    },
    data() {
        return {
            modalText: "",
            modalTitle: "",
            modalBtn: "",
        };
    },

    methods: {
        setModal() {
            this.modalTitle = this.$t(`message.wait_core_art_title`);
            this.modalText = this.$t(`message.wait_core_art_message`);
            // if (this.art.core_art && this.counter !== 2 && !this.art.isFound) {
            //     this.modalTitle = this.$t(`message.wait_core_art_title`);
            //     this.modalText = this.$t(`message.wait_core_art_message`);
            //     this.modalBtn = this.$t(`message.close`);
            // } else if (this.art.isFound) {
            //     this.modalTitle = "Already found!";
            //     this.modalText = "Complete another story";
            //     this.modalBtn = this.$t("message.confirm");
            // } else {
            //     if (
            //         this.art.room.toLowerCase() === "digital collection" ||
            //         this.art.room.toLowerCase() === "digitale cammlung"
            //     ) {
            //         this.modalTitle = this.$t(
            //             `message.art_from_digital_collection_title`
            //         );
            //         this.modalText =
            //             this.$t(`message.art_from_digital_collection_message`) +
            //             this.art.inventory_number;
            //         this.modalBtn = this.$t(`message.enter_code`);
            //     }
            // }
        },
    },
};
</script>

<style scoped>
.modal-wrapper-content {
    width: 90%;
    max-width: 600px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 12px;
    border: 1px solid white;
    gap: 1.25rem;
    padding: 2rem 1.25rem;
    margin: 0 auto;
    box-shadow: 0px 30px 15px 0px rgba(0, 0, 0, 0.50);
}
.close-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 100%;
    font-family: var(--primary-font-medium);
    color: black;
}
p {
    color: black;
    font-family: var(--primary-font-medium);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
</style>
