<template>
    <div>
        <div class="under-line"></div>
        <main>
            <BackgroundStars />
            <!-- <CompetencesFilters @handleSort="handleSort" :sortType="sortType" /> -->
            <section class="section-wrapper">
                <div v-for="item in 12" :key="item" class="orbit-circle"></div>
                <CompetencesSection
                    v-show="competencesList.length > 0"
                    :competencesList="competencesList"
                />
            </section>
        </main>
    </div>
</template>

<script>
import axios from "axios";
import { gsap } from "gsap";
import BackgroundStars from "../../components/ui/BackgroundStars.vue";
import { Flip } from "gsap/Flip";
gsap.registerPlugin(Flip);

import {
    CompetencesSection,
    // CompetencesFilters,
} from "../../components/";
import {
    checkShowModal,
    modalAlreadySeem,
} from "../../helperFunctions/modalChecks";

import {
    tutorialEnglish,
    tutorialGerman,
} from "../../utils/tutorial/competences-tutorials";
export default {
    components: {
        BackgroundStars,
        CompetencesSection,
        // CompetencesFilters,
    },
    data() {
        return {
            competencesList: [],
            competences: [],
            sortType: "most",
            showTutorial: true,
            tutorialArray: [],
        };
    },
    mounted() {
        if (this.$i18n.locale === "en") {
            this.tutorialArray = tutorialEnglish;
        } else {
            this.tutorialArray = tutorialGerman;
        }

        this.getCompetences();
        this.showTutorial = checkShowModal({
            name: "competencesTutorial",
            storageName: "tutorials",
        });
    },

    computed: {
        competenceProgress() {
            return (competence) =>
                Math.floor((competence.collected * 100) / competence.total);
        },
        scaleText() {
            return (size) => gsap.utils.clamp(1, 1.5, size / 90);
        },
    },

    methods: {
        handleSort(sortType) {
            this.sortType = sortType;
            this.competencesList = [...this.competences];

            const competencesList = gsap.utils.toArray(".single-competence");

            if (sortType === "most") {
                competencesList.sort(
                    (a, b) => b.dataset.total - a.dataset.total
                );
            } else if (sortType === "collected") {
                competencesList.sort(
                    (a, b) => b.dataset.collected - a.dataset.collected
                );
            } else {
                competencesList.sort(
                    (a, b) => b.dataset.index + a.dataset.index
                );
            }

            const prevState = Flip.getState(".single-competence");

            competencesList.forEach((item, index) => {
                item.style.margin = 0;
                item.style.order = index + 1;
                item.style.marginTop = index % 2 == 0 && "50px";
                item.style.marginBottom = index % 2 == 1 && "10px";
            });

            Flip.from(prevState, {
                duration: 1,
                ease: "power2.inOut",
            });
        },
        handleTutorialCompleted() {
            modalAlreadySeem({
                name: "competencesTutorial",
                storageName: "tutorials",
            });
            this.showTutorial = !this.showTutorial;
        },
        async getCompetences() {
            try {
                let player_id = localStorage.getItem("player__id");

                let game_id = localStorage.getItem("game__id");
                const res = await axios.post(
                    "game/player-game-competences",
                    { player_id, game_id },
                    {
                        headers: {
                            "Accept-Language": this.$i18n.locale,
                        },
                    }
                );

                this.competences = res.data;

                this.competencesList = [...this.competences];
                setTimeout(() => {
                    this.handleSort("most");
                });

                const clamper = gsap.utils.clamp(0, 150);

                this.competencesList.forEach((el) => {
                    let size = ((100 * el.total) / 150).toFixed();
                    size = parseFloat(size * 2);
                    el.size = clamper(size * 3 + 50);
                });

                const orbits = gsap.utils.toArray(".orbit-circle");
                gsap.set(orbits, { opacity: 0 });

                setTimeout(() => {
                    gsap.to(orbits, {
                        y: -100,
                        x: 200,
                        width: (i) => (1 + i) * 300,
                        height: (i) => (1 + i) * 300,
                        left: "50%",
                        opacity: 1,
                        transform: "translateX(-50%)",
                    });
                }, 100);
                // setTimeout(() => {
                //     gsap.to(orbits, {
                //         y: -100,
                //         x: 200,
                //         width: (i) => (1 + i) * 300,
                //         height: (i) => (1 + i) * 300,
                //         left: "50%",
                //         opacity: 1,
                //         transform: "translateX(-50%)",
                //     });
                // }, 1500);
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.main-container {
    overflow: hidden;
    max-width: 370px;
}
main {
    padding: 1rem;
    overflow: hidden;
}

.section-wrapper {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    gap: 1.4rem;
    justify-content: space-around;
    align-items: center;
    max-width: 370px;
    margin: 0 auto;
    position: relative;
}

.orbit-circle {
    border: 1px solid var(--primary-white);
    position: absolute;
    border-radius: 50%;
    border-width: 0.5px;
    opacity: 0;
    z-index: -1;
}
.no-scroll {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100 - 78px);

    overflow: hidden;
}
</style>
