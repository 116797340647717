<template>
    <div class="content-container">
        <section class="section-title">
            <h2>{{ message }}</h2>
        </section>
        <section>
            <div class="art-container">
                <div class="image-container">
                    <span>
                        <RewardIcon class="icon-wrapper" />
                        <IconKey class="key" />
                    </span>
                    <img :src="currentArt.digital_collection" alt="" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { gsap } from "gsap";
import RewardIcon from "../icons/RewardIcon.vue";
import IconKey from "../icons/IconKey.vue";
export default {
    components: {
        RewardIcon,
        IconKey,
    },
    props: {
        currentArt: {
            type: Object,
        },
        totalNumberOfKeys: {
            type: Number,
        },
        numberOfKeyFound: {
            type: Number,
        },
    },
    mounted() {
        this.artProgress();
    },
    data() {
        return {
            message: "",
            counter: 0,
            buttonMessage: "",
            canPlayCoreArt: false,
            allStoriesCompleted: false,
        };
    },
    methods: {
        keyAnimations() {
            let tl = gsap.timeline();
            tl.set(".key", { xPercent: -50, yPercent: -50 });
            tl.set(".section-title, .links-container", {
                opacity: 0,
                yPercent: 50,
            })
                .to(".key", {
                    rotateY: "-360deg",
                    duration: 2.5,
                })
                .to(
                    ".key",
                    {
                        rotateX: "360",
                        duration: 1.25,
                    },
                    0.5
                )
                .to(".key", {
                    width: 29,
                    height: 13,
                    xPercent: -50,
                    yPercent: 0,
                    top: "8px",
                })
                .to(
                    ".section-title",
                    {
                        opacity: 1,
                        yPercent: 0,
                    },
                    "-=.08"
                )
                .to(
                    ".links-container",
                    {
                        opacity: 1,
                        yPercent: 0,
                    },
                    "+=.01"
                );
        },
        artProgress() {
            let counter = 0;
            this.currentArt.activity_games.forEach((activity) => {
                if (activity.is_completed) {
                    ++counter;
                }
            });

            if (!this.currentArt.core_art) {
                if (this.currentArt.activity_games.length === counter) {
                    this.allStoriesCompleted = true;
                    this.$emit("allStoriesCompleted", true);
                }
                if (this.totalNumberOfKeys !== this.numberOfKeyFound) {
                    if (counter !== 1) {
                        this.message = `Congratulations, you collected ${counter} out ${this.currentArt.activity_games.length}  stories`;
                    } else {
                        this.message = `You have found the key of "${this.currentArt.title}" you need to find one more key to unlocked the core art.`;
                        this.keyAnimations();
                        return;
                    }
                } else if (this.totalNumberOfKeys == this.numberOfKeyFound) {
                    if (counter === 1) {
                        this.message = `You have found the key of "${this.currentArt.title}" and you unlock the core art.`;
                        this.keyAnimations();
                        return;
                    } else {
                        this.message = `Congratulations, you collected ${counter} out ${this.currentArt.activity_games.length}  stories`;
                    }
                    this.canPlayCoreArt = true;
                }
            } else {
                this.message = `Congratulations, you collected "The book of knowledge"`;
            }
            gsap.set(".key", {
                width: 29,
                height: 13,
                xPercent: -50,
                yPercent: 0,
                top: "8px",
            });
        },
    },
};
</script>

<style scoped>
.content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    text-align: center;
    gap: 1rem;
}

.section-title {
    border-bottom: 0.5px solid var(--primary-white);
    /* padding: 31px 0; */
}
.art-container {
    border: 1px solid var(--primary-white);
    padding: 10px;
    /* margin: 58px 0; */
}

.image-container {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    border: 1px solid var(--primary-white);
}
.image-container img {
    width: 100%;
    max-height: 300px;
}
.icon-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    /* test */
    perspective: 1500px;
}
.key {
    position: absolute;
    left: 50%;
    width: 50vw;
    height: 50vh;
}
</style>
