<template>
    <section
        :class="{
            ['is-completed']: item.is_completed,
        }"
        class="single-art-wrapper"
    >
        <div class="circle-constellation"></div>
        <div class="section-wrap">
            <section class="section-right" :class="`right-item-${idx}`">
                <div
                    class="story-circle"
                    :class="{
                        ['is-core-art']: item.core_art,
                    }"
                    :style="
                        item.isFound &&
                        `borderColor: var(--${item.constellation}-color); background:var(--${item.constellation}-color)`
                    "
                >
                    <FoundIndicator
                        class="indicator"
                        :isCoreArt="item.core_art"
                        :isFound="item.isFound"
                        :absolute="false"
                        :counter="counter"
                        :constellation="item.constellation"
                    />
                </div>
            </section>
            <div class="section-left">
                <div class="img-wrapper">
                    <div v-if="item.core_art && (counter < 2)" class="not-found-yet"></div>
                    <img
                        class="art-img"
                        :src="item.digital_collection"
                        :style="
                            item.isFound &&
                            `borderColor: var(--${item.constellation}-color)`
                        "
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FoundIndicator from "../../components/selectImage/FoundIndicator.vue";

export default {
    components: {
        FoundIndicator,
    },
    props: {
        item: {
            type: Object,
        },
        idx: {
            type: Number,
        },
        counter: {
            type: Number,
        },
    },
};
</script>

<style scoped>
.single-art-wrapper {
    width: 100%;
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.section-wrap {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.section-left,
.section-right {
    z-index: 1;
}
.img-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 175px;
    max-height: 95px;
    min-width: 147px;
    min-height: 80px;
    border: 1px solid var(--primary-white);
    overflow: hidden;
}
.art-img {
    width: 100%;
    object-fit: cover;
}
.section-left p {
    text-align: center;
}
.not-found-yet {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--primary-white);
    opacity: 0.6;
    z-index: 5;
}

.story-circle {
    width: 46px;
    height: 46px;
    border: 1px solid var(--primary-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-black);
}
.is-core-art {
    width: 78px;
    height: 78px;
}
.is-found {
    border-color: var(--green-matrix-2);
}

.circle-constellation {
    width: 120%;
    height: 100%;
    border-radius: 50%;
    border-top: 0.5px solid var(--primary-white);
    z-index: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
}
.right-item-0 {
    margin-left: 5%;
}
.right-item-1 {
    margin-left: 20%;
}
.right-item-2 {
    margin-left: 10%;
}
@media (max-width: 412px) {
    .section-wrap {
        width: 90%;
    }
}
</style>
