<template>
    <div class="zoom-section">
        <div class="zoom-section__wrapper-options"  @click="closeZoom">
            <CloseIcon class="close-icon"/>
        </div>
        <div class="pinch-container">
            <div class="zoom-area">
                <img id="pinch" :src="image" alt="" />
            </div>
        </div>
        <p class="tip">{{ $t('message.zoom_double_tap') }}</p>
    </div>
</template>
<script>
import { mapState } from "vuex";
import PinchZoom from "../../../node_modules/pinch-zoom-js";
import CloseIcon from "../icons/CloseIcon.vue";

export default {
    components: {
        CloseIcon,
    },
    props: {
        activity: { type: Object },
    },
    mounted() {
        this.pinch();
    },

    data() {
        return {
            pinchZoomInstance: null,
        };
    },

    computed: {
        ...mapState({
            optionIndex: (state) => state.activitiesState.optionIndex,
            image() {
                const image = this.activity.img
                    ? this.activity.img
                    : this.activity.options[this.optionIndex].img;

                return image.startsWith("http")
                    ? image
                    : require(`../../assets/img/art-img/${image}`);
            },
        }),
    },
    methods: {
        pinch() {
            const el = document.querySelector("div.zoom-area");
            this.pinchZoomInstance = new PinchZoom(el, {
                draggableUnzoomed: false,
                tapZoomFactor: 4,
                animationDuration: 600,
                setOffsetsOnce: true,
                minZoom: 1,
            });
            this.pinchZoomInstance.enable();
        },
        closeZoom() {
            this.zoom = this.minZoom;
            this.$store.dispatch("TOGGLE_ZOOM_COMPONENT");
            this.pinchZoomInstance.destroy();
        },
    },
};
</script>

<style scoped>
.zoom-section {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000; /* todo */

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary-black);
}

.close-icon {
    width: 25px;
    height: 25px;
    top: 25px;
    right: 20px;
    border: none;
    background: transparent;
    font-size: 1.25rem;
}

.pinch-container {
    width: 100%;
    height: 290px;
    overflow: hidden;
}

img {
    width: 100%;
}

.tip {
    color: var(--primary-white);
    text-align: center;
    font-family: var(--primary-font-medium);
    font-size: 1.6rem;
    font-style: normal;
    line-height: 140%; /* 22.4px */
}
</style>
