<template>
    <svg
        width="30"
        height="37"
        viewBox="0 0 30 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.625 35L0.625 35.7142L1.2129 35.3087L15.4922 25.4609L28.7768 35.3013L29.375 35.7445L29.375 35L29.375 0.999999L29.375 0.624999L29 0.624999L0.999998 0.625L0.624998 0.625L0.624999 1L0.625 35Z"
            stroke="white"
            stroke-width="0.75"
        />
        <circle
            cx="15.5"
            cy="13.5"
            r="7.125"
            stroke="white"
            stroke-width="0.75"
        />
        <path
            d="M16.0175 10.644C16.0175 10.308 15.7967 10.0104 15.4127 10.0104C15.0287 10.0104 14.8079 10.308 14.8079 10.644C14.8079 10.9896 15.0287 11.2872 15.4127 11.2872C15.7967 11.2872 16.0175 10.9896 16.0175 10.644ZM16.6127 16.5L16.5839 16.1448L16.3439 16.116C16.0367 16.0968 15.9791 16.0392 15.9791 15.9432V12.0264L14.4143 12.4584L14.4047 12.7368L14.6063 12.7848C14.9519 12.8808 15.0191 12.9672 15.0191 13.572V15.9336C15.0191 16.0488 14.9231 16.0968 14.6927 16.116L14.4527 16.1448L14.4239 16.5H16.6127Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {};
</script>

<style scoped></style>
