<template>
    <div class="menu-background">
        <div class="under-line"></div>
        <div class="menu-container">
            <!-- Message from Al -->
            <router-link class="menu-item" :to="{ name: 'MessageFromAl' }">
                <ConstellationsIcon :newStar="false" />
                <h1 class="font-light">{{ $t("message.title_Al_message") }}</h1>
            </router-link>
            <div class="menu-dashed"></div>

            <!-- constellation book: about star sign -->
            <router-link class="menu-item" :to="{ name: 'ConstellationBook' }">
                <ConstellationsIcon :newStar="false" />
                <h1 class="font-light">{{ $t("message.title_constellation_book") }}</h1>
            </router-link>
            <div class="menu-dashed"></div>

            <!-- color wheel -->
            <router-link class="menu-item" :to="{ name: 'WheelOfColors' }">
                <ConstellationsIcon :newStar="false" />
                <h1 class="font-light">{{ $t("message.title_color_wheel") }}</h1>
            </router-link>
            <div class="menu-dashed"></div>

            <!-- competences -->
            <router-link class="menu-item" :to="{ name: 'Competences' }">
                <ConstellationsIcon :newStar="false" />
                <h1 class="font-light">{{ $t("message.competences") }}</h1>
            </router-link>
            <div class="menu-dashed"></div>

            <!-- book of knowledge -->
            <router-link class="menu-item" :to="{ name: 'BooksArchive' }">
                <ConstellationsIcon :newStar="false" />
                <h1 class="font-light">{{ $t("message.book_of_knowledge") }}</h1>
            </router-link>
            <div class="menu-dashed"></div>

            <div class="menu-small">
                <!-- discover -->
                <router-link class="menu-item" :to="{ name: 'Discover' }">
                    <!--<ConstellationsIcon :newStar="false" />-->
                    <h3 class="font-light">{{ $t("message.title_discover") }}</h3>
                </router-link>
                <div class="menu-dashed"></div>

                <!-- legal notice -->
                <a class="menu-item" :href="$i18n.locale==='de' ? 
                    'https://www.staedelmuseum.de/de/impressum':
                    'https://www.staedelmuseum.de/en/imprint'" target="_blank">
                    <!--<ConstellationsIcon :newStar="false" />-->
                    <h3 class="font-light">{{ $t("message.title_creators") }}</h3>
                </a>
                <div class="menu-dashed"></div>
                
                <!-- privacy policy -->
                <a class="menu-item" :href="$i18n.locale==='de' ? 
                    'https://www.staedelmuseum.de/de/datenschutz':
                    'https://www.staedelmuseum.de/en/privacy-policy'" target="_blank">
                    <!--<ConstellationsIcon :newStar="false" />-->
                    <h3 class="font-light">{{ $t("message.title_my_page") }}</h3>
                </a>
                <div class="menu-dashed"></div>
            </div>
        </div>
    </div>
</template>
<script>
import ConstellationsIcon from "../../components/icons/ConstellationsIcon.vue";
export default {
    components: {
        ConstellationsIcon,
    },
}
</script>
<style scoped>
.menu-background {
    width: 100%;
    overflow: scroll;
}
.menu-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--primary-black);
    padding: 0 2rem;
}
.menu-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2.5rem 1.2rem;
    gap: 1rem;
}
.menu-dashed {
    width:100%;
    background-image: linear-gradient(to right, white 50%, rgba(255,255,255,0) 0%);
    background-size: 10px 1px;
    background-position: bottom;
    background-repeat: repeat-x;
    padding-bottom: 1px;
}
.menu-dashed-bold {
    width:100%;
    background-image: linear-gradient(to right, white 50%, rgba(255,255,255,0) 0%);
    background-size: 10px 2px;
    background-position: bottom;
    background-repeat: repeat-x;
    padding-bottom: 1px;
}
.menu-item h1 {
    width: 100%;
    color: var(--primary-white);
    font-size: 3rem;
    text-align: left;
}

.menu-small {
    margin-left: 40px;
}

</style>