<template>
    <div class="main-container">
        <h2 class="tutorial-title">{{ $t("message.tutorial") }}</h2>
        <div class="modal-content">
            <span @click="nextSlide" ref="nextArrow" class="slider-btn next">
                <ArrowIcon />
            </span>
            <span @click="prevSlide" ref="prevArrow" class="slider-btn prev">
                <ArrowIcon />
            </span>
            <Sliders
                :activeSlideIndex="activeSlideIndex"
                :slides="slides"
                v-if="showSlides"
                @tutorialBtn="$emit('tutorialBtn')"
            />
        </div>
    </div>
</template>
<script>
import ArrowIcon from "./icons/ArrowIcon.vue";
import Sliders from "./Sliders.vue";
export default {
    components: {
        ArrowIcon,
        Sliders,
    },
    props: {
        slides: {
            type: Array,
            default: () => [
                {
                    id: 1,
                    title: "1",
                    image: "constellations_tutorial_2",
                    dsc: "description",
                },
                {
                    id: 2,
                    title: "2",
                    image: "constellations_tutorial_2",
                    dsc: "",
                },
                {
                    id: 3,
                    title: "3",
                    image: "constellations_tutorial_3",
                    dsc: "description",
                },
            ],
        },
    },
    mounted() {
        this.orderSlides(this.slides, this.activeSlideIndex);
    },
    data() {
        return {
            activeSlideIndex: 0,
            showSlides: false,
        };
    },

    methods: {
        nextSlide() {
            ++this.activeSlideIndex;
            this.displayOrder(this.activeSlideIndex);
        },
        prevSlide() {
            --this.activeSlideIndex;
            this.displayOrder();
        },
        displayOrder() {
            if (this.activeSlideIndex === this.slides.length) {
                this.activeSlideIndex = 0;
            } else if (this.activeSlideIndex < 0) {
                this.activeSlideIndex = this.slides.length - 1;
            }

            this.orderSlides(this.slides, this.activeSlideIndex);
        },
        orderSlides(array, activeIndex) {
            array.forEach((element, idx) => {
                element.position = "nextSlide";
                if (idx === activeIndex) {
                    element.position = "activeSlide";
                } else if (
                    idx === activeIndex - 1 ||
                    (activeIndex === 0 && idx === array.length - 1)
                ) {
                    element.position = "lastSlide";
                }
            });
            if (!this.showSlides) {
                this.showSlides = true;
            }

            // toggle arrows
            if (activeIndex === array.length - 1) {
                this.$refs.nextArrow.style.display = "none";
            } else if (activeIndex === 0) {
                this.$refs.prevArrow.style.display = "none";
            } else {
                this.$refs.nextArrow.style.display = "block";
                this.$refs.prevArrow.style.display = "block";
            }
        },
    },
};
</script>

<style scoped>
.main-container {
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    display: grid;
    place-content: center;
    color: black;
}
.modal-content {
    width: 100vw;
    max-width: 380px;
    margin: 0 auto;
    padding: 2rem;
    height: 550px;
    position: relative;
    padding-top: 37px;
    padding-bottom: 20px;
}
.slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.slider-btn svg {
    fill: var(--primary-white);
}
.next {
    right: 0;
}
.next svg {
    transform: rotate(-180deg);
}

.prev {
    left: 0;
}
.tutorial-title {
    color: white;
}
</style>
