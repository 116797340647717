<template>
    <main>
        <div class="main-container">
            <div class="under-line"></div>
            <div class="contents-wrap">
                <h1>{{ $t('message.title_my_page') }}</h1>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
        };
    },
}
</script>

<style scoped>
main,
.contents-wrap {
    width: 100%;
    height: 100%;
}
.contents-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>