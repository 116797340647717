<template>
    <div class="modal-overlay-wrapper">
        <div>
            <h2 v-if="showCoreArtCompleted" class="progress-modal-title">{{ $t('message.core_art_cleared') }}</h2>
            <p class="transmit-data">{{ $t('message.transmit_data') }}</p>
        </div>
        <!-- <h2 v-if="showCoreArtCompleted" class="progress-modal-title">Core Art Completed</h2> -->
        <ConstellationNav
            :canRotate="false"
            @loaded="loaded"
            ref="ConstellationNav"
        />
        <div class="send-message-section" v-if="showCoreArtCompleted">
            <p class="light-text">{{ $t('message.get_page') }}</p>
            <!-- <p class="light-text">Now you can get a page of the book of knowledge</p> -->

            <!-- <p class="medium-text">{{ $t("message.send_text") }}</p> -->

            <button class="btn" @click="$emit('sendMessage')">{{ $t('message.get_a_page_btn') }}</button>
            <!-- <button class="btn" @click="$emit('sendMessage')">Get a page</button> -->
        </div>
    </div>
</template>

<script>
import ConstellationNav from "../constellation/ConstellationsNav.vue";
import { gsap } from "gsap";
export default {
    components: {
        ConstellationNav,
    },
    props: {
        showCoreArtCompleted: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            activeSlideIndex: 0,
        };
    },

    mounted() {},

    methods: {
        loaded() {
            const art = document.querySelector(
                `#id${this.$route.params.inventoryNumber
                    .replace(" ", "")
                    .replace(".", "")}art`
            );

            let tl = gsap.timeline();
            tl.to("#wheelContainerWrapper", { yPercent: -110, duration: 0.7 })
                .to(art, {
                    filter: "brightness(2)",
                    repeat: 3,
                    ease: "none",
                    yoyo: true,
                })
                // .to(
                //     ".modal-overlay-wrapper",
                //     {
                //         background: "rgba(0, 0, 0, 0.85)",
                //     },
                //     0.2
                // )
                .to("#wheelContainerWrapper", {
                    opacity: 0,
                    onComplete: () => this.$emit("wheelAnimationCompleted"),
                })
                .to("#wheelContainerWrapper", {
                    opacity: 1,
                });
        },
    },
};
</script>

<style scoped>
.modal-overlay-wrapper {
    background: rgba(0, 0, 0, 0.85);
    justify-content: space-between;
    align-items: center;
    padding: 10rem 2rem 2rem;
}
.progress-modal-title {
    width: 100%;
    text-align: center;
}
.send-message-section {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}
.send-message-section p {
    color: var(--primary-white);
    text-align: center;
    font-size: 1.8rem;
    font-style: normal;
    line-height: 100%; /* 12px */
}
.light-text {
    font-family: var(--primary-font-light);
}
.medium-text {
    font-family: var(--primary-font-medium);
}
.transmit-data {
    font-family: var(--primary-font-medium);
    color: var(--primary-white);
    margin-top: 1.4rem;
    text-align: center;
    font-size: 1.8rem;
}
</style>
