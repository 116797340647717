<template>
    <main :class="{ ['main-container']: showTutorial }">
        <BackgroundStars />
        <TutorialIconAction
            @handleTutorialIconClick="handleTutorialCompleted"
            :showTutorial="showTutorial"
        />
        <h2>Competences</h2>
        <div class="buttons-wrapper">
            <button
                :class="{ ['is-active']: sortType === 'most' }"
                class="sort-btn"
                @click="handleSort('most')"
            >
                Sort by most achievable competences
            </button>
            <button
                :class="{ ['is-active']: sortType === 'collected' }"
                class="sort-btn"
                @click="handleSort('collected')"
            >
                Sort by acquired competences
            </button>
        </div>
        <section class="main-wrapper">
            <div
                class="single-competence"
                v-for="competence in competencesList"
                :key="competence.id"
            >
                <!-- :style="`width:calc(100% - ${competence.scale}%);
                    height:calc(100% - ${competence.scale}%);background:red; border-radius: 50%;`" -->
                <div
                    class="competence-circle"
                    :style="`width:${competence.scale}px;
                    height:${competence.scale}px`"
                    v-show="competence.total > 0"
                >
                    <div
                        :style="`width:${competenceProgress(competence)}%;
                    height:${competenceProgress(competence)}%`"
                        style=""
                        class="competence-completed"
                    ></div>
                </div>
                <p class="competence-details">
                    {{ competence.name }} <br />
                    <span style="margin-left: 0.5rem">
                        {{ competence.collected }}/ {{ competence.total }}</span
                    >
                </p>
            </div>
        </section>
        <ModalTutorial
            v-if="showTutorial"
            :tutorialArray="tutorialArray"
            @tutorialCompleted="handleTutorialCompleted"
        />
    </main>
</template>

<script>
import axios from "axios";
import { gsap } from "gsap";
import BackgroundStars from "../../components/ui/BackgroundStars.vue";

import { TutorialIconAction, ModalTutorial } from "../../components/";
// tutorial
import {
    checkIsTutorialCompleted,
    tutorialCompleted,
} from "../../helperFunctions/modalChecks";
import { competencesTutorial } from "../../utils/tutorial/constellation-tutorial";
export default {
    components: {
        TutorialIconAction,
        ModalTutorial,
        BackgroundStars,
    },
    data() {
        return {
            competencesList: [],
            competences: [],
            sortType: "most",
            showTutorial: true,
            tutorialArray: competencesTutorial,
        };
    },
    mounted() {
        this.getCompetences();

        this.showTutorial = checkIsTutorialCompleted("competencesTutorial");
    },

    computed: {
        competenceProgress() {
            return (competence) =>
                gsap.utils.clamp(
                    10,
                    100,
                    Math.floor((competence.collected * 100) / competence.total)
                );
        },
        //  console.log(el.total,collected);
    },

    methods: {
        handleSort(type) {
            this.sortType = type;
            this.competencesList = [...this.competences];

            if (this.sortType === "most") {
                this.competencesList.sort((a, b) => b.total - a.total);
            } else if (this.sortType === "collected") {
                this.competencesList.sort((a, b) => b.collected - a.collected);
            }
        },
        handleTutorialCompleted() {
            tutorialCompleted("competencesTutorial");
            this.showTutorial = !this.showTutorial;
        },
        async getCompetences() {
            try {
                let player_id = localStorage.getItem("player__id");

                let game_id = localStorage.getItem("game__id");
                const res = await axios.post(
                    "game/player-game-competences",
                    { player_id, game_id },
                    {
                        headers: {
                            "Accept-Language": this.$i18n.locale,
                        },
                    }
                );

                this.competences = res.data;

                this.competencesList = [...this.competences];
                this.handleSort("most");
                const clamper = gsap.utils.clamp(0, 150);
                this.competencesList.forEach((el) => {
                    let scale = ((100 * el.total) / 150).toFixed();
                    scale = parseFloat(scale * 2);
                    el.scale = clamper(scale * 3 + 50);
                });

                console.log(this.competencesList);
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.main-container {
    overflow: hidden;
}
main {
    padding: 1rem;
}

.main-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.4rem;
    justify-content: space-around;
    align-items: center;
}

.single-competence {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
}

.competence-circle {
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.competence-completed {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: var(--primary-white);
}
.competence-details {
    font-size: 12px;
    text-align: center;
}

.competence-count-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
}
.icon {
    width: 10px;
    height: 10px;
    border: 1px solid var(--primary-white);
    border-radius: 50%;
}

.completed {
    background: var(--green-matrix-2);
    border-color: var(--green-matrix-2);
}
.buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    margin: 1rem auto;
}
.sort-btn {
    width: fit-content;
    background: var(--primary-gray);
    border-radius: 5px;
    border: 1px solid var(--primary-white);
    padding: 0.2rem 0.5rem;
    color: var(--primary-white);
}
.is-active {
    background: var(--primary-white);
    color: var(--primary-gray);
}
</style>
