var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"selection"},[_c('h3',[_vm._v("Languages")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedLang),expression:"selectedLang"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedLang=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.languages),function(lang){return _c('option',{key:lang,domProps:{"value":lang}},[_vm._v(" "+_vm._s(lang)+" ")])}),0),_c('h3',[_vm._v("Art names")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedArt),expression:"selectedArt"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedArt=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.artTitles),function(art,idx){return _c('option',{key:idx,domProps:{"value":art}},[_vm._v(" "+_vm._s(art)+" ")])}),0)]),_c('h2',[_vm._v(" Selected Art: "),_c('br'),_vm._v(" "+_vm._s(_vm.selectedArt)+" ")]),_c('img',{attrs:{"src":_vm.artImage,"alt":""}}),_vm._l((_vm.activitiesList),function(activity){return _c('router-link',{key:activity.activity_name,attrs:{"to":{
            name: 'TestStory',
            params: {
                storyName: activity.activity_name,
                lang: _vm.selectedLang,
            },
        }}},[_c('h3',[_vm._v(" "+_vm._s(activity.activity_name)+" ")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }