<template>
    <svg
        width="110"
        height="47"
        viewBox="0 0 110 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_596_2"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="110"
            height="47"
        >
            <rect width="109.667" height="47" fill="white" />
        </mask>
        <g mask="url(#mask0_596_2)">
            <path
                d="M93.8129 4.7545L100.265 -0.327831L93.7136 -5.48959L98.6372 -12.0807L90.964 -15.3664L94.0114 -22.9998L85.7526 -24.2009L86.7254 -32.3605L78.4368 -31.3777L77.2655 -39.5174L69.513 -36.4204L66.277 -43.9744L59.5865 -38.9814L54.5042 -45.4336L49.3424 -38.8821L42.7513 -43.8057L39.4656 -36.1325L31.8322 -39.1799L30.6311 -30.9211L22.4716 -31.8939L23.4543 -23.6053L15.3146 -22.434L18.4116 -14.6815L10.8576 -11.4355L15.8506 -4.75502L9.39844 0.32731L15.9499 5.48907L11.0264 12.0802L18.6995 15.3659L15.6521 22.9993L23.9109 24.2004L22.9381 32.3599L31.2267 31.3772L32.398 39.5169L40.1506 36.4199L43.3965 43.9739L50.077 38.9809L55.1593 45.4331L60.3211 38.8816L66.9123 43.8051L70.1979 36.132L77.8313 39.1794L79.0324 30.9206L87.192 31.8934L86.2093 23.6048L94.3489 22.4335L91.2519 14.6809L98.8059 11.4449L93.8129 4.7545Z"
                fill="#7E194B"
                stroke="white"
                stroke-width="1.01005"
                stroke-miterlimit="10"
            />
        </g>
    </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
