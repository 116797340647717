<template>
    <main class="main-container">  

        <div v-for="(constellation, key) in constellations" :key="key" :class="['card ', { flipped: constellation.isBack }]">
            
            <div v-if="constellation.isBack" class="card_back" 
                @click="onCardTouched(constellation)">
                <div class="card_back_text" v-html="$t('message.outro_text_' + constellation.name)">
                </div>
                <img :src="constellation.backImage" class="card_back_image">
                <div class="card_back_name">
                    {{ $t('message.outro_name_' + constellation.name) }}
                </div>
            </div>
            <div v-else class="card_front" 
                @click="onCardTouched(constellation)">
                <img :src="constellation.image">
            </div> 
        </div>
    </main>
</template>

<script>
export default {
    name: 'GameDone',
    data() {
        return {
          constellations: [
            { 
              name: 'libra', 
              backImage: require("@/assets/img/outro/back_libra.png"),
              image: require("@/assets/img/outro/img_libra.png"),
              isBack: true,
            },
            {
              name: 'virgo',
              backImage: require("@/assets/img/outro/back_virgo.png"),
              image: require("@/assets/img/outro/img_virgo.png"),
              isBack: true,
            },
            {
              name: 'aquarius',
              backImage: require("@/assets/img/outro/back_aquarius.png"),
              image: require("@/assets/img/outro/img_aquarius.png"),
              isBack: true,
            },
            {
              name: 'gemini',
              backImage: require("@/assets/img/outro/back_gemini.png"),
              image: require("@/assets/img/outro/img_gemini.png"),
              isBack: true,
            },
            {
              name: 'scorpius',
              backImage: require("@/assets/img/outro/back_scorpius.png"),
              image: require("@/assets/img/outro/img_scorpius.png"),
              isBack: true,
            },
            {
              name: 'sagittarius',
              backImage: require("@/assets/img/outro/back_sagittarius.png"),
              image: require("@/assets/img/outro/img_sagittarius.png"),
              isBack: true,
            },
            {
              name: 'pisces',
              backImage: require("@/assets/img/outro/back_pisces.png"),
              image: require("@/assets/img/outro/img_pisces.png"),
              isBack: true,
            },
            {
              name: 'capricorn',
              backImage: require("@/assets/img/outro/back_capricorn.png"),
              image: require("@/assets/img/outro/img_capricorn.png"),
              isBack: true,
            },
            {
              name: 'cancer',
              backImage: require("@/assets/img/outro/back_cancer.png"),
              image: require("@/assets/img/outro/img_cancer.png"),
              isBack: true,
            },
            {
              name: 'aries',
              backImage: require("@/assets/img/outro/back_aries.png"),
              image: require("@/assets/img/outro/img_aries.png"),
              isBack: true,
            }, 
            {
              name: 'taurus',
              backImage: require("@/assets/img/outro/back_taurus.png"),
              image: require("@/assets/img/outro/img_taurus.png"),
              isBack: true,
            },
            {
              name: 'leo',
              backImage: require("@/assets/img/outro/back_leo.png"),
              image: require("@/assets/img/outro/img_leo.png"),
              isBack: true,
            },
          ],
        }
    },
    methods: {
        Range() {
            return Array.from({ length: 10 }, (v, k) => k + 1)
        },
        onCardTouched(constellation) {
            //constellation.isBack != constellation.isBack; 
            this.$set(constellation, 'isBack', !constellation.isBack)
            this.test = !this.test;
        }
    }
}
</script>

<style scoped>
  .container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .main-container  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: var(--black, #000);
    /*perspective: 400px;*/
  }

.card_front {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    border-radius: 15px;
    border: 0.75px dashed var(--white, #FFF);
    background: var(--black, #000);
}
.card_back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    text-align: center;
    border-radius: 15px;
    border: 0.75px dashed var(--white, #FFF);
    background: var(--black, #000); 
    transform: rotateY(180deg);
}

.card_back_text {
    color: var(--white, #FFF);
    text-align: center;
    font-family: "FreightSans Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    width: 400px;
    height: 100px;
    flex-direction: column;
    justify-content: center;
    /*flex-shrink: 0;*/
}
.card_back_name {
    color: var(--white, #FFF);
    text-align: center;
    font-family: "FreightSans Pro";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    width: 400px;
    height: 100px;
    flex-direction: column;
    justify-content: center;
    /*flex-shrink: 0;*/
}


.card {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
    
}
.flipped {
    transform: rotateY(180deg);
}

/* 화면 너비가 400px보다 작으면 */
@media (max-width: 440px) {
    
    .card {
      width: 100vw;
      height: calc(100vw);
    }
    .card_front {
        width: 100%;
        height: 100%;
    }
    .card_back {
        width: 100%;
        height: 100%;
    }
    .card_back_text {
        font-size: 1.41em;
        height: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .card_back_name {
        font-size: 2em;
        height: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .card_back_image {
        width: 50%;
        height: 50%;
    }
}

/*
    .image-container {
      width: 100%;
      height: 2016px;
      position: relative;
    }

    .image-container img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      animation: scrollAnimation 60s linear forwards;
    }

    @keyframes scrollAnimation {
      0% {
        transform: translateY(calc(100vh));
        
        
      }
      100% {
        transform: translateY(calc(-100% + 100vh));
        
      }
    }
*/
  
</style>