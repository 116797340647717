<template>
    <!-- <div class="model-check-number" :style="{ backgroundImage: `url('${art.digital_collection}')` }"> -->
    <div class="model-check-number" :style="bgStyle">

        <div class="close-icon" @click="$emit('closeBtn')">
            <CloseIcon />
        </div>

        <div class="art-info-text">
            <!-- <h2>{{ $t("message.form_art_title") }} {{ inOrAt }}</h2> -->
            <h2>{{ $t("message.form_art_title") }}</h2>
            <h1>{{ art.room }}</h1>
        </div>
        <div class="form-container">
            <p :class="{ showTextCode: IDNumber }" @click="showMeCodeClick">
                {{ $t("message.show_me_code") }}
            </p>
            <form @submit.prevent class="form">
                <input
                    ref="input"
                    type="text"
                    v-model="IDNumber"
                    :placeholder="$t('message.art_id')"
                />
            </form>
            <button
                type="submit"
                @click.prevent="handleSubmit"
                class="btn"
                :disabled="!IDNumber"
            >
                {{ $t("message.continue") }}
            </button>
        </div>
    </div>
</template>

<script>
import { sendLog } from "../../helperFunctions/utils";
import CloseIcon from "../../components/icons/CloseIcon.vue";

export default {
    components: {
        CloseIcon,
    },
    props: {
        selectedStarCode: {
            type: String,
        },
        art: {
            type: Object,
        },
    },
    data() {
        return {
            IDNumber:
                this.art.room.toLowerCase() === "not on display" ||
                this.art.room.toLowerCase() === "on loan"
                    ? this.art.inventory_number
                    : "",
        };
    },

    computed: {
        inOrAt() {
            return this.art.room.toLowerCase() === "digital collection"
                ? "in"
                : "at";
        },

        bgStyle () {
            return {
                // backgroundImage: `url('${this.art.digital_collection}')`,
                backgroundImage: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%, rgba(0,0,0,0) 100%), url('${this.art.digital_collection}')`
                // backgroundPosition: `center 160px`,
            };
        },
    },
    emits: ["closeBtn"],
    methods: {
        showMeCodeClick() {
            this.IDNumber = this.art.inventory_number;
        },
        handleSubmit() {
            if (this.formatCode(this.IDNumber) !== this.formatCode(this.art.inventory_number)) {
                this.IDNumber = this.$i18n.locale == 'en' ? "Wrong code" : "Falscher Code";
                // this.IDNumber = "Wrong code";
                setTimeout(() => {
                    this.IDNumber = "";
                }, 1500);
                sendLog(`wrong code: ${this.art.title}`);
                return;
            }
            sendLog(`correct code: ${this.art.title}`);

            this.$router.push({
                name: "StoryList",
                params: {
                    zodiacName: this.art.constellation,
                    inventoryNumber: this.art.inventory_number,
                },
            });
        },
        formatCode(code) {
            return code.toLowerCase().replace(/\s/g, "");
        },
    },
};
</script>

<style scoped>
.model-check-number {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 300;
    padding: 0;
    overflow: auto;
}

.art-info-text {
    width: 100%;
    font-family: var(--primary-font-light);
    font-style: normal;
    color: var(--primary-white);
    text-align: center;
    /* background-color: var(--primary-black); */
    padding: 2.5rem 2rem;
}
.art-info-text h1 {
    font-size: 4rem;
}
.art-info-text h2 {
    font-size: 2rem;
}
.form {
    width: 100%;
}
.form-container {
    width: 100%;
    justify-content: flex-end;
    padding: 6% 6% 8%;
    gap: 10px;
}
.form-container input {
    width: 100%;
    font-family: var(--primary-font-light);
    background: rgba(0, 0, 0, 0.7);
}
.form-container p {
    font-family: var(--primary-font-light);
    font-size: 2.2rem;
    line-height: normal;
    text-decoration: underline;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}
.showTextCode {
    visibility: hidden;
}
</style>
