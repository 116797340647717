<template>
    <div class="box-page">
        <span @click="$emit('handlePrevPageClick')" class="arrow-btn next">
            <ArrowIcon />
        </span>

        <span @click="$emit('handleNextPageClick')" class="arrow-btn prev">
            <ArrowIcon />
        </span>
        <h3 class="title">{{ $t(`message.${constellation.name}`) }}</h3>
        <p class="constellation-name">
            {{ $t(`message.${constellation.group}`) }}
        </p>
        <div class="content-wrapper">
            <div class="line"></div>
            <div class="img-container">
                <div
                    class="image-link"
                    @click="
                        handleNavigation(
                            constellation.group,
                            constellation.name
                        )
                    "
                >
                    <img :src="image(constellation)" alt="" />
                </div>
                <div v-if="!constellation.is_completed" class="instructions">
                    <p>
                        You have not collected this book yet. <br />Complete the
                        constellation to earn it!
                    </p>

                    <button
                        class="btn"
                        @click="
                            handleNavigation(
                                constellation.group,
                                constellation.name
                            )
                        "
                    >
                        {{ $t("message.play") }}
                    </button>
                </div>
            </div>
            <section class="book-content">
                <div v-if="constellation.is_completed">
                    <p v-html="constellation.book_of_knowledge"></p>
                </div>
                <div v-else><IconPadlock /></div>
            </section>
            <div v-if="!constellation.can_play" class="level-overlay">
                <h2>
                    {{ $t("message.locked_constellation") }} <br />{{
                        constellation.level
                    }}
                </h2>
                <p>
                    {{ $t("message.locked_constellation") }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import IconPadlock from "../icons/IconPadlock.vue";
import ArrowIcon from "../icons/ArrowIcon.vue";
export default {
    components: {
        ArrowIcon,
        IconPadlock,
    },
    props: {
        constellation: {
            type: Object,
        },
    },
    data() {
        return {
            img: require("../../assets/img/tutorials/book.jpeg"),
        };
    },
    computed: {
        image() {
            return (payload) =>
                payload.isComplete ? payload.digital_collection : this.img;
        },
    },
    methods: {
        handleNavigation(group, zodiacName) {
            localStorage.setItem("selectConstellationName", zodiacName); // TODO
            this.$router.push({
                name: "SelectConstellation",
            });
        },
    },
};
</script>

<style scoped>
.box-page {
    height: 100%;
}
.img-container {
    position: relative;
    max-height: 300px;
    width: 100%;
    display: flex;
    border: 0.5px solid var(--primary-white);
    padding: 10px;
}
.img-container img {
    border: 0.5px solid var(--primary-white);
    object-fit: contain;
    width: 100%;
}
.image-link {
    width: 100%;
}
.instructions {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 0.5rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.6);
    /* color: var(--primary-white); */
}
.title {
    margin: 1rem 0 0.5rem 0;
}
.constellation-name {
    text-align: center;
}
.book-content {
    position: relative;
    min-height: 45vh;

    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* margin-bottom: 2rem; */
}
.book-content p {
    font-size: 1rem;
    margin: 0.5rem 0;
}
.book-content svg {
    width: 100px;
    height: 100px;
    margin-top: 2rem;
}

.arrow-btn {
    z-index: 1;
}

.arrow-btn {
    position: absolute;
    top: 30px;
    z-index: 1;
    width: 30px;
    height: 30px;
    display: grid;
    place-content: center;
    border-radius: 50%;
    margin: 0 3rem;
}
.arrow-btn svg {
    height: 20px;
    width: 20px;
    fill: var(--primary-white);
}
.next {
    left: 0;
}
.prev {
    right: 0;
    rotate: 180deg;
}
.content-wrapper {
    position: relative;
}
.level-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    font-size: 2rem;
    text-align: center;
    z-index: 11;
}
</style>
