<template>
    <div class="component-container">
        <section class="paint-details">
            <img class="circle-img" :src="imageActivity" ref="refImg" />
            <span ref="circle" class="circle-drag"></span>
        </section>
        <h4 class="story-title">
            3 - Click a keyword and drag the circle <br />
            to the desired position
        </h4>
        <div class="form-container">
            <div
                class="form-row"
                v-for="(keyword, idx) in keywordList"
                :key="idx"
                :class="{
                    hasData: keyword.position,
                    isSelected: keyword.isSelected,
                }"
                @click="handleClick(keyword)"
            >
                <p>{{ keyword.keyword }}</p>
                <!-- <p>{{ keyword.position }}</p> -->
            </div>
            <button @click="copyValues" :disabled="!canCopy" class="btn">
                {{ copyMessage }}
            </button>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);
export default {
    props: {
        selectedStory: {
            type: Object,
        },
    },
    mounted() {
        this.circle = this.$refs.circle;
        this.draggableFunc();
        this.keywordList = this.selectedStory.rightKeywords.map((keyword) => {
            return {
                ...keyword,
                position: null,
                isSelected: false,
            };
        });
    },

    data() {
        return {
            top: 0,
            left: 0,
            selectedKeyword: {},
            keywordList: [],

            circle: null,
            imageWidth: 0,
            imageHeight: 0,
            artImg: null,
            canCopy: null,

            positionResult: [],
            copyMessage: "Copy keywords position",
        };
    },
    computed: {
        imageActivity() {
            return this.selectedStory.img.startsWith("http")
                ? this.selectedStory.img
                : require(`../../../assets/img/art-img/${this.selectedStory.img}`);
        },
    },
    methods: {
        handleClick(keyword) {
            this.artImg = this.$refs.refImg;
            this.imageWidth = this.artImg.width;
            this.imageHeight = this.artImg.height;

            gsap.set(this.circle, { clearProps: "all" });

            this.selectedKeyword = keyword;
            this.keywordList.forEach((k) => {
                if (k.keyword == keyword.keyword) {
                    k.isSelected = true;
                } else {
                    k.isSelected = false;
                }
            });

            if (keyword.position) {
                let { top, left } = keyword.position;
                let topPosition = Math.floor((top * this.imageHeight) / 100);
                let leftPosition = Math.floor((left * this.imageWidth) / 100);
                gsap.set(this.circle, {
                    translateX: leftPosition,
                    translateY: topPosition,
                });
            }
        },
        copyValues() {
            this.positionResult = this.keywordList.map((item) => {
                let { position, keyword } = item;
                return {
                    position,
                    keyword,
                };
            });

            function copyToClipboard(textToCopy) {
                // navigator clipboard api needs a secure context (https)
                if (navigator.clipboard && window.isSecureContext) {
                    // navigator clipboard api method'
                    return navigator.clipboard.writeText(textToCopy);
                } else {
                    // text area method
                    let textArea = document.createElement("textarea");
                    textArea.value = textToCopy;
                    // make the textarea out of viewport
                    textArea.style.position = "fixed";
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();
                    return new Promise((res, rej) => {
                        // here the magic happens
                        document.execCommand("copy") ? res() : rej();
                        textArea.remove();
                    });
                }
            }

            let clipText = "position: " + JSON.stringify(this.positionResult);

            copyToClipboard(clipText);
            // navigator.clipboard.writeText(clipText);

            this.copyMessage = "Copied";
            setTimeout(() => {
                this.copyMessage = "Copy keywords position";
                alert("Copied to clipboard!");
            }, 500);
        },
        draggableFunc() {
            Draggable.create(this.circle, {
                type: "x,y",
                bounds: ".paint-details",
                onDrag: () => {
                    let transformvaluesToArray =
                        this.circle.style.transform.split("(");
                    let transformvalues2 = transformvaluesToArray[1].split(",");
                    this.left = parseInt(transformvalues2[0]);
                    this.top = parseInt(transformvalues2[1]);
                },
                onDragEnd: () => {
                    let keyword = {
                        top: Math.floor((this.top * 100) / this.imageHeight),
                        left: Math.floor((this.left * 100) / this.imageWidth),
                    };
                    this.selectedKeyword.position = keyword;

                    let counter = 0;
                    this.keywordList.forEach((item) => {
                        if (item.position) {
                            ++counter;
                        }
                    });

                    if (counter === this.keywordList.length) {
                        this.canCopy = true;
                    }
                },
            });
        },
    },
};
</script>

<style scoped>
.component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.paint-details {
    position: relative;
    -webkit-align-self: center;
    align-self: center;
    width: fit-content;
    height: fit-content;
}
.paint-details img {
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 350px;
}

.circle-drag {
    min-height: 0;
    position: absolute;
    border: 4px dotted white;
    background: transparent;
    bottom: 0;
    height: 62px;
    width: 62px;
    border-radius: 50%;
}

.circle-drag {
    top: 0;
    left: 0;
}
.form-container {
    max-width: 300px;
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}
.form-row {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem 0.5rem;
    background: rgba(239, 38, 27, 0.7);
}

.isSelected {
    background: transparent;
    border: 2px solid var(--primary-white);
}

.hasData {
    background: #afbc88;
}
.btn {
    transition: all 0.3s linear;
    font-size: 17px;
}
.btn:disabled {
    opacity: 0.5;
    scale: 0.9;
}
.story-title {
    font-size: 2rem;
}
</style>
