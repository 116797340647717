<template>
    <div class="found-indicator" :class="{ absolute: absolute }">
        <div class="icon">
            <IconKey
                v-if="!isCoreArt"
                :isCompletedOrUnlocked="isFound"
            />
            <IconPadlock
                v-else
                :isCompletedOrUnlocked="counter >= 2"
                :constellation="constellation"
                :isFound="isFound"
            />
        </div>
    </div>
</template>

<script>
import IconKey from "../icons/IconKey";
import IconPadlock from "../icons/IconPadlock";
export default {
    components: {
        IconKey,
        IconPadlock,
    },
    props: {
        constellation: {
            type: String,
        },
        isCoreArt: {
            type: Boolean,
        },
        isFound: {
            type: Boolean,
        },
        counter: {
            type: Number,
        },
        absolute: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>
.found-indicator {
    top: 20px;
    right: 26px;
}
.absolute {
    position: absolute;
}
</style>
